import { mapGetters } from 'vuex'
// components
const Sidebar = resolve => require(["@/components/article/_sidebar.vue"], resolve)
const WordList = resolve => require(["@/components/article/favorites/_word_list.vue"], resolve)
const DictResult = resolve => require(["@/components/article/favorites/_dict_result.vue"], resolve)

export default {
    components: {
        Sidebar,
        WordList,
        DictResult
    },
    data: function() {
        return {
            isRemovedMarkedWord: false,  // 是否取消收藏單字
            isRemoveAllWords: false,

            isSetDataReady: {
                getAllMarkedWordIds: false,  // 取得使用者在此文章下收藏的所有單字 ids
                getFirstActiveWord: false,  // 取得第一個收藏的單字
            },

            renderDictResultKey: 0
        }
    },
    watch: {
        isRemoveAllWords(newValue) {
            if (newValue) {
                this.allMarkedWordIds = [];
            }
        },
        async userInfo(newValue) {
            this.isSetDataReady.getAllMarkedWordIds = false;
            this.isSetDataReady.getFirstActiveWord = false;

            // 已登入 -> 未登入
            if (this.$_.isEmpty(newValue)) {
                this.allMarkedWordIds = [];
            }
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                await this.$store.dispatch('article/updateNoteAndWords', {
                    articleId: this.$route.params.id
                });
            }

            this.getAllMarkedWordIds();
        }
    },
    computed: {
        ...mapGetters({
            isShowCourseInfoBar: 'article/isShowCourseInfoBar'
        }),
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        dictionaryResult: {
            get() {
                return this.$store.state.article.dictionaryResult;
            },
            set(value) {
                this.$store.commit('article/setDictionaryResult', value);
            }
        },
        allMarkedWordIds: {
            get() {
                return this.$store.state.article.allMarkedWordIds;
            },
            set(value) {
                this.$store.commit('article/setAllMarkedWordIds', value);
            }
        },
        tempSortedWordList: {
            get() {
                return this.$store.state.article.tempSortedWordList;
            },
            set(value) {
                this.$store.commit('article/setTempSortedWordList', value);
            }
        }
    },
    created: function() {
        this.getAllMarkedWordIds();
    },
    beforeDestroy: function() {
        this.dictionaryResult = {};
    },
    methods: {
        getAllMarkedWordIds() {
            if (this.$_.isEmpty(this.userInfo)) {
                // 單字照字首 A-Z 排序, 標記星號的單字們排在前面
                let importantWords = [], importantWordIds = [];
                let normalWords = [], normalWordIds = [];

                this.tempSortedWordList.forEach(el => {
                    if (el.isImportant) {
                        importantWords.push(el);
                    } else {
                        normalWords.push(el);
                    }
                });

                importantWords = importantWords.sort((a, b) => {
                    return a.word.localeCompare(b.word);
                });
                importantWordIds = importantWords.map(el => Object.values(el)[0]);  // id

                normalWords = normalWords.sort((a, b) => {
                    return a.word.localeCompare(b.word);
                });
                normalWordIds = normalWords.map(el => Object.values(el)[0]);  // id

                this.allMarkedWordIds = importantWordIds.concat(normalWordIds);

                this.isSetDataReady.getAllMarkedWordIds = true;
                return;
            }

            let params = {
                articleId: this.$route.params.id
            }

            this.$httpRequest.get('/api/article/get_marked_word_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allMarkedWordIds = result;

                            this.isSetDataReady.getAllMarkedWordIds = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
