// common
import Dialogue from "@/components/common/dialogue.vue"
// 2024/02/16 目前先跟"我的最愛"文章列表一起共用

export default {
    props: ['articleId', 'favoriteId'],
    components: {
        Dialogue
    },
    data: function() {
        return {
            favoriteList: [],
            isShowAddClassification: false,
            inputNewClassificationName: '',
            isShowAddFolder: false,
            inputNewFolderName: '',
            checkedFavoriteIds: [],
            allCollectionTotal: 0,

            isSetDataReady: {
                getWordLevels: false
            },
            isPostingApi: {
                saveFavorite: false
            }
        }
    },
    computed: {
        wordLevels() {
            return this.$store.state.article.wordLevels;
        },
        articleBasicInfo: {
            get() {
                return this.$store.state.article.articleBasicInfo;
            },
            set(value) {
                this.$store.commit('article/setArticleBasicInfo', value);
            }
        },
        selectedWordLevel: {
            get() {
                return this.$store.state.article.selectedWordLevel;
            },
            set(value) {
                this.$store.commit('article/setSelectedWordLevel', value);
            }
        },
        articleLevelWords: {
            get() {
                return this.$store.state.article.articleLevelWords;
            },
            set(value) {
                this.$store.commit('article/setArticleLevelWords', value);
            }
        }
    },
    mounted: function () {
        // 一開啟選擇單字程度彈窗時
        $('#favoriteDialogue').on('show.bs.modal', async() => {
            this.favoriteList = [];
            this.checkedFavoriteIds = [];
            await this.getFavroiteList();
            await this.getAllCollection();
            this.isShowAddClassification = false;
            this.isShowAddFolder = false;
        });
    },
    methods: {
        getFavroiteList() {
            return new Promise((resolve, reject) => {
                // 每次pop up打開就要把"所有收藏"勾起來
                this.checkedFavoriteIds.push(0);

                let params = {
                    resType: 0,
                    resId: this.$route.params.id ? this.$route.params.id : this.articleId
                }
                if (!params.resId) {
                    return;
                }

                this.$httpRequest.get('/api/bookmark/get_folders', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for(let item of result) {
                                    let children = [];
                                    if (item.folders.length > 0) {
                                        for(let child of item.folders) {
                                            children.push({
                                                id: child.id,
                                                name: child.name,
                                                no: child.no,
                                                count: child.count,
                                                isChecked: child.isBookmark
                                            })
                                            if (child.isBookmark) {
                                                this.checkedFavoriteIds.push(child.id);
                                            }
                                        }
                                    }
                                    this.favoriteList.push({
                                        id: item.id,
                                        name: item.name,
                                        no: item.no,
                                        isEdit: false,
                                        children: children
                                    })
                                }
                            }
                            resolve();
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getAllCollection() {
            return new Promise((resolve, reject) => {
                let params = {
                    folderId: 0
                }

                this.$httpRequest.post('/api/bookmark/get_bookmark_ids', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allCollectionTotal = result.length;
                            }
                            resolve();
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        showAddClassification() {
            this.isShowAddClassification = true;
            this.inputNewClassificationName = '';
            setTimeout(() => { // 等dom長出來再focus
                this.$refs.addClassificationInput.focus();
            }, 10);
        },
        addClassification() {
            let params = {
                    name: this.inputNewClassificationName
                }

            this.$httpRequest.post('/api/bookmark/add_category', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.favoriteList.unshift({
                                id: result,
                                name: this.inputNewClassificationName,
                                isEdit: false,
                                children: []
                            });
                            this.isShowAddClassification = false;
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showAddFolder(index) {
            if (this.favoriteList[index].children.length > 0 && this.favoriteList[index].children[0].id == '') {
                return;
            }
            this.favoriteList[index].children.unshift({
                id: '',
                name: '',
                count: 0,
                isChecked: false
            })
            this.isShowAddFolder = true;
            this.inputNewFolderName = '';
            setTimeout(() => { // 等dom長出來再focus
                let name = 'addFolderInput' + index;
                this.$refs[name][0].focus();
            }, 10);
        },
        cancelAddFolder(index, key) {
            this.isShowAddFolder = false;
            if (this.favoriteList[index].children[key].id == '') {
                this.favoriteList[index].children.splice(0, 1);
            }
        },
        addFolder(index, key) {
            let params = {
                name: this.inputNewFolderName,
                categoryId: this.favoriteList[index].id
            }

            this.$httpRequest.post('/api/bookmark/add_folder', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.favoriteList[index].children[key] = {
                                id: result,
                                name: this.inputNewFolderName,
                                count: 0,
                                isChecked: false
                            };
                            this.isShowAddFolder = false;
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        save() {
            let params = {
                folderIds: this.checkedFavoriteIds,
                resType: 0,
                resId: this.$route.params.id ? this.$route.params.id : this.articleId,

            }

            if (this.favoriteId && this.favoriteId != '') {
                params.bookmarkId = this.favoriteId;
            }

            this.$httpRequest.post('/api/bookmark/save_bookmark_v2', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (this.articleBasicInfo) {
                            // 在文章用於顯示收藏文章icon是否已收藏
                            this.articleBasicInfo.isBookmark = this.checkedFavoriteIds.length > 0 ? true : false;
                        }
                        if (!this.$route.params.id) {
                            this.$parent.$parent.getInitialData()
                        }
                        $('#favoriteDialogue').modal('hide');
                        this.$store.dispatch('common/setAlert', { msg: this.checkedFavoriteIds.length == 0 ? '取消收藏成功' : response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        setAllfavorite() {
            if (this.$_.find(this.checkedFavoriteIds, 0) != 0) {
                this.checkedFavoriteIds = [];
            }
        },
    }
}
