import AudioRecorder from '@/global/audio_recorder/index.js'
import WaveSurfer from 'wavesurfer.js'
import Microphone from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js'

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)

export default {
    props: {
        recordTime: {  // 錄音時間 (秒)
            required: true,
            type: Number,
        },
    },
    components: {
        Dialogue,
    },
    data: function() {
        return {
            countdownTimerId: 0,
            countdownTime: this.recordTime,
            checkDecibelTime: this.recordTime - 5,
            recordingTimes: 0,
            waveSurfer: null,
            isRecording: false,
            isProcessing: false,
            isExceedDecibel: false,
            errorDialogueId: 'errorDialogue',
        }
    },
    created: function() {
        this.startRecording();
    },
    beforeDestroy: function() {
        clearInterval(this.countdownTimerId);
    },
    methods: {
        async startRecording() {
            try {
                this.isProcessing = true;
                const config = {
                    sampleRate: 16000,
                    channelCount: 1,
                };
                await AudioRecorder.init(config);
                this.isProcessing = false;
                await AudioRecorder.start();
                this.isRecording = true;
                this.recordingTimes++;
                this.countdownTime = this.recordTime;
                this.countdownTimerId = setInterval(this.countdownTimerHandler, 10);
                this.createWaveSurfer();
                if (this.recordingTimes === 1) {
                    this.$emit('startRecording');
                }
            } catch (error) {
                this.handleRecorderError(error);
            }
        },
        async stopRecording() {
            AudioRecorder.stop();
            this.isRecording = false;
            clearInterval(this.countdownTimerId);
            this.recordingTimes = 0;

            if (!this.isExceedDecibel) {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '請檢查麥克風是否正常，再開始朗讀。' });
                this.$emit('stopRecording', '');
                return;
            }

            this.isProcessing = true;
            const file = await AudioRecorder.getFile();
            this.isProcessing = false;
            this.$emit('stopRecording', file);
        },
        checkDecibel() {
            if (this.isExceedDecibel) {
                return;
            }

            // 若因為分貝數未達標準而重新錄音已達 3 次, 則完全停止錄音並恢復為初始未錄音狀態
            if (this.recordingTimes >= 3) {
                this.stopRecording();
                return;
            }

            // 若分貝數未達標準時, 則停止此次錄音並重新開始錄音
            AudioRecorder.stop();
            clearInterval(this.countdownTimerId);
            this.$store.dispatch('common/setAlert', { status: 'danger', msg: '系統未偵測到聲音，請檢查麥克風是否正常。' });
            this.isProcessing = true;
            setTimeout(this.startRecording, 1000);
        },
        countdownTimerHandler() {
            this.countdownTime = this.countdownTime - 0.01;

            const decibel = AudioRecorder.getDecibel();
            // 分貝標準: 15 db
            if (decibel >= 15) {
                this.isExceedDecibel = true;
            }

            // 倒數時間開始前 5 秒
            if (this.countdownTime < this.checkDecibelTime) {
                this.checkDecibel();
            }

            // 倒數時間已結束
            if (this.countdownTime < 0) {
                this.stopRecording();
            }
        },
        createWaveSurfer() {
            if (this.$userAgent.isMac || this.$userAgent.isIpad || this.$userAgent.isIphone) {
                return;
            }

            this.$nextTick(() => {
                const elem = document.getElementById('waveform');
                if (!elem) return;

                this.waveSurfer = WaveSurfer.create({
                    container: elem,
                    height: 80,
                    waveColor: 'rgba(30, 135, 195, 0.5)',
                    cursorWidth: 0,
                    interact: false,
                    hideScrollbar: true,
                    plugins: [Microphone.create()],
                });
                this.waveSurfer.microphone.start();
            });
        },
        handleRecorderError(error) {
            const err = JSON.parse(error);

            if (err.code !== '2') {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: err.msg });
                this.$emit('stopRecording', '');
                return;
            }

            // '2': 未找到麥克風裝置
            $(`#${this.errorDialogueId}`).modal('show');
        },
        errorDialogueLoadedHandler() {
            $(`#${this.errorDialogueId}`).on('hidden.bs.modal', () => {
                this.$emit('stopRecording', '');
            });
        },
    },
}
