import { render, staticRenderFns } from "./_order_item_list.vue?vue&type=template&id=0c98c383&"
import script from "@/assets/js/components/order/index/_order_item_list.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/order/index/_order_item_list.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports