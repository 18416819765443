import { render, staticRenderFns } from "./_favorite_article_dialogue.vue?vue&type=template&id=61f2bfc0&scoped=true&"
import script from "@/assets/js/components/article/read/_favorite_article_dialogue.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/read/_favorite_article_dialogue.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/article/read/_favorite_article.scss?vue&type=style&index=0&id=61f2bfc0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f2bfc0",
  null
  
)

export default component.exports