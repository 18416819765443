// utils
import articleUtil from '@/assets/js/utils/article'

export default {
    props: {
        info: {
            required: true,
            // required keys: name, cover, difficultyLevel, category
            // optional keys: isRead, hasNote, practiceTypes, courseName, publishTime
            type: Object
        },
        mobileStyle: {  // default, list
            type: String,
            default: 'default'
        },
        iconTip: {  // 是否顯示個人行為提示 (已看過、有筆記)
            type: Boolean,
            default: false
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        articleUtil() {
            return articleUtil;
        },
    },
}
