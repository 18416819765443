// common
import Dialogue from "@/components/common/dialogue_client.vue"
// components
const EventInfo = resolve => require(["@/components/event/_event_info.vue"], resolve)

export default {
    props: ['isAllowRegister', 'isCertificateStart', 'individual', 'frontendInfos'],
    components: {
        Dialogue,
        EventInfo
    },
    data: function() {
        return {
            teamIds: [],
            teamList: [],
            listItem: {
                id: '',
                selectedGroup: { name: '' },
                selectedCounty: '',
                selectedDistrict: '',
                inputSchoolName: '',
                selectedGrade: '',
                inputName: ''
            },
            teamArrayIndex: '',

            isPostingApi: {
                adding: false,  // 報名
                editing: false, // 編輯
                downloadCertificate: false
            }
        }
    },
    created: function() {
        this.getRegisterIds();
    },
    methods: {
        getRegisterIds() {
            return new Promise((resolve, reject) => {
            let params = {
                    alias: this.$route.params.alias,
                }

            this.$httpRequest.get('/api/event/get_event_group_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.teamIds = result;
                            this.getRegisterList();
                        }
                        resolve();
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                    reject("event/_my_register.js: 'getRegisterIds' get error");
                });
            });
        },
        getRegisterList() {
            if (this.teamIds.length == 0) {
                return;
            }
            let params = {
                    ids: this.teamIds
                }

            this.$httpRequest.post('/api/event/get_event_group_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            if (!this.$_.isEmpty(result)) {
                                this.teamIds.forEach(id => {
                                    let tempTeam = result[id];
                                    this.teamList.push({
                                        id: id,
                                        name: tempTeam.name,
                                        county: tempTeam.county,
                                        district: tempTeam.district,
                                        schoolName: tempTeam.school_name,
                                        grade: tempTeam.grade,
                                        groupName: tempTeam.ag_name,
                                        count: tempTeam.count,
                                        rank: tempTeam.rank,
                                        groupId: tempTeam.group_id,
                                        isShowMembers: false,
                                        memberIds: [],
                                        memberList: [],
                                        isCertificate: tempTeam.is_certificate
                                    })
                                });
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                });
        },
        getMemberInfo(index) {
            this.teamList[index].isShowMembers = !this.teamList[index].isShowMembers;
            if (!this.teamList[index].isShowMembers || this.teamList[index].memberList.length > 0 || this.teamList[index].count == 0) {
                return;
            }

            let params = {
                    groupId: this.teamList[index].groupId
                }

            this.$httpRequest.post('/api/group/get_group_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.teamList[index].memberIds = result.members;
                            this.getMemberList(index);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                });
        },
        getMemberList(index) {
            let params = {
                    userIds: this.teamList[index].memberIds
                }

            this.$httpRequest.post('/api/group/get_group_member_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            if (!this.$_.isEmpty(result)) {
                                this.teamList[index].memberIds.forEach(id => {
                                    let tempMember = result[id];
                                    this.teamList[index].memberList.push({
                                        id: id,
                                        class: tempMember.class,
                                        classNo: tempMember.classNo,
                                        name: tempMember.name,
                                        account: tempMember.account,
                                    })
                                });
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                });
        },
        showEditEventDialogue(index, team) {
            this.teamArrayIndex = index;
            this.listItem.id = team.id;
            this.listItem.selectedGroup = { id: team.groupId, name: team.groupName };
            this.listItem.selectedCounty = team.county;
            this.listItem.selectedDistrict = team.district;
            this.listItem.inputSchoolName = team.schoolName;
            this.listItem.selectedGrade = team.grade;
            this.listItem.inputName = team.name;

            $('#editEventDialogue').modal('show');
        },
        resetTeamList(info) {
            if (info.name) {
                this.teamList[this.teamArrayIndex].name = info.name;
            }

            if (info.schoolName) {
                this.teamList[this.teamArrayIndex].county = info.county;
                this.teamList[this.teamArrayIndex].district = info.district;
                this.teamList[this.teamArrayIndex].schoolName = info.schoolName;
            }

            if (info.grade) {
                this.teamList[this.teamArrayIndex].grade = info.grade;
            }
        },
        showDeleteEventDialogue(index, teamId) {
            this.teamArrayIndex = index;
            this.listItem.id = teamId;
            $('#deleteEventDialogue').modal('show');
        },
        deleteEvent() {
            let params = {
                    alias: this.$route.params.alias,
                    isRegister: false,
                    groupInfo: {
                        egId: this.listItem.id
                    }
                }

            this.isPostingApi.deleting = true;
            this.$httpRequest.post('/api/event/register_event', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.teamList.splice(this.teamArrayIndex, 1)
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    this.isPostingApi.deleting = false;
                    $('#deleteEventDialogue').modal('hide');
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                    this.isPostingApi.deleting = false;
                    $('#deleteEventDialogue').modal('hide');
                });
        },
        downloadCertificate(team) {
            window.location.href = process.env.VUE_APP_ROOT_API +
            '/api/event/team_certificate?eventId=' + this.$route.params.alias +
            '&groupId=' + team.groupId;
        }
    }
}