import '@/global/markdown/md_preview.css'

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
const ImageLoader = resolve => require(["@/components/common/image_loader.vue"], resolve)

export default {
    props: {
        // v1
        categoryScoreType: {
            type: String,
            default: '0',
        },
        questionList: {
            type: Array,
            default: () => [],
        },
        handInResults: {
            type: Object,
            default: () => {},
        },
        selectedQuestionId: {
            type: String,
            default: '0',
        },
        // v2
        qInfo: {
            type: Object,
            default: () => {},
        },
        firstQuestion: {
            type: Boolean,
            default: false,
        },
        lastQuestion: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Dialogue,
        ImageLoader,
    },
    data: function() {
        return {
            // v1
            activeQuestionIndex: 0,  // 目前正在觀看的題目 index
            isLoadingQuestion: false,

            // common
            audioElem: new Audio(),
            playPromise: null,
            playingAudioInfo: {},
            groupQuestionAudio: new Audio(),
            isPlayingGroupQuesAudio: false,
            mediaElem: null,
            activeReviewTab: 'default',

            // Conversation
            isDemoState: false,
            breathingTimerId: 0,
            breathingRatio: 0,
        }
    },
    watch: {
        'qInfo.id'() {
            this.activeReviewTab = 'default';
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        // v1
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        activeQuestion() {
            return this.questionList[this.activeQuestionIndex];
        },
        isFirstQuestion() {
            return this.activeQuestion ? this.activeQuestionIndex === 0 : this.firstQuestion;
        },
        isLastQuestion() {
            return this.activeQuestion ? this.activeQuestionIndex === (this.questionList.length - 1) : this.lastQuestion;
        },
        questionContentType() {
            if (!this.activeQuestion) return null;

            // 取得題目內容的組成類型
            let type = this.articleUtil.setQuestionContentType(this.activeQuestion.title);

            return type;
        },
        childQuestionContentType() {
            if (!this.activeQuestion) return null;

            // 取得題組子題內容的組成類型
            let type = this.articleUtil.setQuestionContentType(this.activeQuestion.childQuesTitle);

            return type;
        },
        choiceOptionContentTypeInfo() {
            if (!this.activeQuestion) return null;

            // 取得題目各選項的組成資訊
            let info = this.articleUtil.setChoiceOptionContentTypeInfo(this.activeQuestion.options);

            return info;
        },
    },
    created: function() {
        this.isLoadingQuestion = true;
    },
    mounted: function() {
        this.registerAudioEvent();

        this.$nextTick(() => {
            window.addEventListener('resize', this.setAnswerInputFontSizeByInputWidth);
        });
    },
    methods: {
        dialogueLoadedHandler() {
            // 開啟回顧題目內容彈窗後
            $('#reviewQuestionsDialogue').on('shown.bs.modal', () => {
                if (!this.activeQuestion) {
                    this.isLoadingQuestion = false;
                    return;
                }

                // 取得選擇的 題目 id 對應到 questionList 的 index
                this.activeQuestionIndex = this.$_.findIndex(this.questionList, el => {
                    return el.id === this.selectedQuestionId;
                });

                this.initQuestion()
                    .then(() => this.isLoadingQuestion = false);
            });

            // 關閉回顧題目內容彈窗後
            $('#reviewQuestionsDialogue').on('hidden.bs.modal', () => {
                // 停止音檔播放
                this.pauseAudio();
                this.groupQuestionAudio.pause();

                this.isLoadingQuestion = true;
                this.activeReviewTab = 'default';
            });
        },

        // 填充輸入框 DOM 設定
        setAnswerInputFontSizeByInputWidth() {
            this.$util.shrinkFontSizeToFillInputWidth($('.answer-input'), 36, 600);
        },

        // 播放/暫停音檔
        registerAudioEvent() {
            // 註冊音檔播放/結束事件
            this.audioElem.onplaying = () => {
                if (this.mediaElem) {
                    this.mediaElem.pause();
                    this.mediaElem.currentTime = 0;
                }
                this.playingAudioInfo.isPlayingSound = true;
                this.startBreathing();
            };
            this.audioElem.onended = () => {
                this.playingAudioInfo.isPlayingSound = false;
                this.stopBreathing();
            };
        },
        playAudio(audioInfo) {
            $('.lms-btn.play-audio-btn').blur();

            if (audioInfo.isPlayingSound) {
                return;
            }

            this.stopBreathing();

            if (!this.$_.isEmpty(this.playingAudioInfo)) {
                this.playingAudioInfo.isPlayingSound = false;
            }
            this.playingAudioInfo = audioInfo;

            if (audioInfo.url) {
                this.audioElem.src = audioInfo.url;
                if (this.audioElem.paused) {
                    this.playPromise = this.audioElem.play();

                    // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                    if (this.playPromise && this.playPromise !== undefined) {
                        this.playPromise
                            .catch(error => {
                                if (error.name === 'NotSupportedError') {
                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                                }
                                console.error('Catched Error:', error);
                            });
                    }
                }
            } else {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
            }
        },
        pauseAudio() {
            $('.lms-btn.play-audio-btn').blur();

            this.stopBreathing();

            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.playingAudioInfo.isPlayingSound = false;
                    })
                    .catch((error) => {
                        console.error('Catched Error:', error);
                    });
            }
        },
        playGroupQuestionAudio(audioInfo) {
            if (!audioInfo.url) return;

            if (this.mediaElem) {
                this.mediaElem.pause();
                this.mediaElem.currentTime = 0;
            }

            this.groupQuestionAudio.onplaying = () => {
                audioInfo.isPlayingSound = true;
                this.isPlayingGroupQuesAudio = true;
            };
            this.groupQuestionAudio.onpause = () => {
                audioInfo.isPlayingSound = false;
                this.isPlayingGroupQuesAudio = false;
            };
            this.groupQuestionAudio.src = audioInfo.url;

            try {
                this.groupQuestionAudio.volume = 0.5;
                this.groupQuestionAudio.play();
            } catch (error) {
                if (error.name === 'NotSupportedError') {
                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                }
                console.error('Catched Error:', error);
            }
        },

        // 解析媒體
        registerCommentMediaEvent() {
            // 註冊解析媒體(音檔或影片)播放事件
            this.mediaElem = document.getElementById('commentMediaPlayer');
            this.mediaElem.onplaying = () => {
                this.pauseAudio();
                this.groupQuestionAudio.pause();
            };
        },

        initQuestion() {
            return new Promise(resolve => {
                this.isDemoState = false;

                // 重新設置選項/輸入框 DOM & 置頂內容卷軸
                this.$nextTick(() => {
                    this.setAnswerInputFontSizeByInputWidth();

                    $('.review-content').scrollTop(0);

                    resolve();
                });
            });
        },

        changeQuestion(action) {
            if (!this.activeQuestion) {
                this.$emit('changeQuestion', action);
                return;
            }

            this.isLoadingQuestion = true;
            this.pauseAudio();

            switch (action) {
                case 'prev':
                    // 如果當下觀看題目為第一題, 當切換上一題時, 依舊維持第一題
                    if (this.isFirstQuestion) {
                        this.activeQuestionIndex = 0;
                    } else {
                        const currentGroupId = this.activeQuestion.groupId;
                        const prevGroupId = this.questionList[this.activeQuestionIndex - 1].groupId;
                        if (prevGroupId !== currentGroupId) {  // 切換至上一題組
                            this.groupQuestionAudio.pause();
                        }

                        this.activeQuestionIndex--;
                    }
                    break;
                case 'next':
                    // 如果當下觀看題目為最後一題, 當切換下一題時, 依舊維持最後一題
                    if (!this.isLastQuestion) {
                        const currentGroupId = this.activeQuestion.groupId;
                        const nextGroupId = this.questionList[this.activeQuestionIndex + 1].groupId;
                        if (nextGroupId !== currentGroupId) {  // 切換至下一題組
                            this.groupQuestionAudio.pause();
                        }

                        this.activeQuestionIndex++;
                    }
                    break;
            }

            this.initQuestion()
                .then(() => this.isLoadingQuestion = false);
        },

        startBreathing() {
            this.breathingRatio = 0.875;
            this.breathingTimerId = setInterval(this.getRandomRatio, 200);
        },
        stopBreathing() {
            clearInterval(this.breathingTimerId);
        },
        getRandomRatio() {
            const rand = (min, max) => Math.random() * (max - min) + min;
            this.breathingRatio = rand(0.875, 1);
        },
    }
}
