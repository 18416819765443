export default {
    props: {
        id: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        cover: {
            type: String,
            default: '',
        },
        update: {
            type: Object,
            default: () => { return { frequency: '', lastPublish: '' } },
        },
        level: {
            type: Object,
            default: () => { return { applicability: '', difficulty: '' } },
        },
        pinned: {
            type: Boolean,
            default: false,
        },
        pinningIds: {
            type: Object,
            default: () => {},
        },
        progress: {
            type: Object,
            default: () => { return { total: -1, read: -1, practice: -1 } },
        },
        expiredDate: {
            type: String,
            default: '',
        },
    },
}
