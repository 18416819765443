import WebSocket from '@/global/websocket.js'
import '@/global/markdown/md_preview.css'
import { writingTypes, writingStructures, feedbackModes } from '@/assets/js/consts/writing'
import { getRevisedSentences, getGradingResults, getRevisedParagraphs } from '@/assets/js/utils/article_practice'

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
// components
const Header = resolve => require(["@/components/article/practicing/components/header.vue"], resolve)
const ImagePreviewer = resolve => require(["@/components/article/practicing/components/image_previewer.vue"], resolve)

export default {
    props: {
        testedId: {
            type: String,
            default: '',
        },
        question: {
            type: Object,
            default: () => {},
        },
        tempHandedInfo: {
            type: Object,
            default: () => {},
        },
        handedId: {
            type: String,
            default: '',
        },
        headerInfo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Dialogue,
        Header,
        ImagePreviewer,
    },
    data: function() {
        return {
            handinTextLengthLimit: { min: 30, max: 600 },
            timeout: { reviewTime: 180 },
            practicingUrl: location.href,

            writing: {},
            pIds: [],
            paragraphs: {},
            structures: {},
            editingTextSId: '',
            handlingSId: '',
            newStructureCount: 0,
            selectStructureList: [],
            selectedStructureName: '',
            lastWritingText: '',
            practiceTimerId: 0,
            practiceTime: 0,
            tempRecognizeId: '',
            reviewTimerId: 0,
            reviewTime: 0,
            isReviewing: false,
            isReviewTimeout: false,
            websocket: new WebSocket('Review', process.env.VUE_APP_RECOGNIZE_WS_URL, this.getReviewedResult),
            isShowFeedback: true,
            activeFeedbackMode: '',
            previewingImage: '',

            isWritingReady: false,
            isInitialized: false,
            isPostingApi: {
                handInAnswer: false,  // 送出作答
                getReviewedResult: false,  // 取得批改結果
                practiceDone: false,  // 單一題練習結束
            },
        }
    },
    computed: {
        writingTypes() {
            return writingTypes;
        },
        writingTextParagraphs() {
            let paragraphs = [];
            for (const pId of this.pIds) {
                const sIds = this.paragraphs[pId].sIds;
                let texts = [];
                for (const sId of sIds) {
                    texts.push(this.structures[sId].text);
                }
                paragraphs.push(texts.join(' '));
            }
            return paragraphs;
        },
        writingText() {
            return this.writingTextParagraphs.join(' ');
        },
        writingTextLength() {
            return this.writingText.trim() !== '' ? this.writingText.trim().split(/\s+/).length : 0;
        },
        isCheckParagraphFailed() {
            // 每個段落皆至少含有一個結構
            // 每個結構皆有內容
            let paragraphNoStructure = false, structureNoText = false;
            for (const pId of this.pIds) {
                const sIds = this.paragraphs[pId].sIds;
                if (!sIds.length) {
                    paragraphNoStructure = true;
                    break;
                }

                for (const sId of sIds) {
                    if (!this.structures[sId].text.trim()) {
                        structureNoText = true;
                        break;
                    }
                }
            }

            return paragraphNoStructure || structureNoText;
        },
        isEnableReview() {
            // 字數未達最小字數限制或超過最大字數限制
            if ((this.writingTextLength < this.handinTextLengthLimit.min) || (this.writingTextLength > this.handinTextLengthLimit.max)) return false;
            // 段落結構內容檢查
            if (this.isCheckParagraphFailed) return false;

            return true;
        },
        isAllFeedbacksDone() {
            return Object.keys(this.writing.feedbacks).length === Object.keys(this.writing.reviewedFeedbacks).length;
        },
    },
    created: function() {
        this.initWriting();
        this.websocket.connect({
            action: 'register',
            data: { practiceTestedId: this.testedId },
        });
    },
    beforeDestroy: function() {
        this.websocket.close();
    },
    methods: {
        async initWriting() {
            const q = this.question;
            let defaultData = {
                id: q.id,
                text: q.title.text,
                description: q.description,
                images: [],
                relatedWords: q.related_words,
                reference: q.composition_refrence,
            };
            // question images
            for (const image of q.title.file_info.images) {
                defaultData.images.push(image.file_url);
            }
            let variableData = {
                feedbacks: {},
                reviewedFeedbacks: {},
                reReviewedFeedbacks: {},
            };
            // feedback modes
            const modes = q.composition_correction_mechanism.modes;
            for (const mode of Object.keys(feedbackModes)) {
                if (!modes[mode]) continue;

                const feedbackInfo = {
                    name: feedbackModes[mode].tabName,
                    contents: [],
                };
                variableData.feedbacks[mode] = {...feedbackInfo};
            }
            this.writing = {...defaultData, ...variableData};

            this.handinTextLengthLimit.min = q.composition_correction_mechanism.words.least;

            // paragraphs & structures
            const cp = q.composition_paragraph;
            if (cp.style === null) {  // 有限制段落數量
                for (const paragraphType of cp.paragraphs) {
                    const pId = `p${this.pIds.length + 1}`;
                    this.pIds.push(pId);
                    const pInfo = {
                        type: paragraphType,
                        sIds: [],
                    };
                    this.$set(this.paragraphs, pId, pInfo);
                }
            }
            // temp handin
            const handedInfo = this.tempHandedInfo;
            const ac = handedInfo.answer_compositions;
            if (ac) {
                for (let i = 0; i < ac.length; i++) {
                    const p = ac[i];
                    const pId = `p${i + 1}`;
                    const paragraphType = p.style;
                    this.paragraphs[pId].type = paragraphType;
                    for (const s of p.structs) {
                        const sId = `${pId}_${this.paragraphs[pId].sIds.length + 1}`;
                        this.setStructureInfo(pId, sId, s.name, s.text);
                    }
                }
            }
            this.lastWritingText = this.writingText;
            this.tempRecognizeId = this.handedId;

            await this.getReviewedResult(null, ...Object.keys(this.writing.feedbacks));
            this.isInitialized = true;

            this.$nextTick(() => this.$node.resizeTextarea());

            this.practiceTime = 0;
            this.practiceTimerId = setInterval(() => this.practiceTime++, 1000);
        },

        handInAnswer(isTempHandin) {
            clearInterval(this.practiceTimerId);
            this.isReviewing = !isTempHandin;

            // paragraphs & structures
            let compositions = [];
            for (const pId of this.pIds) {
                const _p = this.paragraphs[pId];
                let p = {
                    style: _p.type,
                    structs: [],
                };
                for (const sId of _p.sIds) {
                    const _s = this.structures[sId];
                    const s = {
                        name: _s.name,
                        text: _s.text,
                    };
                    p.structs.push(s);
                }
                compositions.push(p);
            }

            const answer = {
                practiceTestedId: this.testedId,
                questionId: this.writing.id,
                answerOption: {
                    answerContent: '',
                    answerIds: [],
                    answerWords: [],
                    answerCompositions: compositions,
                },
                time: this.practiceTime,
                isTempHandin: isTempHandin,
            };

            let params = new FormData();
            params.append('answer', JSON.stringify(answer));

            if (isTempHandin) {
                this.isPostingApi.handInAnswer = true;
            } else {
                this.reviewTime = 0;
                this.reviewTimerId = setInterval(this.reviewTimerHandler, 1000);
            }

            this.$httpRequest.post('/api/practice/handin', params)
                .then(response => {
                    this.isPostingApi.handInAnswer = false;

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            this.lastWritingText = this.writingText;

                            if (isTempHandin) return;

                            this.tempRecognizeId = result.recognize_id;
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.handInAnswer = false;
                    clearInterval(this.reviewTimerId);
                    this.isReviewing = false;
                    console.error('Catched Error:', error);
                });
        },
        getReviewedResult(data, ...modes) {
            const _handleResult = (results) => {
                // 逐句批改
                if (results['sentence'] !== undefined) {
                    const keyName = 'sentence';
                    // 已使用指定模組重新批改
                    if (results[keyName].retry) {
                        this.$set(this.writing.reReviewedFeedbacks, keyName, 1);
                    }
                    // 已批改完成
                    if (!results[keyName].is_processing) {
                        // 第一個批改回饋項目回覆
                        if (!Object.keys(this.writing.reviewedFeedbacks).length) {
                            this.activeFeedbackMode = keyName;
                            this.isShowFeedback = true;
                        }
                        const originalSentences = results[keyName].result.sentence_revision;
                        this.writing.feedbacks[keyName].contents = getRevisedSentences(originalSentences);
                        this.$set(this.writing.reviewedFeedbacks, keyName, 1);
                    }
                }
                // 總評批改
                if (results['summary'] !== undefined) {
                    const keyName = 'summary';
                    // 已使用指定模組重新批改
                    if (results[keyName].retry) {
                        this.$set(this.writing.reReviewedFeedbacks, keyName, 1);
                    }
                    // 已批改完成
                    if (!results[keyName].is_processing) {
                        // 第一個批改回饋項目回覆
                        if (!Object.keys(this.writing.reviewedFeedbacks).length) {
                            this.activeFeedbackMode = keyName;
                            this.isShowFeedback = true;
                        }
                        const originalResults = results[keyName].result.infos;
                        this.writing.feedbacks[keyName].contents = getGradingResults(originalResults);
                        this.$set(this.writing.reviewedFeedbacks, keyName, 1);
                    }
                }
                // 原文優化
                if (results['optimize'] !== undefined) {
                    const keyName = 'optimize';
                    // 已使用指定模組重新批改
                    if (results[keyName].retry) {
                        this.$set(this.writing.reReviewedFeedbacks, keyName, 1);
                    }
                    // 已批改完成
                    if (!results[keyName].is_processing) {
                        // 第一個批改回饋項目回覆
                        if (!Object.keys(this.writing.reviewedFeedbacks).length) {
                            this.activeFeedbackMode = keyName;
                            this.isShowFeedback = true;
                        }
                        const originalParagraphs = results[keyName].result.revision_results;
                        this.writing.feedbacks[keyName].contents = getRevisedParagraphs(originalParagraphs);
                        this.$set(this.writing.reviewedFeedbacks, keyName, 1);
                    }
                }
            };

            // Api
            if (!data) {
                return new Promise((resolve, reject) => {
                    const params = {
                        ptId: this.testedId,
                        qId: this.writing.id,
                        modes: modes,
                    };

                    this.isPostingApi.getReviewedResult = true;

                    this.$httpRequest.post('/api/practice/get_ai_composition_result', params)
                        .then(response => {
                            this.isPostingApi.getReviewedResult = false;

                            if (response.data.state == 'OK') {
                                if (this.isReviewing && response.data.msg) {
                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: response.data.msg });
                                    resolve();
                                    return;
                                }

                                const result = response.data.result;

                                if (result) {
                                    if (result.is_done === null) {
                                        resolve();
                                        return;
                                    }

                                    this.isReviewing = false;
                                    this.isReviewTimeout = false;
                                    if (!this.activeFeedbackMode) {
                                        this.activeFeedbackMode = Object.keys(this.writing.feedbacks)[0];
                                    }
                                    _handleResult(result);
                                    resolve();
                                }
                            }
                        })
                        .catch(error => {
                            this.isPostingApi.getReviewedResult = false;
                            reject(error);
                        });
                });
            }
            // WebSocket
            else {
                // 註冊連線成功
                if (data.body === 'success') {
                    this.isWritingReady = true;
                }

                // 收到辨識結果
                if (data.ptq_id) {
                    const recognizeId = data.ptq_id;

                    if (recognizeId !== this.tempRecognizeId) {
                        return;
                    }

                    const result = data.result;
                    clearInterval(this.reviewTimerId);
                    this.isReviewing = false;
                    _handleResult(result);
                }
            }
        },
        reReviewAnswer(mode) {
            this.$delete(this.writing.reviewedFeedbacks, mode);
            this.$set(this.writing.reReviewedFeedbacks, mode, 1);

            const params = {
                ptId: this.testedId,
                qId: this.writing.id,
                modes: [mode],
            };

            this.$httpRequest.post('/api/practice/resend_ai_composition', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });
                    }
                })
                .catch(error => {
                    this.$set(this.writing.reviewedFeedbacks, mode, 1);
                    this.$delete(this.writing.reReviewedFeedbacks, mode);
                    console.error('Catched Error:', error);
                });
        },
        reviewTimerHandler() {
            this.reviewTime++;
            if (this.isReviewing && (this.reviewTime === this.timeout.reviewTime)) {
                clearInterval(this.reviewTimerId);
                this.isReviewTimeout = true;
            }
        },
        changeFeedbackMode(mode) {
            this.activeFeedbackMode = mode;
            if (!this.writing.reviewedFeedbacks[mode]) {
                this.getReviewedResult(null, mode);
            }
        },
        setStructureInfo(pId, sId, name = '', text = '') {
            this.paragraphs[pId].sIds.push(sId);
            const sInfo = {
                name: name,
                text: text,
                pId: pId,
            };
            this.$set(this.structures, sId, sInfo);
        },
        changeStructure(pId, sId = '') {
            this.selectStructureList = [];
            this.selectedStructureName = '';
            let _sId = sId;
            if (!_sId) {
                this.newStructureCount++;
                _sId = `ns-${pId}_n${this.newStructureCount}`;
            }
            const structure = this.structures[_sId];
            if (structure) {
                this.selectedStructureName = structure.name;
            }
            setTimeout(() => {
                this.selectStructureList = writingStructures[this.paragraphs[pId].type];
                this.showSelectStructureDialogue(_sId);
            });
        },
        showSelectStructureDialogue(sId) {
            this.handlingSId = sId;
            $('#selectStructureDialogue').modal('show');
        },
        updateStructure() {
            let sId = this.handlingSId;
            const newSId = sId.split('-')[1];
            if (newSId) {
                sId = newSId;
                const pId = newSId.split('_')[0];
                this.setStructureInfo(pId, sId, this.selectedStructureName);
            }
            this.structures[sId].name = this.selectedStructureName;
            this.structures[sId].text = '';
            this.editingTextSId = sId;
            this.$nextTick(() => {
                const elem = document.getElementById(`structure${this.editingTextSId}-field`);
                if (elem) {
                    this.$node.resizeTextarea(elem);
                    elem.focus();
                }
            });
            $('#selectStructureDialogue').modal('hide');
        },
        showDeleteStructureDialogue(sId) {
            this.handlingSId = sId;
            $('#deleteStructureDialogue').modal('show');
        },
        deleteStructure() {
            const sId = this.handlingSId;
            const pId = this.structures[sId].pId;
            const foundIndex = this.paragraphs[pId].sIds.indexOf(sId);
            this.$delete(this.paragraphs[pId].sIds, foundIndex);
            this.$delete(this.structures, sId);
            $('#deleteStructureDialogue').modal('hide');
        },
        zoomInQuestionImage(image) {
            this.previewingImage = image;
            $('#questionImagePreviewer').modal('show');
        },
        showRelatedWordsDialogue() {
            $('#relatedWordsDialogue').modal('show');
        },
        showReferenceDialogue() {
            $('#referenceDialogue').modal('show');
        },
        showReviewDescriptionDialogue() {
            $('#reviewDescriptionDialogue').modal('show');
        },
        changeQuestion() {
            this.websocket.close();

            const params = {
                practiceTestedId: this.testedId,
                questionIds: [this.writing.id],
            };

            this.isPostingApi.practiceDone = true;

            this.$httpRequest.post('/api/practice/ai_question_done', params)
                .then(response => {
                    this.isPostingApi.practiceDone = false;

                    if (response.data.state == 'OK') {
                        this.$emit('changeQuestion');
                    }
                })
                .catch(error => {
                    this.isPostingApi.practiceDone = false;
                    console.error('Catched Error:', error);
                });
        },
    },
}
