import { mapGetters } from 'vuex'
// components
const Sidebar = resolve => require(["@/components/article/_sidebar.vue"], resolve)

export default {
    components: {
        Sidebar
    },
    data: function() {
        return {
            paragraphs: [],  // 文章段落內容

            audioElem: new Audio(),
            playPromise: null,
            isPlaying: false,  // 是否正在播放狀態
            isPaused: false,  // 是否正在暫停狀態
            scrollSpeed: 500,  // 卷軸移動速度
            activePlayingIndex: 0,  // 正在播放的清單 index
            activePlaybackRate: 1,  // 目前播放速度 (預設 1倍速)

            isPlayRepeatedly: false,  // 是否設定循環播放
            isShowRepeatSettingBlock: false,  // 是否開啟循環播放的設定框
            isRepeatSettingItemsExpanded: false,  // 是否正展開循環播放的設定項目
            repeatSettingBlockAniClasses: ['scroll-up-show', 'scroll-down-hide-full', 'scroll-down-hide-part', 'scroll-up', 'scroll-down'],  // 循環播放設定框的動畫 class 列表
            isAnimationPlaying: false,  // 是否正在播放動畫, 若正在播放則 disable 一次/循環播放按鈕
            repeatType: '0',  // 反覆播放類型 (0: 單句 ; 1: 整段)
            repeatTimes: 2,  // 單句重覆次數 (預設 2次, 最多 10次)
            repeatGapSec: 1,  // 單句重覆時間間隔 (預設 1秒, 最多 3秒)
            playedTimes: 0,  // 某一單句目前播放的次數

            isShowChineseTranslation: false,  // 是否顯示中文翻譯
            isPlayedLogAdded: false,  // 是否已紀錄 '播放' 行為 log

            isSetDataReady: {
                getContent: false,  // 取得文章內容
            }
        }
    },
    watch: {
        scrollToTargetOffset() {
            this.$nextTick(() => this.getSentencesDOMOffsetTop());
        },
        userInfo(newValue) {
            // 已登入 -> 未登入
            if (this.$_.isEmpty(newValue)) {
                this.pauseAudio();
            }
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                this.$store.dispatch('article/updateNoteAndWords', {
                    articleId: this.$route.params.id
                });
            }
        }
    },
    computed: {
        ...mapGetters({
            scrollToTargetOffset: 'article/scrollToTargetOffset'
        }),
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        articleData() {
            return this.$store.state.article.articleData;
        },
        articleBasicInfo() {
            return this.$store.state.article.articleBasicInfo;
        },
        listeningSentencesPlayList: {
            get() {
                return this.$store.state.article.listeningSentencesPlayList;
            },
            set(value) {
                this.$store.commit('article/setListeningSentencesPlayList', value);
            }
        },
        playbackRates() {
            return this.$store.state.article.playbackRates;
        },
        repeatTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '單句'
                },
                {
                    value: '1',
                    text: '整段'
                }
            ]
            return opts;
        },
    },
    created: function() {
        this.getData();

        // log
        this.$util.addLog({
            type: 'articleListen',
            articleId: this.$route.params.id
        });
    },
    mounted: function() {
        // 開啟註冊/登入彈窗時
        $('#loginDialogue').on('show.bs.modal', () => {
            this.pauseAudio();
        });

        // 播放時將卷軸滑動到正在播放的詞句 DOM 位置
        this.audioElem.onplaying = () => {
            this.isPlaying = true;
            this.isPaused = false;
            $('html, body').animate({
                scrollTop: this.listeningSentencesPlayList[this.activePlayingIndex].DOMTop - this.scrollToTargetOffset
            }, this.scrollSpeed);
        };
        // 該詞句播放結束後, 則繼續播放下一個詞句音檔
        this.audioElem.onended = () => {
            this.scrollSpeed = 500;
            this.playNextListItem();
        };

        window.addEventListener('scroll', this.articleUtil.bottomToolbarScrollHandler);
    },
    updated: function() {
        this.$nextTick(() => {
            this.getSentencesDOMOffsetTop();
        });
    },
    beforeDestroy: function() {
        this.pauseAudio();  // 離開頁面需停止音檔播放

        window.removeEventListener('scroll', this.articleUtil.bottomToolbarScrollHandler);
    },
    methods: {
        getData() {
            this.isSetDataReady.getContent = false;
            this.paragraphs = this.getParagraphsAndPlayList();
            this.isSetDataReady.getContent = true;
        },

        // 組詞句無進行拆字的段落內容資料, 以及跟讀 tts音檔 的播放清單
        getParagraphsAndPlayList() {
            // API傳回的資料中, 文章內沒有段落內容則直接返回首頁
            if (!this.articleData.paragraph_contents.length) {
                return;
            }

            let paragraphs = [];
            this.listeningSentencesPlayList = [];

            let addSentenceToContent = (tempContents, englishObjs, chineseStr) => {
                if (englishObjs.length && chineseStr) {
                    let addContent = {
                        type: 'sentence',
                        englishSentences: englishObjs,  // 英文內容 + tts音檔
                        chinese: chineseStr  // 中文翻譯
                    }
                    tempContents.push(addContent);
                }
            }

            this.articleData.paragraph_contents.forEach(paragraph => {
                let tempContents = [];
                let englishObjs = [];  // 詞句的英文內容 + tts音檔
                let chineseStr = "";  // 詞句的中文翻譯
                paragraph.contents.forEach(content => {
                    // 詞句
                    if (content.type === 'sentence') {
                        // 詞句內容
                        englishObjs = this.$_.concat(englishObjs, {
                            id: content.apc_id,
                            english: content.english
                        });
                        chineseStr = chineseStr + content.chinese;
                        // 新增項目至詞句播放清單
                        this.listeningSentencesPlayList.push({
                            id: content.apc_id,
                            english: content.english,
                            audioFile: content.tts_audio.file_path  // 跟讀 tts音檔
                        });
                    }
                    // 圖片
                    if (content.type === 'image') {
                        // 如果詞句下一個為圖片, 則將原本已連接好的詞句內容, 新增至段落內容
                        addSentenceToContent(tempContents, englishObjs, chineseStr);
                        englishObjs = [];
                        chineseStr = "";
                        // 新增圖片至段落內容
                        let addContent = {
                            type: 'image',
                            imageFilePath: content.image.file_path,  // 檔案
                            description: content.comment  // 說明
                        }
                        tempContents.push(addContent);
                    }
                });
                // 如果段落最後項目為詞句, 需將原本已連接好的詞句內容, 新增至段落內容
                addSentenceToContent(tempContents, englishObjs, chineseStr);
                englishObjs = [];
                chineseStr = "";

                paragraphs.push({
                    id: paragraph.ap_id,
                    contents: tempContents
                });
            });

            // console.log(paragraphs);

            return paragraphs;
        },

        getSentencesDOMOffsetTop() {
            // 取得各詞句 DOM 位置 的 y軸座標
            let DOMTops = [];
            $('[id^=paragraphSentence]').each(function() {
                DOMTops.push(Math.floor($(this).offset().top));
            });
            if (this.listeningSentencesPlayList.length == DOMTops.length) {
                this.listeningSentencesPlayList.forEach((el, i) => {
                    el.DOMTop = DOMTops[i];
                });
            }
        },
        playAudio() {
            let tempAudioUrl = this.listeningSentencesPlayList[this.activePlayingIndex].audioFile;

            if (tempAudioUrl) {
                if (!this.isPaused) {
                    this.audioElem.src = tempAudioUrl;
                }

                this.audioElem.playbackRate = this.activePlaybackRate;
                if (this.audioElem.paused) {
                    this.playPromise = this.audioElem.play();

                    // log
                    if (!this.isPlayedLogAdded) {
                        this.$util.addLog({
                            type: 'articleListenPlayed',
                            articleId: this.$route.params.id
                        });
                        this.isPlayedLogAdded = true;
                    }

                    // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                    if (this.playPromise && this.playPromise !== undefined) {
                        this.playPromise
                            .catch((error) => {
                                if (error.name === 'NotSupportedError') {
                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                                }
                                console.log('catched error: ' + error);
                            });
                    }
                }
            }
        },
        pauseAudio() {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.isPlaying = false;
                        this.isPaused = true;
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        playNextListItem() {
            let addPlayingIndex = () => {
                this.activePlayingIndex = this.activePlayingIndex >= this.listeningSentencesPlayList.length - 1 ? 0 : this.activePlayingIndex + 1;

                // 最後一句播完後, 依據是否設定循環播放決定是否繼續播放
                if (this.activePlayingIndex === 0) {
                    // 設定循環播放時則需繼續播放第一句
                    if (this.isPlayRepeatedly) {
                        // 卷軸移動回第一句 DOM 位置
                        $('html, body').animate({
                            scrollTop: this.listeningSentencesPlayList[this.activePlayingIndex].DOMTop - this.scrollToTargetOffset
                        }, this.scrollSpeed);

                        this.playAudio();
                    }
                    // 設定一次播放則將卷軸拉到最上方
                    else {
                        this.isPlaying = false;

                        // 卷軸移動回到畫面最上方
                        $('html, body').animate({
                            scrollTop: 0
                        }, this.scrollSpeed);
                    }
                } else {
                    this.playAudio();
                }
            }

            // 循環播放且反覆播放單句
            if (this.isPlayRepeatedly && this.repeatType === '0') {
                // 重覆播放設定的重覆次數
                this.playedTimes++;
                if (this.playedTimes < this.repeatTimes) {
                    // 重覆播放設定的時間間隔
                    setTimeout(() => this.playAudio(), this.repeatGapSec * 1000);
                } else {
                    this.playedTimes = 0;
                    addPlayingIndex();
                }
            } else {
                addPlayingIndex();
            }
        },
        changeSentenceAndPlay() {
            if (this.activePlayingIndex < 0 || this.activePlayingIndex >= this.listeningSentencesPlayList.length) {
                return;
            }

            this.scrollSpeed = 0;
            this.playedTimes = 0;
            this.playAudio();
        },
        changeActivePlaybackRate() {
            let currentIndex = this.$_.indexOf(this.playbackRates, this.activePlaybackRate);

            if (currentIndex >= 0 && currentIndex < this.playbackRates.length - 1) {
                this.activePlaybackRate = this.playbackRates[currentIndex + 1];
            } else {
                this.activePlaybackRate = this.playbackRates[0];
            }

            this.audioElem.playbackRate = this.activePlaybackRate;  // 更換播放速度
        },
        setIsPlayRepeatedly() {
            $('.repeat-setting-block').removeClass(this.repeatSettingBlockAniClasses);

            this.isPlayRepeatedly = !this.isPlayRepeatedly;

            // 一次 -> 循環
            if (this.isPlayRepeatedly) {
                this.isShowRepeatSettingBlock = true;
                this.$nextTick(() => {
                    $('.repeat-setting-block').addClass('scroll-up-show');
                    setTimeout(() => this.isRepeatSettingItemsExpanded = true, 300);
                });
            }
            // 循環 -> 一次
            else {
                let aniClass = this.isRepeatSettingItemsExpanded ? 'scroll-down-hide-full' : 'scroll-down-hide-part';
                $('.repeat-setting-block').addClass(aniClass);
                this.isRepeatSettingItemsExpanded = false;
                setTimeout(() => this.isShowRepeatSettingBlock = false, 600);
            }

            this.isAnimationPlaying = true;
            setTimeout(() => this.isAnimationPlaying = false, 700);
        },
        controlRepeatSettingItems() {
            $('.repeat-setting-block').removeClass(this.repeatSettingBlockAniClasses);

            // 展開 -> 收合
            if (this.isRepeatSettingItemsExpanded) {
                $('.repeat-setting-block').addClass('scroll-down');
                this.isRepeatSettingItemsExpanded = false;
            }
            // 收合 -> 展開
            else {
                $('.repeat-setting-block').addClass('scroll-up');
                setTimeout(() => this.isRepeatSettingItemsExpanded = true, 300);
            }
        },
        clickTranslationToggle() {
            this.isShowChineseTranslation = !this.isShowChineseTranslation;

            // log
            this.$util.addLog({
                type: 'articleTranslate',
                articleId: this.$route.params.id,
                event: this.isShowChineseTranslation ? 'on' : 'off'
            });
        }
    }
}
