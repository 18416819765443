// common
const OrderItemList = resolve => require(["@/components/order/checkout/_order_item_list.vue"], resolve)

export default {
    props: ['ordersPageTab'],
    components: {
        OrderItemList
    },
    data: function() {
        return {
            currentUrl: window.location.href
        }
    },
    computed: {
        isAppWebview() {
            return this.$store.state.common.isAndroidWebview || this.$store.state.common.isiOSWebview;
        },
        isGiftOrder() {
            return this.$store.state.order.isGiftOrder;
        },
        finishedOrderItemList() {
            return this.$store.state.order.finishedOrderItemList;
        }
    }
}
