import WebSocket from '@/global/websocket.js'

// consts
import { punctuationRegexp } from '@/global/consts/regexp'
// components
const SearchDropdown = resolve => require(["@/components/header/_search_dropdown.vue"], resolve)
const Login = resolve => require(["@/components/header/_login.vue"], resolve)

export default {
    components: {
        SearchDropdown,
        Login
    },
    data: function() {
        return {
            recentSearchedList: [],

            recentLearnedCourses: [-1],

            cartItemCourseIds: [],

            websocket: new WebSocket('Notification', process.env.VUE_APP_NOTIFICATION_WS_URL, this.handleNotificationEvents),
            activeNotiTab: 'notification', // 通知tab預設通知欄位
            notificationMsgList: [],
            uuids: [],
            announcementMsgList: [],
            announcementIds: [],
            totalNotificationCount: 0, // 總通知數
            notificationItemsCount: 0, // 通知數
            announcementItemsCount: 0, // 公告數
        }
    },
    watch: {
        userInfo(newValue) {
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                this.getMyCartItemsCount();
                this.getNotificationCount();
                this.websocket.connect({ action: 'getConnID' });
            }
        }
    },
    computed: {
        routePath() {
            return this.$route.path;
        },
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isPointerFine() {
            return this.$store.state.common.isPointerFine;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        isSetUserInfoReady() {
            return this.$store.state.common.isSetUserInfoReady;
        },
        isAdminPage() {
            return this.$store.state.common.isAdminPage;
        },
        headerActiveLink() {
            return this.$store.state.common.headerActiveLink;
        },
        headerChildrenActiveLink() {
            return this.$store.state.common.headerChildrenActiveLink;
        },
        isSetHeaderDataReady: {
            get() {
                return this.$store.state.common.isSetHeaderDataReady;
            },
            set(value) {
                this.$store.commit('common/setIsSetHeaderDataReady', value);
            }
        },
        cartItemsCount: {
            get() {
                return this.$store.state.common.cartItemsCount;
            },
            set(value) {
                this.$store.commit('common/setCartItemsCount', value);
            }
        },
        inputKeyword: {
            get() {
                return this.$store.state.common.inputKeyword;
            },
            set(value) {
                this.$store.commit('common/setInputKeyword', value);
            }
        },
        isShowSearchBar: {
            get() {
                return this.$store.state.common.isShowSearchBar;
            },
            set(value) {
                this.$store.commit('common/setIsShowSearchBar', value);
            }
        },
        orderItemsInfo() {
            return this.$store.state.order.orderItemsInfo;
        },
        courseUtil() {
            return this.$store.state.course.courseUtil;
        },
        cartTotalPrice() {
            let totalPrice = 0;

            for (let el of this.cartItemCourseIds) {
                if (!this.orderItemsInfo[el]) {
                    continue;
                }

                totalPrice += (this.orderItemsInfo[el].order.unit * this.orderItemsInfo[el].order.price);
            }

            return totalPrice;
        },
        tabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'notification',
                    text: '通知列表',
                    notiCount: this.notificationItemsCount
                },
                {
                    value: 'announcement',
                    text: '公告訊息',
                    notiCount: this.announcementItemsCount
                }
            ]
            return tabs;
        },
        // 使用者下拉選單
        userDropdownItemList() {
            let itemArr = [];

            itemArr = [
                // 個人設定
                {
                    name: ['userProfile'],
                    text: `Hi, ${this.userInfo.name}`,
                    url: '/user',
                    isShow: true,
                },
                // 觀看紀錄
                {
                    name: ['userViewHistory'],
                    text: '觀看紀錄',
                    url: '/',
                    isShow: false,
                },
                // 我的優惠券
                {
                    name: ['userCoupons'],
                    text: '我的優惠券',
                    url: '/user/coupons',
                    isShow: true,
                },
                // 訂購紀錄
                {
                    name: ['userOrders'],
                    text: '訂購紀錄',
                    url: '/user/orders',
                    isShow: true,
                },
                // 課程兌換
                {
                    name: ['userGifts'],
                    text: '課程兌換',
                    url: '/user/gifts',
                    isShow: true,
                },
                // 課程兌換
                {
                    name: ['userFavorite'],
                    text: '最愛文章',
                    url: '/user/favorite',
                    isShow: true,
                },
                // 成員管理
                {
                    name: ['userGroup'],
                    text: '班級管理',
                    url: '/user/group',
                    isShow: true,
                },
                // 學習任務
                {
                    name: ['userMission'],
                    text: '學習任務',
                    url: '/user/mission',
                    isShow: true,
                },
                // 活動任務
                {
                    name: ['eventMission'],
                    text: '活動任務',
                    url: '/user/mission?list=event',
                    isShow: this.userInfo.permission === 'user',
                },
                // 學習歷程
                {
                    name: ['userPortfolio'],
                    text: '學習歷程',
                    url: '/user/portfolio',
                    isShow: true,
                },
                // 系統管理
                {
                    name: ['admin'],
                    text: '系統管理',
                    url: this.userInfo.permission === 'admin' ?
                            '/admin/user/list/all' :
                            '/admin/course/list/all',
                    isShow: this.userInfo.permission === 'admin',
                },
                // 縣市數據
                {
                    name: ['countyReport'],
                    text: '縣市報表',
                    url: '/report',
                    isShow: this.userInfo.isAllowCountyReport,
                },
            ]

            for (let el of itemArr) {
                el.isActive = new RegExp(el.name.join("|")).test(this.headerActiveLink);
            }
            itemArr = itemArr.filter(item => item.isShow);

            return itemArr;
        },
        // 系統後臺連結
        adminPageLinkList() {
            let linkArr = [];

            linkArr = [
                // 平台數據
                {
                    name: ['adminDataLog'],
                    text: '平台數據',
                    url: '/admin/data-log/list/all',
                    children: [],
                    isShow: this.userInfo.permission == 'admin'
                },
                // 帳號列表
                {
                    name: ['adminUser'],
                    text: '帳號列表',
                    url: '/admin/user/list/all',
                    children: [],
                    isShow: this.userInfo.permission == 'admin'
                },
                // 課程列表
                {
                    name: ['adminCourse'],
                    text: '課程列表',
                    url: '/admin/course/list/all',
                    children: [],
                    isShow: true
                },
                // 素材列表
                // 暫時註解，等文章這頁有使用需求或一定規劃再開放
                // {
                //     name: ['adminMaterial'],
                //     text: '素材列表',
                //     url: '',
                //     children: [
                //         // 文章
                //         {
                //             name: ['adminMaterialArticle'],
                //             text: '文章',
                //             url: '/admin/article/list/all',
                //             isShow: true
                //         },
                //         // 測驗
                //         {
                //             name: ['adminMaterialExam'],
                //             text: '測驗',
                //             url: '/admin/exam/list/all',
                //             isShow: true
                //         }
                //     ],
                //     isShow: true
                // },
                // 優惠方案
                {
                    name: ['adminCoupon'],
                    text: '優惠方案',
                    url: '',
                    children: [
                        // 體驗券
                        {
                            name: ['adminCouponExperience'],
                            text: '體驗券',
                            url: '/admin/coupon-experience/list/all',
                            isShow: true
                        },
                        // 優訂券
                        {
                            name: ['adminCouponSubscrib'],
                            text: '優訂券',
                            url: '/admin/coupon-subscrib/list/all',
                            isShow: true
                        },
                        // 折扣券
                        {
                            name: ['adminCouponDiscount'],
                            text: '折扣券',
                            url: '/admin/coupon-discount/list/all',
                            isShow: true
                        },
                        // 全站券
                        {
                            name: ['adminCouponAll'],
                            text: '全站券',
                            url: '/admin/coupon-all/list/all',
                            isShow: true
                        },
                        // 合購方案
                        {
                            name: ['adminBuyingBundles'],
                            text: '合購方案',
                            url: '/admin/buying-bundles/list/all',
                            isShow: true
                        }
                    ],
                    isShow: this.userInfo.permission == 'admin'
                },
                // 推播管理
                {
                    name: ['adminPushNotification'],
                    text: '推播管理',
                    url: '/admin/push-notification/list/banner',
                    children: [],
                    isShow: this.userInfo.permission == 'admin'
                }
            ]

            if (this.userInfo.permission == 'admin') {
                if (this.userInfo.adminPermission.length == 0 ||
                    this.userInfo.adminPermission.group_buying == 0 &&
                    this.userInfo.adminPermission.school_buying == 0) {
                    linkArr.push(
                        // 訂單列表
                        {
                            name: ['adminPersonalOrder'],
                            text: '個人訂單',
                            url: '/admin/order/list/all',
                            children: [],
                            isShow: true
                        },
                    )
                }
                else {
                    let orderArr = {
                        name: ['adminOrder'],
                        text: '訂單列表',
                        url: '',
                        children: [
                            // 訂單列表
                            {
                                name: ['adminPersonalOrder'],
                                text: '個人訂單',
                                url: '/admin/order/list/all',
                                children: [],
                                isShow: true
                            },
                        ],
                        isShow: true
                    }

                    if (this.userInfo.adminPermission.school_buying == 1) {
                        orderArr.children.push(
                            // 學校訂單
                            {
                                name: ['adminSchoolBuying'],
                                text: '學校訂單',
                                url: '/admin/school-buying/list/all',
                                children: [],
                                isShow: true
                            }
                        )
                    }

                    if (this.userInfo.adminPermission.group_buying == 1) {
                        orderArr.children.push(
                            // 團購管理
                            {
                                name: ['adminGroupBuying'],
                                text: '團購列表',
                                url: '/admin/group-buying/list/all',
                                children: [],
                                isShow: true
                            }
                        )
                    }

                    linkArr.push(orderArr)
                }
            }

            for (let el of linkArr) {
                el.isActive = new RegExp(el.name.join("|")).test(this.headerActiveLink);
                // children items
                for (let child of el.children) {
                    child.isActive = new RegExp(child.name.join("|")).test(this.headerChildrenActiveLink);
                }
                el.children = el.children.filter(link => link.isShow);
            }
            linkArr = linkArr.filter(link => link.isShow);

            return linkArr;
        }
    },
    created: function() {
        if (!this.$_.isEmpty(this.userInfo)) {
            this.getMyCartItemsCount();
            this.getNotificationCount();
            this.websocket.connect({ action: 'getConnID' });
        }

        let plus_error = this.$cookieStore.getCookie('plus_error');
        if (plus_error) {
            this.$store.dispatch('common/setAlert', { msg: decodeURIComponent(plus_error), status: 'danger' });
            this.$cookieStore.delCookie('plus_error', location.hostname);
        }
    },
    methods: {
        scrollListToTop(listDOM) {
            $(listDOM).scrollTop(0);
        },

        // 搜尋文章
        setRecentSearchedList(payload) {
            this.recentSearchedList = payload;
        },
        focusKeywordInput() {
            $('#keywordInput').focus();
        },
        blurKeywordInput() {
            $('#keywordInput').blur();
        },
        clearInputKeyword() {
            this.inputKeyword = '';
            this.focusKeywordInput();
        },
        search(keyword) {
            if (this.isTextInputEmpty(keyword)) {
                return;
            }

            this.blurKeywordInput();
            this.$router.push(`/search?q=${this.encodeKeywordSigns(keyword)}`).catch(() => {});
        },
        encodeKeywordSigns(keyword) {
            let encoded = keyword.replace(punctuationRegexp, match => {
                return encodeURIComponent(match[0]);
            });

            return encoded;
        },
        isTextInputEmpty(text) {
            if (!text) {
                return true;
            }

            let formattedText = text.replace(/\n|\r\n/g, '').trim();
            return !formattedText ? true : false;
        },
        toggleSearchBar() {  // for "優化前過渡版本 (Mobile)"
            this.isShowSearchBar = !this.isShowSearchBar;

            if (!this.isShowSearchBar) {
                this.$router.push('/').catch(() => {});  // 取消搜尋, 返回首頁
            }
        },

        // 學習中心
        getRecentLearnedCourses() {
            this.recentLearnedCourses = [-1];

            this.$httpRequest.get('/api/course/get_recent_courses')
                .then(response => {
                    this.recentLearnedCourses = [];

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            for (const c of result) {
                                let course = {
                                    alias: c.alias,
                                    name: c.name,
                                    cover: c.cover_url,
                                    updateFrequency: this.courseUtil.getUpdateFrequency(c.update_frequency),
                                };
                                // 學生顯示課程完成進度
                                if (this.userInfo.permission === 'user') {
                                    const totalDone = c.article.read + c.practice.done;
                                    const totalCount = c.article.total + c.practice.total;
                                    course.progress = totalCount ? Math.round(totalDone * 100 / totalCount) : 0;
                                }
                                this.recentLearnedCourses.push(course);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        // 購物車
        getMyCartItemsCount() {
            this.$httpRequest.get('/api/shopping_cart/get_cart_count')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;
                        this.cartItemsCount = +result;
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        async getMyCartData() {
            if (this.isSetHeaderDataReady.getMyCartData) {
                return;
            }

            this.cartItemCourseIds = await this.$store.dispatch('order/getCartItemCourseIds');
            await this.$store.dispatch('order/getCourseBundleCases', { courseIds: this.cartItemCourseIds });

            this.isSetHeaderDataReady.getMyCartData = true;
        },

        // 站內通知
        handleNotificationEvents(data) {
            if (data.event === 'getConnID') { // register dynamodb
                let params = {
                    connID: data.id
                };
                this.$httpRequest.post('/api/notification/register_dynamodb', params)
                    .then(response => {
                        if (response.data.state != 'OK') {
                            console.error('register_dynamodb failed');
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                    });
            }

            if (data.event === 'newMessage') { // new message
                this.getNotificationCount(); // 由於通知是做hover，所以有新訊息只要更新鈴鐺數字，而不用即時插入一筆通知
            }

            if (data.event === 'readAllMessage') { // read all message
                for (let el of this.notificationMsgList) {
                    el.isRead = true;
                }
                for (let el of this.announcementMsgList) {
                    el.isRead = true;
                }
                this.totalNotificationCount = 0;
                this.notificationItemsCount = 0;
                this.announcementItemsCount = 0;
            }

            if (data.event === 'readMessage') { // read all message
                if (data.info && data.info.uuid) {
                    let isNotificationRead = false;
                    for (let el of this.notificationMsgList) {
                        if (data.info.uuid == el.uuid && el.isRead == false) {
                            el.isRead = true;
                            isNotificationRead = true;
                        }
                    }
                    if (isNotificationRead) { // 避免目前點擊的網頁再次-1
                        this.notificationItemsCount--;
                        this.totalNotificationCount--;
                    }
                }
                if (data.info && data.info.announcement_id) {
                    let isAnnouncementRead = false;
                    for (let el of this.announcementMsgList) {
                        if (data.info.id == el.id && el.isRead == false) {
                            el.isRead = true;
                            isAnnouncementRead = true;
                        }
                    }
                    if (isAnnouncementRead) {
                        this.announcementItemsCount--;
                        this.totalNotificationCount--;
                    }
                }
            }
        },
        getNotificationUuids() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/notification/get_notification_ids')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;
                            this.uuids = result;
                            resolve();
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async getNotificationMsgList() {
            this.notificationMsgList = [];
            this.getNotificationCount();
            await this.getNotificationUuids();

            if (this.uuids.length) {
                let params = {
                    uuids: this.uuids
                }

                this.$httpRequest.post('/api/notification/get_notification_infos', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let uuid of this.uuids) {
                                    let n = result[uuid];

                                    if (!n) {
                                        continue;
                                    }

                                    let nInfo = {
                                        uuid: uuid,
                                        title: n.title,
                                        message: n.message,
                                        isRead: n.is_read === '1',
                                        enterdate: n.enterdate,
                                        thumb: n.thumb,
                                        info: n.info
                                    }

                                    if (!nInfo.thumb) {
                                        nInfo.thumb = require(`@/assets/image/notification/${n.info.type}.png`);
                                    }

                                    this.notificationMsgList.push(nInfo);
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Catched Error:', error);
                    });
            }
        },
        getAnnouncementIds() { // 公告ID
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/announcement/get_announcement_ids')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;
                            this.announcementIds = result;
                            resolve();
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async getAnnouncementMsgList() { // 公告
            this.announcementMsgList = [];
            await this.getAnnouncementIds();

            if (this.announcementIds.length) {
                let params = {
                    announcementIds: this.announcementIds
                }
                this.$httpRequest.post('/api/announcement/get_announcement_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;
                            if (!this.$_.isEmpty(result)) {
                                this.announcementIds.forEach(id => {
                                    let tempAnnouncement = result[id];
                                    if (tempAnnouncement) {
                                        this.announcementMsgList.push({
                                            id: id,
                                            title: tempAnnouncement.title,
                                            message: tempAnnouncement.content,
                                            type: tempAnnouncement.type,
                                            isRead: tempAnnouncement.is_read == 1 ? true : false,
                                            enterdate: tempAnnouncement.enterdate,
                                            imgSrc: tempAnnouncement.file && tempAnnouncement.file.file_name ? tempAnnouncement.file.file_name : '',
                                            resource: tempAnnouncement.resource
                                        });
                                    }
                                });
                            }
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        getNotificationCount() { // 取得通知數
            this.$httpRequest.get('/api/notification/get_not_read_count')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;
                        this.totalNotificationCount = result.notification + result.announcement;
                        this.notificationItemsCount = result.notification;
                        this.announcementItemsCount = result.announcement;
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        setTimeSince(time) {
            time = +time;
            let seconds = Math.floor(Date.now() / 1000 - time);  // Date.now() --> milliseconds

            // __周前
            let interval = seconds / 604800;
            if (interval >= 1) {
                return Math.floor(interval) + " 周前";
            }
            // __天前
            interval = seconds / 86400;
            if (interval >= 1) {
                return Math.floor(interval) + " 天前";
            }
            // __小時前
            interval = seconds / 3600;
            if (interval >= 1) {
                return Math.floor(interval) + " 小時前";
            }
            // __分鐘前
            interval = seconds / 60;
            if (interval >= 1) {
                return Math.floor(interval) + " 分鐘前";
            }

            return Math.floor(seconds) + " 秒前";
        },
        changeTab(val) {
            this.activeNotiTab = val;
        },
        readMessage(item) {
            let params = {};

            if (this.activeNotiTab == 'notification') {
                params.uuid = item.uuid;
                let isNotificationRead = false;
                for (let el of this.notificationMsgList) {
                    if (item.uuid == el.uuid && el.isRead == false) {
                        el.isRead = true;
                        isNotificationRead = true;
                    }
                }
                if (isNotificationRead) {
                    this.notificationItemsCount--;
                    this.totalNotificationCount--;
                }

                let type = item.info.type;
                switch (type) {
                    case 'course':
                    case 'article': {
                        this.$httpRequest.get('/api/course/get_basic_info', {
                            courseId: item.info.course_id
                        })
                            .then(response => {
                                if (response.data.state == 'OK') {
                                    let result = response.data.result;

                                    let courseInfo = result.course_info;
                                    if (courseInfo.state != 3) {
                                        this.$store.dispatch('common/setAlert', { msg: type == 'article' ? '文章已下架' : '課程已下架', status: 'danger' });
                                    }
                                    else {
                                        this.$router.push(type == 'article' ? `/article/${item.info.article_id}` : `/course/${courseInfo.alias}`).catch(() => {});
                                    }
                                }
                            })
                            .catch(error => {
                                console.error('Catched Error:', error);
                            });
                        break;
                    }
                    case 'mission': {
                        this.$router.push(`/user/mission?list=assigned&m=${item.info.mission_id}`).catch(() => {});
                        break;
                    }
                }
            }

            if (this.activeNotiTab == 'announcement') {
                params.announcementId = item.id;
                let isAnnouncementRead = false;
                for (let el of this.announcementMsgList) {
                    if (item.id == el.id && el.isRead == false) {
                        el.isRead = true;
                        isAnnouncementRead = true;
                    }
                }
                if (isAnnouncementRead) {
                    this.announcementItemsCount--;
                    this.totalNotificationCount--;
                }

                let type = item.type;
                if (type == '1' && item.resource.resource_id) {
                    this.$httpRequest.get('/api/course/get_basic_info', {
                        courseId: item.resource.resource_id
                    })
                        .then(response => {
                            if (response.data.state == 'OK') {
                                let result = response.data.result;

                                let courseInfo = result.course_info;
                                if (courseInfo.state != 3) {
                                    this.$store.dispatch('common/setAlert', { msg: '課程已下架', status: 'danger' });
                                }
                                else {
                                    this.$router.push(`/course/${courseInfo.alias}`);
                                }
                            }
                        })
                        .catch(error => {
                            console.error('Catched Error:', error);
                        });
                }
                if (type == '2') {
                    this.$httpRequest.post('/api/coupon/user_get_coupon', {
                        code: item.resource.code, courseIds: []
                    })
                        .then(response => {
                            if (response.data.state == 'OK') {
                                this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                            }
                        })
                        .catch(error => {
                            console.error('Catched Error:', error);
                        });
                }
            }

            this.$httpRequest.get('/api/notification/read_message', params)
                .catch(error => {
                    console.error('Catched Error:', error);
                });

        },
        readAllMessage() {
            for (let el of this.notificationMsgList) {
                el.isRead = true;
            }
            for (let el of this.announcementMsgList) {
                el.isRead = true;
            }
            this.totalNotificationCount = 0;
            this.notificationItemsCount = 0;
            this.announcementItemsCount = 0;
            this.$httpRequest.get('/api/notification/read_all_message')
                .then(response => {
                    if (response.data.state == 'OK') {
                        console.log('Read all message success.');
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        // 註冊/登入
        showLoginDialogue() {
            $('#loginDialogue').modal('show');
        },
        logout() {
            this.$store.dispatch('common/logout');
            this.$router.push('/').catch(() => {});
        },
    }
}
