// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
const CourseList = resolve => require(["@/components/user/portfolio/_course_list.vue"], resolve)
const ArticleList = resolve => require(["@/components/user/portfolio/_article_list.vue"], resolve)
const MissionList = resolve => require(["@/components/user/portfolio/_mission_list.vue"], resolve)
const EventList = resolve => require(["@/components/user/portfolio/_event_list.vue"], resolve)
const PortfolioContent = resolve => require(["@/components/user/portfolio/_portfolio_content.vue"], resolve)

export default {
    components: {
        Dialogue,
        MobileUnsupported,
        CourseList,
        ArticleList,
        MissionList,
        EventList,
        PortfolioContent,
    },
    data: function() {
        return {
            selectedType: '',
            selectedCourse: { id: '', name: ''},
            selectedAIds: [],  // a: article
            selectedMIds: [],  // m: mission
            selectedEvent: { id: '', name: ''},
            isRenderList: {},

            groupCount: null,
            isGettingGroupCount: false,
            portfolioTitle: '',
            getPortfolioCount: 0,
            isGettingPortfolio: false,
        }
    },
    watch: {
        windowWidth() {
            this.setContainerHeight();
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        typeOptions() {
            let aryOpts = [];
            aryOpts = [
                {
                    value: 'course',
                    text: '課程',
                    isShow: true,
                },
                {
                    value: 'mission',
                    text: '任務',
                    isShow: true,
                },
                {
                    value: 'event',
                    text: '活動',
                    isShow: true,
                },
            ];
            aryOpts = aryOpts.filter((opt) => opt.isShow);
            for (const opt of aryOpts) {
                this.$set(this.isRenderList, opt.value, false);
            }

            return aryOpts;
        },
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userPortfolio');
    },
    mounted: function() {
        this.setContainerHeight();
    },
    methods: {
        setContainerHeight() {
            let elem = document.querySelector('.portfolio-container');

            if (!elem) {
                return;
            }

            let top = this.$node.getElementTop(elem);
            elem.style.height = `calc(100 * var(--vh) - ${top + 20}px)`;  // 20 for margin-bottom
        },

        selectType(type) {
            this.selectedType = type;

            setTimeout(() => {
                $(`#${this.selectedType}Dropdown`).on('show.bs.dropdown', () => {
                    this.isRenderList[this.selectedType] = true;
                });
            });
        },
        clearType() {
            this.selectedType = '';
            Object.keys(this.selectedCourse).forEach((key) => this.selectedCourse[key] = '');
            this.selectedAIds = [];
            this.selectedMIds = [];
            Object.keys(this.selectedEvent).forEach((key) => this.selectedEvent[key] = '');
            Object.keys(this.isRenderList).forEach((key) => this.isRenderList[key] = false);
        },
        selectCourse(course) {
            this.selectedCourse = course;
            this.selectedAIds = [];
            setTimeout(() => {  // 自動開啟文章下拉選單
                $('#articleDropdownToggle').dropdown('show');
            });
        },

        getGroupCount() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/group/get_group_count')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                resolve(+result);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async getPortfolio() {
            if (this.userInfo.permission === 'teacher') {
                // 取得自建班級數量
                if (this.groupCount === null) {
                    this.isGettingGroupCount = true;
                    this.groupCount = await this.getGroupCount();
                    this.isGettingGroupCount = false;
                }

                // 若無班級則需導引使用者前往 '班級管理' 頁面
                if (!this.groupCount) {
                    $('#getFailedDialogue').modal('show');
                    return;
                }
            }

            switch (this.selectedType) {
                case 'course': {
                    this.portfolioTitle = this.selectedCourse.name;
                    break;
                }
                case 'mission': {
                    this.portfolioTitle = '任務';
                    break;
                }
                case 'event': {
                    this.portfolioTitle = this.selectedEvent.name;
                    break;
                }
            }
            this.getPortfolioCount++;
            this.isGettingPortfolio = true;
        },
    },
}
