import '@/global/markdown/md_preview.css'

// common
const ImageLoader = resolve => require(["@/components/common/image_loader.vue"], resolve)

export default {
    /**
     * 一般分類(單/多選題)
     *
     * @param  number  questionContentType  題目內容的組成類型
     * 題目組成只有一個成分(音檔、文字、圖片)時, 上下左右置中於題目區塊內
     * 題目組成含有多個成分時, 距離上方 60px 後開始顯示內容
     *
     * @param  number  choiceOptionContentTypeInfo  各選項內容的組成資訊
     * 選項組成若含有圖片, 則雙欄 2列顯示 ; 若只有文字, 則單欄 4列顯示
     * 如果為單欄 4列顯示, 則選項標號上下置中
     */
    props: [
        'activeQuestion',
        'isLastQuestion',
        'questionContentType',
        'choiceOptionContentTypeInfo',
        'minOptionsCount',
        'isShowHandinBtn',
        'isPostingApi',
        'handInResult',
        'isShowHandInFeedback',
        'isCommentCardExpanded'
    ],
    components: {
        ImageLoader
    },
    computed: {
        practiceQuestionInfo() {
            return this.$store.state.article.practiceQuestionInfo;
        },
    },
    mounted: function() {
        this.initQuestion();

        // resize events
        window.addEventListener('resize', this.$parent.setQuestionTextItemJustifyContent);
        window.addEventListener('resize', this.$parent.setQuestionImageItemMaxWidth);
        window.addEventListener('resize', this.$parent.setOptionListAndItemHeight);
        window.addEventListener('resize', this.$parent.setOptionTextItemStyle);
        window.addEventListener('resize', this.$parent.setOptionImageItemMaxWidth);
    },
    beforeDestroy: function() {
        // resize events
        window.removeEventListener('resize', this.$parent.setQuestionTextItemJustifyContent);
        window.removeEventListener('resize', this.$parent.setQuestionImageItemMaxWidth);
        window.removeEventListener('resize', this.$parent.setOptionListAndItemHeight);
        window.removeEventListener('resize', this.$parent.setOptionTextItemStyle);
        window.removeEventListener('resize', this.$parent.setOptionImageItemMaxWidth);
    },
    methods: {
        initQuestion() {
            this.$nextTick(() => {
                this.$parent.setQuestionTextItemJustifyContent();
                this.$parent.setQuestionImageItemMaxWidth();
                this.$parent.setOptionListAndItemHeight();
                this.$parent.setOptionTextItemStyle();
                this.$parent.setOptionImageItemMaxWidth();
            });
        }
    }
}
