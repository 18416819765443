import { mapGetters } from 'vuex'

export default {
    watch: {
        isMarkWordSuccess(newValue) {
            if (newValue) {
                setTimeout(() => this.isMarkWordSuccess = false, 400);
            }
        }
    },
    computed: {
        ...mapGetters({
            isShowCourseInfoBar: 'article/isShowCourseInfoBar'
        }),
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        courseBasicInfo() {
            return this.$store.state.course.courseBasicInfo;
        },
        articleBasicInfo() {
            return this.$store.state.article.articleBasicInfo;
        },
        fromCategory() {
            return this.$store.state.article.fromCategory;
        },
        isMarkWordSuccess: {
            get() {
                return this.$store.state.article.isMarkWordSuccess;
            },
            set(value) {
                this.$store.commit('article/setIsMarkWordSuccess', value);
            }
        },
        sidebarList() {
            let list = [];

            list = [
                {
                    text: '文章閱讀',
                    page: 'read',
                    isShow: true
                },
                {
                    text: '文章聽讀',
                    page: 'listen',
                    isShow: this.articleBasicInfo.type === '0'
                },
                {
                    text: '內容講解',
                    page: 'teach',
                    isShow: this.articleBasicInfo.isTeachShow
                },
                {
                    text: '題目練習',
                    page: 'practice',
                    isShow: this.articleBasicInfo.isPracticeShow
                },
                {
                    text: '單字收藏',
                    page: 'favorites',
                    isShow: this.articleBasicInfo.type === '0'
                }
            ]

            list.forEach(el => {
                if (!this.$route.params.page && el.page === 'read') {
                    el.isActive = true;
                } else {
                    el.isActive = el.page === this.$route.params.page;
                }
            });
            list = list.filter(item => item.isShow);

            return list;
        }
    }
}
