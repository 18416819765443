export default {
    props: {
        isDeletingCourse: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        courseUtil() {
            return this.$store.state.course.courseUtil;
        },
        orderItemsInfo() {
            return this.$store.state.order.isGiftOrder ? this.$store.state.order.giftOrderItemsInfo : this.$store.state.order.orderItemsInfo;
        },
        orderItemCourseIds() {
            return this.$store.state.order.orderItemCourseIds;
        }
    }
}
