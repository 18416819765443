import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
// common
import Dialogue from "@/components/common/dialogue.vue"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import '@/assets/scss/vendors/vue2-datepicker.scss'
// components
const MarkdownEditor = resolve => require(["@/components/common/markdown/editor.vue"], resolve)
const MarkdownPreviewer = resolve => require(["@/components/common/markdown/previewer.vue"], resolve)

export default {
    props: ['courseBasicInfo'],
    components: {
        Dialogue,
        DatePicker,
        MarkdownEditor,
        MarkdownPreviewer
    },
    data: function() {
        return {
            activeTab: 'info',

            courseInfo: null,
            oldCourseInfo: null,
            groups: [],

            uploadErrorMsg: {
                cover: null,
            },
            aliasError: false,
            urlError: false,

            tempUploadFileName: '',
            coverCropper: null,
            isSettingCropper: {
                cover: false
            },

            isSetDataReady: {
                getCourseInfo: false  // 取得課程資訊
            }
        }
    },
    computed: {
        host() {
            return process.env.VUE_APP_HOST;
        },
        editInfoTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'info',
                    text: '基本資料'
                },
                {
                    value: 'intro',
                    text: '內容簡介'
                },
                {
                    value: 'witness',
                    text: '好評口碑'
                }
            ]
            return tabs;
        },
        // contentTypeOptList() {
        //     let opts = [];
        //     opts = [
        //         {
        //             value: '0',
        //             text: '英文'
        //         }
        //     ]
        //     return opts;
        // },
        purchaseTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '訂閱'
                },
                {
                    value: '1',
                    text: '購買'
                }
            ]
            return opts;
        },
        updateFrequencyOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '無',
                },
                {
                    value: '1',
                    text: '每日',
                },
                {
                    value: '2',
                    text: '每周',
                },
                {
                    value: '3',
                    text: '每月',
                },
            ]
            return opts;
        },
        suitableTargetOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '國小'
                },
                {
                    value: '1',
                    text: '國中'
                },
                {
                    value: '2',
                    text: '高中'
                }
            ]
            return opts;
        },
        difficultyLevelOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '初級'
                },
                {
                    value: '1',
                    text: '中級'
                },
                {
                    value: '2',
                    text: '中高級'
                },
                {
                    value: '3',
                    text: '高級'
                },
                {
                    value: '4',
                    text: '優級'
                }
            ]
            return opts;
        },
        updateCourseData() {
            if (!this.isSetDataReady.getCourseInfo) {
                return;
            }

            let updateData = {};

            let excludeKeys = ['cover'];  // 排除檔案
            // 以下比對物件差異方式適用於物件只有一層結構, 不適用兩層結構以上
            updateData = this.$_.omitBy(this.$_.omit(this.courseInfo, excludeKeys), (v, k) => {
                return this.oldCourseInfo[k] == v;
            });
            // 基本資料 - 課程封面
            if (this.oldCourseInfo.cover.path != this.courseInfo.cover.path) {
                // 新增封面檔案
                if (this.courseInfo.cover.path) {
                    updateData.cover = this.courseInfo.cover;
                }
                // 移除封面檔案
                if (this.oldCourseInfo.cover.path && !this.courseInfo.cover.path) {
                    updateData.deleteCoverFile = '1';
                }
            }

            if (updateData.issueNoDate) {
                updateData.issueNoDate = this.$util.datetimeToUnixTimestamp(updateData.issueNoDate);
            }

            updateData.saveType = 'info';  // 指定儲存類型

            return updateData;
        },
        isEditable() {
            // 是否可以編輯課程資料項目, 條件如下:
            // 1. 課程狀態為"編輯中"
            // 2. 使用者權限為 admin 時, "待審核"狀態還可編輯以上項目
            return this.courseBasicInfo.state == '0' ||
                    (this.$store.state.common.userInfo.permission == 'admin' && this.courseBasicInfo.state == '1');
        }
    },
    created: function() {
        this.initialize();
    },
    mounted: function() {
        // 關閉裁剪課程封面圖片彈窗後, 銷毀並初始 coverCropper
        $('#cropCoverImageDialogue').on('hidden.bs.modal', () => {  // 裁剪課程封面圖片
            if (!this.$_.isEmpty(this.coverCropper)) {
                this.coverCropper.destroy();
                this.coverCropper = null;
            }
            $('#cropCoverImageDialogue').off('shown.bs.modal');  // 銷毀已註冊的事件, 防止重複註冊
        });
    },
    beforeDestroy: function() {
        this.$store.commit('adminCourse/setIsInputFormatError', false);
    },
    methods: {
        initialize() {
            let refreshCourseInfo = () => {
                this.$parent.isFinishUpdatingData = false;
                this.isSetDataReady.getCourseInfo = false;
                this.oldCourseInfo = null;

                this.getCourseInfo()
                    .then(() => {
                        this.isSetDataReady.getCourseInfo = true;

                        // 監聽編輯內容是否更動
                        this.$watch('updateCourseData', newValue => {
                            this.$store.commit('adminCourse/setUpdateCourseData', newValue);
                            this.$store.commit('adminCourse/setIsShowNotSaveCoursePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                        });
                        // 編輯課程名稱, title bar 內的課程名稱需跟著改變
                        this.$watch('courseInfo.name', newValue => this.$store.commit('adminCourse/changeCourseName', newValue));
                        // 輸入框是否輸入格式錯誤
                        this.$watch('aliasError', newValue => {
                            this.$store.commit('adminCourse/setIsInputFormatError', newValue);
                        });
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            refreshCourseInfo();

            // 當成功更新課程資訊後, 則重新取得課程資訊
            this.$watch('$parent.isFinishUpdatingData', newValue => {
                if (newValue) {
                    // 將更新過後的課程購買類型, assign 到 courseBasicInfo.purchaseType
                    this.$store.commit('adminCourse/changeCoursePurchaseType', this.courseInfo.purchaseType);
                    refreshCourseInfo();
                }
            });
        },

        getCourseInfo() {
            if (!this.$route.params.id) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseId: this.$route.params.id
                }

                this.$httpRequest.get('/admin_api/course/get_course_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.courseInfo = {
                                    // 基本資料
                                    name: result.name,
                                    subName: result.sub_name,
                                    contentType: result.content_type,
                                    provider: result.provider,
                                    isPreOrder: result.is_pre_order,
                                    groupId: result.course_group_id,
                                    issueNo: result.issue_no,
                                    issueNoDate: this.$util.unixTimestampToDatetime(result.issue_no_date, this.$util.getBrowserCurrentTimeZone(), 'date'),
                                    purchaseType: result.purchase_type,
                                    updateFrequency: result.update_frequency,  // 購買類型為訂閱時, 有"更新頻率"
                                    alias: result.alias === this.$route.params.id ? '' : result.alias,
                                    // meta data
                                    metaTitle: result.meta_title,
                                    metaDescription: result.meta_description,
                                    cover: {
                                        name: null,
                                        path: null,
                                        file: null
                                    },
                                    suitable: result.suitable,
                                    difficultyLevel: result.difficulty_level,  // 內容類型為英文時, 有"等級程度"
                                    // 內容簡介
                                    introduction: result.introduction,
                                    // 好評口碑
                                    witness: result.witness,
                                    // 創造栗外部網址
                                    externalLink: result.external_link
                                }
                                // 課程封面
                                if (!this.$_.isEmpty(result.cover)) {
                                    this.courseInfo.cover.name = result.cover.original_name;
                                    this.courseInfo.cover.path = result.cover.file_name;
                                }
                                // 設定"課程類型"下拉選單資料
                                this.groups = result.groups;

                                // 暫存原本的課程資料, 以用來判斷是否enable儲存按鈕
                                this.oldCourseInfo = this.$_.cloneDeep(this.courseInfo);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        onChangeIsPreOrder() {
            if (this.courseInfo.isPreOrder) {
                this.courseInfo.purchaseType = '1';
            } else {
                this.courseInfo.purchaseType = '0';
                this.courseInfo.updateFrequency = '1';
            }
        },
        issueNoInputValid(value) {
            return value.replace(/[^0-9]/g, '');
        },
        uploadCoverFile() {
            this.uploadErrorMsg.cover = null;
            this.tempUploadFileName = '';

            let tempUploadFile = this.$refs.uploadCover.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {
                this.tempUploadFileName = tempUploadFile.name;

                // 鎖住點擊非彈窗區域關閉彈窗, 避免拉大裁剪框時而關閉彈窗
                $('#cropCoverImageDialogue').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                this.isSettingCropper.cover = true;

                $('#cropCoverImageDialogue').on('shown.bs.modal', () => {
                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = () => {
                        let dataURL = reader.result;
                        document.querySelector('#cropCoverImg').src = dataURL;
                        const image = document.getElementById('cropCoverImg');
                        // 建立cropper實例
                        this.coverCropper = new Cropper(image, {
                            aspectRatio: 16 / 9,
                            viewMode: 3,
                            dragMode: 'move',
                        });
                        this.isSettingCropper.cover = false;
                    }
                });
            }

            this.$refs.uploadCover.value = null;
        },
        cropCourseCoverImage() {
            this.courseInfo.cover.name = this.tempUploadFileName;

            let dataURL = this.coverCropper.getCroppedCanvas().toDataURL("image/jpeg", 1.0);
            this.courseInfo.cover.path = dataURL;

            // dataURL to file
            this.$util.dataURLToFile(dataURL, this.courseInfo.cover.name, "image/jpeg")
                .then(file => {
                    this.courseInfo.cover.file = file;
                    this.coverCropper.destroy();
                    this.coverCropper = null;
                    $('#cropCoverImageDialogue').modal('hide');
                })
                .catch(error => console.log('catched error: ' + error));
        }
    }
}
