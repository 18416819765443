// components
const Sidebar = resolve => require(["@/components/article/_sidebar.vue"], resolve)
const Note = resolve => require(["@/components/article/_note.vue"], resolve)
const WordWrapper = resolve => require(["@/components/article/read/_word_wrapper.vue"], resolve)
const DictResultDialogue = resolve => require(["@/components/article/read/_dict_result_dialogue.vue"], resolve)
const SelectLevelDialogue = resolve => require(["@/components/article/read/_select_level_dialogue.vue"], resolve)
const FavoriteDialogue = resolve => require(["@/components/article/read/_favorite_article_dialogue.vue"], resolve)

export default {
    components: {
        Sidebar,
        Note,
        WordWrapper,
        DictResultDialogue,
        SelectLevelDialogue,
        FavoriteDialogue
    },
    data: function() {
        return {
            wordId: 1,  // 將個別單字給予獨立 id
            splitWordsParagraphs: [],  // 詞句被拆字的段落內容
            splitWordsAdditionalMaterials: [],  // 單字或例句被拆字的補充內容
            nonverbalContent: "",  // 非語文文章內容
            nonverbalTTSAudio: "", // 非語文tts audio

            audioElem: new Audio(),
            playPromise: null,
            isPlaying: false,  // 是否正在播放狀態
            isPaused: false,  // 是否正在暫停狀態
            activePlaybackRate: 1,  // 目前播放速度 (預設 1倍速)
            playingSoundMaterial: {},
            playerSecondsList: [], // 撥放器的秒數點擊陣列
            playerCurrentTime: 0, // 目前撥放器撥放的時間點
            playerTimeout: "",
            audioDuration: 0, // 音檔的總長度
            audioStartTime: 0,
            audioEndTime: 0,
            articleStartTime: 0, // 開啟與離開文章的時間(ms)
            articleEndTime: 0,

            articleId: this.$route.params.id,
            timer: '',
            durationOfSecond: 0, // 計時器的秒數參數
            isPageHide: false, // 當網頁頁面有縮小或切換tab時會等於true

            isShowChineseTranslation: false,  // 是否顯示中文翻譯

            isSetDataReady: {
                getContent: false,  // 取得文章內容
            }
        }
    },
    watch: {
        async userInfo(newValue) {
            this.isScrollRightArticleContent = false;

            // 已登入 -> 未登入
            if (this.$_.isEmpty(newValue)) {
                this.pauseAudio();
            }
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                await this.$store.dispatch('article/updateNoteAndWords', {
                    articleId: this.$route.params.id
                });
            }

            this.getData();
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        articleData() {
            return this.$store.state.article.articleData;
        },
        articleBasicInfo() {
            return this.$store.state.article.articleBasicInfo;
        },
        playbackRates() {
            return this.$store.state.article.playbackRates;
        },
        dictionaryResult() {
            return this.$store.state.article.dictionaryResult;
        },
        isShowDictBriefResult() {
            return this.$store.state.article.isShowDictBriefResult;
        },
        articleContentHeight: {
            get() {
                return this.$store.state.article.articleContentHeight;
            },
            set(value) {
                this.$store.commit('article/setArticleContentHeight', value);
            }
        },
        selectedWordLevel() {
            return this.$store.state.article.selectedWordLevel;
        },
        isOpenNote: {
            get() {
                return this.$store.state.article.isOpenNote;
            },
            set(value) {
                this.$store.commit('article/setIsOpenNote', value);
            }
        },
        isScrollRightArticleContent: {
            get() {
                return this.$store.state.article.isScrollRightArticleContent;
            },
            set(value) {
                this.$store.commit('article/setIsScrollRightArticleContent', value);
            }
        },
    },
    created: function() {
        this.getData();
        this.timer = setInterval(this.counter, 1000);
    },
    mounted: function() {
        // 開啟註冊/登入彈窗時
        $('#loginDialogue').on('show.bs.modal', () => {
            this.pauseAudio();
        });

        // 非語文tts播放開始
        this.audioElem.onplaying = () => {
            this.isPlaying = true;
            this.isPaused = false;
            if (this.articleBasicInfo.type === '1' && this.nonverbalTTSAudio) {
                this.playerSecondsList = [];
                for(let i = 0; i <= 100; i++) {
                    this.playerSecondsList.push(i);
                }
            }
        };
        // 非語文tts播放結束
        this.audioElem.onended = () => {
            this.isPlaying = false;
            this.isPaused = true;
            if (this.articleBasicInfo.type === '1' && this.nonverbalTTSAudio) {
                this.audioElem.currentTime = 0;
                this.playerCurrentTime = 0;
                this.audioStartTime = 0;
                this.audioEndTime = this.audioDuration;
                clearInterval(this.playerTimeout);
            }
        };
        window.addEventListener('scroll', this.articleUtil.bottomToolbarScrollHandler);
    },
    updated: function() {
        this.$nextTick(() => {
            this.getArticleContentHeight();
        });
    },
    beforeDestroy: function() {
        this.pauseAudio();  // 離開頁面需停止音檔播放

        this.isOpenNote = false;
        this.isScrollRightArticleContent = false;

        window.removeEventListener('scroll', this.articleUtil.bottomToolbarScrollHandler);

        clearInterval(this.timer); // 離開要停止setInterval
        this.recordTime(this.articleId); // 記錄

        this.articleEndTime = new Date().getTime();
        this.recordPeriod();
    },
    methods: {
        async getData() {
            this.isSetDataReady.getContent = false;

            // 英文內容
            if (this.articleBasicInfo.type === '0') {
                await this.getUserMarkedWords();
                this.splitWordsParagraphs = this.getParagraphsWithSplitWords();
                this.splitWordsAdditionalMaterials = this.getAdditionalMaterialsWithSplitWords();
                this.isSetDataReady.getContent = true;
            }
            // 非語文內容
            else {
                this.nonverbalContent = this.getNonverbalContentHtml();
                this.nonverbalTTSAudio = this.getNonverbalTTSAudio();
                if (this.articleBasicInfo.type === '1' && this.nonverbalTTSAudio) {
                    // 先取得tts音檔總長度
                    this.getTTSAudioDuration(this.nonverbalTTSAudio.filePath)
                        .then((time) => {
                            this.audioDuration = Math.round(time);
                            this.audioEndTime = Math.round(time);
                        })
                        .catch(error => console.log('getTTSAudioDuration() catched error: ' + error));
                }
                this.isSetDataReady.getContent = true;
            }

            // 讀取文章便重置時間
            this.articleStartTime = new Date().getTime();
            this.articleEndTime = 0;
        },

        // 取得此文章下有被使用者收藏的單字們
        getUserMarkedWords() {
            return new Promise((resolve, reject) => {
                if (this.$_.isEmpty(this.userInfo)) {
                    resolve();
                    return;
                }

                let payload = {
                    articleId: this.$route.params.id
                }
                this.$store.dispatch('article/getUserMarkedWords', payload)
                    .then(() => resolve())
                    .catch(error => reject(error));
            });
        },

        // 組詞句被拆字的段落內容資料
        getParagraphsWithSplitWords() {
            // API傳回的資料中, 文章內沒有段落內容則直接返回首頁
            if (!this.articleData.paragraph_contents.length) {
                return;
            }

            let splitWordsParagraphs = [];

            let addSentenceToContent = (tempContents, words, chineseStr) => {
                if (words.length && chineseStr) {
                    let newWords = [];  // 重組拆開後單字們資料
                    words.forEach(el => {
                        newWords.push(this.createWordObject(el));
                    });

                    let addContent = {
                        type: 'sentence',
                        words: newWords,  // 拆字後的詞句內容
                        chinese: chineseStr  // 中文翻譯
                    }
                    tempContents.push(addContent);
                }
            }

            this.articleData.paragraph_contents.forEach(paragraph => {
                let tempContents = [];
                let words = [];  // 拆開後的單字們
                let chineseStr = "";  // 詞句的中文翻譯
                paragraph.contents.forEach(content => {
                    // 詞句
                    if (content.type === 'sentence') {
                        // 依空格拆開詞句
                        words = this.$_.concat(words, content.english.split(' '));
                        chineseStr = chineseStr + content.chinese;
                    }
                    // 圖片
                    if (content.type === 'image') {
                        // 如果詞句下一個為圖片, 則將原本的拆好的單字們, 新增至段落內容
                        addSentenceToContent(tempContents, words, chineseStr);
                        words = [];
                        chineseStr = "";
                        // 新增圖片至段落內容
                        let addContent = {
                            type: 'image',
                            imageFilePath: content.image.file_path,  // 檔案
                            description: content.comment  // 說明
                        }
                        tempContents.push(addContent);
                    }
                });
                // 如果段落最後項目為詞句, 需將原本的拆好的單字們, 新增至段落內容
                addSentenceToContent(tempContents, words, chineseStr);
                words = [];
                chineseStr = "";

                splitWordsParagraphs.push({
                    id: paragraph.ap_id,
                    contents: tempContents
                });
            });

            // console.log(splitWordsParagraphs);

            return splitWordsParagraphs;
        },
        // 組單字或例句被拆字的補充內容資料
        getAdditionalMaterialsWithSplitWords() {
            let splitWordsAdditionalMaterials = [];

            this.articleData.additional_materials.forEach(material => {
                let tempContents = [];
                material.contents.forEach(content => {
                    if (!content.word_sentence) {
                        return;
                    }

                    let wordSentenceSplitWords = content.word_sentence.split(' ');
                    let newWordSentenceWords = [];  // 重組拆開後單字們資料
                    wordSentenceSplitWords.forEach(el => {
                        newWordSentenceWords.push(this.createWordObject(el));
                    });

                    let tempExamples = [];
                    content.example.forEach(example => {
                        let exampleSentenceSplitWords = example.example_sentence.split(' ');
                        let newExampleSentenceWords = [];  // 重組拆開後單字們資料
                        exampleSentenceSplitWords.forEach(el => {
                            newExampleSentenceWords.push(this.createWordObject(el));
                        });

                        tempExamples.push({
                            id: example.aamce_id,
                            exampleSentenceWords: newExampleSentenceWords,  // 拆字後的例句內容
                            chinese: example.sentence_explain,  // 例句中文翻譯
                            isGettingAudioFile: false,  // 是否正在取得 tts音檔路徑
                            isPlayingSound: false  // 是否正在播放 tts音檔
                        });
                    });

                    tempContents.push({
                        id: content.aamc_id,
                        wordSentenceWords: newWordSentenceWords,  // 拆字後的單字或文句內容
                        speechType: content.speech_type,  // 單字詞性
                        chinese: content.chinese_explain,  // 中文翻譯
                        examples: tempExamples,  // 例句
                        isGettingAudioFile: false,  // 是否正在取得 tts音檔路徑
                        isPlayingSound: false  // 是否正在播放 tts音檔
                    });
                });

                if (tempContents.length) {
                    splitWordsAdditionalMaterials.push({
                        id: material.aam_id,
                        type: material.type,  // 區塊類型 (0: 單字, 1: 文句)
                        name: material.name,  // 區塊名稱
                        contents: tempContents
                    });
                }
            });

            // console.log(splitWordsAdditionalMaterials);

            // 因為補充資料非必填, 所以直接 return 即可
            return splitWordsAdditionalMaterials;
        },
        // 建立單字物件
        createWordObject(word) {
            return {
                id: this.wordId++,
                word: word,
                wordHtml: this.createWordDOM(word),
                // 單字是否被收藏
                isMarked: this.$_.includes(this.userMarkedWords, this.articleUtil.handleWord(word, true)),
                // 字典簡略釋義小框是否顯示在單字上方
                isBriefResultPositionTop: false,
            }
        },
        // 建立單字 DOM (可被標記的單字加上 has-highlighter class)
        createWordDOM(word) {
            let formattedWord = this.articleUtil.handleWord(word, false);
            return this.$_.replace(word, formattedWord, `<span class="has-highlighter">${formattedWord}</span>`);
        },

        // 取得非語文文章內容 HTML
        getNonverbalContentHtml() {
            // API傳回的資料中, 文章內沒有段落內容則直接返回首頁
            if (!this.articleData.content_html) {
                return;
            }

            return this.articleData.content_html;
        },
        // 取得非語文tts audio
        getNonverbalTTSAudio() {
            let ttsAudio = null;

            if (this.articleData.tts_audio) {
                ttsAudio = {
                    fileName: this.articleData.tts_audio.file_name,
                    filePath: this.articleData.tts_audio.file_path
                }
            }

            return ttsAudio;
        },

        counter() { // timer 的計數器
            if (document.hidden) {
                if (this.isPageHide) {
                    return;
                }
                this.recordTime(); // 記錄
                this.durationOfSecond = 0;
                this.isPageHide = true;
                return;
            }
            this.isPageHide = false;
            this.durationOfSecond++;
        },
        recordTime() {
            if (this.$_.isEmpty(this.userInfo)) {
                return;
            }
            let duration = this.durationOfSecond;
            if (!duration) {
                return;
            }

            // 紀錄已閱讀的行為，包含時間長度
            this.$util.addLog({
                type: 'articleRead',
                articleId: this.articleId,
                isPublic: this.articleData.is_public,   // 後端需注意bool是否會收到字串
                courseId: this.articleData.course_id,
                duration: duration,
            });

            let params = {
                resType: "article",
                resId: this.articleId && this.articleId != 0 ? this.articleId : this.$route.params.id,
                duration: duration
            }

            this.$httpRequest.post('/api/log/write_res_log', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        console.log('Article ' + this.articleId + ' record success');
                    }
                    if (response.data.state == 'ERROR') {
                        console.log("article/read.js: 'recordTime' get error");
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                    console.log("article/read.js: 'recordTime' get error");
                });
        },
        // 紀錄行為時間區間
        recordPeriod() {
            if (this.$_.isEmpty(this.userInfo)) {
                console.log('article/read.js: recordPeriod: not login.');
                return;
            }
            if (this.articleStartTime <= 0 || this.articleEndTime <= 0) {
                console.log('article/read.js: recordPeriod: error time.');
                return;
            }

            let params = {
                resType: 'article',
                resId: this.articleId && this.articleId != 0 ? this.articleId : this.$route.params.id,
                articleType: this.articleBasicInfo.type,
                courseId: this.articleData.course_id,
                startTime: this.articleStartTime,
                endTime: this.articleEndTime,
            }
            this.$httpRequest.post('/api/log/write_res_period', params)
                .then(response => {
                    if (response.data.state == 'ERROR') {
                        console.log("article/read.js: 'recordPeriod' get error");
                    }
                })
                .catch(error => {
                    console.log("article/read.js: 'recordPeriod' catched error", error);
                });
        },

        getTTSAudioDuration(src) {
            return new Promise((resolve) => {
                var audio = new Audio();
                $(audio).on("loadedmetadata", function(){
                    resolve(audio.duration);
                });
                audio.src = src;
            });
        },

        setSecToTime(time) {
            let second = +time,

                minute = 0,
                hour = 0;

            if (second > 3600) {
                minute = parseInt(second / 60);
                second = parseInt(second % 60);
                if(minute > 60) {
                    hour = parseInt(minute / 60);
                    minute = parseInt(minute % 60);
                }

                let h = hour,
                    m = minute < 10 ? "0" + minute : minute,
                    s = second < 10 ? "0" + second : second;

                return h + ":" + m + ":" + s;
            }

            if (second > 60) {
                minute = parseInt(second / 60);
                second = parseInt(second % 60);

                let m = minute < 10 ? "0" + minute : minute,
                    s = second < 10 ? "0" + second : second;

                return m + ":" + s;
            }

            second = second < 10 ? "0" + second : second

            return "00:" + second;
        },

        getArticleContentHeight() {
            this.articleContentHeight = Math.floor($('.article-content').outerHeight());
        },

        playAdditionalMaterialAudio(type, material) {
            if (material.isGettingAudioFile || material.isPlayingSound) {
                return;
            }

            if (material != this.playingSoundMaterial) {
                this.playingSoundMaterial.isPlayingSound = false;
            }
            this.playingSoundMaterial = material;

            let params = {
                type: type,
                id: material.id
            }

            material.isGettingAudioFile = true;

            this.$httpRequest.get('/api/article/get_additional_materials_audio', params)
                .then(response => {
                    material.isGettingAudioFile = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let audioUrl = result.tts_audio.file_path;

                            if (audioUrl) {
                                this.audioElem.src = audioUrl;
                                if (this.audioElem.paused) {
                                    this.playPromise = this.audioElem.play();

                                    // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                                    if (this.playPromise && this.playPromise !== undefined) {
                                        this.playPromise
                                            .catch((error) => {
                                                if (error.name === 'NotSupportedError') {
                                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                                                }
                                                console.log('catched error: ' + error);
                                            });
                                    }
                                }
                                this.audioElem.onplaying = () => {
                                    material.isPlayingSound = true;
                                };
                                this.audioElem.onended = () => {
                                    material.isPlayingSound = false;
                                };
                            } else {
                                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                            }
                        }
                    }
                })
                .catch(error => {
                    material.isGettingAudioFile = false;
                    console.log('catched error: ' + error);
                });
        },
        playAudio() {
            this.playerSecondsList = [];
            let tempAudioUrl = this.nonverbalTTSAudio.filePath;

            if (tempAudioUrl) {
                if (!this.isPaused) {
                    this.audioElem.src = tempAudioUrl;
                }

                this.audioElem.playbackRate = this.activePlaybackRate;
                if (this.audioElem.paused) {
                    this.playPromise = this.audioElem.play();
                    if (this.articleBasicInfo.type === '1' && this.nonverbalTTSAudio) {
                        this.playerTimeout = setInterval(() => {
                            // 設定顯示目前秒數和剩餘秒數
                            this.audioStartTime = Math.round(this.audioElem.currentTime);
                            this.audioEndTime = this.audioDuration - this.audioStartTime;
                            // 設定目前的進度(百分比)
                            this.playerCurrentTime = Math.round(this.audioElem.currentTime / this.audioElem.duration * 100);
                        }, 100);
                    }

                    // log
                    if (!this.isPlayedLogAdded) {
                        this.$util.addLog({
                            type: 'articleReadPlayed',
                            articleId: this.$route.params.id
                        });
                        this.isPlayedLogAdded = true;
                    }

                    // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                    if (this.playPromise && this.playPromise !== undefined) {
                        this.playPromise
                            .catch((error) => {
                                if (error.name === 'NotSupportedError') {
                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '此文章不支援音檔播放' });
                                }
                                console.log('catched error: ' + error);
                            });
                    }
                }
            }
        },
        pauseAudio() {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.isPlaying = false;
                        this.isPaused = true;
                        if (this.articleBasicInfo.type === '1' && this.nonverbalTTSAudio) {
                            clearInterval(this.playerTimeout);
                        }
                        this.playingSoundMaterial.isPlayingSound = false;
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        changeActivePlaybackRate() {
            let currentIndex = this.$_.indexOf(this.playbackRates, this.activePlaybackRate);

            if (currentIndex >= 0 && currentIndex < this.playbackRates.length - 1) {
                this.activePlaybackRate = this.playbackRates[currentIndex + 1];
            } else {
                this.activePlaybackRate = this.playbackRates[0];
            }

            this.audioElem.playbackRate = this.activePlaybackRate;  // 更換播放速度
        },
        setSpecificTime(time) {
            this.audioElem.currentTime = time / 100 * this.audioElem.duration;
            this.playerCurrentTime = time;
        },
        showSelectWordLevelDialogue() {
            $('#selectWordLevelDialogue').modal('show');
        },
        clickTranslationToggle() {
            this.isShowChineseTranslation = !this.isShowChineseTranslation;

            // log
            this.$util.addLog({
                type: 'articleTranslate',
                articleId: this.$route.params.id,
                event: this.isShowChineseTranslation ? 'on' : 'off'
            });
        },
        openNote() {
            if (this.isOpenNote) {
                return;
            }

            this.isOpenNote = true;
            this.isScrollRightArticleContent = false;
        },
        showFavoriteDialogue() {
            $('#favoriteDialogue').modal('show');
        },
    }
}
