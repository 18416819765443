export default {
    props: {
        imageUrl: {
            required: true,
            type: String
        },
        aspectRatio: {
            type: String,  // 16-9 | 4-3
            default: '16-9'
        }
    },
    data: function() {
        return {
            isLoadedComplete: false,  // 圖片是否載入完成
            isLoadedError: false,  // 圖片是否載入失敗
        }
    },
    methods: {
        onLoadedComplete() {
            this.isLoadedComplete = true;
            this.$emit('loadedComplete');
        },
        onLoadedError() {
            this.isLoadedError = true;
            this.$emit('loadedError');
        },
        reloadImage() {
            this.isLoadedComplete = false;
            this.isLoadedError = false;
        }
    }
}
