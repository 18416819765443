// components
const ExchangedCourseList = resolve => require(["@/components/user/gifts/_exchanged_course_list.vue"], resolve)

export default {
    components: {
        ExchangedCourseList
    },
    data: function() {
        return {
            allExchangedCoursesInfo: [],
            inputGiftCode: '',
            newCourseList: [],  // 新兌換的課程列表
            deletingCourseIds: [],  // 準備刪除的課程 ids (遇到重複狀況時)
            isInputCodeError: false,  // 兌換代碼是否輸入錯誤
            isExchangingGift: false,  // 是否正在透過代碼兌換課程
            isSortDateDesc: true,  // 兌換日期遞減排序(最新到最舊, 預設)

            isSetDataReady: {
                getExchangedCoursesInfo: false,  // 取得個人兌換過的課程 info
            }
        }
    },
    watch: {
        inputGiftCode() {
            this.isInputCodeError = false;
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userGifts');

        this.getExchangedCoursesInfo();
    },
    methods: {
        getExchangedCoursesInfo() {
            let params = {
                isReverse: this.isSortDateDesc ? '0' : '1'
            }

            this.$httpRequest.get('/api/exchange/get_exchange_list', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allExchangedCoursesInfo = [];

                            for (let el of result) {
                                this.allExchangedCoursesInfo.push({
                                    courseId: el.course_id,
                                    orderNo: el.order_no
                                });
                            }

                            this.isSetDataReady.getExchangedCoursesInfo = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        async exchangeGift() {
            $('.lms-btn.outline').blur();

            if (!this.inputGiftCode) {
                return;
            }

            let payload = {
                code: this.inputGiftCode,
                allCourses: this.allExchangedCoursesInfo
            }

            this.isExchangingGift = true;

            try {
                let newCourses = await this.$store.dispatch('user/getNewExchangedCourses', payload);

                this.deletingCourseIds = [];
                let concatingCoursesInfo = [];

                for (let newCourse of newCourses) {
                    // 使用者曾經兌換過課程, 再次兌換相同課程 (兌換訂閱制課程才會發生)
                    if (newCourse.isExist) {
                        // 從原 exchangedCoursesInfo 陣列中, 刪除該 courseInfo element
                        this.allExchangedCoursesInfo = this.$_.remove(this.allExchangedCoursesInfo, el => {
                            return el.courseId !== newCourse.courseId;
                        });
                        // 準備刪除的課程 ids
                        this.deletingCourseIds.push(newCourse.courseId);
                    }

                    concatingCoursesInfo.push({ courseId: newCourse.courseId, orderNo: newCourse.orderNo });
                }

                // 把新增的 coursesInfo 至 exchangedCoursesInfo 陣列前面
                this.allExchangedCoursesInfo = concatingCoursesInfo.concat(this.allExchangedCoursesInfo);

                // 取得課程資訊
                let newCourseList = await this.$store.dispatch('user/getExchangedCourseInfo', { infos: concatingCoursesInfo });
                // 設置列表新增的項目資訊
                this.newCourseList = newCourseList;

                this.inputGiftCode = '';
                this.isExchangingGift = false;

                // 卷軸移動回到畫面最上方
                $('html, body').animate({
                    scrollTop: 0
                }, 500);
            } catch (error) {
                this.isExchangingGift = false;
                this.isInputCodeError = true;
                console.log('catched error: ', error);
            }
        },
        clearInputGiftCode() {
            this.inputGiftCode = '';
            $('#giftCodeInput').focus();
        },
        sortItemByDate() {
            this.isSortDateDesc = !this.isSortDateDesc;
            this.isSetDataReady.getExchangedCoursesInfo = false;
            this.getExchangedCoursesInfo();
        }
    }
}
