import { mapGetters } from 'vuex'
// components
const Sidebar = resolve => require(["@/components/article/_sidebar.vue"], resolve)
const Note = resolve => require(["@/components/article/_note.vue"], resolve)

export default {
    components: {
        Sidebar,
        Note
    },
    data: function() {
        return {
            paragraphAndMaterialComments: null,  // 文章講解內容(含標題, 段落內容與補充資料的講解內容)

            videoExtTypes: ['mp4', 'mov', 'wmv'],
            audioExtTypes: ['mp3', 'wav'],

            mediaElem: null,  // 音檔或影片 DOM
            playPromise: null,  // 執行 .play() 播放時回傳的 promise
            isPlaying: false,  // 是否正在播放狀態
            isPaused: false,  // 是否正在暫停狀態
            activePlayingIndex: 0,  // 正在播放的清單 index
            activePlaybackRate: 1,  // 目前播放速度 (預設 1倍速)

            timerProcedure: null,  // 計時器的 interval id
            timer: 0,  // 計時器(秒)
            isReachingTimePoint: false,  // 是否正在講解點時間範圍內
            isChangeItemDirectly: false,  // 是否直接按上下鍵切換講解區塊

            windowHeight: null,  // 視窗可視範圍高度
            activeReachingDOMIndex: -1,  // pointer 正在到達的清單項目 index
            isShowPointer: false,  // 是否顯示 pointer (卷軸有滑動時顯示)
            isMouseOverJumpPlayBtn: false,  // 是否 hover 在跳轉播放的浮動按鈕上
            pointerTimeoutProcedure: null,  // pointer 的 timeout id

            isChangeIsOpenNoteVal: false,  // 是否改變開關筆記狀態
            isPlayedLogAdded: false,  // 是否已紀錄 '播放' 行為 log

            isSetDataReady: {
                getContent: false,  // 取得文章內容
            },
        }
    },
    watch: {
        windowWidth(newValue) {
            if (newValue >= 768) {
                this.activeReachingDOMIndex = -1;
                this.$nextTick(() => {
                    $(window).scrollTop(0);
                    this.getCommentBlockDOMOffsetTop();
                });
            }
        },
        async userInfo(newValue) {
            // 已登入 -> 未登入
            if (this.$_.isEmpty(newValue)) {
                this.pauseMedia();
                this.clearTimerProcedure();
            }
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                await this.$store.dispatch('article/updateNoteAndWords', {
                    articleId: this.$route.params.id
                });
            }

            this.getData();
        },
        isReachingTimePoint(newValue) {
            // 監聽此值一有變化為 true 時表示剛到達目前播放項目的開始時間點, 則移動到目前講解區塊的 DOM 位置
            if (newValue) {
                $('html, body').animate({
                    scrollTop: this.activePlayingItem.DOMTop - this.scrollToTargetOffset - this.scrollOffsetByMediaType
                }, this.scrollSpeed);

                this.isChangeItemDirectly = false;
            }
        },
        isOpenNote() {
            this.isChangeIsOpenNoteVal = true;
        },
        scrollToTargetOffset() {
            this.$nextTick(() => this.getCommentBlockDOMOffsetTop());
        }
    },
    computed: {
        ...mapGetters({
            isShowCourseInfoBar: 'article/isShowCourseInfoBar',
            scrollToTargetOffset: 'article/scrollToTargetOffset'
        }),
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        articleData() {
            return this.$store.state.article.articleData;
        },
        articleBasicInfo() {
            return this.$store.state.article.articleBasicInfo;
        },
        isVideoCommentMedia() {
            if (!this.isSetDataReady.getContent) {
                return false;
            }

            return this.$_.includes(this.videoExtTypes, this.paragraphAndMaterialComments.media.fileExtType);
        },
        isAudioCommentMedia() {
            if (!this.isSetDataReady.getContent) {
                return false;
            }

            return this.$_.includes(this.audioExtTypes, this.paragraphAndMaterialComments.media.fileExtType);
        },
        playbackRates() {
            return this.$store.state.article.playbackRates;
        },
        scrollOffsetByMediaType() {
            // 360(video height) - 20(video block margin-bottom)
            return this.isVideoCommentMedia ? 340 : 0;
        },
        teachingCommentsPlayList: {
            get() {
                return this.$store.state.article.teachingCommentsPlayList;
            },
            set(value) {
                this.$store.commit('article/setTeachingCommentsPlayList', value);
            }
        },
        activePlayingItem() {  // 正在播放的清單項目
            if (this.teachingCommentsPlayList[this.activePlayingIndex]) {
                return this.teachingCommentsPlayList[this.activePlayingIndex];
            } else {
                return null;
            }
        },
        activeReachingDOMItem() {  // pointer 正在到達的清單項目
            if (this.activeReachingDOMIndex < 0) {
                return null;
            }

            if (this.teachingCommentsPlayList[this.activeReachingDOMIndex]) {
                return this.teachingCommentsPlayList[this.activeReachingDOMIndex];
            } else {
                return null;
            }
        },
        scrollSpeed() {  // 卷軸移動速度
            return this.isChangeItemDirectly ? 0 : 500
        },
        isOpenNote: {
            get() {
                return this.$store.state.article.isOpenNote;
            },
            set(value) {
                this.$store.commit('article/setIsOpenNote', value);
            }
        },
        isScrollRightArticleContent: {
            get() {
                return this.$store.state.article.isScrollRightArticleContent;
            },
            set(value) {
                this.$store.commit('article/setIsScrollRightArticleContent', value);
            }
        }
    },
    created: function() {
        this.getData();

        // log
        this.$util.addLog({
            type: 'articleComment',
            articleId: this.$route.params.id
        });
    },
    mounted: function() {
        // 開啟註冊/登入彈窗時
        $('#loginDialogue').on('show.bs.modal', () => {
            this.pauseMedia();
            this.clearTimerProcedure();
        });

        this.$nextTick(() => {
            this.getWindowHeight();

            // 若媒體為音檔類型, 則先註冊播放、暫停與結束事件
            // 影片類型待影片播放器載入完成後註冊
            if (this.isAudioCommentMedia) {
                this.handlePlayingAndEndedEvent();
            }
        });

        window.addEventListener('scroll', this.articleUtil.bottomToolbarScrollHandler);
        window.addEventListener('scroll', this.pointersScrollHandler);
        window.addEventListener('scroll', this.jumpPlayBtnScrollHandler);
        window.addEventListener('resize', this.getWindowHeight);
    },
    updated: function() {
        this.$nextTick(() => {
            this.getCommentBlockDOMOffsetTop();
        });
    },
    beforeDestroy: function() {
        this.pauseMedia();  // 離開頁面需停止音檔播放
        this.clearTimerProcedure();

        this.isOpenNote = false;
        this.isScrollRightArticleContent = false;

        window.removeEventListener('scroll', this.articleUtil.bottomToolbarScrollHandler);
        window.removeEventListener('scroll', this.pointersScrollHandler);
        window.removeEventListener('scroll', this.jumpPlayBtnScrollHandler);
        window.removeEventListener('resize', this.getWindowHeight);
    },
    methods: {
        getData() {
            this.isSetDataReady.getContent = false;
            this.paragraphAndMaterialComments = this.getParagraphAndMaterialComments();
            this.isSetDataReady.getContent = true;
        },

        // 組含講解內容的段落與補充內容資料
        getParagraphAndMaterialComments() {
            // API傳回的資料中, 文章內沒有講解的影音媒體檔案或沒有段落內容則直接返回首頁
            if (this.$_.isNull(this.articleData.media) ||
                !this.articleData.paragraph_contents.length) {
                return;
            }

            let paragraphAndMaterialComments = null;
            this.teachingCommentsPlayList = [];

            let addItemToPlayList = (type, id, timePoint) => {
                if (timePoint.start && timePoint.end) {
                    this.teachingCommentsPlayList.push({
                        DOMId: `${type}${id}`,
                        timePoint: {
                            start: +timePoint.start,
                            end: +timePoint.end
                        }
                    });
                }
            }

            let tempTimePoint = {};

            paragraphAndMaterialComments = {
                media: {  // 講解影音
                    fileExtType: this.articleData.media.file_name.split('.').pop().toLowerCase(),
                    filePath: this.articleData.media.file_path
                },
                titleComment: this.articleData.comment,  // 標題講解
                paragraphs: [],  // 段落內容
                additionalMaterials: []  // 補充資料
            }
            // 新增項目至講解內容播放清單
            tempTimePoint = {
                start: this.articleData.comment_time_point_start,  // 開始時間點(秒)
                end: this.articleData.comment_time_point_end  // 結束時間點(秒)
            }
            addItemToPlayList('title', this.$route.params.id, tempTimePoint);

            this.articleData.paragraph_contents.forEach(paragraph => {
                let tempContents = [];
                paragraph.contents.forEach(content => {
                    let tempContent = {};
                    tempContent.id = content.apc_id;
                    tempContent.type = content.type;
                    // 詞句
                    if (content.type === 'sentence') {
                        // 詞句內容
                        tempContent.english = content.english;  // 英文
                        tempContent.chinese = content.chinese;  // 中文
                        // 講解內容
                        tempContent.comment = content.comment;  // 文字
                        tempContent.commentImages = [];  // 圖片
                        // 講解內容 - 圖片
                        content.comment_images.forEach(image => {
                            tempContent.commentImages.push({
                                id: image.apccf_id,
                                filePath: image.file_path
                            });
                        });
                        // 新增項目至講解內容播放清單
                        tempTimePoint = {
                            start: content.comment_time_point_start,  // 開始時間點(秒)
                            end: content.comment_time_point_end  // 結束時間點(秒)
                        }
                        addItemToPlayList('paragraphContent', tempContent.id, tempTimePoint);
                    }
                    // 圖片
                    if (content.type === 'image') {
                        // 圖片內容
                        tempContent.imageFilePath = content.image.file_path;  // 檔案
                        tempContent.description = content.comment;  // 說明
                    }
                    tempContents.push(tempContent);
                });

                paragraphAndMaterialComments.paragraphs.push({
                    id: paragraph.ap_id,
                    contents: tempContents
                });
            });

            this.articleData.additional_materials.forEach(material => {
                let tempContents = [];
                material.contents.forEach(content => {
                    if (!content.word_sentence) {
                        return;
                    }

                    let tempExamples = [];
                    content.example.forEach(example => {
                        tempExamples.push({
                            id: example.aamce_id,
                            exampleSentence: example.example_sentence,  // 例句英文內容
                            chinese: example.sentence_explain  // 例句中文翻譯
                        });
                    });

                    tempContents.push({
                        id: content.aamc_id,
                        wordSentence: content.word_sentence,  // 單字或文句內容
                        speechType: content.speech_type,  // 單字詞性
                        chinese: content.chinese_explain,  // 中文翻譯
                        comment: content.comment,  // 講解內容
                        examples: tempExamples  // 例句
                    });
                });

                if (tempContents.length) {
                    let tempMaterial = {
                        id: material.aam_id,
                        type: material.type,  // 區塊類型 (0: 單字, 1: 文句)
                        name: material.name,  // 區塊名稱
                        contents: tempContents
                    }
                    paragraphAndMaterialComments.additionalMaterials.push(tempMaterial);
                    // 新增項目至講解內容播放清單
                    tempTimePoint = {
                        start: material.comment_time_point_start,  // 開始時間點(秒)
                        end: material.comment_time_point_end  // 結束時間點(秒)
                    }
                    addItemToPlayList('additionalMaterial', tempMaterial.id, tempTimePoint);
                }
            });

            // console.log(paragraphAndMaterialComments);

            return paragraphAndMaterialComments;
        },

        getCommentBlockDOMOffsetTop() {
            if (!this.isSetDataReady.getContent) {
                return;
            }

            // 取得有標時間點的各講解區塊 DOM 位置 的 y軸座標
            this.teachingCommentsPlayList.forEach(el => {
                el.DOMTop = Math.floor($(`#${el.DOMId}`).offset().top);
                el.DOMHeight = Math.floor($(`#${el.DOMId}`).height());
            });
        },
        getWindowHeight() {
            this.windowHeight = $(window).innerHeight();
        },

        handlePlayingAndEndedEvent() {
            if (!this.isSetDataReady.getContent) {
                return;
            }

            if (this.isVideoCommentMedia) {
                this.mediaElem = document.getElementById('videoPlayer');

                // 如果影片正在播放狀態下, 點擊筆記開關時需讓影片維持正常播放
                if (this.isChangeIsOpenNoteVal && this.isPlaying) {
                    this.playMedia();
                    this.isChangeIsOpenNoteVal = false;
                }
            }
            if (this.isAudioCommentMedia) {
                this.mediaElem = new Audio();
            }

            if (this.$_.isNull(this.mediaElem)) {
                return;
            }

            // 影音播放時需檢查目前播放時間是否到達有標時間點的內容, 若到達則需將卷軸移動到該 DOM 位置
            this.mediaElem.onplaying = () => {
                this.isPlaying = true;
                this.isPaused = false;

                // 以下為影音拖拉進度條或直接跳轉時, 尋找內容定位的行為
                // 影音播放情況下, 拖拉進度條或直接跳轉會先 pause 再 play
                if (this.isVideoCommentMedia) {
                    this.teachingCommentsPlayList.some((item, index) => {
                        // 目前播放的時間符合在時間點範圍的區塊, 將 active 設在該區塊
                        if (this.mediaElem.currentTime >= item.timePoint.start &&
                            this.mediaElem.currentTime < item.timePoint.end) {
                            this.activePlayingIndex = index;

                            // 將卷軸移動到該 DOM 位置
                            $('html, body').animate({
                                scrollTop: this.activePlayingItem.DOMTop - this.scrollToTargetOffset - this.scrollOffsetByMediaType
                            }, this.scrollSpeed);

                            return true;
                        }
                        // 目前播放時間皆不在各區塊的時間點範圍裡, 將 active 設在第一個尚未到達的區塊
                        if (this.mediaElem.currentTime < item.timePoint.start) {
                            this.activePlayingIndex = index;
                            return true;
                        }
                    });
                }

                // 計時器 0.1秒 跑一次
                this.timerProcedure = setInterval(() => {
                    this.timer = this.mediaElem.currentTime;
                    // console.log(this.timer);

                    // 到達目前播放項目的時間點範圍內, 則 highlight 該播放項目內容
                    if (this.activePlayingItem &&
                        this.timer >= this.activePlayingItem.timePoint.start &&
                        this.timer < this.activePlayingItem.timePoint.end) {
                        this.isReachingTimePoint = true;
                    }

                    // 到達目前播放項目的結束時間點, 立即更換為下一個播放項目的 index
                    if (this.activePlayingItem &&
                        (Math.round(this.timer * 10) / 10) == this.activePlayingItem.timePoint.end) {
                        this.isReachingTimePoint = false;
                        if (this.activePlayingIndex < this.teachingCommentsPlayList.length) {
                            this.activePlayingIndex++;
                        }
                    }
                }, 100);
            };

            // 影音一暫停時, 需停止計時器計時
            this.mediaElem.onpause = () => {
                this.clearTimerProcedure();
                this.isReachingTimePoint = false;
                this.isPlaying = false;
                this.isPaused = true;
            };

            // 影音播放結束後, 需將卷軸移動到最上方
            this.mediaElem.onended = () => {
                this.isPlaying = false;
                this.activePlayingIndex = 0;

                this.clearTimerProcedure();
                this.timer = 0;

                // 卷軸移動回到畫面最上方
                $('html, body').animate({
                    scrollTop: 0
                }, this.scrollSpeed);
            };
        },
        clearTimerProcedure() {
            // 計時器停止
            clearInterval(this.timerProcedure);
            this.timerProcedure = null;
        },

        // 自製播放控制器
        playMedia() {
            let tempMediaUrl = this.paragraphAndMaterialComments.media.filePath;

            if (tempMediaUrl) {
                if (!this.isPaused) {
                    this.mediaElem.src = tempMediaUrl;
                }

                this.mediaElem.currentTime = this.timer;
                this.mediaElem.playbackRate = this.activePlaybackRate;
                if (this.mediaElem.paused) {
                    this.playPromise = this.mediaElem.play();

                    // log
                    if (!this.isPlayedLogAdded) {
                        this.$util.addLog({
                            type: 'articleCommentPlayed',
                            articleId: this.$route.params.id
                        });
                        this.isPlayedLogAdded = true;
                    }

                    // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                    if (this.playPromise && this.playPromise !== undefined) {
                        this.playPromise
                            .catch((error) => {
                                if (error.name === 'NotSupportedError') {
                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                                }
                                console.log('catched error: ' + error);
                            });
                    }
                }
            }
        },
        pauseMedia() {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.mediaElem.pause();
                        this.isPlaying = false;
                        this.isPaused = true;
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        changeItemAndPlay() {
            if (this.activePlayingIndex < 0 || this.activePlayingIndex >= this.teachingCommentsPlayList.length) {
                return;
            }

            let play = () => {
                this.isChangeItemDirectly = true;
                this.clearTimerProcedure();
                this.isReachingTimePoint = false;
                if (this.activePlayingItem) {
                    this.timer = this.activePlayingItem.timePoint.start;
                }
                this.playMedia();
            }

            if (this.playPromise && this.playPromise !== undefined) {
                // 若音檔正在播放時則先暫停再播放, 否則聲音會有重疊的狀況
                this.playPromise
                    .then(() => {
                        this.mediaElem.pause();
                        this.isPaused = false;
                        play();
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            } else {
                play();
            }
        },
        jumpToItemAndPlay() {
            $('.lms-btn.jump-play').blur();

            let play = () => {
                this.clearTimerProcedure();
                this.isReachingTimePoint = false;
                this.activePlayingIndex = this.activeReachingDOMIndex;
                if (this.activePlayingItem) {
                    this.timer = this.activePlayingItem.timePoint.start;
                }
                this.playMedia();
            }

            if (this.playPromise && this.playPromise !== undefined) {
                // 若音檔正在播放時則先暫停再播放, 否則聲音會有重疊的狀況
                this.playPromise
                    .then(() => {
                        this.mediaElem.pause();
                        this.isPaused = false;
                        play();
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            } else {
                play();
            }
        },
        changeActivePlaybackRate() {
            let currentIndex = this.$_.indexOf(this.playbackRates, this.activePlaybackRate);

            if (currentIndex >= 0 && currentIndex < this.playbackRates.length - 1) {
                this.activePlaybackRate = this.playbackRates[currentIndex + 1];
            } else {
                this.activePlaybackRate = this.playbackRates[0];
            }

            this.mediaElem.playbackRate = this.activePlaybackRate;  // 更換播放速度
        },

        // 跳轉播放
        pointersScrollHandler() {
            if (!this.teachingCommentsPlayList.length) {
                return;
            }

            this.isShowPointer = true;
            this.fadeOutJumpPlayBtn();

            let scrollTop = $(window).scrollTop(); // 取得卷軸目前的 Y座標 位置

            let header = 60;
            let courseInfoBar = this.isShowCourseInfoBar ? 80 : 0;
            // video: 380 = 360(video height) + 20(video block margin-bottom)
            let otherOffset = this.isVideoCommentMedia ? 380 : 60;
            let offset = header + courseInfoBar + otherOffset;

            let count = 0;
            this.teachingCommentsPlayList.forEach((el, i) => {
                if (scrollTop >= (el.DOMTop - offset) &&
                    scrollTop <= ((el.DOMTop - offset) + el.DOMHeight)) {
                    this.activeReachingDOMIndex = i;
                } else {
                    count++;
                }
            });

            if (count === this.teachingCommentsPlayList.length) {
                this.activeReachingDOMIndex = -1;
            }
        },
        jumpPlayBtnScrollHandler() {
            let elemFooterHeight = $('.lms-footer').height();  // footer 高度

            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  // 取得 elem 因包含 overflow 而沒顯示在螢幕上的內容高度
            let windowHeight = $(window).innerHeight();  // 取得目前可視範圍高度
            let scrollTop = $(window).scrollTop(); // 取得卷軸目前的 Y座標 位置

            if (windowHeight + scrollTop >= scrollHeight - elemFooterHeight) {
                $('.jump-play-btn').addClass('absoulte-position').removeClass('sticky-position');
            } else {
                $('.jump-play-btn').addClass('sticky-position').removeClass('absoulte-position');
            }
        },
        clearPointerTimeoutProcedure() {
            if (this.pointerTimeoutProcedure) {
                clearTimeout(this.pointerTimeoutProcedure);
                this.pointerTimeoutProcedure = null;
            }
        },
        fadeOutJumpPlayBtn() {
            // 停留畫面顯示2秒後淡出隱藏
            this.clearPointerTimeoutProcedure();

            if (!this.isMouseOverJumpPlayBtn) {
                this.pointerTimeoutProcedure = setTimeout(() => {
                    $('.triangle-pointers').addClass('fade-out');
                    $('.jump-play-btn').addClass('fade-out');
                    setTimeout(() => this.isShowPointer = false, 300);
                }, 2300);
            }
        },

        // 筆記
        openNote() {
            if (this.isOpenNote) {
                return;
            }

            this.isOpenNote = true;
            this.isScrollRightArticleContent = false;
        }
    }
}
