import { render, staticRenderFns } from "./_question_group_tablet.vue?vue&type=template&id=4322f07b&"
import script from "@/assets/js/components/article/practicing/body/_question_group_tablet.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/practicing/body/_question_group_tablet.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports