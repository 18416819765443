import InfiniteScroll from 'vue-infinite-scroll'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import '@/assets/scss/vendors/vue2-datepicker.scss'

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
// components
const Forum = resolve => require(["@/components/user/mission/_forum.vue"], resolve)
const StatusUserList = resolve => require(["@/components/user/mission/_status_user_list.vue"], resolve)

export default {
    props: {
        selectedList: {
            required: true,
            type: String,
            validator: value => {
                return ['created', 'assigned', 'event'].includes(value)
            }
        },
        filteredPeriod: {
            type: String,
            default: ''
        },
        filteredTeacher: {
            type: String,
            default: ''
        },
        filteredGroup: {
            type: String,
            default: ''
        },
        filteredStatus: {
            type: String,
            default: ''
        },
    },
    directives: {
        InfiniteScroll
    },
    components: {
        DatePicker,
        Dialogue,
        Forum,
        StatusUserList,
    },
    data: function() {
        return {
            // m: mission
            allMIds: [],
            mIds: [],
            mInfos: {},  // key: mId
            mOldNames: {},  // 任務的原始名稱, 用於檢查差異變動 (key: mId)
            newMission: {
                ccItems: {},  // key: cId-ccId
                aItems: {},  // key: aId
            },
            isEditCopiedMission: false,  // 是否正在編輯複製的任務內容

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個 index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            expandedMIds: {},  // 正在展開的 mIds
            handlingMIds: {},  // 正在處理的 mIds
            editingNameMId: '',  // 正在編輯名稱的 mId
            savingNameMId: '',  // 正在儲存名稱的 mId
            tempExpandMId: '',  // 暫存準備展開的 mId
            tempDeleteMId: '',  // 暫存準備刪除的 mId
            tempGetStatusMId: '',  // 暫存準備取得任務狀態的 mId

            selectedStatus: 'unstart',

            forumTypes: {
                '0': '學生可以任意留言',
                '1': '學生只能回覆教師留言',
                '2': '學生只能觀看教師留言',
            },
            tempForumId: '',
            forumOnlyRead: false,
            forumNewPostContent: {},
            forumRenderKey: '',

            defaultClassPrefix: {
                mName: 'name-',
                mNameEditor: 'nameEditor-',
                mNameInput: 'nameInput-',
                mNamePseudo: 'nameInputPseudo-',
            },
            defaultId: {
                mAdd: 'addMissionDialogue',
                mDelete: 'deleteMissionDialogue',
                mStat: 'missionStatusDialogue',
                mForum: 'missionForumDialogue',
            },

            isSetDataReady: {
                getAssignedMission: false,  // 取得指定任務資訊
            },
            isPostingApi: {
                saveMission: false,  // 儲存任務
            },
        }
    },
    computed: {
        statusTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'unstart',
                    text: '未開始'
                },
                {
                    value: 'ongoing',
                    text: '進行中'
                },
                {
                    value: 'complete',
                    text: '完成'
                }
            ]

            return tabs;
        },
        addStepList() {
            let steps = [];
            steps = ['任務設定', '指派內容', '指派對象'];

            return steps;
        },
        assignContentTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'course',
                    text: '我的課程'
                },
                {
                    value: 'favorite',
                    text: '最愛文章'
                }
            ]

            return tabs;
        },
        isDisableAddMission() {
            // 所有項目皆必填
            // 步驟 1: 任務設定
            if (this.newMission.currentStep === 0) {
                return !(
                    this.newMission.name &&
                    (this.newMission.startDateTime || this.newMission.isPublishNow) &&
                    this.newMission.endDateTime &&
                    (!this.newMission.isOpenForum || this.newMission.forumType)
                );
            }
            // 步驟 2: 指派內容
            if (this.newMission.currentStep === 1) {
                return !(
                    this.newMission.contentCount['read'] || this.newMission.contentCount['practice']
                );
            }
            // 步驟 3: 指派對象
            if (this.newMission.currentStep === 2) {
                return !(
                    this.newMission.userCount['student']
                );
            }
        },
    },
    created: function() {
        this.getMissionIds();

        this.setNewMission();
        this.isSetDataReady.getAssignedMission = true;
    },
    methods: {
        getMissionIds() {
            let params = {
                roleType: this.selectedList,
            };

            // 篩選時間
            if (this.filteredPeriod) {
                params.periodType = this.filteredPeriod;
            }
            // 篩選教師
            if (this.filteredTeacher) {
                params.teacherId = this.filteredTeacher;
            }
            // 篩選班級
            if (this.filteredGroup) {
                params.groupId = this.filteredGroup;
            }
            // 篩選狀態
            if (this.filteredStatus) {
                params.status = this.filteredStatus;
            }

            this.$httpRequest.get('/api/mission/get_mission_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allMIds = result;

                            // 網址帶有指定 mId
                            if (this.$route.query['m']) {
                                let mId = this.$route.query['m'];

                                let foundIndex = this.allMIds.indexOf(mId);
                                if (~foundIndex) {
                                    // 將指定 mId 放至列表第一筆
                                    this.$delete(this.allMIds, foundIndex);
                                    this.allMIds.unshift(mId);
                                    this.tempExpandMId = mId;
                                } else {
                                    this.$store.dispatch('common/setAlert', { msg: '查無任務資料', status: 'danger' });
                                }
                            }

                            if (!this.allMIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                                this.$emit('loadedData');
                            } else {
                                this.$emit('canLoadData');
                            }

                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            let mIds = [];
            for (let i = 0; i < this.loadCountPerPage; i++) {
                let mId = this.allMIds[this.loadingIndex];
                if (!mId) {
                    this.isGetAllList = true;
                    break;
                }
                mIds.push(mId);
                this.loadingIndex++;
            }
            await this.getMissionInfos(mIds);
            this.mIds = [...this.mIds, ...mIds];
            this.busy = false;

            // 有指定展開 mId
            if (this.tempExpandMId) {
                this.expandMission('', this.tempExpandMId);
                this.tempExpandMId = '';
            }

            this.$emit('loadedData');
        },
        getMissionInfos(mIds) {
            return new Promise((resolve, reject) => {
                if (!mIds.length) {
                    resolve();
                    return;
                }

                const params = {
                    missionIds: mIds,
                    roleType: this.selectedList,
                };

                this.$httpRequest.post('/api/mission/get_mission_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (const mId of params.missionIds) {
                                    const m = result[mId];

                                    if (!m) {
                                        continue;
                                    }

                                    const mInfo = {
                                        name: m.name,
                                        forum: {
                                            id: m.forum_group_id,
                                            unreadCount: m.forum_not_read,
                                        },
                                        contentCount: {
                                            read: m.read_count,
                                            practice: m.practice_count
                                        },
                                        startTime: +m.publish_start,
                                        endTime: +m.publish_end,
                                        isStarted: Math.floor(Date.now() / 1000) >= +m.publish_start,
                                        studentsCount: m.member_count,
                                        assignedTeacher: m.teacher_name,
                                        status: m.status ? m.status : '',
                                        statusCount: {
                                            unstart: m.unstart_count ? m.unstart_count : 0,
                                            ongoing: m.ongoing_count ? m.ongoing_count : 0,
                                            complete: m.complete_count ? m.complete_count : 0,
                                        },
                                    };
                                    this.$set(this.mInfos, mId, mInfo);

                                    this.$set(this.mOldNames, mId, mInfo.name);
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        expandMission(event, mId) {
            if (event && event.target.closest('.advanced-more-dropdown') !== null) {
                return;
            }

            // 收合
            if (this.expandedMIds[mId]) {
                this.$delete(this.expandedMIds, mId);
                this.$delete(this.mInfos[mId], 'contents');
                return;
            }

            this.$set(this.expandedMIds, mId, 1);

            let params = {
                missionId: mId,
                roleType: this.selectedList,
            };

            this.$httpRequest.get('/api/mission/get_mission_contents', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let items = [];
                            let completeCount = 0, unstartCount = 0;
                            for (let el of result) {
                                let tempType = el.type === 'article' ? 'read' : 'practice';

                                let item = {
                                    articleId: el.article_id,
                                    articleName: el.article_name,
                                    courseName: el.course_name,
                                    type: tempType,
                                    itemName: tempType === 'read' ? '文章閱讀' : el.practice_name.substring(0, 20),
                                    status: el.status ? el.status : '',
                                };

                                // 統計子項目完成狀態數量
                                if (item.status) {
                                    if (item.status === 'complete') {
                                        completeCount++;
                                    } else if (item.status === 'unstart') {
                                        unstartCount++;
                                    }
                                }

                                items.push(item);
                            }

                            // 依據子項目完成狀態數量改變父層任務狀態
                            if (this.selectedList !== 'created') {
                                if (items.length === completeCount) {
                                    this.mInfos[mId].status = 'complete';
                                } else if (items.length === unstartCount) {
                                    this.mInfos[mId].status = 'unstart';
                                } else {
                                    this.mInfos[mId].status = 'ongoing';
                                }
                            }

                            this.$set(this.mInfos[mId], 'contents', items);
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        showForumDialogue(mId) {
            this.cancelNameEdit();

            this.tempForumId = this.mInfos[mId].forum.id;
            this.mInfos[mId].forum.unreadCount = '0';

            const nowTime = Date.now();
            this.forumOnlyRead = (Math.floor(nowTime / 1000) < this.mInfos[mId].startTime) || (Math.floor(nowTime / 1000) > this.mInfos[mId].endTime);
            this.forumRenderKey = `${this.tempForumId}_${nowTime}`;

            this.$nextTick(() => $(`#${this.defaultId.mForum}`).modal('show'));
        },

        showStatusDialogue(mId) {
            this.cancelNameEdit();

            this.tempGetStatusMId = mId;
            $(`#${this.defaultId.mStat}`).modal('show');
        },
        statusDialogueLoadedHandler() {
            // 關閉彈窗後
            $(`#${this.defaultId.mStat}`).on('hidden.bs.modal', () => {
                this.tempGetStatusMId = '';
                this.selectedStatus = 'unstart';
            });
        },

        editMissionName(mId) {
            let elem = document.getElementById(`${this.defaultClassPrefix.mName}${mId}`);
            let elemWidth = elem.offsetWidth;

            this.editingNameMId = mId;

            this.$nextTick(() => {
                let editorElem = document.getElementById(`${this.defaultClassPrefix.mNameEditor}${mId}`),
                    inputElem = document.getElementById(`${this.defaultClassPrefix.mNameInput}${mId}`),
                    pseudoElem = document.getElementById(`${this.defaultClassPrefix.mNamePseudo}${mId}`);

                editorElem.style.flex = '1';
                let maxWidth = inputElem.offsetWidth;
                editorElem.style.flex = '';

                inputElem.style.width = `${elemWidth + 8}px`;  // 8 for <input> padding
                inputElem.style.maxWidth = `${maxWidth}px`;
                inputElem.focus();

                pseudoElem.style.maxWidth = `${maxWidth}px`;
            });
        },
        resizeNameInput(mId) {
            setTimeout(() => {
                let inputElem = document.getElementById(`${this.defaultClassPrefix.mNameInput}${mId}`),
                    pseudoElem = document.getElementById(`${this.defaultClassPrefix.mNamePseudo}${mId}`);

                inputElem.style.width = `${pseudoElem.offsetWidth}px`;
            });
        },
        cancelNameEdit() {
            if (!this.editingNameMId) {
                return;
            }

            this.mInfos[this.editingNameMId].name = this.mOldNames[this.editingNameMId];
            this.editingNameMId = '';
        },
        saveMissionName() {
            if (!this.editingNameMId) {
                return;
            }

            let mId = this.editingNameMId;

            // 任務名稱不能為空
            if (!this.mInfos[mId].name) {
                return;
            }
            // 與舊名稱進行差異比對, 若相同則不需更新
            if (this.mInfos[mId].name === this.mOldNames[mId]) {
                this.editingNameMId = '';
                return;
            }

            let params = {
                missionId: mId,
                name: this.mInfos[mId].name
            };

            this.savingNameMId = mId;

            this.$httpRequest.post('/api/mission/update_mission_name', params)
                .then(response => {
                    this.savingNameMId = '';

                    if (response.data.state == 'OK') {
                        this.$set(this.mOldNames, mId, this.mInfos[mId].name);
                        this.editingNameMId = '';

                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    this.savingNameMId = '';
                    console.error('Catched Error:', error);
                });
        },

        showDeleteMissionDialogue(mId) {
            this.cancelNameEdit();

            this.tempDeleteMId = mId;
            $(`#${this.defaultId.mDelete}`).modal('show');
        },
        deleteMission(mId) {
            $(`#${this.defaultId.mDelete}`).modal('hide');

            let params = {
                missionId: mId
            };

            this.$set(this.handlingMIds, mId, 1);

            this.$httpRequest.post('/api/mission/delete_mission', params)
                .then(response => {
                    this.$delete(this.handlingMIds, mId);

                    if (response.data.state == 'OK') {
                        this.$delete(this.mInfos, mId);

                        let foundIndex = this.mIds.indexOf(mId);
                        this.$delete(this.mIds, foundIndex);

                        this.$delete(this.mOldNames, mId);

                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    this.$delete(this.handlingMIds, mId);
                    console.error('Catched Error:', error);
                });
        },

        setNewMission() {
            let defaultStruct = {
                currentStep: 0,
                // name
                name: '',
                // time
                startDateTime: '',
                endDateTime: '',
                isPublishNow: false,
                // forum
                isOpenForum: false,
                forumType: '',
                // assign contents
                selectedTab: 'course',
                expandedCIds: {},
                expandedCCIds: {},
                expandedFIds: {},
                expandedAIds: {},  // key: cId-aId | fId-aId
                loadingCCIds: {},  // key: cId-ccId
                loadingAIds: {},  // key: cId-aId | fId-aId
                assignedContents: {},
                contentCount: { read: 0, practice: 0 },
                // assign users
                expandedGIds: {},
                loadingGIds: {},
                assignedUsers: {},
                userCount: { group: 0, student: 0 },
            };
            this.newMission = {...this.newMission, ...defaultStruct};
        },
        addMissionDialogueLoadedHandler() {
            // 一開啟彈窗時
            $(`#${this.defaultId.mAdd}`).on('show.bs.modal', () => {
                this.cancelNameEdit();

                if (!this.isEditCopiedMission) {
                    this.getAssignContents(this.newMission.selectedTab);
                    this.getMyGroupList();
                }
            });
            // 開啟彈窗後
            $(`#${this.defaultId.mAdd}`).on('shown.bs.modal', () => {
                let elem = document.getElementById(`${this.defaultClassPrefix.mNameInput}new`);
                elem && elem.focus();
            });
            // 關閉彈窗後
            $(`#${this.defaultId.mAdd}`).on('hidden.bs.modal', () => {
                if (this.isEditCopiedMission) {
                    this.setNewMission();
                    this.isEditCopiedMission = false;
                }
            });
        },

        getAssignContents(tab) {
            this.newMission.selectedTab = tab;

            switch (tab) {
                case 'course':
                    this.getMyCourseList();
                    break;
                case 'favorite':
                    this.getMyFavoriteList();
                    break;
            }
        },

        getMyCourseList() {
            return new Promise((resolve, reject) => {
                // 已讀取過資料
                if (this.newMission.cIds) {
                    resolve();
                    return;
                }

                let params = {
                    sortType: 'name_asc'
                };

                this.$httpRequest.get('/api/course/get_course_id_name', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let cIds = [], cInfos = {};
                                for (let c of result) {
                                    let cId = c.id;
                                    cIds.push(cId);

                                    let cInfo = {
                                        name: c.name,
                                        isLoadingCategories: false
                                    };
                                    this.$set(cInfos, cId, cInfo);
                                }
                                this.$set(this.newMission, 'cIds', cIds);
                                this.$set(this.newMission, 'cInfos', cInfos);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        expandCourse(cId) {
            if (!this.newMission.cInfos[cId]) {
                return;
            }

            // 收合
            if (this.newMission.expandedCIds[cId]) {
                this.$delete(this.newMission.expandedCIds, cId);
                return;
            }

            // 已讀取過資料
            if (this.newMission.cInfos[cId].ccIds) {
                this.$set(this.newMission.expandedCIds, cId, 1);
                return;
            }

            // 正在讀取資料
            if (this.newMission.cInfos[cId].isLoadingCategories) {
                return;
            }

            this.newMission.cInfos[cId].isLoadingCategories = true;

            let params = {
                courseId: cId
            };

            this.$httpRequest.get('/api/course/get_category_id_name', params)
                .then(response => {
                    this.newMission.cInfos[cId].isLoadingCategories = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let ccIds = [], ccInfos = {};
                            for (let cc of result) {
                                let ccId = `${cId}-${cc.id}`;
                                ccIds.push(ccId);

                                let ccInfo = {
                                    name: cc.name
                                };
                                this.$set(ccInfos, ccId, ccInfo);
                            }
                            this.$set(this.newMission.cInfos[cId], 'ccIds', ccIds);
                            this.$set(this.newMission.cInfos[cId], 'ccInfos', ccInfos);

                            this.$set(this.newMission.expandedCIds, cId, 1);
                        }
                    }
                })
                .catch(error => {
                    this.newMission.cInfos[cId].isLoadingCategories = false;
                    console.error('Catched Error:', error);
                });
        },
        expandCourseCategory(ccId) {
            // 收合
            if (this.newMission.expandedCCIds[ccId]) {
                this.$delete(this.newMission.expandedCCIds, ccId);
                return;
            }

            // 已讀取過資料
            if (this.newMission.ccItems[ccId]) {
                this.$set(this.newMission.expandedCCIds, ccId, 1);
                return;
            }

            // 正在讀取資料
            if (this.newMission.loadingCCIds[ccId]) {
                return;
            }

            this.$set(this.newMission.loadingCCIds, ccId, 1);

            let splitKey = ccId.split('-');
            let params = {
                courseId: splitKey[0],
                categoryIds: [splitKey[1]]
            };

            this.$httpRequest.post('/api/course/get_article_by_categories', params)
                .then(response => {
                    this.$delete(this.newMission.loadingCCIds, ccId);

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.newMission.ccItems[ccId] = {};

                            for (let categoryId of params.categoryIds) {
                                let aIds = [], aInfos = {};
                                for (let a of result[categoryId]) {
                                    let aId = a.id;
                                    aIds.push(aId);

                                    let aInfo = {
                                        name: a.name
                                    };
                                    this.$set(aInfos, aId, aInfo);
                                }

                                this.$set(this.newMission.ccItems[ccId], 'aIds', aIds);
                                this.$set(this.newMission.ccItems[ccId], 'aInfos', aInfos);

                                this.$set(this.newMission.expandedCCIds, ccId, 1);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.$delete(this.newMission.loadingCCIds, ccId);
                    console.error('Catched Error:', error);
                });
        },

        getMyFavoriteList() {
            return new Promise((resolve, reject) => {
                // 已讀取過資料
                if (this.newMission.fIds) {
                    resolve();
                    return;
                }

                this.$httpRequest.get('/api/bookmark/get_folders')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let fIds = [], fInfos = {};
                                for (let ca of result) {
                                    if (!ca.folders.length) {
                                        continue;
                                    }

                                    let isDeleteCategory = true;

                                    let caId = ca.id;
                                    fIds.push(caId);

                                    let caInfo = {
                                        name: ca.name,
                                        isCategory: true
                                    };
                                    this.$set(fInfos, caId, caInfo);

                                    for (let f of ca.folders) {
                                        if (!+f.count) {
                                            continue;
                                        }

                                        isDeleteCategory = false;

                                        let fId = f.id;
                                        fIds.push(fId);

                                        let fInfo = {
                                            name: f.name,
                                            isCategory: false,
                                            isLoadingArticles: false
                                        };
                                        this.$set(fInfos, fId, fInfo);
                                    }

                                    if (isDeleteCategory) {
                                        fIds.pop();
                                        this.$delete(fInfos, caId);
                                    }
                                }
                                this.$set(this.newMission, 'fIds', fIds);
                                this.$set(this.newMission, 'fInfos', fInfos);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        expandFolder(fId) {
            if (!this.newMission.fInfos[fId]) {
                return;
            }

            // 點擊分類不做任何動作
            if (this.newMission.fInfos[fId].isCategory) {
                return;
            }

            // 收合
            if (this.newMission.expandedFIds[fId]) {
                this.$delete(this.newMission.expandedFIds, fId);
                return;
            }

            // 已讀取過資料
            if (this.newMission.fInfos[fId].aIds) {
                this.$set(this.newMission.expandedFIds, fId, 1);
                return;
            }

            // 正在讀取資料
            if (this.newMission.fInfos[fId].isLoadingArticles) {
                return;
            }

            this.newMission.fInfos[fId].isLoadingArticles = true;

            let params = {
                folderId: fId
            };

            this.$httpRequest.get('/api/bookmark/get_article_id_name', params)
                .then(response => {
                    this.newMission.fInfos[fId].isLoadingArticles = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let aIds = [], aInfos = {};
                            for (let a of result) {
                                let aId = a.id;
                                aIds.push(aId);

                                let aInfo = {
                                    name: a.name
                                };
                                this.$set(aInfos, aId, aInfo);
                            }
                            this.$set(this.newMission.fInfos[fId], 'aIds', aIds);
                            this.$set(this.newMission.fInfos[fId], 'aInfos', aInfos);

                            this.$set(this.newMission.expandedFIds, fId, 1);
                        }
                    }
                })
                .catch(error => {
                    this.newMission.fInfos[fId].isLoadingArticles = false;
                    console.error('Catched Error:', error);
                });
        },

        async expandArticle(keyPrefix, aId) {
            let aKey = `${keyPrefix}${aId}`;

            // 收合
            if (this.newMission.expandedAIds[aKey]) {
                this.$delete(this.newMission.expandedAIds, aKey);
                return;
            }

            // 正在讀取資料
            if (this.newMission.loadingAIds[aKey]) {
                return;
            }

            this.$set(this.newMission.loadingAIds, aKey, 1);
            await this.getArticleItems(aId);
            this.$delete(this.newMission.loadingAIds, aKey);
            this.$set(this.newMission.expandedAIds, aKey, 1);
        },
        getArticleItems(aId) {
            return new Promise((resolve, reject) => {
                // 已讀取過資料
                if (this.newMission.aItems[aId]) {
                    resolve();
                    return;
                }

                let params = {
                    articleId: aId
                };

                this.$httpRequest.get('/api/mission/get_article_items', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let items = [];
                                for (let el of result) {
                                    let tempType = el.type === 'article' ? 'read' : 'practice';

                                    let pId = el.practice_id;
                                    let iId = tempType === 'read' ? `${aId}-r` : `${aId}-p-${pId}`;

                                    items.push({
                                        id: iId,
                                        name: tempType === 'read' ? '文章閱讀' : el.practice_name
                                    });
                                }
                                this.$set(this.newMission.aItems, aId, items);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        async selectArticle(event, keyPrefix) {
            let aId = event.target.value;

            if (event.target.checked) {
                this.$set(this.newMission.assignedContents, aId, {});

                // 尚未取得子項目資料, 則展開該文章項目
                if (!this.newMission.aItems[aId]) {
                    await this.expandArticle(keyPrefix, aId);
                }

                for (let el of this.newMission.aItems[aId]) {
                    this.$set(this.newMission.assignedContents[aId], el.id, 1);
                }
            } else {
                this.$delete(this.newMission.assignedContents, aId);
            }

            this.countItems();
        },
        selectItem(event, aId) {
            let iId = event.target.value;

            if (event.target.checked) {
                // 上層文章尚未被勾選
                if (!this.newMission.assignedContents[aId]) {
                    this.$set(this.newMission.assignedContents, aId, {});
                }
                this.$set(this.newMission.assignedContents[aId], iId, 1);
            } else {
                this.$delete(this.newMission.assignedContents[aId], iId);

                // 若子項目皆無被勾選時, 則取消文章勾選
                if (!Object.keys(this.newMission.assignedContents[aId]).length) {
                    this.$delete(this.newMission.assignedContents, aId);
                }
            }

            this.countItems();
        },
        countItems() {
            let read = 0, practice = 0;

            for (let aId in this.newMission.assignedContents) {
                let items = this.newMission.assignedContents[aId];
                for (let iId in items) {
                    let split = iId.split('-');
                    split[1] === 'r' ? read++ : practice++;
                }
            }

            this.newMission.contentCount['read'] = read;
            this.newMission.contentCount['practice'] = practice;
        },

        getMyGroupList() {
            return new Promise((resolve, reject) => {
                // 已讀取過資料
                if (this.newMission.gIds) {
                    resolve();
                    return;
                }

                this.$httpRequest.get('/api/group/get_group_id_name')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let gIds = [], gInfos = {};
                                for (let g of result) {
                                    let gId = g.id;
                                    gIds.push(gId);

                                    let gInfo = {
                                        name: g.name,
                                        isLoadingUsers: false
                                    };
                                    this.$set(gInfos, gId, gInfo);
                                }
                                this.$set(this.newMission, 'gIds', gIds);
                                this.$set(this.newMission, 'gInfos', gInfos);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async expandGroup(gId) {
            if (!this.newMission.gInfos[gId]) {
                return;
            }

            // 收合
            if (this.newMission.expandedGIds[gId]) {
                this.$delete(this.newMission.expandedGIds, gId);
                return;
            }

            // 已讀取過資料
            if (this.newMission.gInfos[gId].users) {
                this.$set(this.newMission.expandedGIds, gId, 1);
                return;
            }

            // 正在使用間接方式讀取資料
            if (this.newMission.loadingGIds[gId]) {
                return;
            }

            this.newMission.gInfos[gId].isLoadingUsers = true;
            await this.getUsersByGroup(gId);
            this.newMission.gInfos[gId].isLoadingUsers = false;
            this.$set(this.newMission.expandedGIds, gId, 1);
        },
        getUsersByGroup(gId) {
            return new Promise((resolve, reject) => {
                let params = {
                    groupId: gId
                };

                this.$httpRequest.get('/api/group/get_group_member_id_name', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let users = [];
                                for (let u of result) {
                                    users.push({
                                        id: u.id,
                                        name: u.name
                                    });
                                }
                                this.$set(this.newMission.gInfos[gId], 'users', users);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        async selectGroup(event) {
            let gId = event.target.value;

            if (event.target.checked) {
                this.$set(this.newMission.assignedUsers, gId, {});

                // 尚未取得學生資料
                if (!this.newMission.gInfos[gId].users) {
                    this.$set(this.newMission.loadingGIds, gId, 1);
                    await this.getUsersByGroup(gId);
                    this.$delete(this.newMission.loadingGIds, gId);
                }

                for (let el of this.newMission.gInfos[gId].users) {
                    this.$set(this.newMission.assignedUsers[gId], el.id, 1);
                }
            } else {
                this.$delete(this.newMission.assignedUsers, gId);
            }

            this.countUsers();
        },
        selectUser(event, gId) {
            let uId = event.target.value;

            if (event.target.checked) {
                // 上層班級尚未被勾選
                if (!this.newMission.assignedUsers[gId]) {
                    this.$set(this.newMission.assignedUsers, gId, {});
                }
                this.$set(this.newMission.assignedUsers[gId], uId, 1);
            } else {
                this.$delete(this.newMission.assignedUsers[gId], uId);

                // 若班級下的學生皆無被勾選時, 則取消班級勾選
                if (!Object.keys(this.newMission.assignedUsers[gId]).length) {
                    this.$delete(this.newMission.assignedUsers, gId);
                }
            }

            this.countUsers();
        },
        countUsers() {
            let students = {};

            // 同一學生在不同班級需不重複計算
            for (let gId in this.newMission.assignedUsers) {
                students = {...students, ...this.newMission.assignedUsers[gId]};
            }

            this.newMission.userCount['group'] = Object.keys(this.newMission.assignedUsers).length;
            this.newMission.userCount['student'] = Object.keys(students).length;
        },

        async copyMission(mId) {
            this.isSetDataReady.getAssignedMission = false;

            this.isEditCopiedMission = true;
            $(`#${this.defaultId.mAdd}`).modal('show');

            let promises = [
                this.getMyCourseList(),
                this.getMyFavoriteList(),
                this.getMyGroupList()
            ];

            Promise.all(promises)
                .then(() => {
                    let params = {
                        missionId: mId
                    };

                    this.$httpRequest.get('/api/mission/get_mission_info', params)
                        .then(async (response) => {
                            if (response.data.state == 'OK') {
                                let result = response.data.result;

                                if (result) {
                                    this.setNewMission();

                                    // 名稱
                                    this.newMission.name = `${result.name}（複製）`;

                                    // 指派內容
                                    let courses = {}, folders = {};
                                    for (let el of result.contents) {
                                        let aId = el.article_id;

                                        // 需展開的課程與資料夾 Ids
                                        // 課程
                                        let cId = el.course_id;
                                        let ccId = `${cId}-${el.course_category_id}`;
                                        if (!courses[cId]) {
                                            courses[cId] = {};
                                        }
                                        if (!courses[cId][ccId]) {
                                            courses[cId][ccId] = [];
                                        }
                                        courses[cId][ccId].push(aId);
                                        // 資料夾
                                        for (let fId of el.folder_ids) {
                                            if (!folders[fId]) {
                                                folders[fId] = [];
                                            }
                                            folders[fId].push(aId);
                                        }

                                        // 取得文章子項目
                                        await this.getArticleItems(aId);

                                        // 定義勾選項目
                                        this.$set(this.newMission.assignedContents, aId, {});
                                        let iId = '';
                                        // 有勾選閱讀項目
                                        if (el.is_read) {
                                            iId = `${aId}-r`;
                                            this.$set(this.newMission.assignedContents[aId], iId, 1);
                                        }
                                        // 有勾選練習項目
                                        for (let pId of el.practice_ids) {
                                            iId = `${aId}-p-${pId}`;
                                            this.$set(this.newMission.assignedContents[aId], iId, 1);
                                        }
                                    }
                                    this.countItems();

                                    // 展開課程或資料夾
                                    for (let cId in courses) {
                                        this.expandCourse(cId);

                                        for (let ccId in courses[cId]) {
                                            this.expandCourseCategory(ccId);

                                            for (let aId of courses[cId][ccId]) {
                                                this.expandArticle(`c-${cId}_`, aId);
                                            }
                                        }
                                    }
                                    for (let fId in folders) {
                                        this.expandFolder(fId);

                                        for (let aId of folders[fId]) {
                                            this.expandArticle(`f-${fId}_`, aId);
                                        }
                                    }

                                    // 指派對象
                                    for (let el of result.assign) {
                                        let gId = el.group_id;

                                        // 展開項目
                                        this.expandGroup(gId);

                                        // 定義勾選項目
                                        this.$set(this.newMission.assignedUsers, gId, {});
                                        // 有勾選學生
                                        for (let uId of el.user_ids) {
                                            this.$set(this.newMission.assignedUsers[gId], uId, 1);
                                        }
                                    }
                                    this.countUsers();

                                    this.isSetDataReady.getAssignedMission = true;

                                    this.$nextTick(() => {
                                        let elem = document.getElementById(`${this.defaultClassPrefix.mNameInput}new`);
                                        elem && elem.focus();
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            console.error('Catched Error:', error);
                        });
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        saveMission() {
            if (this.isDisableAddMission || this.isPostingApi.saveMission) {
                return;
            }

            // 若尚未到建立的最後一步, 則繼續前往下一步
            if ((this.newMission.currentStep + 1) < this.addStepList.length) {
                this.newMission.currentStep++;
                return;
            }

            // 任務立即生效
            if (this.newMission.isPublishNow) {
                this.newMission.startDateTime = this.$util.unixTimestampToDatetime(Math.floor(Date.now() / 1000), this.$util.getBrowserCurrentTimeZone(), 'datetime', true);
            }

            const processedStartTime = this.newMission.startDateTime.replace(/\./g, '/'),
                  processedEndTime = this.newMission.endDateTime.replace(/\./g, '/');

            let params = {
                name: this.newMission.name,
                publishStart: this.$util.datetimeToUnixTimestamp(processedStartTime),
                publishEnd: this.$util.datetimeToUnixTimestamp(processedEndTime),
                contents: [],
                assign: [],
                forumPermission: null,
            };

            // 討論區
            if (this.newMission.isOpenForum) {
                let canPost = true, canReply = true;
                switch (this.newMission.forumType) {
                    case '1': {  // 學生只能回覆教師留言
                        canPost = false; canReply = true;
                        break;
                    }
                    case '2': {  // 學生只能觀看教師留言
                        canPost = false; canReply = false;
                        break;
                    }
                }
                params.forumPermission = { student: { post: canPost, reply: canReply } };
            }

            // 指派內容
            for (const aId in this.newMission.assignedContents) {
                let aData = {
                    id: aId,
                    isCheckRead: 0,
                    practiceIds: [],
                };

                const items = this.newMission.assignedContents[aId];
                for (const iId in items) {
                    let split = iId.split('-');
                    if (split[1] === 'r') {
                        aData.isCheckRead = 1;
                    } else {
                        aData.practiceIds.push(split[2]);
                    }
                }

                params.contents.push(aData);
            }

            // 指派對象
            for (const gId in this.newMission.assignedUsers) {
                const gData = {
                    id: gId,
                    userIds: Object.keys(this.newMission.assignedUsers[gId]),
                };

                params.assign.push(gData);
            }

            this.isPostingApi.saveMission = true;

            this.$httpRequest.post('/api/mission/create_mission', params)
                .then(async (response) => {
                    this.isPostingApi.saveMission = false;
                    $(`#${this.defaultId.mAdd}`).modal('hide');

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            const newMId = result;
                            await this.getMissionInfos([newMId]);
                            this.mIds.unshift(newMId);

                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                            // 新任務將新增於列表第一筆, 置頂卷軸以方便使用者查看
                            window.scrollTo(0, 0);

                            // 初始新增畫面元件
                            this.isSetDataReady.getAssignedMission = false;
                            setTimeout(() => {
                                this.setNewMission();
                                this.isSetDataReady.getAssignedMission = true;
                            }, 500);
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.saveMission = false;
                    console.error('Catched Error:', error);
                });
        },

        getFormattedDateTime(timestamp) {
            return this.$util.unixTimestampToDatetime(timestamp, this.$util.getBrowserCurrentTimeZone(), 'datetime', true);
        },

        disablePickerStartDate(date) {
            // disable 小於今天以前的日期 & 大於有設定的結束日期
            return (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (this.newMission.endDateTime && date.setHours(0, 0, 0, 0) > new Date(this.newMission.endDateTime).setHours(0, 0, 0, 0));
        },
        disablePickerStartTime(date) {
            // disable 小於目前時間(分)以前的時間 & 大於有設定的結束時間(分)
            return (date.setSeconds(0, 0) <= new Date().setSeconds(0, 0)) ||
                (this.newMission.endDateTime && date.setSeconds(0, 0) >= new Date(this.newMission.endDateTime).setSeconds(0, 0));
        },
        disablePickerEndDate(date) {
            // disable 小於今天以前的日期 & 小於有設定的開始日期
            return (date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (this.newMission.startDateTime && date.setHours(0, 0, 0, 0) < new Date(this.newMission.startDateTime).setHours(0, 0, 0, 0));
        },
        disablePickerEndTime(date) {
            // disable 小於目前時間(分)以前的時間 & 小於有設定的開始時間(分)
            return (date.setSeconds(0, 0) <= new Date().setSeconds(0, 0)) ||
                (this.newMission.startDateTime && date.setSeconds(0, 0) <= new Date(this.newMission.startDateTime).setSeconds(0, 0));
        },
    }
}
