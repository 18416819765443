export default {
    data: function() {
        return {
            // 分類資料，會有id
            categoryList1: [],
            categoryList2: [],
            // 各個參數
            name: '',
            email: '',
            phone: '',
            category1: '',
            category2: '',
            description: '',
            images: [], // 放附圖的陣列
            url: this.$route.query.p ? this.$route.query.p : '',
            isShowForm: false,

            // 是否有輸入錯誤
            isEmailError: false,
            isPhoneError: false,
            uploadFileMsg: '',

            isPostingApi: {
                addFeedback: false,  // 新增回饋
            },
            // [重要]之後要補機器人驗證
        }
    },
    watch: {
        'userInfo.name'(newValue) {
            // 未登入 -> 已登入
            if (newValue) {
                window.scrollTo(0, 0);
                this.name = this.$store.state.common.userInfo.name;
                this.email = this.$store.state.common.userInfo.email;
                this.getCategoryList();
            }
        },
        windowWidth(newValue) {
            this.isShowForm = newValue >= 768;
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        isDisableAddFeedback() {
            // 姓名未填寫完全
            if (!this.name) return true;
            // 聯絡信箱未填寫完全或格式有誤
            if (!this.email || this.isEmailError) return true;
            // 聯絡電話未填寫完全或格式有誤
            if (!this.phone || this.isPhoneError) return true;
            // 需求類別未填寫完全
            if (!this.category1 || !this.category2) return true;
            // 描述未填寫完全
            if (!this.description) return true;
            // 正在送出回饋
            if (this.isPostingApi.addFeedback) return true;

            return false;
        },
    },
    created: function() {
        this.initialize();
    },
    methods: {
        async initialize() {
            this.isShowForm = this.windowWidth >= 768;
            this.name = this.$store.state.common.userInfo.name;
            this.email = this.$store.state.common.userInfo.email;
            await this.getCategoryList();
            if (this.$route.query.c1) {
                this.changeCategory1(this.$route.query.c1);
            }
        },
        getCategoryList() {
            return new Promise((resolve, reject) => {
                const params = {
                    parentId: this.category1,
                };

                this.$httpRequest.get('/api/problem_feedback/get_category', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                if (params.parentId) {
                                    this.categoryList2 = result;
                                } else {
                                    this.categoryList1 = result;
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        changeCategory1(categoryId) {
            this.category1 = categoryId;
            this.category2 = '';
            this.getCategoryList();
        },
        checkEmail() {
            this.isEmailError = false;
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!this.email.match(/^$/) && !this.email.match(re)) {
                this.isEmailError = true;
            }
        },
        checkPhone() {
            this.isPhoneError = false;
            const re = /^[0-9]{10}$/;
            if (!this.phone.match(/^$/) && !this.phone.match(re)) {
                this.isPhoneError = true;
            }
        },
        addImage(event) {
            const files = event.target.files;
            const validFileTypes = ['jpg', 'jpeg', 'png', 'gif'];
            const perFileLimitSize = 4194304;  // 4mb

            this.uploadFileMsg = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (this.uploadFileMsg) {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過4MB或格式不符，請重新上傳', status: 'danger' });
                event.target.value = '';
                return;
            }

            const binary = files[0];
            this.images.push({ url: URL.createObjectURL(binary), file: binary });
            event.target.value = '';
        },
        delImage(index) {
            this.images.splice(index, 1);
        },
        addFeedback() {
            let params = new FormData(),
                data = {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    categoryId: this.category2,
                    description: this.description,
                    device: navigator.userAgent,
                    problemUrl: this.url,
                };

                params.append('problemInfo', JSON.stringify(data));

                for (let i = 0; i < this.images.length; i++) {
                    const key = `image_${i+1}`;
                    params.append(key, this.images[i].file);
                }

            this.isPostingApi.addFeedback = true;

            this.$httpRequest.post('/api/problem_feedback/add_problem', params)
                .then(response => {
                    this.isPostingApi.addFeedback = false;

                    if (response.data.state == 'OK') {
                        this.$router.push('/feedback/success');
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
    },
}
