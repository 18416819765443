import store from '@/store';

export default class WebSocket {
    /**
     * 初始化實體屬性
     * @param {String} name ws 名稱
     * @param {String} url ws url
     * @param {Function} msgCallback Server 發送訊息時的回調函數
     */
    constructor(name, url, msgCallback) {
        this.name = name;
        this.url = url;
        this.msgCallback = msgCallback;
    }
    #ws = {
        webSocket: null,
        isConnect: false,
        reconnectTimes: 0,
    }
    #heartCheck = {
        heartbeat: null,
        data: { action: 'ping' },
        timeout: 8 * 60 * 1000,  // 每 8分鐘 發送一次心跳包
    }
    /**
     * 初始化 WebSocket 與連線
     * @param {*} data 連線成功時要發送給 Server 的資料 (optional)
     */
    connect = function (data) {
        const WebSocket = window.WebSocket || window.MozWebSocket;

        if (!WebSocket) {
            this.#handleError('0');
            return;
        }

        // 連線 WebSocket
        this.#ws.webSocket = new WebSocket(this.url);

        // 註冊監聽事件
        this.#ws.webSocket.onopen = () => {
            console.log(`WebSocket State: ${this.name} connected (time: ${new Date().toLocaleString()})`);

            this.#ws.isConnect = true;
            this.#ws.reconnectTimes = 0;
            this.#startHeartCheck();
            store.commit('common/resetAlert');

            if (data) {
                this.send(data);
            }
        };
        this.#ws.webSocket.onmessage = (event) => {
            return this.msgCallback(JSON.parse(event.data));
        };
        this.#ws.webSocket.onclose = (event) => {
            console.log(`WebSocket State: ${this.name} closed (status code: ${event.code}, time: ${new Date().toLocaleString()})`);

            this.#ws.isConnect = false;
            this.#stopHeartCheck();

            // Server 端斷開連線, 則進行重新連線
            if (event.code === 1001) {
                this.connect();
                console.log('Reconnect WebSocket...');
            }

            // 非正常關閉連線時則嘗試重新連線
            if (event.code === 1006) {
                setTimeout(() => {
                    this.#ws.reconnectTimes++;
                    this.connect();
                    console.log('Reconnect WebSocket...');
                }, 1000);
            }
        };
        this.#ws.webSocket.onerror = () => {
            this.#ws.isConnect = false;
            this.#stopHeartCheck();
            this.#handleError('1');
        };
    }
    /**
     * 發送訊息給 Server
     * @param {Object|Any} data 要傳遞的資料
     */
    send = function (data) {
        if (!this.#ws.webSocket) return;
        this.#ws.webSocket.send(JSON.stringify(data));
    }
    /**
     * 關閉 WebSocket 連線
     */
    close = function () {
        if (!this.#ws.webSocket) return;
        this.#ws.webSocket.close(1000);
        this.#ws.webSocket = null;
        this.#ws.isConnect = false;
        this.#ws.reconnectTimes = 0;
        this.#stopHeartCheck();
    }
    /**
     * 取得網路連線狀態
     * @returns 網路連線狀態
     */
    getConnectionState = function () {
        return this.#ws.isConnect;
    }
    /**
     * 開始心跳監測
     */
    #startHeartCheck = function () {
        this.#heartCheck.heartbeat = setInterval(() => {
            if (this.#ws.isConnect) {
                this.send(this.#heartCheck.data);
                console.log(`WebSocket State: ${this.name} heartCheck (time: ${new Date().toLocaleString()})`);
            } else {
                this.#stopHeartCheck();
            }
        }, this.#heartCheck.timeout);
    }
    /**
     * 關閉心跳監測
     */
    #stopHeartCheck = function () {
        clearInterval(this.#heartCheck.heartbeat);
    }
    /**
     * 例外錯誤處理
     * @param {String} errCode 錯誤代碼
     * @param {String} errName 錯誤名稱
     */
    #handleError = function (errCode, errName = '') {
        const errMsg = {
            '-1': '系統繁忙中，請稍後再試',
            '0': '瀏覽器不支援 WebSocket 傳輸協定',
            '1': '網路異常，請確認網路連線狀況',
        };
        const errLog = {
            '-1': `Exception error`,
            '0': `"WebSocket" is not supported`,
            '1': `The connection was closed abnormally (locally) by the browser implementation`,
        };

        let outputLog = `WebSocket Error: ${errLog[errCode]} (${this.name})`;
        if (errCode === '-1' && errName) {
            outputLog = `${outputLog}: ${errName}`;
        }
        console.error(outputLog);

        if (errMsg[errCode]) {
            store.dispatch('common/setAlert', { msg: errMsg[errCode], status: 'danger', duration: -1 });
        }
    }
}
