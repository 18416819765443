// utils
import searchUtil from '@/assets/js/utils/search'

export default {
    props: {
        selectedCourseId: {
            required: true,
            type: String
        }
    },
    data: function() {
        return {
            ccIds: [],  // cc: course category
            ccInfos: {},
            expandedCCIds: {},

            keyword: '',
            onKeywordInput: this.$node.debounce(this.searchArticles, 500),
            articleList: [],  // 搜尋結果的文章列表
            isSearching: false,  // 是否正在搜尋文章

            selectedAIds: {},  // { aId: ccId }
            maxArticlesSelected: 25,  // 最多可被勾選的文章數量

            isSetDataReady: {
                getCategoryList: false,  // 取得指定課程下的分類列表
            }
        }
    },
    watch: {
        keyword(newValue) {
            this.isSearching = !!newValue;
        },
        selectedAIds(newValue) {
            if (newValue) {
                this.$emit('articleSelect', Object.keys(newValue));
            }
        }
    },
    computed: {
        searchUtil() {
            return searchUtil;
        }
    },
    created: function() {
        if (this.selectedCourseId) {
            this.getCategoryList();
        }
    },
    mounted: function() {
        this.registerDropdownEvents();
    },
    methods: {
        registerDropdownEvents() {
            // 一開啟文章下拉選單時
            $('#articleDropdown').on('show.bs.dropdown', () => {
                if (this.keyword) {
                    this.keyword = '';
                }
                this.checkSelectedArticles();
            });
            // 一關閉文章下拉選單時
            $('#articleDropdown').on('hide.bs.dropdown', () => {
                if (this.keyword) {
                    document.getElementById('articleList').scrollTop = 0;  // 置頂列表卷軸
                }
            });
        },

        getCategoryList() {
            let params = {
                courseId: this.selectedCourseId
            };

            this.$httpRequest.get('/api/course/get_category_id_name', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            for (const cc of result) {
                                const ccId = cc.id;
                                this.ccIds.push(ccId);

                                let ccInfo = {
                                    name: cc.name,
                                    isLoadingArticles: false
                                };
                                this.$set(this.ccInfos, ccId, ccInfo);
                            }

                            this.isSetDataReady.getCategoryList = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        expandCategories(ccIds) {
            const deletedCCIds = [];
            for (const ccId of ccIds) {
                // 收合
                if (this.expandedCCIds[ccId]) {
                    this.$delete(this.expandedCCIds, ccId);
                    deletedCCIds.push(ccId);
                    continue;
                }

                // 已讀取過資料
                if (this.ccInfos[ccId].articles) {
                    this.$set(this.expandedCCIds, ccId, 1);
                    deletedCCIds.push(ccId);
                    continue;
                }

                // 正在讀取資料
                if (this.ccInfos[ccId].isLoadingArticles) {
                    deletedCCIds.push(ccId);
                }
            }

            let handledCCIds = ccIds;
            for (const ccId of deletedCCIds) {
                const foundIndex = handledCCIds.indexOf(ccId);
                if (~foundIndex) {
                    this.$delete(handledCCIds, foundIndex);
                }
            }
            if (!handledCCIds.length) {
                return;
            }

            // 正在讀取資料
            for (const ccId of handledCCIds) {
                this.ccInfos[ccId].isLoadingArticles = true;
            }

            let params = {
                courseId: this.selectedCourseId,
                categoryIds: handledCCIds
            };

            this.$httpRequest.post('/api/course/get_article_by_categories', params)
                .then(response => {
                    for (const ccId of params.categoryIds) {
                        this.ccInfos[ccId].isLoadingArticles = false;
                    }

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            for (const ccId of params.categoryIds) {
                                let articles = [];

                                for (const a of result[ccId]) {
                                    articles.push({
                                        id: a.id,
                                        name: a.name
                                    });
                                }
                                this.$set(this.ccInfos[ccId], 'articles', articles);

                                this.$set(this.expandedCCIds, ccId, 1);
                            }
                        }
                    }
                })
                .catch(error => {
                    for (const ccId of params.categoryIds) {
                        this.ccInfos[ccId].isLoadingArticles = false;
                    }
                    console.error('Catched Error:', error);
                });
        },

        searchArticles() {
            if (!this.keyword) {
                this.checkSelectedArticles();
                return;
            }

            let params = {
                courseId: this.selectedCourseId,
                keyword: this.keyword
            };

            this.$httpRequest.get('/api/course/get_articles_by_search', params)
                .then(response => {
                    this.isSearching = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.articleList = [];

                            for (const a of result) {
                                this.articleList.push({
                                    id: a.id,
                                    name: a.name,
                                    categoryId: a.course_category_id
                                });
                            }
                        }
                    }
                })
                .catch(error => {
                    this.isSearching = false;
                    console.error('Catched Error:', error);
                });
        },
        clearKeyword() {
            this.keyword = '';
            document.getElementById('articleListKeyword').focus();
            this.checkSelectedArticles();
        },

        selectArticle(event, ccId) {
            const aId = event.target.value;

            if (event.target.checked) {
                if (Object.keys(this.selectedAIds).length === this.maxArticlesSelected) {
                    event.target.checked = false;
                    this.$store.dispatch('common/setAlert', { msg: `最多只能選取${this.maxArticlesSelected}篇文章`, status: 'danger' });
                    return;
                }

                this.$set(this.selectedAIds, aId, ccId);
            } else {
                this.$delete(this.selectedAIds, aId);
            }
        },
        checkSelectedArticles() {
            // 當取消搜尋狀態後恢復為分類列表時, 需展開有勾選文章的分類
            let handledCCIds = [];

            for (const aId in this.selectedAIds) {
                const ccId = this.selectedAIds[aId];

                if (!this.expandedCCIds[ccId] && !handledCCIds.includes(ccId)) {
                    handledCCIds.push(ccId);
                }
            }

            this.expandCategories(handledCCIds);
        }
    }
}
