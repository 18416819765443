// components
const OrderList = resolve => require(["@/components/user/orders/_order_list.vue"], resolve)

export default {
    components: {
        OrderList
    },
    data: function() {
        return {
            isSortDateDesc: true,  // 訂購日期遞減排序(最新到最舊)

            activeTab: this.$route.params.subPage || 'purchased',

            allSubscribeOrdersInfo: [],
            orderNoToCourseId: {},  // 訂閱制課程, 訂單編號對到的課程 id
            allPurchasedOrderNos: [],

            isSetDataReady: {
                getOrdersData: false,  // 取得訂單項目 courseIds & orderNos
            }
        }
    },
    watch: {
        '$route.params.subPage'(newValue) {
            // 處理瀏覽器切換上一頁時, activeTab 沒有即時同步的狀況
            if (this.activeTab !== newValue) {
                this.changeTab(newValue);
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        purchasingPurposeList() {
            let types = [];
            types = [
                {
                    value: false,
                    text: '我的課程'
                },
                {
                    value: true,
                    text: '課程贈送'
                }
            ]
            return types;
        },
        isGiftOrder: {
            get() {
                return this.$store.state.user.isGiftOrder;
            },
            set(value) {
                this.$store.commit('user/setIsGiftOrder', value);
            }
        },
        tabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'purchased',
                    text: '課程制'
                },
                {
                    value: 'subscribe',
                    text: '訂閱制'
                }
            ]
            return tabs;
        },
        isShowSortBtn() {
            return (this.activeTab === 'subscribe' && this.allSubscribeOrdersInfo.length > 1) ||
                    (this.activeTab === 'purchased' && this.allPurchasedOrderNos.length > 1);
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userOrders');

        this.getOrdersData();
    },
    methods: {
        async getOrdersData() {
            this.isSetDataReady.getOrdersData = false;

            // 訂閱制
            if (this.activeTab === 'subscribe') {
                await this.getAllSubscribeOrdersInfo();
            }
            // 課程制
            if (this.activeTab === 'purchased') {
                await this.getAllPurchasedOrderNos();
            }

            this.isSetDataReady.getOrdersData = true;
        },
        getAllSubscribeOrdersInfo() {
            return new Promise((resolve, reject) => {
                let params = {
                    isGift: this.isGiftOrder ? '1' : '0'
                }

                this.$httpRequest.get('/api/order/get_subscribe_list_course_ids', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allSubscribeOrdersInfo = [];

                                for (let el of result) {
                                    this.allSubscribeOrdersInfo.push({
                                        courseId: el.course_id,
                                        orderNo: el.order_no
                                    });

                                    this.orderNoToCourseId[el.order_no] = el.course_id;
                                }

                                // 如果時間排序設定為正序(舊->新), 則需反轉陣列
                                if (!this.isSortDateDesc) {
                                    this.$_.reverse(this.allSubscribeOrdersInfo);
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getAllPurchasedOrderNos() {
            return new Promise((resolve, reject) => {
                let params = {
                    isGift: this.isGiftOrder ? '1' : '0'
                }

                this.$httpRequest.get('/api/order/get_buyout_list_order_nos', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allPurchasedOrderNos = result;

                                // 如果時間排序設定為正序(舊->新), 則需反轉陣列
                                if (!this.isSortDateDesc) {
                                    this.$_.reverse(this.allPurchasedOrderNos);
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        changeTab(targetTab) {
            this.activeTab = targetTab;
            this.getOrdersData()
                .then(() => this.$router.push(`/user/${this.isGiftOrder ? 'gift-orders' : 'orders'}/${targetTab}`).catch(() => {}));
        },
        sortItemByDate() {
            this.isSortDateDesc = !this.isSortDateDesc;
            this.getOrdersData();
        }
    }
}
