import { mapGetters } from 'vuex'
// components
const OrderItemList = resolve => require(["@/components/order/index/_order_item_list.vue"], resolve)
const OrderInfo = resolve => require(["@/components/order/index/_order_info.vue"], resolve)
const SelectCouponDialogue = resolve => require(["@/components/order/index/_select_coupon_dialogue.vue"], resolve)

export default {
    components: {
        OrderItemList,
        OrderInfo,
        SelectCouponDialogue
    },
    data: function() {
        return {
            courseBundleCases: {},  // 課程制, 課程合購組合
            bundleCasesToPrompt: {},  // 課程制, 需提示的課程合購組合
            isHideBanner: localStorage.getItem('hideGiftBanner') === '1' || false,

            isSetDataReady: {
                getItems: false  // 取得商品列表
            }
        }
    },
    computed: {
        ...mapGetters({
            currentCoursePurchaseType: 'order/currentCoursePurchaseType'
        }),
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        isCheckToRenewOrder: {
            get() {
                return this.$store.state.order.isCheckToRenewOrder;
            },
            set(value) {
                this.$store.commit('order/setIsCheckToRenewOrder', value);
            }
        },
        currentGiftOrderItems: {
            get() {
                return this.$store.state.order.currentGiftOrderItems;
            },
            set(value) {
                this.$store.commit('order/setCurrentGiftOrderItems', value);
            }
        },
        orderItemsInfo: {
            get() {
                return this.$store.state.order.giftOrderItemsInfo;
            },
            set(value) {
                this.$store.commit('order/setGiftOrderItemsInfo', value);
            }
        },
        orderItemCourseIds: {
            get() {
                return this.$store.state.order.orderItemCourseIds;
            },
            set(value) {
                this.$store.commit('order/setOrderItemCourseIds', value);
            }
        },
        confirmSelectedCoupon: {
            get() {
                return this.$store.state.order.confirmSelectedCoupon;
            },
            set(value) {
                this.$store.commit('order/setConfirmSelectedCoupon', value);
            }
        },
        giftExchangeCount: {
            get() {
                return this.$store.state.order.giftExchangeCount;
            },
            set(value) {
                this.$store.commit('order/setGiftExchangeCount', value);
            }
        },
        bannerImageSrc() {
            let d = new Date();
            let currentMonth = d.getMonth() + 1;

            // 12月初 ~ 2月底 使用節日版本
            if ([12, 1, 2].includes(currentMonth)) {
                return require('@/assets/image/order/gift_banner_festival.png');
            }
            // 3月初 ~ 11月底 使用一般版本
            else {
                return require('@/assets/image/order/gift_banner_default.png');
            }
        },
        checkCouponCourseIds() {
            if (!this.currentGiftOrderItems.length || !this.isSetDataReady.getItems) {
                return [];
            }

            // 需排除合購方案的課程 ids
            let courseIds = [];

            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                if (!this.orderItemsInfo[id].order.isFitBundleCase) {
                    courseIds.push(id);
                }
            }

            return courseIds;
        },
        isDisableUseCoupon() {
            if (!this.currentGiftOrderItems.length || !this.isSetDataReady.getItems) {
                return false;
            }

            // 訂閱制課程, 選擇訂閱方案不得使用優惠券
            // 購買制課程, 若所有購買商品皆適用合購優惠組合, 則不得使用優惠券
            let isDisable = !this.checkCouponCourseIds.length;

            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                if (this.orderItemsInfo[id].course.purchaseType === '0' &&
                    this.orderItemsInfo[id].order.type === 'plan') {
                    isDisable = true;
                    break;
                }
            }

            return isDisable;
        },
        isHideRenewOrder() {
            let isEnable = true;
            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }
                if (this.orderItemsInfo[id].order.quantity == 1) {
                    isEnable = true;
                    this.isCheckToRenewOrder = true;
                    break;
                }
                if (this.orderItemsInfo[id].order.quantity > 1) {
                    isEnable = false;
                    this.isCheckToRenewOrder = false;
                    break;
                }
            }

            return isEnable;
        },
        originalTotalPrice() {
            if (!this.currentGiftOrderItems.length || !this.isSetDataReady.getItems) {
                return 0;
            }

            let totalPrice = 0,
                noBundleTotalPrice = 0,
                quantity = 0;

            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                totalPrice += (this.orderItemsInfo[id].order.quantity * this.orderItemsInfo[id].order.unit * this.orderItemsInfo[id].order.price);
                // 把沒有合購的挑出(目前贈送用不到，但未來可能會)
                if (!this.orderItemsInfo[id].order.isFitBundleCase) {
                    noBundleTotalPrice += (this.orderItemsInfo[id].order.quantity * this.orderItemsInfo[id].order.unit * this.orderItemsInfo[id].order.price);
                }
                quantity += this.orderItemsInfo[id].order.quantity;
            }

            let result = {
                price: totalPrice,
                noBundleTotal: noBundleTotalPrice,
                quantity: quantity
            };

            return result;
        },
        orderTotalPrice() {
            let totalPrice = this.originalTotalPrice.price,
                noBundleTotal = this.originalTotalPrice.noBundleTotal, // 合購價格
                discount = 0; // 折抵金額

            // 使用優惠券(折扣價格)
            if (!this.$_.isEmpty(this.confirmSelectedCoupon)) {
                // 折扣金額
                if (this.confirmSelectedCoupon.discountType == '2') {
                    let discountPrice = Math.round(noBundleTotal * (this.confirmSelectedCoupon.discount / 100));
                    discount = noBundleTotal - discountPrice;
                    totalPrice = discountPrice + totalPrice - noBundleTotal;
                }
                // 折價金額
                else {
                    totalPrice = totalPrice - this.confirmSelectedCoupon.discount;
                }
                // 若折價後總計已小於 0 時, 則以 $0 顯示
                if (totalPrice < 0) {
                    totalPrice = 0;
                }
            }

            let result = {
                price: totalPrice,
                discount: discount
            }

            return result;
        }
    },
    created: function() {
        this.getData();
    },
    mounted: function() {
        window.addEventListener('scroll', this.bannerBlockScrollHandler);

        if (this.windowWidth >= 768) {
            $(document).ready(() => {
                let elemFooterHeight = $('.lms-footer').height();  // footer 高度

                if (($(document).height() - $(window).height()) <= elemFooterHeight) {
                    $('.banner-block').addClass('absoulte-position').removeClass('sticky-position');
                } else {
                    $('.banner-block').addClass('sticky-position').removeClass('absoulte-position');
                }
            });
        }
    },
    beforeDestroy: function() {
        window.removeEventListener('scroll', this.bannerBlockScrollHandler);
    },
    methods: {
        async getData() {
            await this.addGiftOrderItemsToList();

            // 若欲贈送 '課程制' 課程, 則需檢查該課程是否有合購資訊
            if (this.currentCoursePurchaseType === '1') {
                let payload = {
                    courseIds: this.orderItemCourseIds,
                    isGiftOrder: true
                }

                this.courseBundleCases = await this.$store.dispatch('order/getCourseBundleCases', payload);

                // 提示合購優惠組合給使用者
                for (let caseId in this.courseBundleCases) {
                    if (this.courseBundleCases[caseId].isPromptToUser) {
                        this.bundleCasesToPrompt[caseId] = this.courseBundleCases[caseId];
                    }
                }
            }

            this.isSetDataReady.getItems = true;
        },
        addGiftOrderItemsToList() {
            return new Promise((resolve, reject) => {
                if (!this.currentGiftOrderItems.length) {
                    reject("order/gift_orders.js: 'addGiftOrderItemsToList' error");
                    return;
                }

                let quantity = this.giftExchangeCount > 0 ? this.giftExchangeCount : 1;
                this.orderItemCourseIds = [];

                for (let el of this.currentGiftOrderItems) {
                    this.orderItemsInfo[el.courseInfo.id] = this.orderUtil.setOrderItemInfo(el.courseInfo, el.selectedPrice, quantity);
                    this.orderItemCourseIds.push(el.courseInfo.id);
                }

                if (this.orderItemCourseIds.length) {
                    resolve();
                } else {
                    reject("order/gift_orders.js: 'addGiftOrderItemsToList' error");
                }
                // 清空已選擇的優惠券，避免從"付款資訊"頁面回合購時出現優惠券
                this.confirmSelectedCoupon = {};
            });
        },
        addBundleCoursesToList(bundleCase, caseId) {
            $('.lms-btn.plain-text').blur();
            let element = {},
                quantity = this.giftExchangeCount > 0 ? this.giftExchangeCount : 1;
            // 將課程加入贈送內容
            for (let el of bundleCase.courses) {
                if (!this.orderItemCourseIds.includes(el.id)) {
                    element = el;
                }
            }

            if (element) {
                this.orderItemsInfo[element.id] = this.orderUtil.setOrderItemInfo(element, {}, quantity);
                this.orderItemCourseIds.push(element.id);
            }

            // 顯示合購折扣後的價錢
            for (let el of bundleCase.courses) {
                if (!this.orderItemsInfo[el.id]) {
                    continue;
                }

                // 價錢旁邊顯示 '合購優惠'
                this.orderItemsInfo[el.id].order.isFitBundleCase = true;
                // 目前顯示的價錢改為合購優惠價錢
                this.orderItemsInfo[el.id].order.price = +el.discountPrice;
                // 記錄課程對應到的合購課程組合 id
                this.orderItemsInfo[el.id].order.bundleCaseId = caseId;
            }

            // 目前合購優惠組合已不需提示使用者
            this.$delete(this.bundleCasesToPrompt, caseId);

            // 清空已選擇的優惠券
            this.confirmSelectedCoupon = {};
        },
        editQuantity(item, type) {
            if (type == 'plus') {
                if (this.courseBundleCases[item.order.bundleCaseId]) { // 如果是合購，所有的數量都要+1
                    this.courseBundleCases[item.order.bundleCaseId].courses.forEach(el => {
                        if (this.orderItemsInfo[el.id]) {
                            this.orderItemsInfo[el.id].order.quantity++;
                            this.giftExchangeCount = this.orderItemsInfo[el.id].order.quantity;
                        }
                    });
                }
                else {
                    this.orderItemsInfo[item.course.id].order.quantity++;
                    this.giftExchangeCount = this.orderItemsInfo[item.course.id].order.quantity;
                }
            }
            else {
                if (this.courseBundleCases[item.order.bundleCaseId]) { // 如果是合購，所有的數量都要-1
                    this.courseBundleCases[item.order.bundleCaseId].courses.forEach(el => {
                        if (this.orderItemsInfo[el.id]) {
                            this.orderItemsInfo[el.id].order.quantity--;
                            this.giftExchangeCount = this.orderItemsInfo[el.id].order.quantity;
                        }
                    });
                }
                else {
                    this.orderItemsInfo[item.course.id].order.quantity--;
                    this.giftExchangeCount = this.orderItemsInfo[item.course.id].order.quantity;
                }
            }
            // 讓orderItemCourseIds重新assign值，這樣可讓所有的component得到新值
            let uniqueCourseIds = [...new Set(this.orderItemCourseIds)];
            this.orderItemCourseIds = uniqueCourseIds;
        },
        deleteItemFromGiftOrder(item, index) {
            // 目前贈送訂單內容為 '課程制' 課程並且課程數目 > 1, 表示目前購買合購課程組合
            // 若刪除其一課程, 則需提示合購優惠組合給使用者
            if (this.currentCoursePurchaseType === '1' && this.orderItemCourseIds.length > 1) {
                // 若刪除的課程為合購課程組合內的課程, 則課程顯示價格需改回原本售價
                if (this.courseBundleCases[item.order.bundleCaseId]) {
                    let tempBundleCase = this.courseBundleCases[item.order.bundleCaseId];
                    let courseCount = 0;  // 目前訂單內該合購優惠組合的課程數量

                    for (let el of tempBundleCase.courses) {
                        if (!this.orderItemsInfo[el.id]) {
                            continue;
                        }

                        courseCount++;

                        // 價錢旁邊隱藏 '合購優惠'
                        this.orderItemsInfo[el.id].order.isFitBundleCase = false;
                        // 目前顯示的價錢改成 'originalPrice'
                        this.orderItemsInfo[el.id].order.price = +this.orderItemsInfo[el.id].order.originalPrice;
                    }

                    // 目前訂單內符合合購優惠組合的課程數量僅剩 1個, 表示刪除此課程後即可不需提示此合購優惠組合
                    if (courseCount === 1) {
                        // 從合購優惠組合列表中刪除目前合購組合
                        this.$delete(this.courseBundleCases, item.order.bundleCaseId);
                        this.$delete(this.bundleCasesToPrompt, item.order.bundleCaseId);
                    } else if (courseCount > 1) {
                        // 提示合購優惠組合給使用者
                        this.courseBundleCases[item.order.bundleCaseId].isPromptToUser = true;
                        this.bundleCasesToPrompt[item.order.bundleCaseId] = this.courseBundleCases[item.order.bundleCaseId];
                    }
                }

                // 從顯示的商品列表中刪除課程
                this.$delete(this.orderItemsInfo, item.course.id);
                this.$delete(this.orderItemCourseIds, index);

                this.orderItemsInfo = this.$_.cloneDeep(this.orderItemsInfo);
                this.orderItemCourseIds = this.$_.cloneDeep(this.orderItemCourseIds);
                // 清空已選擇的優惠券
                this.confirmSelectedCoupon = {};

                return;
            }

            let redirectCourseId = localStorage.getItem('fromCourse');
            if (redirectCourseId && redirectCourseId !== '0') {
                localStorage.removeItem('fromCourse');
                this.currentGiftOrderItems = [];  // 刪除目前贈送訂單商品
                this.$router.push(`/course/${redirectCourseId}`).catch(() => {});
            } else {
                this.$router.push('/').catch(() => {});
            }
        },
        bannerBlockScrollHandler() {
            if (this.windowWidth < 768) {
                return;
            }

            let elemFooterHeight = $('.lms-footer').height();  // footer 高度

            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  // 取得 elem 因包含 overflow 而沒顯示在螢幕上的內容高度
            let windowHeight = $(window).innerHeight();  // 取得目前可視範圍高度
            let scrollTop = $(window).scrollTop(); // 取得卷軸目前的 Y座標 位置

            if (windowHeight + scrollTop >= scrollHeight - elemFooterHeight) {
                $('.banner-block').addClass('absoulte-position').removeClass('sticky-position');
            } else {
                $('.banner-block').addClass('sticky-position').removeClass('absoulte-position');
            }
        },
        hideBanner() {
            this.isHideBanner = true;
            localStorage.setItem('hideGiftBanner', '1');
        }
    }
}
