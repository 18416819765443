import mime from 'mime-types'

export default class AudioRecorder {
    constructor(data) {
        this.sampleRate = data.sampleRate;
        this.channelCount = data.channelCount;
    }

    #errMsg = {
        '-1': '系統繁忙中，請稍後再試',
        '0': '瀏覽器未使用https協定請求或不支援存取多媒體數據',
        '1': '瀏覽器尚未允許麥克風輸入權限',
        '2': '瀏覽器找不到麥克風裝置',
        '3': '瀏覽器不支援存取多媒體數據'
    }
    #errLog = {
        '-1': `Exception error`,
        '0': `"mediaDevices API" or "getUserMedia" is not supported`,
        '1': `A "NotAllowedError" has occured`,
        '2': `A "NotFoundError" has occured`,
        '3': `The browser doesn't support the specified MIME type`
    }

    #recorder = {
        mediaRecorder: null,  /* MediaRecorder */
        capturedStream: null,  /* MediaStream */
        audioBlobs: []  /* Blob[] */
    }

    /**
     * 初始瀏覽器錄音設定
     */
    init = async function () {
        // 不支援存取多媒體數據 (不支援 WebRTC)
        if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
            return Promise.reject(this.#getRejectedMsg('0'));
        }

        try {
            // 初始音訊 Blob 資料
            this.#recorder.audioBlobs = [];

            // 取得聲音流
            let constraints = {
                channelCount: this.channelCount
            }
            this.#recorder.capturedStream = await navigator.mediaDevices.getUserMedia({ audio: constraints });

            // 取得輸入裝置名稱
            const tracks = this.#recorder.capturedStream.getTracks();
            const label = tracks[0].label;

            return Promise.resolve(label);
        } catch (error) {
            let errCode = this.#handleGetUserMediaExceptions(error.name);
            return Promise.reject(this.#getRejectedMsg(errCode, error.name));
        }
    }

    /**
     * 開始錄音
     */
    start = function () {
        try {
            const isChrome = /Chrome|CriOS/i.test(navigator.userAgent);
            const isSafari = /Safari/i.test(navigator.userAgent);

            let mimeType = isSafari && !isChrome ? 'audio/mp4' : 'audio/webm;codecs=pcm';

            let options = {
                mimeType: mimeType
            }
            this.#recorder.mediaRecorder = new MediaRecorder(this.#recorder.capturedStream, options);

            // store the audio data Blobs when recording
            this.#recorder.mediaRecorder.addEventListener('dataavailable', event => {
                // console.log(event.data.size);
                event.data.size && this.#recorder.audioBlobs.push(event.data);
            });

            // start the recording (inactive -> recording)
            this.#recorder.mediaRecorder.start();
        } catch (error) {
            let errCode = this.#handleMediaRecorderExceptions(error.name);
            return Promise.reject(this.#getRejectedMsg(errCode, error.name));
        }
    }

    /**
     * 暫停錄音
     */
    pause = function () {
        // pause the recording (recording -> paused)
        this.#recorder.mediaRecorder.pause();
    }

    /**
     * 恢復錄音
     */
    resume = async function () {
        try {
            const isTouchDevice = navigator.maxTouchPoints > 0;
            if (!isTouchDevice) {  // 非觸控裝置才再次檢查麥克風裝置是否正常
                await navigator.mediaDevices.getUserMedia({ audio: true });
            }

            // resume the recording (paused -> recording)
            this.#recorder.mediaRecorder.resume();
        } catch (error) {
            let errCode = this.#handleGetUserMediaExceptions(error.name);
            return Promise.reject(this.#getRejectedMsg(errCode, error.name));
        }
    }

    /**
     * 停止錄音
     */
    stop = function () {
        // stop the recording (recording -> inactive)
        this.#recorder.mediaRecorder.stop();

        // 關閉麥克風
        if (this.#recorder.capturedStream) {
            const tracks = this.#recorder.capturedStream.getTracks();
            // tracks[0] -> MediaStreamTrack
            for (const track of tracks) {
                track.stop();
            }
        }

        this.#resetProperties();
    }

    /**
     * 取得錄音檔案 (.webm, .weba, .m4a)
     * @returns {Promise.<File>} 若成功執行則回傳錄音檔案
     */
    getFile = function () {
        return new Promise(resolve => {
            this.#recorder.mediaRecorder.addEventListener('stop', () => {
                let mimeType = this.#recorder.mediaRecorder.mimeType;
                let extension = mime.extension(mimeType);

                // create a single blob object
                let audioBlob = new Blob(this.#recorder.audioBlobs, { type: mimeType });

                let rand = Math.floor(Math.random() * 1000000);
                let audioFile = new File([audioBlob], `voice_${rand}.${extension}`, { type: mimeType });

                resolve(audioFile);
            });
        });
    }

    /**
     * 取得輸入音量的平均分貝數 (暫不支援)
     */
    getDecibel = function () {
        return 100;
    }

    /**
     * 重設屬性值
     * @private
     */
    #resetProperties = function () {
        this.#recorder.capturedStream = null;
    }

    /**
     * 處理 `getUserMedia()` 捕獲的例外錯誤
     * @private
     * @param {String} errName 錯誤名稱
     * @returns {String} 錯誤代碼
     */
    #handleGetUserMediaExceptions = function (errName) {
        switch (errName) {
            case 'NotAllowedError':
                return '1';
            case 'NotFoundError':
                return '2';
            default:
                return '-1';
        }
    }

    /**
     * 處理 `MediaRecorder()` 捕獲的例外錯誤
     * @private
     * @param {String} errName 錯誤名稱
     * @returns {String} 錯誤代碼
     */
    #handleMediaRecorderExceptions = function (errName) {
        switch (errName) {
            /**
             * "NotSupportedError": The specified MIME type is not supported by the user agent.
             */
            case 'NotSupportedError':
                return '3';
            default:
                return '-1';
        }
    }

    /**
     * 取得錯誤返回內容
     * @private
     * @param {String} errCode 錯誤代碼
     * @param {String} errName 錯誤名稱
     * @returns {String} 包含代碼與訊息的回應內容
     */
    #getRejectedMsg = function (errCode, errName = '') {
        let outputLog = `Audio Recorder Error: ${this.#errLog[errCode]}`;
        if (errCode === '-1' && errName) {
            outputLog = `${outputLog}: ${errName}`;
        }
        console.error(outputLog);

        let response = { code: errCode, msg: this.#errMsg[errCode] };

        return JSON.stringify(response);
    }
}
