import { mapGetters } from 'vuex'
// components
const AgreementDialogue = resolve => require(["@/components/order/checkout/_agreement_dialogue.vue"], resolve)

export default {
    components: {
        AgreementDialogue
    },
    data: function() {
        return {
            // 訂購人資訊 (我的專區頁面帶入)
            inputBuyerName: '',
            inputBuyerPhone: '',
            inputBuyerTelephone: '',
            inputBuyerEmail: '',

            // 發票形式
            selectedInvoiceType: 'ezpay',
            // 雲端發票
            inputCarrierNumber: '',
            // 統編發票
            inputUbn: '',
            inputCompanyName: '',
            inputCompanyEmail: '',
            // 是否與訂購人資訊 email 一致
            isCheckSameEmail: false,
            // 愛心捐贈
            selectedLoveCode: '',

            isOrderListExpanded: false,  // 是否展開購買列表(預設收合)

            // 是否勾選「已詳閱並同意 線上課程購買暨服務契約 所論述的內容。」
            isCheckAgreement: false,

            isSetDataReady: {
                getBuyerInfo: false,  // 取得訂購人資訊
                getCourseBasicInfo: false,  // 取得課程基本資訊 (for Webview 串接)
            },
            isPostingApi: {
                createOrder: false,  // 前往付款(建立訂單)
            },

            isWebviewError: false,
        }
    },
    computed: {
        ...mapGetters({
            currentCoursePurchaseType: 'order/currentCoursePurchaseType'
        }),
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isAppWebview() {
            return this.$store.state.common.isAndroidWebview || this.$store.state.common.isiOSWebview;
        },
        isAndroidWebview() {
            return this.$store.state.common.isAndroidWebview;
        },
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        isGiftOrder() {
            return this.$store.state.order.isGiftOrder;
        },
        isCheckToRenewOrder() {
            return this.$store.state.order.isCheckToRenewOrder;
        },
        orderItemsInfo() {
            return this.isGiftOrder ? this.$store.state.order.giftOrderItemsInfo : this.$store.state.order.orderItemsInfo;
        },
        quantity() { // 每個課程購買數量總數
            let quantity = 0;
            for (let id of this.orderItemCourseIds) {
                quantity += this.orderItemsInfo[id].order.quantity;
            }
            return quantity;
        },
        appUsedItemsInfo: {  // App端 webview用, 未來將移除
            get() {
                return this.$store.state.order.orderItemsInfo;
            },
            set(value) {
                this.$store.commit('order/setOrderItemsInfo', value);
            }
        },
        orderItemCourseIds: {
            get() {
                return this.$store.state.order.orderItemCourseIds;
            },
            set(value) {
                this.$store.commit('order/setOrderItemCourseIds', value);
            }
        },
        isCellPhoneInputValid() {
            let regExp = /^0{0,1}9[0-9]{8}$/; // 手機號碼必須09開頭
            return value => value == '' ? true : regExp.test(value);
        },
        isEmailInputValid() {
            let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // email格式
            // 不是email格式則為 false
            return value => !value || regExp.test(value);  // 排除 value 為空的情況
        },
        invoiceTypeList() {
            let types = [];
            types = [
                {
                    value: 'mobile',
                    text: '雲端發票'
                },
                {
                    value: 'ezpay',
                    text: '電子發票'
                },
                {
                    value: 'ubn',
                    text: '統編發票'
                },
                {
                    value: 'lovecode',
                    text: '愛心捐贈'
                }
            ]
            return types;
        },
        loveCodeList() {
            let items = [];
            items = [
                {
                    value: '',
                    text: '請選擇'
                },
                {
                    value: '51811',
                    text: '51811 財團法人第一社會福利基金會'
                },
                {
                    value: '531',
                    text: '531 財團法人董氏基金會'
                },
                {
                    value: '88518',
                    text: '88518 社團法人台北市脊髓損傷者協會'
                },
                {
                    value: '9217',
                    text: '9217 財團法人台北市關懷台灣文教基金會'
                }
            ]
            return items;
        },
        invoiceNameMaxLength() {
            return key => {
                // 發票形式非統編發票時, 則訂購人姓名為發票名稱(限30字)
                if (this.selectedInvoiceType !== 'ubn' && key === 'inputBuyerName') {
                    return 30;
                }
                // 發票形式為統編發票時, 則公司抬頭為發票名稱(限60字)
                if (this.selectedInvoiceType === 'ubn' && key === 'inputCompanyName') {
                    return 60;
                }
            }
        },
        isInvoiceNameInputLengthValid() {
            return (key, value) => !value || value.length <= this.invoiceNameMaxLength(key);  // 排除 value 為空的情況
        },
        confirmSelectedCoupon() {
            return this.$store.state.order.confirmSelectedCoupon;
        },
        currentSubTotal: {
            get() {
                return this.$store.state.order.currentSubTotal;
            },
            set(value) {
                this.$store.commit('order/setCurrentSubTotal', value);
            }
        },
        currentTotalAmount: {
            get() {
                return this.$store.state.order.currentTotalAmount;
            },
            set(value) {
                this.$store.commit('order/setCurrentTotalAmount', value);
            }
        },
        isDisableCreateOrder() {
            // 訂單筆數 = 0
            if (!this.orderItemCourseIds.length) {
                return;
            }
            // 訂單結帳總金額 = 0
            if (!this.currentTotalAmount) {
                return true;
            }

            // 訂購人資訊
            // 姓名、聯絡電話、聯絡信箱 皆必填
            if (!this.inputBuyerName || (!this.inputBuyerPhone && !this.inputBuyerTelephone) || !this.inputBuyerEmail) {
                return true;
            }
            // 姓名字數是否在限制內
            if (this.invoiceNameMaxLength('inputBuyerName') && !this.isInvoiceNameInputLengthValid('inputBuyerName', this.inputBuyerName)) {
                return true;
            }
            // 聯絡手機格式需正確
            if (this.inputBuyerPhone && !this.isCellPhoneInputValid(this.inputBuyerPhone)) {
                return true;
            }
            // 聯絡信箱格式需正確
            if (!this.isEmailInputValid(this.inputBuyerEmail)) {
                return true;
            }

            // 發票形式
            switch (this.selectedInvoiceType) {
                // 雲端發票
                case 'mobile':
                    // 手機條碼 必填
                    if (!this.inputCarrierNumber) {
                        return true;
                    }
                    break;
                // 統編發票
                case 'ubn':
                    // 統一編號、公司抬頭、發票寄送信箱 皆必填
                    if (!this.inputUbn || !this.inputCompanyName || !this.inputCompanyEmail) {
                        return true;
                    }
                    // 公司抬頭字數是否在限制內
                    if (this.invoiceNameMaxLength('inputCompanyName') && !this.isInvoiceNameInputLengthValid('inputCompanyName', this.inputCompanyName)) {
                        return true;
                    }
                    // 發票寄送信箱格式需正確
                    if (!this.isEmailInputValid(this.inputCompanyEmail)) {
                        return true;
                    }
                    break;
                case 'lovecode':
                    // 捐贈單位 必填
                    if (!this.selectedLoveCode) {
                        return true;
                    }
                    break;
            }

            // 沒有勾選「已詳閱並同意 線上課程購買暨服務契約 所論述的內容。」
            if (!this.isCheckAgreement) {
                return true;
            }

            return false;
        },
        paymentPageHtml: {
            get() {
                return this.$store.state.order.paymentPageHtml;
            },
            set(value) {
                this.$store.commit('order/setPaymentPageHtml', value);
            }
        }
    },
    created: function() {
        this.getBuyerInfo();

        // 如果 userAgent 為 App端的 Webview, 則擷取網址上的課程 Id
        if (this.isAppWebview) {
            if (this.$route.query.courseId) {
                // 取得課程基本資訊
                this.getCourseBasicInfo(this.$route.query.courseId);
            } else {  // 沒有課程 Id
                this.isWebviewError = true;
            }
        }
        // userAgent 非 App端的 Webview
        else {
            this.isSetDataReady.getCourseBasicInfo = true;
        }
    },
    methods: {
        getBuyerInfo() {
            this.$httpRequest.get('/api/user/get_buyer_info')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.inputBuyerName = result.buyer_name;
                            this.inputBuyerPhone = result.buyer_phone;
                            this.inputBuyerTelephone = result.buyer_telephone;
                            this.inputBuyerEmail = result.contact_email;

                            this.isSetDataReady.getBuyerInfo = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getCourseBasicInfo(courseId) {
            let params = {
                courseId: courseId
            }

            this.$httpRequest.get('/api/course/get_basic_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let courseInfo = {
                                id: result.course_info.id,
                                name: result.course_info.name,
                                cover: result.course_info.cover.file_path,
                                purchaseType: result.course_info.purchase_type,
                                price: result.course_info.price
                            }

                            // 設置目前購買項目的資訊與金額總計
                            this.appUsedItemsInfo[courseInfo.id] = this.orderUtil.setOrderItemInfo(courseInfo);
                            this.orderItemCourseIds = [courseInfo.id];

                            this.currentSubTotal = +this.appUsedItemsInfo[courseInfo.id].order.unit * +this.appUsedItemsInfo[courseInfo.id].order.price;
                            this.currentTotalAmount = this.currentSubTotal;

                            this.isSetDataReady.getCourseBasicInfo = true;
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.isWebviewError = true;
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },

        resetInvoiceSettings() {
            // 雲端發票
            this.inputCarrierNumber = '';

            // 統編發票
            this.inputUbn = '';
            this.inputCompanyName = '';
            this.inputCompanyEmail = '';
            this.isCheckSameEmail = false;

            // 愛心捐贈
            this.selectedLoveCode = '';
        },
        setEmailSameAsBuyerInfo() {
            this.isCheckSameEmail = !this.isCheckSameEmail;
            this.inputCompanyEmail = this.isCheckSameEmail ? this.inputBuyerEmail : '';
        },

        showAgreementDialogue() {
            $('#agreementDialogue').modal('show');
        },

        createOrder() {
            if (this.isDisableCreateOrder) {
                return;
            }

            let items = [],
                exchangeCount = 0;
            for (let id of this.orderItemCourseIds) {
                if (!this.orderItemsInfo[id]) {
                    continue;
                }

                let tempItem = {};

                tempItem.courseId = id;
                // 選擇訂閱方案
                if (this.orderItemsInfo[id].order.type === 'plan') {
                    tempItem.subscribePriceId = this.orderItemsInfo[id].order.id;
                }
                // 贈送數量不管買幾個課程，全部都只能同樣的數量
                exchangeCount = this.orderItemsInfo[id].order.quantity > 100 ? 100 : this.orderItemsInfo[id].order.quantity;

                items.push(tempItem);
            }

            let params = {
                items: items,
                device: "web",
                purchaseType: this.currentCoursePurchaseType,
                paymentType: "credit",
                totalPrice: this.currentTotalAmount,
                // 訂購人資訊
                buyerName: this.inputBuyerName,
                buyerPhone: this.inputBuyerPhone,
                buyerTelephone: this.inputBuyerTelephone,
                buyerEmail: this.inputBuyerEmail,
                // 發票形式
                invoiceType: this.selectedInvoiceType,
                // 雲端發票
                invoiceCarrierNumber: this.inputCarrierNumber ? `/${this.inputCarrierNumber}` : '',
                // 統編發票
                invoiceUbn: this.inputUbn,
                invoiceName: this.inputCompanyName,
                invoiceEmail: this.inputCompanyEmail,
                // 愛心捐贈
                invoiceLoveCode: this.selectedLoveCode
            }

            // device
            if (this.isAndroidWebview) {
                params.device = "android";
            }

            // 贈送訂單
            if (this.isGiftOrder) {
                params.isGift = true;
                params.exchangeCount = exchangeCount;
                
                // 訂閱制課程是否自動續訂
                if (this.currentCoursePurchaseType === '0') {
                    params.isGiftSubscribing = this.isCheckToRenewOrder;
                }
            }

            // 使用者有選擇優惠券
            if (!this.$_.isEmpty(this.confirmSelectedCoupon)) {
                params.userCouponId = this.confirmSelectedCoupon.id;
            }

            this.isPostingApi.createOrder = true;

            this.$httpRequest.post('/api/order/create_order', params)
                .then(response => {
                    this.isPostingApi.createOrder = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 初始訂單商品相關變數
                            this.$store.commit('order/setCurrentOrderItem', {});
                            this.$store.commit('order/setCurrentGiftOrderItems', []);

                            // 導向金流支付頁面
                            this.paymentPageHtml = result.payment_page;
                            this.$router.push('/checkout/payment');
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.createOrder = false;
                    console.log('catched error: ' + error);
                });
        }
    }
}
