import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
// common
import Dialogue from "@/components/common/dialogue.vue"
import InputTag from "@/components/common/input_tag.vue"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import '@/assets/scss/vendors/vue2-datepicker.scss'

export default {
    props: ['articleBasicInfo'],
    components: {
        InputTag,
        Dialogue,
        DatePicker
    },
    data: function() {
        return {
            activeTab: 'info',

            articleInfo: null,
            oldArticleInfo: null,

            uploadErrorMsg: {
                cover: null,
            },
            // upload cover
            tempUploadFileName: '',
            coverCropper: null,
            isSettingCropper: {
                cover: false
            },

            isSetDataReady: {
                getArticleInfo: false  // 取得文章資訊
            },

            tag: '1',
            switchLList: ['isShowRead', 'isShowListenRead', 'isShowExplain', 'isShowPractice', 'isShowCollect'],
        }
    },
    computed: {
        editInfoTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'info',
                    text: '基本資料'
                },
                {
                    value: 'classificationTag',
                    text: '分類標籤'
                },
                // 240321 先註解 因為前台程式尚未完成
                // {
                //     value: 'sidebarSetting',
                //     text: '側欄設定'
                // },
            ]
            return tabs;
        },
        contentTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '英文'
                },
                {
                    value: '1',
                    text: '非語文'
                }
            ]
            return opts;
        },
        difficultyLevelOptList() {
            let opts = [];
            opts = [
                {
                    value: null,
                    text: '無'
                },
                {
                    value: '0',
                    text: '初級'
                },
                {
                    value: '1',
                    text: '中級'
                },
                {
                    value: '2',
                    text: '中高級'
                },
                {
                    value: '3',
                    text: '高級'
                },
                {
                    value: '4',
                    text: '優級'
                }
            ]
            return opts;
        },
        updateArticleData() {
            if (!this.isSetDataReady.getArticleInfo) {
                return;
            }

            let updateData = {};

            let excludeKeys = ['cover', 'catagories', 'category', 'tags'];  // 排除檔案
            updateData = this.$_.omitBy(this.$_.omit(this.articleInfo, excludeKeys), (v, k) => {
                return this.oldArticleInfo[k] === v;
            });

            // 主分類的選擇
            if (this.oldArticleInfo.category.id != this.articleInfo.category.id) {
                updateData.courseCategoryId = this.articleInfo.category.id;
            }
            // 基本資料 - 文章封面
            if (this.oldArticleInfo.cover.path != this.articleInfo.cover.path) {
                // 新增封面檔案
                if (this.articleInfo.cover.path) {
                    updateData.cover = this.articleInfo.cover;
                }
                // 移除封面檔案
                if (this.oldArticleInfo.cover.path && !this.articleInfo.cover.path) {
                    updateData.deleteCoverFile = '1';
                }
            }

            // tags新舊陣列有資料才會進入
            if (this.oldArticleInfo.tags.length > 0 || this.articleInfo.tags.length > 0) {
                // 如果其中一個陣列為空
                if (this.oldArticleInfo.tags.length == 0 || this.articleInfo.tags.length == 0) {
                    // 沒有舊資料代表一開始是空的，只需要處理新資料
                    if (this.oldArticleInfo.tags.length == 0) {
                        updateData.tags = [];
                        this.articleInfo.tags.forEach((el) => {
                            updateData.tags.push(el.name);
                        });
                    }
                    // 沒有新資料代表舊資料被刪除了
                    if (this.articleInfo.tags.length == 0) {
                        updateData.deleteTags = [];
                        this.oldArticleInfo.tags.forEach((el) => {
                            updateData.deleteTags.push(el.id);
                        });
                    }
                }
                else {// 代表新舊陣列皆有值，必須做比對
                    this.oldArticleInfo.tags.forEach((el) => {
                        // 如果舊資料不存在新資料中
                        if (!this.$_.find(this.articleInfo.tags, el)) {
                            // 1.分類刪除tags(有id代表刪除)
                            if (el.id != 0) {
                                if (!updateData.deleteTags) {
                                    updateData.deleteTags = [];
                                }
                                updateData.deleteTags.push(el.id);
                            }
                        }
                    });
                    this.articleInfo.tags.forEach((el) => {
                        // 如果新資料不存在舊資料中
                        if (!this.$_.find(this.oldArticleInfo.tags, el)) {
                            // 2.分類新增tags(id = 0代表新增)
                            if (el.id == 0) {
                                if (!updateData.tags) {
                                    updateData.tags = [];
                                }
                                updateData.tags.push(el.name);
                            }
                        }
                    });
                }
            }

            if (updateData.issueNoDate) {
                updateData.issueNoDate = this.$util.datetimeToUnixTimestamp(updateData.issueNoDate);
            }

            // 側欄檢查使用
            if (typeof updateData.isShowRead !== 'undefined' ||
                typeof updateData.isShowListenRead !== 'undefined' ||
                typeof updateData.isShowExplain !== 'undefined' ||
                typeof updateData.isShowPractice !== 'undefined' ||
                typeof updateData.isShowCollect !== 'undefined') {
                    updateData.sideFlags = {
                        read: this.articleInfo.isShowRead,
                        listen: this.articleInfo.isShowListenRead,
                        additional_materials: this.articleInfo.isShowExplain,
                        practice: this.articleInfo.isShowPractice,
                        marked_word: this.articleInfo.isShowCollect
                    };
            }

            updateData.saveType = 'info';  // 指定儲存類型
            // console.log(updateData);
            return updateData;
        },
        isEditable() {
            // 是否可以編輯文章資料項目, 條件如下:
            // 1. 文章狀態為"編輯中"
            // 2. 使用者權限為 admin 時, "待審核"狀態還可編輯以上項目
            return this.articleBasicInfo.state == '0' ||
                    (this.$store.state.common.userInfo.permission == 'admin' && this.articleBasicInfo.state == '1');
        }
    },
    created: function() {
        this.initialize();
    },
    mounted: function() {
        // 關閉裁剪文章封面圖片彈窗後, 銷毀並初始 coverCropper
        $('#cropCoverImageDialogue').on('hidden.bs.modal', () => {  // 裁剪文章封面圖片
            if (!this.$_.isEmpty(this.coverCropper)) {
                this.coverCropper.destroy();
                this.coverCropper = null;
            }
            $('#cropCoverImageDialogue').off('shown.bs.modal');  // 銷毀已註冊的事件, 防止重複註冊
        });
    },
    methods: {
        initialize() {
            let refreshArticleInfo = () => {
                this.$parent.isFinishUpdatingData = false;
                this.isSetDataReady.getArticleInfo = false;
                this.oldArticleInfo = null;

                this.getArticleInfo()
                    .then(() => {
                        this.isSetDataReady.getArticleInfo = true;

                        // 監聽編輯內容是否更動
                        this.$watch('updateArticleData', newValue => {
                            this.$store.commit('adminArticle/setUpdateArticleData', newValue);
                            this.$store.commit('adminArticle/setIsShowNotSaveArticlePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                        });
                        // 編輯文章類型，需更新vuex的值
                        this.$watch('articleInfo.type',
                        newValue => {
                            this.$store.commit('adminArticle/changeArticleType', newValue)
                            // 如果改變文章類型，側欄開關就會全打開，使用者須重新設定
                            for (let item of this.switchLList) {
                                this.articleInfo[item] = true
                            }
                        });
                        // 編輯文章名稱，上方 bar 的文章名稱需跟著改變
                        this.$watch('articleInfo.name', newValue => this.$store.commit('adminArticle/changeArticleName', newValue));
                        // 編輯文章副標題，需更新vuex的值
                        this.$watch('articleInfo.subName', newValue => this.$store.commit('adminArticle/changeArticleSubName', newValue));
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            refreshArticleInfo();

            // 當成功更新文章資訊後, 則重新取得文章資訊
            this.$watch('$parent.isFinishUpdatingData', newValue => {
                if (newValue) {
                    refreshArticleInfo();
                }
            });
        },

        getArticleInfo() {
            return new Promise((resolve, reject) => {
                let params = {
                    articleId: this.$route.params.id
                }

                this.$httpRequest.get('/admin_api/article/get_article_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.articleInfo = {
                                    // 基本資料
                                    type: result.type,
                                    name: result.name,
                                    subName: result.sub_name,
                                    tags: result.tags,
                                    cover: {
                                        name: null,
                                        path: null,
                                        file: null
                                    },
                                    coverDescription: result.cover_description,
                                    issueNoDate: this.$util.unixTimestampToDatetime(result.issue_no_date, this.$util.getBrowserCurrentTimeZone(), 'date'),
                                    difficultyLevel: result.difficulty_level,
                                    category: result.category,
                                    catagories: result.catagories,
                                    isShowRead: result.side_flags.read,
                                    isShowListenRead: result.side_flags.listen,
                                    isShowExplain: result.side_flags.additional_materials,
                                    isShowPractice: result.side_flags.practice,
                                    isShowCollect: result.side_flags.marked_word
                                }

                                // 文章封面
                                if (!this.$_.isEmpty(result.cover)) {
                                    this.articleInfo.cover.name = result.cover.original_name;
                                    this.articleInfo.cover.path = result.cover.file_name;
                                }

                                if (this.articleInfo) {
                                    // 暫存原本的文章資料, 以用來判斷是否enable儲存按鈕
                                    this.oldArticleInfo = this.$_.cloneDeep(this.articleInfo);
                                }
                                resolve();
                            } else {
                                reject("admin/article/_edit.js: 'getArticleInfo' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/article/_edit.js: 'getArticleInfo' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log(3)
                        console.log('catched error: ' + error);
                        reject("admin/article/_edit.js: 'getArticleInfo' get error");
                    });
            });
        },
        tagsObjToArr(data) {
            let arr = [];
            if (data) {
                data.forEach((el) => {
                    arr.push(el.name);
                });
            }

            return arr;
        },
        uploadCoverFile() {
            this.uploadErrorMsg.cover = null;
            this.tempUploadFileName = '';

            let tempUploadFile = this.$refs.uploadCover.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {
                this.tempUploadFileName = tempUploadFile.name;

                // 鎖住點擊非彈窗區域關閉彈窗, 避免拉大裁剪框時而關閉彈窗
                $('#cropCoverImageDialogue').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                this.isSettingCropper.cover = true;

                $('#cropCoverImageDialogue').on('shown.bs.modal', () => {
                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = () => {
                        let dataURL = reader.result;
                        document.querySelector('#cropCoverImg').src = dataURL;
                        const image = document.getElementById('cropCoverImg');
                        // 建立cropper實例
                        this.coverCropper = new Cropper(image, {
                            aspectRatio: 16 / 9,
                            viewMode: 3,
                            dragMode: 'move',
                        });
                        this.isSettingCropper.cover = false;
                    }
                });
            }

            this.$refs.uploadCover.value = null;
        },
        cropCoverImage() {
            this.articleInfo.cover.name = this.tempUploadFileName;

            let dataURL = this.coverCropper.getCroppedCanvas().toDataURL("image/jpeg", 1.0);
            this.articleInfo.cover.path = dataURL;

            // dataURL to file
            this.$util.dataURLToFile(dataURL, this.articleInfo.cover.name, "image/jpeg")
                .then(file => {
                    this.articleInfo.cover.file = file;
                    this.coverCropper.destroy();
                    this.coverCropper = null;
                    $('#cropCoverImageDialogue').modal('hide');
                })
                .catch(error => console.log('catched error: ' + error));
        },
        checkSwitch(type) { // 檢查開關是否全部關閉
            let closeCount = 0;

            for (let item of this.switchLList) {
                if (item != type && this.articleInfo[item] == false) {
                    closeCount++;
                }
            }

            // 因為更換文章類型會全部打開，所以closeCount是以全部數量來檢查
            if (this.articleInfo.type == '0' && closeCount >= 4 ||
                this.articleInfo.type == '1' && closeCount >= 1) {
                this.$store.dispatch('common/setAlert', { msg: '開關不能全部關閉', status: 'danger' });
                setTimeout(() => {
                    this.articleInfo[type] = true;
                }, 100)
            }
        }
    }
}
