import WWA_Recorder from './lib/WebAudioApi';
import MR_Recorder from './lib/MediaRecorder';

var recorder;

const init = async (cfg) => {
    const params = {
        sampleRate: cfg.sampleRate || 48000,  // 取樣率 (每秒取樣次數, 單位 Hz)
        channelCount: cfg.channelCount || 2,  // 通道數
    };

    /**
     * 特定裝置使用 Web Audio Api 實現錄音時出現問題, 改採用 MediaRecorder
     * 三星、小米、紅米 手機或平板: 錄音檔案會被壓縮, 內容語速加快
     */
    const isSpecifiedAgent = /SAMSUNG|SM|Mi|Redmi/i.test(navigator.userAgent);

    if (!isSpecifiedAgent) {
        recorder = new WWA_Recorder(params);
    } else {
        recorder = new MR_Recorder(params);
    }

    await recorder.init();
};

const start = async () => {
    if (!recorder) return;

    await recorder.start();
}

const pause = () => {
    if (!recorder) return;

    recorder.pause();
};

const resume = async () => {
    if (!recorder) return;

    await recorder.resume();
};

const stop = () => {
    if (!recorder) return;

    recorder.stop();
};

const getFile = async () => {
    if (!recorder) return;

    let file = recorder.getFile();

    return file;
};

const getDecibel = () => {
    if (!recorder) return;

    let db = recorder.getDecibel();

    return db;
};

const Interface = {
    init,
    start,
    pause,
    resume,
    stop,
    getFile,
    getDecibel,
};

export default Interface;
