// components
const CourseList = resolve => require(["@/components/learning_center/course/_course_list.vue"], resolve)

export default {
    components: {
        CourseList,
    },
    data: function() {
        return {
            // g: group
            gIds: [-1],
            gInfos: {},
            bindingKeyword: '',
            keyword: '',
            selectedSortType: '',
        }
    },
    watch: {
        keyword() {
            this.keywordChangedHandler();
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        selectedGroupId() {
            return this.$route.query.group || this.gIds[0];
        },
        sortTypes() {
            const types = {
                'activated_date': { text: '預設排序', hover: '課程購買時間由新到舊' },
                'publish_date': { text: '最近上架', hover: '課程上架時間由新到舊' },
                'course_name': { text: '課程名稱', hover: '課程名稱首字筆畫由少到多' },
            };
            this.selectedSortType = Object.keys(types)[0];

            return types;
        },
        renderListKey() {
            return `${this.selectedGroupId}|${this.keyword}|${this.selectedSortType}`;
        },
    },
    created: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            if (Object.keys(this.userInfo).length) {  // 已登入使用者
                this.getCourseGroups();
            } else {
                this.gIds = [];
            }
        },
        getCourseGroups() {
            this.$httpRequest.get('/api/course/get_my_course_groups')
                .then(response => {
                    this.gIds = [];

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            for (const g of result) {
                                const gId = g.course_group_id;
                                this.gIds.push(gId);

                                const gInfo = {
                                    name: g.name,
                                    courseCount: g.num,
                                };
                                this.$set(this.gInfos, gId, gInfo);
                            }
                            // 若網址取得的 groupId 不在 list 範圍內, 則以 list 的第一個 groupId 取代
                            const queryGroup = this.$route.query.group;
                            if (queryGroup && !this.gIds.includes(queryGroup)) {
                                this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, group: this.gIds[0] } });
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        keywordChangedHandler() {
            let params = {};
            // 關鍵字搜尋
            if (this.keyword) {
                params.keyword = this.keyword;
            }

            this.$httpRequest.get('/api/course/get_my_course_groups', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            for (const g of result) {
                                const gId = g.course_group_id;
                                this.gInfos[gId].courseCount = g.num;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        clearKeyword() {
            this.bindingKeyword = '';
            this.keyword = this.bindingKeyword;
        },
    },
}
