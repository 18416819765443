import draggable from "vuedraggable"
// common
import Dialogue from "@/components/common/dialogue.vue"
import InputTag from "@/components/common/input_tag.vue"
// components
const MarkdownEditor = resolve => require(["@/components/common/markdown/editor.vue"], resolve)
const MarkdownPreviewer = resolve => require(["@/components/common/markdown/previewer.vue"], resolve)
import { writingTypes } from '@/assets/js/consts/writing'

export default {
    props: ['articleBasicInfo'],
    components: {
        draggable,
        InputTag,
        Dialogue,
        MarkdownEditor,
        MarkdownPreviewer
    },
    data: function() {
        return {
            activeTab: 'classification',

            // 文章基本資料description
            articleInfo: null,
            oldArticleInfo: null,

            // 分類
            categoryList: [],
            oldCategoryList: [],
            defaultCategory: [],
            selectedCategoryIndex: null,
            oldCategoryName: '',
            newCategoryName: '',
            isNewCategoryNameRepeat: false,
            inputCategoryNameRepeatMsg: '分類名稱不可重複，請重新命名',
            combineCategories: [],
            deletedCategoryIds: [],
            newCategoryClassType: 0, // 新增分類時，選取單題/題組/朗讀所使用的modal參數

            isSetDataReady: {
                getCategoryList: false,  // 取得分類資訊
                getAddSubjectStatus: false // 新增題目pop up的確認紐
            },

            uploadErrorMsg: {
                file: null,
            },

            categoryId: 0, // [題目分類]下拉選單選取後存放v-model的參數
            subjectList: [], // 題目列表
            oldSubjectList: [],
            isProhibitSelect: false, // 為了點擊[刪除題目]按鈕後，不會瞬間點到下一個題目
            newSubject: [], // 新增題目使用
            categoryClassType: 0, // [題目分類]下拉選單選取後，需知道這個分類是什麼類別，0:其他，1:單題，2:朗讀(前端使用)
            clickedCategoryClassType: 0, // 儲存分類的類別，主要是因為在[其他]分類中，需靠這參數來判斷，不然[作答類型]會顯示全部的題型
            // 上傳題目
            subjectFile: {
                path: '',
                name: '',
                file: ''
            },

            // 排序
            sortSubjectList: [],
            isSortSubject: false,
            isSortSubSubject: false, // 用於題組
            subjectIndex: 0,
            subQuestionIndex: 0,

            // 題組新增子題或選項flag
            isAddSubQuestion: false,
            isAddSubOption: false,

            // 刪除資料存放的各陣列
            deleteSubjectIds: [],
            deleteOptionIds: [],
            // 停用題目的id陣列
            disableQuestionIds: [],
            audio: new Audio(),
            renderMdCount: 0,

            // 抽題量
            adjustSubjects: [], // 放抽題量資料的陣列
            adjustSubjectData: {}, // 抽題量的pop up變數
            pickNum: 0,
            // 練習次數
            practiceTimes: 0
        }
    },
    computed: {
        editInfoTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'classification',
                    text: '題目分類'
                },
                {
                    value: 'subject',
                    text: '練習題目'
                },
                {
                    value: 'set',
                    text: '習題設定'
                },
            ]
            return tabs;
        },
        categoriesSelectList() {
            let list = [];
            list = [
                {
                    value: '0',
                    text: '單題'
                },
                {
                    value: '2',
                    text: '題組'
                },
                {
                    value: '1',
                    text: '朗讀'
                },
                {
                    value: '3',
                    text: '對話'
                },
                {
                    value: '4',
                    text: '翻譯'
                },
                {
                    value: '5',
                    text: '寫作'
                },
            ]
            return list;
        },
        categoriesTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "no",
                    itemText: "排序",
                    ownClass: "practice-category-no",
                    isShow: this.isEditable
                },
                {
                    itemName: "name",
                    itemText: "分類名稱",
                    ownClass: "practice-category-name",
                    isShow: true
                },
                {
                    itemName: "score_type",
                    itemText: "類別",
                    ownClass: "practice-category-type",
                    isShow: true
                },
                {
                    itemName: "total",
                    itemText: "題量",
                    ownClass: "practice-category-total",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "practice-category-advanced",
                    isShow: this.isEditable
                },
                {
                    itemName: "answer",
                    itemText: "立即對答",
                    ownClass: "instant-response-answer",
                    isShow: this.isEditable
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        categoryName() {
            return type => type == 0 ? '單題' : type == 1 ? '朗讀' : type == 2 ? '題組' : type == 3 ? '對話' : type == 4 ? '翻譯' : '寫作';
        },
        subjectTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "name",
                    itemText: "分類名稱",
                    ownClass: "practice-category-name",
                    isShow: true
                },
                {
                    itemName: "score_type",
                    itemText: "類別",
                    ownClass: "practice-category-type",
                    isShow: true
                },
                {
                    itemName: "total",
                    itemText: "總題量",
                    ownClass: "practice-category-total",
                    isShow: true
                },
                {
                    itemName: "random",
                    itemText: "抽題量",
                    ownClass: "practice-category-random",
                    isShow: true
                },
                {
                    itemName: "times",
                    itemText: "練習次數",
                    ownClass: "practice-category-times",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "practice-category-advanced",
                    isShow: this.isEditable
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        subjectType() {
            let opts = [
                    {
                        value: '0',
                        text: '單選題'
                    },
                    {
                        value: '1',
                        text: '多選題'
                    },
                    {
                        value: '2',
                        text: '填充題'
                    },
                    {
                        value: '3',
                        text: '重組題'
                    },
                    {
                        value: '4',
                        text: '朗讀題'
                    }
                ];

            if(this.clickedCategoryClassType == 1) {
                opts = [
                    {
                        value: '0',
                        text: '單選題'
                    },
                    {
                        value: '1',
                        text: '多選題'
                    },
                    {
                        value: '2',
                        text: '填充題'
                    },
                    {
                        value: '3',
                        text: '重組題'
                    }
                ]
            }
            else if(this.clickedCategoryClassType == 2) {
                opts = [
                    {
                        value: '4',
                        text: '朗讀題'
                    }
                ]
            }
            else if(this.clickedCategoryClassType == 3) {
                opts = [
                    {
                        value: '0',
                        text: '單選題'
                    },
                    {
                        value: '1',
                        text: '多選題'
                    }
                ]
            }
            return opts;
        },
        selectedSubjectIndex: {
            get() {
                return this.$store.state.adminArticle.selectedSubjectIndex;
            },
            set(value) {
                this.$store.commit('adminArticle/setSubjectIndex', value);
            }
        },
        attachAudioList() {
            let opts = [
                    {
                        value: '0',
                        text: '無'
                    },
                    {
                        value: '1',
                        text: '上傳檔案'
                    },
                    {
                        value: '2',
                        text: '文字轉換'
                    }
                ]

            if (this.categoryClassType == 2 || this.categoryClassType == 4) {
                opts = [
                    {
                        value: '1',
                        text: '上傳檔案'
                    },
                    {
                        value: '2',
                        text: '文字轉換'
                    }
                ]
            }

            return opts;
        },
        subjectDifficultyLevelList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '低'
                },
                {
                    value: '1',
                    text: '中'
                },
                {
                    value: '2',
                    text: '高'
                }
            ]
            return opts;
        },
        speakTopicTypeList() { // 朗讀題目類型
            let opts = [];
            opts = [
                {
                    value: '1',
                    text: '朗讀'
                },
                {
                    value: '0',
                    text: '聽讀'
                }
            ]
            return opts;
        },
        beforeAnswerList() { // 是否作答之前的開關
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '關閉'
                },
                {
                    value: '1',
                    text: '開啟'
                }
            ]
            return opts;
        },
        recognizeLangOptions() {
            let opts = {};

            opts = {
                'val-2': '英文',
                'val-1': '中文',
                'val-3': '文言文',
            };

            return opts;
        },
        sexSelectList() {
            let opts = [];
            opts = [
                {
                    value: '1',
                    text: '男'
                },
                {
                    value: '2',
                    text: '女'
                },
            ]
            return opts;
        },
        roleSelectList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '角色A'
                },
                {
                    value: '1',
                    text: '角色B'
                },
            ]
            return opts;
        },
        paragraphSettingList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '段落設定'
                },
                {
                    value: '1',
                    text: '不限段落'
                }
            ]
            return opts;
        },
        writingTypes() {
            return writingTypes;
        },
        currentCategoryNames() {
            let ary = [];

            this.categoryList.forEach(el => ary.push(el.name));  // 自訂分類
            ary.push('其他');  // 預設分類
            return ary;
        },
        updatePracticeData() {
            if (!this.isSetDataReady.getCategoryList) {
                return;
            }

            let updateData = {
                    saveType: 'practice',  // 指定儲存類型
                },
                articleInfoChange = {},
                updateInfo = {},
                deleteTagIds = [],
                isEmpty = false,
                conversationChenged = false;

            // 題目分類
            // 判斷分類列表是否有做新增, 更名, 異動順序或刪除項目
            let tempCategoryIds = [], oldCategoryIds = [];
            this.categoryList.forEach(el => tempCategoryIds.push(el.id));
            this.oldCategoryList.forEach(el => oldCategoryIds.push(el.id));
            let diffCategoryIds = this.$_.difference(tempCategoryIds, oldCategoryIds);
            let diffCategoryNames = this.$_.differenceBy(this.categoryList, this.oldCategoryList, 'name');
            let diffCategoryPickNum = [];
            let diffCategoryPracticeTimes = [];

            if (this.activeTab == 'classification' ) { // 侷限[題目分類]才可以比對
                for(let i=0; i < this.categoryList.length; i++) {
                    if (typeof this.categoryList[i].is_immediately_get_answer !== 'undefined' &&
                        typeof this.oldCategoryList[i] !== 'undefined' &&
                        this.categoryList[i].is_immediately_get_answer != this.oldCategoryList[i].is_immediately_get_answer) {
                        updateInfo.category = this.categoryList;
                        updateData.updateInfo = updateInfo;
                    }
                }
            }

            if (this.activeTab == 'set' ) { // 侷限[習題設定]才可以比對
                // 由於differenceBy無法使用，只好跑迴圈檢查
                this.categoryList.forEach((el, index) => {
                    if (this.oldCategoryList[index].pick_num != el.pick_num) {
                        diffCategoryPickNum.push(el);
                    }
                });
                this.categoryList.forEach((el, index) => {
                    if (this.oldCategoryList[index].practice_times != el.practice_times) {
                        diffCategoryPracticeTimes.push(el);
                    }
                });
            }

            // 有新增, 更名或異動順序過的分類
            // 將兩陣列 join 為字串是用來判斷分類列表是否有做重新排序
            // 刪除分類 updateData.category 裡會有刪剩下的分類
            if (diffCategoryIds.length ||
                diffCategoryNames.length ||
                diffCategoryPickNum.length ||
                diffCategoryPracticeTimes.length ||
                (tempCategoryIds.join('') !== oldCategoryIds.join(''))) {
                updateInfo.category = this.categoryList;
                updateData.updateInfo = updateInfo;
            }

            // 有刪除的分類
            if (this.deletedCategoryIds.length) {
                updateInfo.deleteCategoryIds = this.deletedCategoryIds;
                updateData.updateInfo = updateInfo;
            }

            if (this.subjectList.length > 0) {
                this.subjectList.forEach((el, index) => {
                    let sort = {
                            id : el.id,
                            options : []
                        };
                    if (el.answer_type != 5) { // 單題、題組、朗讀的檢查
                        let firstIsChange = false,
                            subject = {
                                id: el.id,
                                answerType: el.answer_type
                            };

                        // 參數初始化
                        el.isTitleError = false;
                        el.titleErrText = '';
                        el.isSpeechError = false;
                        el.speechErrText = '';
                        el.isCommentError = false;
                        el.commentErrText = '';

                        // 檢查題目、附加圖片、附加音檔和tts是否有填其中一個
                        // 由於附加音檔的載入可能會比較慢，會抓不到path，所以要多判斷file
                        if (el.answer_type != 4 && !el.title && !el.image.path && !el.audio.path && !el.audio.file && !el.text_to_speech) {
                            isEmpty = true;
                            return false;
                        }
                        // 朗讀題
                        if (el.answer_type == 4 && ((el.recognize_language === '2' && !el.title) || (el.attachAudio == 1 && !el.audio.path && !el.audio.file))) {
                            isEmpty = true;
                            return false;
                        }
                        // 單題
                        if (el.is_group == 0) {
                            // 單題、對話、朗讀
                            if (el.answer_type < 5) {
                                if (!el.score.match(/^\d+$/g)) { // 檢查分數是否有填數字以外的字元或空白
                                    isEmpty = true;
                                    return false;
                                }

                                if (el.answer_type != 4) {
                                    if (el.title.length > 2500) { // 檢查題目的字數
                                        el.isTitleError = true;
                                        el.titleErrText = "文字數量不能超過2500個字";
                                        isEmpty = true;
                                        return false;
                                    }
                                }
                                else { // 朗讀題檢查一些額外項目
                                    if (el.title.length > 300) { // 檢查題目的字數
                                        el.isTitleError = true;
                                        el.titleErrText = "文字數量不能超過300個字";
                                        isEmpty = true;
                                        return false;
                                    }
                                    if (el.attachAudio == 1 && !el.audio.path && !el.audio.file) {
                                        isEmpty = true;
                                        return false;
                                    }
                                }
                                // 檢查解析的字數
                                if (el.comment.length > 2500) {
                                    el.isCommentError = true;
                                    el.commentErrText = "文字數量不能超過2500個字";
                                }
                            }

                            if (el.answer_type == 6) {
                                if (!el.description ||
                                    el.leastWords < 5 ||
                                    !el.leastWords.toString().match(/^\d+$/g) ||
                                    !el.isPastExamCorrection) {
                                    isEmpty = true;
                                    return false;
                                }
                            }

                            if (el.answer_type == 7) {
                                if (!el.request ||
                                    !el.description) {
                                    isEmpty = true;
                                    return false;
                                }
                                if (!el.isPastExamAppraisal &&
                                    !el.isSentenceCorrection &&
                                    !el.isArticleOptimization) {
                                    isEmpty = true;
                                    return false;
                                }
                                if (!el.basicWords.toString().match(/^\d+$/g)) {
                                    isEmpty = true;
                                    return false;
                                }
                                if (el.leastWords < 5 ||
                                    !el.leastWords.toString().match(/^\d+$/g)) {
                                    isEmpty = true;
                                    return false;
                                }
                            }
                        }
                        else {
                            if (el.title.length > 2500) { // 檢查題組題幹的字數
                                el.isTitleError = true;
                                el.titleErrText = "文字數量不能超過2500個字";
                                isEmpty = true;
                                return false;
                            }
                        }
                        // 檢查tts的字數
                        if (el.attachAudio == 2 && el.text_to_speech && el.text_to_speech.length > 250) {
                            el.isSpeechError = true;
                            el.speechErrText = "文字數量不能超過250個字";
                        }

                        // 分類
                        if (this.oldSubjectList[index].category != el.category) {
                            subject.categoryId = el.category;
                            firstIsChange = true;
                        }
                        // 題目
                        if (this.oldSubjectList[index].title != el.title) {
                            subject.title = el.title;
                            firstIsChange = true;
                        }
                        // 題目圖片(file預設為空，刪除會變null)
                        if (this.oldSubjectList[index].image.file != el.image.file) {
                            if (el.image.file) { // 新增
                                subject.image = 'image' + el.id;
                                subject.imageFile = el.image.file;
                            }
                            else { // 刪除
                                subject.image = '';
                            }
                            firstIsChange = true;
                        }
                        // tts
                        if (this.oldSubjectList[index].text_to_speech != el.text_to_speech) {
                            subject.text_to_speech = el.text_to_speech;
                            firstIsChange = true;
                        }
                        // 附加音檔
                        if (this.oldSubjectList[index].audio.file != el.audio.file) {
                            if (el.audio.file) { // 新增
                                subject.upload_audio = 'upload_audio_' + el.id;
                                subject.audioFile = el.audio.file;
                            }
                            else { // 刪除音檔，不是朗讀題且沒有tts才需要讓upload_audio = ''
                                if (el.answer_type != 4 && !subject.text_to_speech) {
                                    subject.upload_audio = '';
                                }
                            }
                            firstIsChange = true;
                        }

                        // 單題
                        if (el.is_group == 0) {
                            // 題目要求
                            if (this.oldSubjectList[index].request != el.request) {
                                subject.request = el.request;
                                firstIsChange = true;
                            }
                            // 分數
                            if (this.oldSubjectList[index].score != el.score) {
                                subject.score = el.score;
                                firstIsChange = true;
                            }
                            // 說明
                            if (this.oldSubjectList[index].description != el.description) {
                                subject.description = el.description;
                                firstIsChange = true;
                            }
                            // 提示
                            if (this.oldSubjectList[index].tips != el.tips) {
                                subject.tips = el.tips;
                                firstIsChange = true;
                            }
                            // 相關字詞
                            if (this.oldSubjectList[index].relatedWords != el.relatedWords) {
                                subject.relatedWords = el.relatedWords;
                                firstIsChange = true;
                            }
                            // 參考文章
                            if (this.oldSubjectList[index].compositionReference != el.compositionReference) {
                                subject.compositionReference = el.compositionReference;
                                firstIsChange = true;
                            }
                            // 段落選擇
                            if (this.oldSubjectList[index].paragraph != el.paragraph) {
                                subject.paragraph = el.paragraph;
                                firstIsChange = true;
                            }
                            // 學測歷屆中翻英批改
                            if (this.oldSubjectList[index].isPastExamCorrection != el.isPastExamCorrection) {
                                subject.correctionMechanism = {
                                    modes: {
                                        translation: {
                                            correct: el.isPastExamCorrection ? true : false
                                        }
                                    },
                                    words: {
                                        basic: el.basicWords
                                    }
                                };
                                firstIsChange = true;
                            }
                            // 學測歷屆總評V1
                            if (this.oldSubjectList[index].isPastExamAppraisal != el.isPastExamAppraisal) {
                                subject.correctionMechanism = {
                                    modes: {
                                        composition: {
                                            summary: el.isPastExamAppraisal ? true : false,
                                            sentence: el.isSentenceCorrection ? true : false,
                                            optimize: el.isArticleOptimization ? true : false
                                        },
                                    },
                                    words: {
                                        basic: el.basicWords,
                                        least: el.leastWords
                                    }
                                };
                                firstIsChange = true;
                            }
                            // 逐句批改V1
                            if (this.oldSubjectList[index].isSentenceCorrection != el.isSentenceCorrection) {
                                subject.correctionMechanism = {
                                    modes: {
                                        composition: {
                                            summary: el.isPastExamAppraisal ? true : false,
                                            sentence: el.isSentenceCorrection ? true : false,
                                            optimize: el.isArticleOptimization ? true : false
                                        },
                                    },
                                    words: {
                                        basic: el.basicWords,
                                        least: el.leastWords
                                    }
                                };
                                firstIsChange = true;
                            }
                            // 優化文章+改寫說明V1
                            if (this.oldSubjectList[index].isArticleOptimization != el.isArticleOptimization) {
                                subject.correctionMechanism = {
                                    modes: {
                                        composition: {
                                            summary: el.isPastExamAppraisal ? true : false,
                                            sentence: el.isSentenceCorrection ? true : false,
                                            optimize: el.isArticleOptimization ? true : false
                                        },
                                    },
                                    words: {
                                        basic: el.basicWords,
                                        least: el.leastWords
                                    }
                                };
                                firstIsChange = true;
                            }
                            // 至少字數
                            if (this.oldSubjectList[index].basicWords != el.basicWords) {
                                subject.correctionMechanism = {
                                    modes: {
                                        composition: {
                                            summary: el.isPastExamAppraisal ? true : false,
                                            sentence: el.isSentenceCorrection ? true : false,
                                            optimize: el.isArticleOptimization ? true : false
                                        },
                                    },
                                    words: {
                                        basic: el.basicWords,
                                        least: el.leastWords
                                    }
                                };
                                firstIsChange = true;
                            }
                            // 最少字數
                            if (this.oldSubjectList[index].leastWords != el.leastWords) {
                                if (el.answer_type == 6) {
                                    subject.correctionMechanism = {
                                        modes: {
                                            translation: {
                                                correct: el.isPastExamCorrection ? true : false
                                            }
                                        },
                                        words: {
                                            least: el.leastWords
                                        }
                                    };
                                }
                                if (el.answer_type == 7) {
                                    subject.correctionMechanism = {
                                        modes: {
                                            composition: {
                                                summary: el.isPastExamAppraisal ? true : false,
                                                sentence: el.isSentenceCorrection ? true : false,
                                                optimize: el.isArticleOptimization ? true : false
                                            },
                                        },
                                        words: {
                                            basic: el.basicWords,
                                            least: el.leastWords
                                        }
                                    };
                                }
                                firstIsChange = true;
                            }
                            // 解析
                            if (this.oldSubjectList[index].comment != el.comment) {
                                subject.comment = el.comment;
                                firstIsChange = true;
                            }
                            // 解析媒體
                            if (this.oldSubjectList[index].media.file != el.media.file) {
                                if (el.media.file) { // 新增
                                    subject.comment_media = 'comment_media_' + el.id;
                                    subject.mediaFile = el.media.file;
                                }
                                else { // 刪除
                                    subject.comment_media = '';
                                }
                                firstIsChange = true;
                            }
                            // 難度
                            if (this.oldSubjectList[index].difficulty_level != el.difficulty_level) {
                                subject.difficulty_level = el.difficulty_level;
                                firstIsChange = true;
                            }

                            // 多張圖片
                            if (el.answer_type == 7 && (this.oldSubjectList[index].images && this.oldSubjectList[index].images.length > 0 || el.images && el.images.length > 0)) {
                                // 如果其中一個陣列為空
                                if (this.oldSubjectList[index].images.length == 0 || el.images.length == 0) {
                                    // 沒有舊資料代表一開始是空的，只需要處理新資料
                                    if (this.oldSubjectList[index].images.length == 0) {
                                        subject.images = [];
                                        subject.imageFiles = [];
                                        el.images.forEach((item, imgIndex) => {
                                            subject.images.push('image_' + index + imgIndex);
                                            subject.imageFiles.push(item.file);
                                            firstIsChange = true;
                                        });
                                    }
                                    // 沒有新資料代表舊資料被刪除了
                                    if (el.images.length == 0) {
                                        subject.deleteImageFiles = [];
                                        this.oldSubjectList[index].images.forEach((el) => {
                                            let fileName = el.path.split('/');
                                            subject.deleteImageFiles.push(fileName[fileName.length-1]);
                                        });
                                        firstIsChange = true;
                                    }
                                }
                                else {// 代表新舊陣列皆有值，必須做比對
                                    this.oldSubjectList[index].images.forEach((item) => {
                                        // 如果舊資料不存在新資料中
                                        if (!this.$_.find(el.images, item)) {
                                            // 1.分類刪除images(有path代表刪除)
                                            if (item.path) {
                                                let fileName = item.path.split('/');
                                                if (!subject.deleteImageFiles) {
                                                    subject.deleteImageFiles = [];
                                                }
                                                subject.deleteImageFiles.push(fileName[fileName.length-1]);
                                                firstIsChange = true;
                                            }
                                        }
                                    });
                                    el.images.forEach((item, imgIndex) => {
                                        // 如果新資料不存在舊資料中
                                        if (!this.$_.find(this.oldSubjectList[index].images, item)) {
                                            if (!subject.images) {
                                                subject.images = [];
                                            }
                                            if (!subject.imageFiles) {
                                                subject.imageFiles = [];
                                            }
                                            subject.images.push('image_' + index + imgIndex);
                                            subject.imageFiles.push(item.file);
                                            firstIsChange = true;
                                        }
                                    });
                                }
                            }

                            // 段落
                            if (el.answer_type == 7 && el.paragraphs && (this.oldSubjectList[index].paragraphs.length > 0 || el.paragraphs.length > 0)) {
                                // 如果其中一個陣列為空
                                if (this.oldSubjectList[index].paragraphs.length == 0 || el.paragraphs.length == 0) {
                                    // 沒有舊資料代表一開始是空的，只需要處理新資料
                                    // 沒有新資料代表舊資料被刪除了
                                    subject.compositionParagraph = {
                                        paragraphs : el.paragraphs
                                    };
                                    firstIsChange = true;
                                }
                                else {// 代表新舊陣列皆有值，必須做比對
                                    this.oldSubjectList[index].paragraphs.forEach((item, index) => {
                                        // 舊陣列跟新陣列每個值比對，如果不一樣就要更新
                                        if (el.paragraphs[index] != item) {
                                            subject.compositionParagraph = {
                                                paragraphs : el.paragraphs
                                            };
                                            firstIsChange = true;
                                        }
                                    });
                                    el.paragraphs.forEach((item, key) => {
                                        // 新陣列跟舊陣列每個值比對，如果不一樣就要更新
                                        if (!this.oldSubjectList[index].paragraphs[key] ||
                                            this.oldSubjectList[index].paragraphs[key] != item) {
                                            subject.compositionParagraph = {
                                                paragraphs : el.paragraphs
                                            };
                                            firstIsChange = true;
                                        }
                                    });

                                }
                            }

                            // 標籤
                            if (this.oldSubjectList[index].tags.length > 0 || el.tags.length > 0) {
                                // 如果其中一個陣列為空
                                if (this.oldSubjectList[index].tags.length == 0 || el.tags.length == 0) {
                                    // 沒有舊資料代表一開始是空的，只需要處理新資料
                                    if (this.oldSubjectList[index].tags.length == 0) {
                                        subject.tags = [];
                                        el.tags.forEach((item) => {
                                            subject.tags.push(item.name);
                                            firstIsChange = true;
                                        });
                                    }
                                    // 沒有新資料代表舊資料被刪除了
                                    if (el.tags.length == 0) {
                                        this.oldSubjectList[index].tags.forEach((el) => {
                                            deleteTagIds.push(el.id);
                                        });
                                    }
                                }
                                else {// 代表新舊陣列皆有值，必須做比對
                                    this.oldSubjectList[index].tags.forEach((item) => {
                                        // 如果舊資料不存在新資料中
                                        if (!this.$_.find(el.tags, item)) {
                                            // 1.分類刪除tags(有id代表刪除)
                                            if (item.id != 0) {
                                                deleteTagIds.push(item.id);
                                            }
                                        }
                                    });
                                    el.tags.forEach((item) => {
                                        // 如果新資料不存在舊資料中
                                        if (!this.$_.find(this.oldSubjectList[index].tags, item)) {
                                            // 2.分類新增tags(id = 0代表新增)
                                            if (item.id == 0) {
                                                if (!subject.tags) {
                                                    subject.tags = [];
                                                }
                                                subject.tags.push(item.name);
                                                firstIsChange = true;
                                            }
                                        }
                                    });
                                }
                            }
                        }

                        if (firstIsChange) {
                            if (!updateInfo.question) {
                                updateInfo.question = {};
                                updateInfo.question.update = [];
                            }
                            if (!updateInfo.question.update) {
                                updateInfo.question.update = [];
                            }
                            updateInfo.question.update[index] = subject;
                        }

                        // 單題選項
                        if (el.is_group == 0) {
                            // 因為在排序的過程中，有可能新舊options的數量會不同，陣列長度必須一樣才能比對
                            if (this.oldSubjectList[index].options.length == el.options.length) {
                                el.options.forEach((option, oIndex) => {
                                    let secondChange = false,
                                        optionItem = {
                                            id: option.id,
                                        };

                                    // 參數初始化
                                    option.isOptionError = false;
                                    option.optionErrText = '';

                                    let emptyCount = 0;
                                    // 檢查選擇題答案是否沒填
                                    if (el.answer_type == 1) {
                                        el.answer.forEach((el) => {
                                            if (el == 0 || el == false) {
                                                emptyCount++;
                                            }
                                        });
                                    }
                                    if (el.answer_type < 2 && el.answer.length == emptyCount) {
                                        isEmpty = true;
                                        el.options[el.options.length-1].isOptionError = true;
                                        el.options[el.options.length-1].optionErrText = '答案沒有選取';
                                        return false;
                                    }

                                    // 檢查選項內容是否為空
                                    if (el.answer_type < 2) {
                                        if (!option.title && !option.image.path && !option.image.file) {
                                            isEmpty = true;
                                            option.isOptionError = true;
                                            option.optionErrText = '選項或答案內容不能空白';
                                            return false;
                                        }
                                        if (option.title.length > 150) {
                                            isEmpty = true;
                                            option.isOptionError = true;
                                            option.optionErrText = '文字數量不能超過150個字';
                                            return false;
                                        }
                                    }
                                    else {
                                        if (!option.title) {
                                            isEmpty = true;
                                            option.isOptionError = true;
                                            option.optionErrText = '選項或答案內容不能空白';
                                            return false;
                                        }
                                        // 重組題
                                        if (el.answer_type == 3) {
                                            let tempArray = option.title.replace(/(?:\\[rn]|[\r\n]+)+/g, ',').split(',');

                                            if (tempArray.length != 0 ) {
                                                if (tempArray.length > 12) {
                                                    isEmpty = true;
                                                    option.isOptionError = true;
                                                    option.optionErrText = '超過12行請刪除12行以後的資料';
                                                    return false;
                                                }
                                                tempArray.forEach((tl, index) => {
                                                    if (tl.length > 30) {
                                                        isEmpty = true;
                                                        option.isOptionError = true;
                                                        option.optionErrText = '第' + (index+1) + '行超過30個文字';
                                                        return false;
                                                    }
                                                    if (tl.match(/ /g) && !tl.match(/[^ ]/g)
                                                    || !tl && !tl.match(/[^ ]/g)) {
                                                        isEmpty = true;
                                                        option.isOptionError = true;
                                                        option.optionErrText = '第' + (index+1) + '行沒有輸入或是輸入空格，請檢查';
                                                        return false;
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    // 朗讀題朗讀
                                    if (el.answer_type == 4) {
                                        let maxWords = el.recognize_language !== '2' ? 300 : 600;
                                        if (option.title.length > maxWords) {
                                            isEmpty = true;
                                            option.isOptionError = true;
                                            option.optionErrText = `文字數量不能超過${maxWords}個字`;
                                            return false;
                                        }
                                    }
                                    // 選項內容
                                    if (this.oldSubjectList[index].options[oIndex].title != option.title) {
                                        optionItem.title = option.title;
                                        secondChange = true;
                                        // 如果是朗讀題且題目有改變，須把tts值設定成答案的值
                                        if (el.answer_type == 4) {
                                            subject.text_to_speech = option.title;
                                        }
                                    }
                                    // 如果是朗讀題且選tts，須把值設定成答案的值
                                    if (el.answer_type == 4 && el.attachAudio == 2) {
                                        subject.text_to_speech = option.title;
                                        // 必須要重設第一層的資料
                                        if (!updateInfo.question) {
                                            updateInfo.question = {};
                                            updateInfo.question.update = [];
                                        }
                                        if (!updateInfo.question.update) {
                                            updateInfo.question.update = [];
                                        }
                                        updateInfo.question.update[index] = subject;
                                        secondChange = true;
                                    }
                                    // 圖片
                                    if (this.oldSubjectList[index].options[oIndex].image.file != option.image.file) {
                                        if (option.image.file) { // 新增
                                            optionItem.image = 'option_image_' + option.id;
                                            optionItem.imageFile = option.image.file;
                                        }
                                        else { // 刪除
                                            optionItem.image = '';
                                        }
                                        secondChange = true;
                                    }

                                    // 填充題、重組題、朗讀題 (每個都是答案，所以每個is_answer = 1)
                                    if (el.answer_type > 1) {
                                        optionItem.is_answer = 1;
                                    }

                                    // 如果el.answer有不一樣才會進入
                                    if (this.oldSubjectList[index].answer.join('') != el.answer.join('')) {
                                        optionItem.is_answer = 0;
                                        // 單選題 (若answer[0]等於oIndex，is_answer = 1)
                                        if (el.answer_type == 0 && el.answer[0] == oIndex) {
                                            optionItem.is_answer = 1;
                                        }
                                        // 多選題 (若answer[oIndex]是1或true，is_answer = 1)
                                        if (el.answer_type == 1 && el.answer[oIndex]) {
                                            optionItem.is_answer = 1;
                                        }
                                        secondChange = true;
                                    }
                                    // 翻譯
                                    if (el.answer_type == 6) {
                                        let maxWords = 500;
                                        if (option.title.length > maxWords) {
                                            isEmpty = true;
                                            option.isOptionError = true;
                                            option.optionErrText = `文字數量不能超過${maxWords}個字`;
                                            return false;
                                        }
                                    }

                                    if (secondChange) {
                                        if (updateInfo.question) {
                                            if (updateInfo.question.update) {
                                                if (!updateInfo.question.update[index]) {
                                                    subject.options = [];
                                                    subject.options[oIndex] = subject;
                                                    updateInfo.question.update[index] = subject;
                                                }

                                                if (updateInfo.question.update[index].options) {
                                                    updateInfo.question.update[index].options[oIndex] = optionItem;
                                                }
                                                else {
                                                    updateInfo.question.update[index].options = [];
                                                    updateInfo.question.update[index].options[oIndex] = optionItem;
                                                }
                                            }
                                            else {
                                                updateInfo.question.update = [];
                                                subject.options = [];
                                                subject.options[oIndex] = optionItem;
                                                updateInfo.question.update[index] = subject;
                                            }
                                        }
                                        else {
                                            subject.options = [];
                                            subject.options[oIndex] = optionItem;
                                            if (!updateInfo.question) {
                                                updateInfo.question = {};
                                                updateInfo.question.update = [];
                                            }
                                            updateInfo.question.update[index] = subject;
                                        }
                                    }
                                    sort.options.push(option.id);
                                });
                            }
                        }
                        else { // 題組子題和選項
                            let addGroup = false,
                                group = {
                                    id: el.id,
                                    subQuestions: []
                                },
                                newSubQusIndex = 0;
                            if (this.oldSubjectList[index].sub_questions.length == el.sub_questions.length) {
                                el.sub_questions.forEach((sl, sIndex) => {
                                    let subQusChange = false,
                                        subQus = {
                                            id: sl.id,
                                            answerType: el.answer_type
                                        },
                                        newSubOptionIndex = 0,
                                        tmpSubQus = { // 排序或新增子題使用，不使用subQus是因為會塞一些不正確的資料進入subQus
                                            id: sl.id
                                        };
                                    // 參數初始化
                                    sl.isTitleError = false;
                                    sl.titleErrText = '';
                                    sl.isCommentError = false;
                                    sl.commentErrText = '';

                                    // 檢查題目、附加圖片是否有填其中一個
                                    if (!sl.title && !sl.image.path) {
                                        isEmpty = true;
                                        return false;
                                    }
                                    // 檢查分數是否有填數字以外的字元或空白
                                    if (!sl.score.match(/^\d+$/g)) {
                                        isEmpty = true;
                                        return false;
                                    }

                                    if (sl.title.length > 2500) { // 檢查題目的字數
                                        sl.isTitleError = true;
                                        sl.titleErrText = "文字數量不能超過2500個字";
                                        isEmpty = true;
                                        return false;
                                    }
                                    if (sl.comment.length > 2500) { // 檢查解析的字數
                                        sl.isCommentError = true;
                                        sl.commentErrText = "文字數量不能超過2500個字";
                                    }

                                    // 如果有新增或排序題組子題
                                    if (this.isAddSubQuestion || this.isAddSubOption || this.isSortSubSubject) {
                                        if (sl.id == null || sl.id == undefined) {
                                            let tags = [];
                                            if (sl.tags.length > 0) {
                                                sl.tags.forEach((el) => {
                                                    tags.push(el.name);
                                                });
                                            }

                                            tmpSubQus = {
                                                    id: null,
                                                    score: sl.score,
                                                    title: sl.title,
                                                    difficultyLevel: sl.difficulty_level,
                                                    comment: sl.comment,
                                                    tags: tags
                                                }
                                            // 新增子題圖片
                                            if (sl.image.file) {
                                                tmpSubQus.imageFile = sl.image.file;
                                            }
                                            // 新增解析媒體
                                            if (sl.media.file) {
                                                tmpSubQus.mediaFile = sl.media.file;
                                            }
                                            addGroup = true;
                                        }
                                        group.subQuestions[newSubQusIndex] = tmpSubQus;
                                    }

                                    // 分數
                                    if (this.oldSubjectList[index].sub_questions[sIndex].score != sl.score) {
                                        subQus.score = sl.score;
                                        subQusChange = true;
                                    }
                                    // 題目
                                    if (this.oldSubjectList[index].sub_questions[sIndex].title != sl.title) {
                                        subQus.title = sl.title;
                                        subQusChange = true;
                                    }
                                    // 題目圖片(file預設為空，刪除會變null)
                                    if (this.oldSubjectList[index].sub_questions[sIndex].image.file != sl.image.file) {
                                        if (sl.image.file) { // 新增
                                            subQus.image = 'image' + sl.id;
                                            subQus.imageFile = sl.image.file;
                                        }
                                        else { // 刪除
                                            subQus.image = '';
                                        }
                                        subQusChange = true;
                                    }
                                    // 解析
                                    if (this.oldSubjectList[index].sub_questions[sIndex].comment != sl.comment) {
                                        subQus.comment = sl.comment;
                                        subQusChange = true;
                                    }
                                    // 解析媒體
                                    if (this.oldSubjectList[index].sub_questions[sIndex].media.file != sl.media.file) {
                                        if (sl.media.file) { // 新增
                                            subQus.comment_media = 'comment_media_' + sl.id;
                                            subQus.mediaFile = sl.media.file;
                                        }
                                        else { // 刪除
                                            subQus.comment_media = '';
                                        }
                                        subQusChange = true;
                                    }
                                    // 難度
                                    if (this.oldSubjectList[index].sub_questions[sIndex].difficulty_level != sl.difficulty_level) {
                                        subQus.difficulty_level = sl.difficulty_level;
                                        subQusChange = true;
                                    }

                                    // 標籤
                                    if (this.oldSubjectList[index].sub_questions[sIndex].tags.length > 0 || sl.tags.length > 0) {
                                        // 如果其中一個陣列為空
                                        if (this.oldSubjectList[index].sub_questions[sIndex].tags.length == 0 || sl.tags.length == 0) {
                                            // 沒有舊資料代表一開始是空的，只需要處理新資料
                                            if (this.oldSubjectList[index].sub_questions[sIndex].tags.length == 0) {
                                                subQus.tags = [];
                                                sl.tags.forEach((item) => {
                                                    subQus.tags.push(item.name);
                                                    subQusChange = true;
                                                });
                                            }
                                            // 沒有新資料代表舊資料被刪除了
                                            if (sl.tags.length == 0) {
                                                this.oldSubjectList[index].sub_questions[sIndex].tags.forEach((el) => {
                                                    deleteTagIds.push(el.id);
                                                });
                                            }
                                        }
                                        else {// 代表新舊陣列皆有值，必須做比對
                                            this.oldSubjectList[index].sub_questions[sIndex].tags.forEach((item) => {
                                                // 如果舊資料不存在新資料中
                                                if (!this.$_.find(sl.tags, item)) {
                                                    // 1.分類刪除tags(有id代表刪除)
                                                    if (item.id != 0) {
                                                        deleteTagIds.push(item.id);
                                                    }
                                                }
                                            });
                                            sl.tags.forEach((item) => {
                                                // 如果新資料不存在舊資料中
                                                if (!this.$_.find(this.oldSubjectList[index].sub_questions[sIndex].tags, item)) {
                                                    // 2.分類新增tags(id = 0代表新增)
                                                    if (item.id == 0) {
                                                        if (!subQus.tags) {
                                                            subQus.tags = [];
                                                        }
                                                        subQus.tags.push(item.name);
                                                        subQusChange = true;
                                                    }
                                                }
                                            });
                                        }
                                    }

                                    if (sl.id == null || sl.id == undefined) {
                                        subQusChange = false;
                                    }

                                    if (subQusChange) {
                                        // sub_update 暫存，下面會全部合進update
                                        if (!updateInfo.question) {
                                            updateInfo.question = {};
                                            updateInfo.question.sub_update = [];
                                        }
                                        if (!updateInfo.question.sub_update) {
                                            updateInfo.question.sub_update = [];
                                        }
                                        updateInfo.question.sub_update[sIndex] = subQus;
                                    }

                                    if (this.oldSubjectList[index].sub_questions[sIndex].options.length == sl.options.length) {
                                        sl.options.forEach((option, oIndex) => {
                                            let subOptionChange = false,
                                                subOptionItem = {
                                                    id: option.id,
                                                },
                                                tmpSubOptionItem = { // 排序或新增子題選項使用，不使用subOptionItem是因為會塞一些不正確的資料進入subOptionItem
                                                    id: option.id
                                                };

                                            // 參數初始化
                                            option.isOptionError = false;
                                            option.optionErrText = '';

                                            if (!option.title && !option.image.path && !option.image.file) {
                                                isEmpty = true;
                                                option.isOptionError = true;
                                                option.optionErrText = '選項或答案內容不能空白';
                                                return false;
                                            }
                                            if (option.title.length > 300) {
                                                isEmpty = true;
                                                option.isOptionError = true;
                                                option.optionErrText = '文字數量不能超過300個字';
                                                return false;
                                            }

                                            // 如果有新增或排序子題選項
                                            if (this.isAddSubOption || this.isSortSubSubject) {
                                                if (!group.subQuestions[newSubQusIndex]) {
                                                    group.subQuestions[newSubQusIndex] = tmpSubQus;
                                                }
                                                if (!group.subQuestions[newSubQusIndex].options) {
                                                    group.subQuestions[newSubQusIndex].options = [];
                                                }
                                                if (option.id == null || option.id == undefined) {
                                                    let newIsAnswer = 0;
                                                    // 單選題 (若answer[0]等於oIndex，is_answer = 1)
                                                    if (el.answer_type == 0 && sl.answer[0] == oIndex) {
                                                        newIsAnswer = 1;
                                                    }
                                                    // 多選題 (若answer[oIndex]是1或true，is_answer = 1)
                                                    if (el.answer_type == 1 && sl.answer[oIndex]) {
                                                        newIsAnswer = 1;
                                                    }
                                                    tmpSubOptionItem = {
                                                            id: null,
                                                            isAnswer: newIsAnswer,
                                                            title: option.title
                                                        }
                                                    // 子題選項圖片
                                                    if (option.image.file) {
                                                        tmpSubOptionItem.imageFile = option.image.file;
                                                    }
                                                    group.subQuestions[newSubQusIndex].id = sl.id;
                                                    addGroup = true;
                                                }
                                                group.subQuestions[newSubQusIndex].options[newSubOptionIndex] = tmpSubOptionItem;
                                                newSubOptionIndex++;
                                            }
                                            let emptyCount = 0;
                                            // 檢查選擇題答案是否沒填
                                            if (el.answer_type == 1) {
                                                sl.answer.forEach((el) => {
                                                    if (el == 0 || el == false) {
                                                        emptyCount++;
                                                    }
                                                });
                                            }
                                            if (sl.answer.length == emptyCount) {
                                                isEmpty = true;
                                                sl.options[sl.options.length-1].isOptionError = true;
                                                sl.options[sl.options.length-1].optionErrText = '答案沒有選取';
                                                return false;
                                            }
                                            // 選項內容
                                            if (this.oldSubjectList[index].sub_questions[sIndex].options[oIndex].title != option.title) {
                                                subOptionItem.title = option.title;
                                                subOptionChange = true;
                                            }
                                            // 圖片
                                            if (this.oldSubjectList[index].sub_questions[sIndex].options[oIndex].image.file != option.image.file) {
                                                if (option.image.file) { // 新增
                                                    subOptionItem.image = 'option_image_' + option.id;
                                                    subOptionItem.imageFile = option.image.file;
                                                }
                                                else { // 刪除
                                                    subOptionItem.image = '';
                                                }
                                                subOptionChange = true;
                                            }
                                            // 如果el.answer有不一樣才會進入
                                            if (this.oldSubjectList[index].sub_questions[sIndex].answer.join('') != sl.answer.join('')) {
                                                subOptionItem.is_answer = 0;
                                                // 單選題 (若answer[0]等於oIndex，is_answer = 1)
                                                if (el.answer_type == 0 && sl.answer[0] == oIndex) {
                                                    subOptionItem.is_answer = 1;
                                                }
                                                // 多選題 (若answer[oIndex]是1或true，is_answer = 1)
                                                if (el.answer_type == 1 && sl.answer[oIndex]) {
                                                    subOptionItem.is_answer = 1;
                                                }
                                                subOptionChange = true;
                                            }
                                            if (option.id == null || option.id == undefined) {
                                                subOptionChange = false;
                                            }
                                            if (subOptionChange) {
                                                if (updateInfo.question) {
                                                    if (updateInfo.question.sub_update) {
                                                        if (!updateInfo.question.sub_update[sIndex]) {
                                                            subQus.options = [];
                                                            subQus.options[oIndex] = subQus;
                                                            updateInfo.question.sub_update[sIndex] = subQus;
                                                        }

                                                        if (updateInfo.question.sub_update[sIndex].options) {
                                                            updateInfo.question.sub_update[sIndex].options[oIndex] = subOptionItem;
                                                        }
                                                        else {
                                                            updateInfo.question.sub_update[sIndex].options = [];
                                                            updateInfo.question.sub_update[sIndex].options[oIndex] = subOptionItem;
                                                        }
                                                    }
                                                    else {
                                                        updateInfo.question.sub_update = [];
                                                        subQus.options = [];
                                                        subQus.options[oIndex] = subOptionItem;
                                                        updateInfo.question.sub_update[sIndex] = subQus;
                                                    }
                                                }
                                                else {
                                                    subQus.options = [];
                                                    subQus.options[oIndex] = subOptionItem;
                                                    if (!updateInfo.question) {
                                                        updateInfo.question = {};
                                                        updateInfo.question.sub_update = [];
                                                    }
                                                    updateInfo.question.sub_update[sIndex] = subQus;
                                                }
                                            }
                                        });
                                    }

                                    if (this.isAddSubQuestion || this.isAddSubOption || this.isSortSubSubject) {
                                        // 新增子題或子題選項使用的index，不能在上面子題組完資料後馬上遞增，不然在子題選項組資料時，會拿到加1的index
                                        newSubQusIndex++;
                                    }
                                });
                            }

                            if (addGroup || this.isSortSubSubject) {
                                if (!updateInfo.groups) {
                                    updateInfo.groups = [];
                                }
                                updateInfo.groups.push(group);
                            }
                        }
                        if (this.isSortSubject) {
                            if (!updateInfo.question) {
                                updateInfo.question = {};
                                updateInfo.question.sort = [];
                            }
                            if (!updateInfo.question.sort) {
                                updateInfo.question.sort = [];
                            }
                            updateInfo.question.sort.push(sort);
                        }
                    }
                    else { // 對話的檢查
                        let categoryChange = false,
                            category = {
                                answerType: el.answer_type,
                                id: el.id,
                            },
                            firstChange = false,
                            subject = {
                                id: el.id,
                                subQuestions: [],
                            },
                            roleA = [],
                            roleB = [];

                        // 配合新API，需要把每個物件都組出
                        if (!updateInfo.conversations) {
                            updateInfo.conversations = {
                                categoryId: el.category,
                                update: [],
                            };
                            updateInfo.conversations.update = [];
                        }
                        // 參數初始化
                        el.isRoleError = false;
                        // 分類
                        if (this.oldSubjectList[index].category != el.category) {
                            category.categoryId = el.category;
                            categoryChange = true;
                        }
                        // 角色
                        if (this.oldSubjectList[index].roleA != el.roleA ||
                            this.oldSubjectList[index].roleB != el.roleB) {
                            subject.roleGender = el.roleA + el.roleB;
                            firstChange = true;
                        }

                        // 第一層或第一層有排序
                        if (firstChange || this.isSortSubject) {
                            conversationChenged = true;
                        }

                        if (this.oldSubjectList[index].sub_questions.length == el.sub_questions.length) {
                            el.sub_questions.forEach((sl, sIndex) => {
                                let subQusChange = false,
                                    subQus = {
                                        id: sl.id
                                    };
                                // 參數初始化
                                sl.isAudioError = false;
                                sl.audioErrText = '';
                                sl.isTitleError = false;
                                sl.titleErrText = '';
                                sl.options[0].isOptionError = false;
                                sl.options[0].optionErrText = '';

                                // 若有選擇角色會分別push 1進roleA或B角色陣列
                                if (sl.voiceGroup == '0') {
                                    roleA.push(1);
                                }

                                if (sl.voiceGroup == '1') {
                                    roleB.push(1);
                                }

                                if (!sl.options[0].title) {
                                    isEmpty = true;
                                    sl.options[0].isOptionError = true;
                                    sl.options[0].optionErrText = '朗讀內容不能空白';
                                    return false;
                                }

                                if (!sl.title) {
                                    isEmpty = true;
                                    sl.isTitleError = true;
                                    sl.titleErrText = '中文翻譯不能空白';
                                    return false;
                                }

                                // 朗讀內容的字數檢查(目前沒有中文，所以字數都一樣)
                                let maxWords = this.newSubject.recognize_language !== '2' ? 50 : 150;
                                if (sl.options[0].title && sl.options[0].title.length > maxWords) {
                                    isEmpty = true;
                                    sl.options[0].isOptionError = true;
                                    sl.options[0].optionErrText = `文字數量不能超過${maxWords}個字`;
                                    return false;
                                }
                                // 翻譯的字數檢查 (目前沒有中文，所以字數都一樣)
                                let translateMaxWords = this.newSubject.recognize_language !== '2' ? 500 : 500;
                                if (sl.title && sl.title.length > translateMaxWords) {
                                    isEmpty = true;
                                    sl.isTitleError = true;
                                    sl.titleErrText = `文字數量不能超過${translateMaxWords}個字`;
                                    return false;
                                }

                                // 角色
                                if (this.oldSubjectList[index].sub_questions[sIndex].voiceGroup != sl.voiceGroup) {
                                    subQus.voiceGroup = sl.voiceGroup;
                                    subQusChange = true;
                                }

                                if (this.oldSubjectList[index].sub_questions[sIndex].audio.path != sl.audio.path) {
                                    // 附加音檔新增
                                    if (sl.audio.file) {
                                        subQus.upload_audio = 'upload_audio' + index + sIndex; // $_FILE名稱
                                        subQus.audioFile = sl.audio.file;
                                        subQusChange = true;
                                    }
                                    else { // 附加音檔刪除
                                        subQus.upload_audio = '';
                                        subQusChange = true;
                                    }

                                }
                                // 若選"附加音檔" 要多一個參數
                                if (this.oldSubjectList[index].sub_questions[sIndex].attachAudio != sl.attachAudio && sl.attachAudio == '2') {
                                    subQus.is_change_tts = true;
                                }
                                // 朗讀內容
                                if (this.oldSubjectList[index].sub_questions[sIndex].options[0].title != sl.options[0].title) {
                                    subQus.options = [];
                                    subQus.options.push({title: sl.options[0].title});
                                    subQusChange = true;
                                }
                                // 中文翻譯
                                if (this.oldSubjectList[index].sub_questions[sIndex].title != sl.title) {
                                    subQus.title = sl.title;
                                    subQusChange = true;
                                }

                                if (sl.id == null || sl.id == undefined) {
                                    // 用於新增子題使用 (把所有需要的參數都組好)
                                    subQus = {
                                        voiceGroup: sl.voiceGroup,
                                        upload_audio: sl.audio.file ? 'upload_audio' + index + sIndex : '',
                                        audioFile: sl.audio.file ? sl.audio.file : '',
                                        options: [{ title: sl.options[0].title }],
                                        title: sl.title
                                    }
                                }

                                // 若el.isAddSubQuestion = true 代表有新增子題或el.isSortSubSubject = true 代表子題有排序，那這題的所有子題都要push到更新的陣列裡
                                if (subQusChange || el.isAddSubQuestion || el.isSortSubSubject) {
                                    conversationChenged = true;
                                }
                                subject.subQuestions.push(subQus);
                            });
                        }

                        updateInfo.conversations.update[index] = subject;

                        if (!isEmpty &&
                            (roleA.length == 0 ||
                            roleB.length == 0)) {
                                isEmpty = true;
                                let roleText = roleA.length == 0 ? 'B' : 'A';
                                el.isRoleError = true;
                                el.roleErrText = '不能都是角色 ' + roleText + ' 請重新選擇'
                        }
                        if (categoryChange) {
                            if (!updateInfo.question) {
                                updateInfo.question = {};
                                updateInfo.question.update = [];
                            }
                            if (!updateInfo.question.update) {
                                updateInfo.question.update = [];
                            }
                            updateInfo.question.update[index] = category;
                        }
                    }
                });

                // sub_question有更改資料才會進入
                if (updateInfo.question && updateInfo.question.sub_update) {
                    if (!updateInfo.question.update) {
                        updateInfo.question.update = updateInfo.question.sub_update;
                    }
                    else {
                        updateInfo.question.sub_update.forEach((el) => {
                            updateInfo.question.update.push(el);
                        })
                    }
                    delete updateInfo.question.sub_update;
                }

                if (!conversationChenged) {
                    delete updateInfo.conversations;
                }

                if (updateInfo.question && (updateInfo.question.update || updateInfo.question.sort) || updateInfo.groups || updateInfo.conversations) {
                    if (updateInfo.conversations &&
                        updateInfo.conversations.update.length > 0) {
                            updateInfo.conversations.update = updateInfo.conversations.update.filter(n => n); // 因為後端需要從index0開始檢查，如果陣列中有null會有問題
                    }
                    updateData.updateInfo = updateInfo;
                }
            }

            // 檢查tag有沒有刪除
            if (deleteTagIds.length > 0) {
                if (!updateInfo.question) {
                    updateInfo.question = {};
                }
                updateInfo.question.deleteTagIds = deleteTagIds;
                updateData.updateInfo = updateInfo;
            }
            // 檢查題目有沒有刪除
            if (this.deleteSubjectIds.length > 0) {
                if (!updateInfo.question) {
                    updateInfo.question = {};
                }
                updateInfo.question.deleteQuestionIds = this.deleteSubjectIds;
                updateData.updateInfo = updateInfo;
            }
            // 檢查題目的選項有沒有刪除
            if (this.deleteOptionIds.length > 0) {
                if (!updateInfo.question) {
                    updateInfo.question = {};
                }
                updateInfo.question.deleteOptionIds = this.deleteOptionIds;
                updateData.updateInfo = updateInfo;
            }
            // 檢查題目有沒有停用
            if (this.disableQuestionIds.length > 0) {
                if (!updateInfo.question) {
                    updateInfo.question = {};
                }
                updateInfo.question.disableQuestionIds = this.disableQuestionIds;
                updateData.updateInfo = updateInfo;
            }

            let excludeKeys = [''];  // 排除檔案
            articleInfoChange = this.$_.omitBy(this.$_.omit(this.articleInfo, excludeKeys), (v, k) => {
                return this.oldArticleInfo[k] === v;
            });

            // 習題設定
            // 值有改變才會進入
            if (articleInfoChange.isRandomQuestion
                || articleInfoChange.isRandomQuestionOption
                || articleInfoChange.isShowScore) {
                updateInfo.settings = {};

                if (articleInfoChange.isRandomQuestion) {
                    updateInfo.settings.is_random_question = articleInfoChange.isRandomQuestion;
                }

                if (articleInfoChange.isRandomQuestionOption) {
                    updateInfo.settings.is_random_question_option = articleInfoChange.isRandomQuestionOption;
                }

                if (articleInfoChange.isShowScore) {
                    updateInfo.settings.is_show_score = articleInfoChange.isShowScore;
                }
                updateData.updateInfo = updateInfo;
            }

            // 如果資料空值，儲存紐disable
            if (isEmpty) {
                updateData = {
                    saveType: 'practice'
                };
            }

            // console.log(updateData);
            return updateData;
        },
        isEditable() {
            // 是否可以編輯文章資料項目, 條件如下:
            // 1. 文章狀態為"編輯中"
            // 2. 使用者權限為 admin 時, "待審核"狀態還可編輯以上項目
            return this.articleBasicInfo.state == '0' ||
                    (this.$store.state.common.userInfo.permission == 'admin' && this.articleBasicInfo.state == '1');
        }
    },
    created: function() {
        this.initialize();
    },
    mounted: function() {
    },
    methods: {
        initialize() {
            let refreshArticleInfo = () => {
                this.$parent.isFinishUpdatingData = false;
                this.isSetDataReady.getCategoryList = false;
                this.oldCategoryList = [];
                this.deletedCategoryIds = [];
                this.deleteSubjectIds = [];
                this.deleteOptionIds = [];
                this.disableQuestionIds = [];
                // 以下為重新reset排序flag
                this.isSortSubject = false;
                this.isSortSubSubject = false;
                this.isAddSubQuestion = false;
                this.isAddSubOption = false;

                this.getCategoryList()
                    .then(() => {
                        this.isSetDataReady.getCategoryList = true;

                        // 如果category這個變數有值，代表需要重新獲取subjectList
                        if (this.categoryId) {
                            this.getSubjectList();
                        }

                        // 監聽編輯內容是否更動
                        this.$watch('updatePracticeData', newValue => {
                            this.$store.commit('adminArticle/setUpdateArticleData', newValue);
                            this.$store.commit('adminArticle/setIsShowNotSaveArticlePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                        });
                        // 編輯文章類型，需更新vuex的值
                        this.$watch('articleInfo.isRandomQuestion', newValue => this.$store.commit('adminArticle/changeArticleIsRandomQuestion', newValue));
                        // 編輯題目順序，需更新vuex的值
                        this.$watch('articleInfo.isRandomQuestionOption', newValue => this.$store.commit('adminArticle/changeArticleIsRandomQuestionOption', newValue));
                        // 編輯練習分數，需更新vuex的值
                        this.$watch('articleInfo.isShowScore', newValue => this.$store.commit('adminArticle/changeArticleIsShowScore', newValue));
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            refreshArticleInfo();

            // 當成功更新文章資訊後, 則重新取得文章資訊
            this.$watch('$parent.isFinishUpdatingData', newValue => {
                if (newValue) {
                    refreshArticleInfo();
                }
            });
        },
        changeActiveTab(newTab) {
            // 如果有尚未儲存的項目, 需跳出提示彈窗提醒使用者
            if (!this.$_.isEmpty(this.$_.omit(this.updatePracticeData, ['saveType']))) {
                if (window.confirm('系統可能不會儲存您所做的變更。')) {
                    this.articleInfo = this.oldArticleInfo;
                    this.initialize();
                } else {
                    return;
                }
            }
            this.activeTab = newTab;
        },

        getCategoryList() {
            if (!this.$route.params.id) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: this.$route.params.id
                }

                this.categoryList = [];

                this.$httpRequest.get('/admin_api/practice/get_category', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                result.map(el => {
                                    el.total = parseInt(el.total);
                                });

                                this.articleInfo = {
                                    // 基本資料
                                    isRandomQuestion: this.articleBasicInfo.isRandomQuestion,
                                    isRandomQuestionOption: this.articleBasicInfo.isRandomQuestionOption,
                                    isShowScore: this.articleBasicInfo.isShowScore,
                                }

                                if (this.articleInfo) {
                                    // 暫存原本的文章資料, 以用來判斷是否enable儲存按鈕
                                    this.oldArticleInfo = this.$_.cloneDeep(this.articleInfo);
                                }

                                this.categoryList = this.$_.cloneDeep(result);
                                this.categoryList = this.$_.dropRight(this.categoryList, 1);
                                this.categoryList.forEach((el, index) => {
                                    el.combine = [];
                                    this.$set(this.categoryList[index], 'isImmediatelyGetAnswer', el.is_immediately_get_answer);
                                    this.$set(this.categoryList[index], 'practiceTimes', el.practice_times);
                                    el.is_immediately_get_answer = el.is_immediately_get_answer == '1' ? true : false;
                                });

                                this.defaultCategory = this.$_.last(result);  // 練習題

                                // 暫存原本的分類資料, 以用來判斷是否enable儲存按鈕
                                this.oldCategoryList = this.$_.cloneDeep(this.categoryList);

                                // 新增新題目的物件
                                this.newSubject = this.initialNewSubjectList();

                                resolve();
                            } else {
                                reject("admin/course/edit/articles.js: 'getCategoryList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/course/edit/articles.js: 'getCategoryList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("admin/course/edit/articles.js: 'getCategoryList' get error");
                    });
            });
        },
        changeImmediatelyGetAnswer(index) {
            //
            if (this.categoryList[index].isImmediatelyGetAnswer == '1') {
                this.categoryList[index].isImmediatelyGetAnswer = '0';
            }
            else {
                this.categoryList[index].isImmediatelyGetAnswer = '1';
            }
        },
        initialNewSubjectList(categoryClassType) { // 初始化新增題目的物件
            let originalCategoryClassType = this.categoryClassType, // 只有分類是[其他]，才會用到這個暫存起來的值
                categoryListType1 = [],
                categoryListType2 = [],
                categoryListType3 = [],
                categoryListType4 = [],
                categoryListType5 = [],
                categoryListType6 = [];
            this.categoryClassType = categoryClassType >= 0 ? categoryClassType : 0; // 只有分類是[其他]，categoryClassType才會使用帶進來的值
            this.clickedCategoryClassType = categoryClassType;
            this.categoryList.forEach((el) => {
                if(el.id == this.categoryId) {
                    if (el.score_type == 0) {
                        this.categoryClassType = 1;
                    }
                    if (el.score_type == 1) {
                        this.categoryClassType = 2;
                    }
                    if (el.score_type == 2) {
                        this.categoryClassType = 3;
                    }
                    if (el.score_type == 3) {
                        this.categoryClassType = 4;
                    }
                    if (el.score_type == 4) {
                        this.categoryClassType = 5;
                    }
                    if (el.score_type == 5) {
                        this.categoryClassType = 6;
                    }
                }
                // 分類類別
                if (el.score_type == 0) { // 類別[單題]
                    categoryListType1.push(el);
                }
                if (el.score_type == 1) { // 類別[朗讀]
                    categoryListType2.push(el);
                }
                if (el.score_type == 2) { // 類別[題組]
                    categoryListType3.push(el);
                }
                if(el.score_type == 3) { // 類別[對話]
                    categoryListType4.push(el);
                }
                if(el.score_type == 4) { // 類別[翻譯]
                    categoryListType5.push(el);
                }
                if(el.score_type == 5) { // 類別[寫作]
                    categoryListType6.push(el);
                }
            });

            let obj = {
                is_group: this.categoryClassType == 3 ? 1 : 0,
                category: this.categoryId,
                categoryList: this.categoryClassType == 1 ? categoryListType1 : this.categoryClassType == 2 ? categoryListType2 : this.categoryList,
                answer_type: this.categoryClassType == 2 ? 4 : this.categoryClassType == 4 ? 5 : this.categoryClassType == 5 ? 6 : this.categoryClassType == 6 ? 7 : 0,
                title: '',
                score: '0',
                image: {
                    name: '',
                    path: ''
                },
                recognize_language: this.categoryClassType == '2' ||  this.categoryClassType == '4' ? '2' : '0',
                speakTopicType: '1',
                beforeAnswer: '1',
                attachAudio: this.categoryClassType == 2 ? '1' : '0',
                text_to_speech: '',
                audio: {
                    name: '',
                    path: ''
                },
                // 對話
                roleA: '',
                roleB: '',
                // 翻譯
                description: '',
                tips: '',
                isPastExamCorrection: 1,
                leastWords: 5,
                // 寫作
                images: [],
                relatedWords: '',
                compositionReference: '',
                paragraph: 0,
                paragraphs: [''],
                isPastExamAppraisal: 1,
                isSentenceCorrection: 1,
                isArticleOptimization: 1,
                basicWords: 0,
                options: [],
                answer: [],
                comment: '',
                media: {
                    name: '',
                    path: ''
                },
                difficulty_level: '0',
                tags: [],
                isClick: false,
                isShowSubject: false,
                isTitleError: false,
                titleErrText: false,
                isSpeechError: false,
                speechErrText: false,
                isCommentError: false,
                commentErrText: false
            }

            let options = [];

            // 根據題目類型來給予不同的選項
            switch(obj.answer_type) {
                case 0:
                case 1:
                    options = [
                        {
                            title: '',
                            image: {
                                path: '',
                                name: '',
                                file: ''
                            },
                            isOptionError: false,
                            optionErrText: false
                        },
                        {
                            title: '',
                            image: {
                                path: '',
                                name: '',
                                file: ''
                            },
                            isOptionError: false,
                            optionErrText: false
                        }
                    ];
                    break;
                case 2:
                case 3:
                case 4:
                case 6:
                    options = [
                        {
                            title: '',
                            image: {
                                path: '',
                                name: '',
                                file: ''
                            },
                            isOptionError: false,
                            optionErrText: false
                        }
                    ];
                    break;
            }

            if (this.categoryClassType == 3) { // 如果是題組的話需要新增子題物件
                let sub_questions = [ // 暫用sub_questions，看後端給什麼名稱，到時候再改也行
                        {
                            score: '0',
                            title: '',
                            image: {
                                name: '',
                                path: ''
                            },
                            options: options,
                            answer: [],
                            comment: '',
                            media: {
                                name: '',
                                path: ''
                            },
                            difficulty_level: '0',
                            tags: [],
                            isClick: false,
                            isShowAdvance: false,
                            isTitleError: false,
                            titleErrText: false,
                            isCommentError: false,
                            commentErrText: false
                        }
                    ];

                obj.sub_questions = sub_questions;
            }
            else if (this.categoryClassType == 4) {
                let sub_questions = [
                    // 對話預設要兩題
                    {
                        voiceGroup: '0',
                        attachAudio: '2',
                        audio: {
                            name: '',
                            path: ''
                        },
                        options: [{
                            title: '',
                            isOptionError: false,
                            optionErrText: ''
                        }],
                        title: '', // 中文翻譯
                        isClick: false,
                        isAudioError: false,
                        audioErrText: '',
                        isTitleError: false,
                        titleErrText: '',
                    },
                    {
                        voiceGroup: '1',
                        attachAudio: '2',
                        audio: {
                            name: '',
                            path: ''
                        },
                        options: [{
                            title: '',
                            isOptionError: false,
                            optionErrText: ''
                        }],
                        title: '', // 中文翻譯
                        isClick: false,
                        isAudioError: false,
                        audioErrText: '',
                        isTitleError: false,
                        titleErrText: '',
                    }
                ];
                obj.isAddSubQuestion = false;
                obj.isSortSubSubject = false;
                obj.isRoleError = false;
                obj.roleErrText = '';
                obj.sub_questions = sub_questions;
            }
            else {
                obj.options = options;
            }

            if (originalCategoryClassType == 0) { // 只有分類是[其他]，才需要重新設
                this.categoryClassType = originalCategoryClassType; // 原因是新增題目pop up打開並設定好值後，必須再把原本的type set回去
            }
            this.renderMdCount++;

            return obj;
        },
        getSubjectList() {
            this.subjectList = [];

            let params = {
                categoryId: this.categoryId
            };

            this.$httpRequest.get('/admin_api/practice/get_questions', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        this.categoryClassType = 0; // 如果分類是[其他]，categoryClassType就會是0

                        // 設定categoryClassType以讓前端知道目前是哪個類別
                        for (let el of this.categoryList) {
                            if (el.id == this.categoryId) {
                                if (el.score_type == 0) { // 單題題
                                    this.categoryClassType = 1;
                                }
                                if (el.score_type == 1){ // 朗讀題
                                    this.categoryClassType = 2;
                                }
                                if (el.score_type == 2){ // 題組題
                                    this.categoryClassType = 3;
                                }
                                if (el.score_type == 3){ // 對話題
                                    this.categoryClassType = 4;
                                }
                                if (el.score_type == 4){ // 翻譯題
                                    this.categoryClassType = 5;
                                }
                                if (el.score_type == 5){ // 寫作題
                                    this.categoryClassType = 6;
                                }
                            }
                        }

                        if (result.length > 0) {
                            this.subjectList = result;

                            this.subjectList.forEach((el, index) => {
                                this.$set(this.subjectList[index], 'category', this.categoryId);
                                let editCategoryList = []; // 由於每題的對應類別都不同，所以要根據每題的answer_type來取得categoryList
                                this.categoryList.forEach((cl) => {
                                    if (el.answer_type < 4 && el.is_group == 0 && cl.score_type == 0) { // 類別[單題]
                                        editCategoryList.push(cl);
                                    }
                                    if (el.answer_type == 4 && cl.score_type == 1) { // 類別[朗讀]
                                        editCategoryList.push(cl);
                                    }
                                    if (el.answer_type < 2 && el.is_group == 1 && cl.score_type == 2) { // 類別[題組]
                                        editCategoryList.push(cl);
                                    }
                                    if(el.answer_type == 5 && el.is_group == 1 && cl.score_type == 3) { // 類別[對話]
                                        editCategoryList.push(cl);
                                    }
                                    if(el.answer_type == 6 && el.is_group == 0 && cl.score_type == 4) { // 類別[翻譯]
                                        editCategoryList.push(cl);
                                    }
                                    if(el.answer_type == 7 && el.is_group == 0 && cl.score_type == 5) { // 類別[翻譯]
                                        editCategoryList.push(cl);
                                    }
                                });
                                this.$set(this.subjectList[index], 'categoryList', editCategoryList);
                                this.$set(this.subjectList[index], 'image', {
                                    path: el.image,
                                    name: '',
                                    file: ''
                                });
                                if (el.images) {
                                    let imagesTmp = [];
                                    for(let item of el.images) {
                                        imagesTmp.push({
                                            path: item,
                                            name: ''
                                        })
                                    }
                                    el.images = imagesTmp;
                                }
                                else {
                                    this.$set(this.subjectList[index], 'images', []);
                                }
                                this.$set(this.subjectList[index], 'attachAudio', this.categoryClassType == 2 ? '1' : '0');
                                this.$set(this.subjectList[index], 'text_to_speech', '');
                                this.$set(this.subjectList[index], 'audio', {
                                    path: el.audio,
                                    name: '',
                                    file: ''
                                });
                                this.$set(this.subjectList[index], 'media', {
                                    path: el.comment_media && el.comment_media.path ? el.comment_media.path : '',
                                    name: el.comment_media && el.comment_media.name ? el.comment_media.name : '',
                                    file: ''
                                });
                                this.$set(this.subjectList[index], 'media', {
                                    path: el.comment_media && el.comment_media.path ? el.comment_media.path : '',
                                    name: el.comment_media && el.comment_media.name ? el.comment_media.name : '',
                                    file: ''
                                });

                                if (el.answer_type == 4) {
                                    this.$set(this.subjectList[index], 'speakTopicType', el.is_show_speak_text);
                                    this.$set(this.subjectList[index], 'beforeAnswer', el.is_show_speak_audio);
                                }

                                // 對話特有參數
                                if (el.answer_type == 5 && el.is_group == 1) {
                                    this.$set(this.subjectList[index], 'roleA', el.role_gender.split('')[0]);
                                    this.$set(this.subjectList[index], 'roleB', el.role_gender.split('')[1]);
                                }

                                if (el.answer_type == 6 && el.correction_mechanism) {
                                    this.$set(this.subjectList[index], 'isPastExamCorrection', el.correction_mechanism.modes.translation.correct ? 1 : 0);
                                    this.$set(this.subjectList[index], 'leastWords', el.correction_mechanism.words.least);
                                }

                                if (el.answer_type == 7 && el.correction_mechanism) {

                                    this.$set(this.subjectList[index], 'relatedWords', el.related_words);
                                    this.$set(this.subjectList[index], 'paragraph', el.composition_paragraph.style == null ? 0 : 1);
                                    this.$set(this.subjectList[index], 'paragraphs', el.composition_paragraph.paragraphs ? el.composition_paragraph.paragraphs : []);
                                    this.$set(this.subjectList[index], 'compositionReference', el.composition_reference);
                                    this.$set(this.subjectList[index], 'isPastExamAppraisal', el.correction_mechanism.modes.composition.summary ? 1 : 0);
                                    this.$set(this.subjectList[index], 'isSentenceCorrection', el.correction_mechanism.modes.composition.sentence ? 1 : 0);
                                    this.$set(this.subjectList[index], 'isArticleOptimization', el.correction_mechanism.modes.composition.optimize ? 1 : 0);
                                    this.$set(this.subjectList[index], 'basicWords', el.correction_mechanism.words.basic);
                                    this.$set(this.subjectList[index], 'leastWords', el.correction_mechanism.words.least);
                                }

                                if (el.is_group == 0) { // 單題選項
                                    let answer = [];
                                    el.options.forEach((option, key) => {
                                        // 設定答案
                                        if (el.answer_type == 0 && option.is_answer == 1) { // 單選題，根據is_answer是否等於1，等於1把index帶入
                                            answer.push(key);
                                        }
                                        if (el.answer_type == 1) { // 多選題，根據is_answer是否等於1來給[answer陣列]值
                                            let number = option.is_answer == 1 ? 1 : 0;
                                            answer.push(number);
                                        }
                                        if (el.answer_type > 1) { // 填充題、重組題、朗讀題、翻譯題 、寫作題[answer陣列]直接push 1
                                            answer.push(1);
                                        }
                                        // 設定image
                                        if (option.image) {
                                            this.$set(this.subjectList[index].options[key], 'image', {
                                                path: option.image,
                                                name: '',
                                                file: ''
                                            });
                                        }
                                        // 重組題需把答案全部放在第一個option
                                        if (el.answer_type == 3 && key > 0) {
                                            el.options[0].title += '\n' + option.title;
                                        }
                                        this.$set(this.subjectList[index].options[key], 'isOptionError', false);
                                        this.$set(this.subjectList[index].options[key], 'optionErrText', false);
                                    });
                                    // 重組題只能留第一個選項
                                    if (el.answer_type == 3) {
                                        for(let i = el.options.length-1; i > 0; i--) {
                                            el.options.splice(i);
                                        }
                                    }

                                    this.$set(this.subjectList[index], 'answer', answer);
                                    this.$set(this.subjectList[index], 'isCommentError', false);
                                    this.$set(this.subjectList[index], 'commentErrText', false);
                                }
                                else { // 題組子題
                                    el.sub_questions.forEach((subQus, sIndex) => {
                                        if (el.answer_type != 5) {
                                            this.$set(this.subjectList[index].sub_questions[sIndex], 'image', {
                                                path: subQus.image !== undefined ? subQus.image : '',
                                                name: '',
                                                file: ''
                                            });
                                            this.$set(this.subjectList[index].sub_questions[sIndex], 'media', {
                                                path: subQus.comment_media !== undefined ? subQus.comment_media.path : '',
                                                name: subQus.comment_media !== undefined ? subQus.comment_media.name : '',
                                                file: ''
                                            });

                                            let answer = [];
                                            subQus.options.forEach((option, oIndex) => {
                                                // 設定答案
                                                if (el.answer_type == 0 && option.is_answer == 1) { // 單選題，根據is_answer是否等於1，等於1把index帶入
                                                    answer.push(oIndex);
                                                }
                                                if (el.answer_type == 1){ // 多選題，根據is_answer是否等於1來給[answer陣列]值
                                                    let number = option.is_answer == 1 ? 1 : 0;
                                                    answer.push(number);
                                                }
                                                // 設定image
                                                this.$set(this.subjectList[index].sub_questions[sIndex].options[oIndex], 'image', {
                                                    path: option.image !== undefined ? option.image : '',
                                                    name: '',
                                                    file: ''
                                                });
                                                this.$set(this.subjectList[index].sub_questions[sIndex].options[oIndex], 'isOptionError', false);
                                                this.$set(this.subjectList[index].sub_questions[sIndex].options[oIndex], 'optionErrText', false);
                                            });
                                            this.$set(this.subjectList[index].sub_questions[sIndex], 'answer', answer);
                                        }
                                        else {
                                            this.$set(this.subjectList[index].sub_questions[sIndex], 'voiceGroup', this.subjectList[index].sub_questions[sIndex].voice_group);
                                            this.$set(this.subjectList[index].sub_questions[sIndex], 'attachAudio', subQus.audio !== undefined ? (subQus.is_tts ? '2' : '1') : '0');
                                            this.$set(this.subjectList[index].sub_questions[sIndex], 'audio', {
                                                path: subQus.audio !== undefined ? subQus.audio : '',
                                                name: '',
                                                file: ''
                                            });
                                            subQus.options.forEach((option, oIndex) => {
                                                this.$set(this.subjectList[index].sub_questions[sIndex].options[oIndex], 'isOptionError', false);
                                                this.$set(this.subjectList[index].sub_questions[sIndex].options[oIndex], 'optionErrText', false);
                                            });
                                        }

                                        this.$set(this.subjectList[index].sub_questions[sIndex], 'isClick', false);
                                        this.$set(this.subjectList[index].sub_questions[sIndex], 'isTitleError', false);
                                        this.$set(this.subjectList[index].sub_questions[sIndex], 'titleErrText', false);
                                        this.$set(this.subjectList[index].sub_questions[sIndex], 'isCommentError', false);
                                        this.$set(this.subjectList[index].sub_questions[sIndex], 'commentErrText', false);
                                    });
                                }

                                this.$set(this.subjectList[index], 'isClick', false);
                                this.$set(this.subjectList[index], 'isShowSubject', false);
                                this.$set(this.subjectList[index], 'isTitleError', false);
                                this.$set(this.subjectList[index], 'titleErrText', false);
                                this.$set(this.subjectList[index], 'isSpeechError', false);
                                this.$set(this.subjectList[index], 'speechErrText', false);
                                this.$set(this.subjectList[index], 'isRoleError', false);
                                this.$set(this.subjectList[index], 'roleErrText', '');
                                this.subjectList[index].is_freeze = this.subjectList[index].is_freeze == 1 ? true : false;
                                this.subjectList[index].is_disable = this.subjectList[index].is_disable == 1 ? true : false;
                            });

                            if (this.subjectList) {
                                // 暫存原本的題目資料, 以用來判斷是否enable儲存按鈕
                                this.oldSubjectList = this.$_.cloneDeep(this.subjectList);
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        showAddCategoryDialogue() {
            if (this.categoryList.length >= 15) {
                return;
            }

            this.newCategoryName = '';
            this.isNewCategoryNameRepeat = false;
            $('#addCategoryDialogue').modal('show');
        },
        addCategory() {
            if (!this.newCategoryName || this.isNewCategoryNameRepeat) {
                return;
            }

            let newItem = {
                id: null,
                name: this.newCategoryName,
                score_type: this.newCategoryClassType,
                total: 0,
                pick_num: 0,
                practice_times: ['4', '5'].includes(this.newCategoryClassType) ? 2 : 0,
                practiceTimes: ['4', '5'].includes(this.newCategoryClassType) ? 2 : 0,
                is_immediately_get_answer: true,
                combine: [],
            }
            this.categoryList.push(newItem);
            $('#addCategoryDialogue').modal('hide');
            // 因為新增的項目在分類列表最後一個, 因此拉動捲軸至列表最下方
            this.$nextTick(() => $('#categoryTableList').scrollTop($('#categoryTableList')[0].scrollHeight));
        },
        showRenameCategoryDialogue(index, name) {
            this.selectedCategoryIndex = index;
            this.oldCategoryName = name;
            this.newCategoryName = name;
            $('#renameCategoryDialogue').modal('show');
        },
        renameCategory() {
            if (!this.newCategoryName || this.newCategoryName === this.oldCategoryName || this.isNewCategoryNameRepeat) {
                return;
            }

            this.categoryList.forEach((el, i) => {
                if (i === this.selectedCategoryIndex) {
                    el.name = this.newCategoryName;
                }
            });
            $('#renameCategoryDialogue').modal('hide');
        },
        showCombineCategoryDialogue(index, name) {
            this.selectedCategoryIndex = index;
            this.newCategoryName = name;
            this.combineCategories = [];
            $('#combineCategoryDialogue').modal('show');
        },
        combineCategory() {
            if (!this.combineCategories.length || !this.newCategoryName) {
                return;
            }

            let combineIds = [], combineIndexs = [], combineTotal = 0, errorCategoryClass = [];
            this.combineCategories.forEach(el => {
                combineIds.push(el.id);
                combineIndexs.push(el.index);
                combineTotal += el.total;
                if (this.categoryList[this.selectedCategoryIndex].score_type != el.score_type) {
                    errorCategoryClass.push(el);
                }
            });

            if (errorCategoryClass.length > 0) { // 如果合併時有選到不同的分類類別，就不能合併
                this.$store.dispatch('common/setAlert', { msg: '您選的分類不能進行合併，因為分類類別不同，請重新選擇', status: 'danger' });
                return;
            }
            this.categoryList.forEach((el, i) => {
                if (i === this.selectedCategoryIndex) {
                    el.name = this.newCategoryName;
                    el.combine = this.$_.compact(combineIds);  // 去除值為 null 的 element
                    el.total += combineTotal;
                }
            });
            // 移除被合併的分類
            this.categoryList = this.$_.remove(this.categoryList, (el, i) => {
                return !combineIndexs.includes(i);
            });

            $('#combineCategoryDialogue').modal('hide');
        },
        showDeleteCategoryDialogue(index) {
            this.selectedCategoryIndex = index;
            $('#deleteCategoryDialogue').modal('show');
        },
        deleteCategory() {
            this.categoryList = this.$_.remove(this.categoryList, (el, i) => {
                if (i === this.selectedCategoryIndex && el.id) {  // 欲刪除的分類為原本存在的分類
                    this.deletedCategoryIds.push(el.id);
                    this.defaultCategory.total += el.total;
                }
                return i !== this.selectedCategoryIndex;
            });
            $('#deleteCategoryDialogue').modal('hide');
        },
        selectItem(index) {
            if (this.isProhibitSelect) {
                this.isProhibitSelect = false;
                return;
            }
            let isAlleditHide = true;
            this.subjectList.forEach((el, key) => {
                if (index == key) {
                    el.isClick = true;
                    this.selectedSubjectIndex = index;
                }
                else {
                    el.isClick = false;
                }
                if (el.isClick && el.isShowSubject) {
                    isAlleditHide = false;
                }
            });

            if (isAlleditHide) {
                this.selectedSubjectIndex = '';
            }
        },
        selectSubQuestionItem(subQuestionIndex, subjectIndex) { // 重設題組子題的點擊狀態
            if (subjectIndex === undefined) { // 新增
                if (this.newSubject.sub_questions.length > 0) {
                    this.newSubject.sub_questions.forEach((el, key) => {
                        if (subQuestionIndex == key) {
                            el.isClick = true;
                        }
                        else {
                            el.isClick = false;
                        }
                    });
                }
            }
            else { // 編輯
                if (this.subjectList[subjectIndex].sub_questions.length > 0) {
                    this.subjectList[subjectIndex].sub_questions.forEach((el, key) => {
                        if (subQuestionIndex == key) {
                            el.isClick = true;
                        }
                        else {
                            el.isClick = false;
                        }
                    });
                }
            }
        },
        changeSubjectType(opt) {
            let options = [];
            switch(opt.value) {
                case '0':
                case '1':
                    options = [
                        {
                            title: '',
                            image: {
                                path: '',
                                name: '',
                                file: ''
                            },
                            isOptionError: false,
                            optionErrText: ''
                        },
                        {
                            title: '',
                            image: {
                                path: '',
                                name: '',
                                file: ''
                            },
                            isOptionError: false,
                            optionErrText: ''
                        }
                    ];
                    break;
                case '2':
                case '3':
                case '4':
                    options = [
                        {
                            title: '',
                            image: {
                                path: '',
                                name: '',
                                file: ''
                            },
                            isOptionError: false,
                            optionErrText: ''
                        }
                    ];
                    break;
            }

            if (this.categoryClassType < 3) {
                this.newSubject.options = options;
                if (opt.value == 0) { // 單選
                    this.newSubject.answer = []; // 答案清空
                }
                if (opt.value == 1) { // 多選
                    this.newSubject.answer = [false, false];
                }
            }
            else { // 題組
                if (this.newSubject.sub_questions.length > 1) {
                    this.newSubject = this.initialNewSubjectList(this.categoryClassType);
                    this.newSubject.answer_type = opt.value;
                }
                else {
                    this.newSubject.sub_questions[0].options = options;
                }
                if (opt.value == 0) { // 單選
                    this.newSubject.sub_questions[0].answer = []; // 答案清空
                }
                if (opt.value == 1) { // 多選
                    this.newSubject.sub_questions[0].answer = [false, false];
                }
            }
        },
        resetAttachAudio() {
            if (this.newSubject.attachAudio == 0 || this.newSubject.attachAudio == 1) {
                this.newSubject.text_to_speech = '';
            }
            if (this.newSubject.attachAudio == 0 || this.newSubject.attachAudio == 2) {
                this.newSubject.audio = {
                        path: null,
                        name: null,
                        file: null
                    };
            }
        },
        checkNewQuestionContent() { // 新增題目檢查輸入內容
            if (!$('body').hasClass('modal-open')) { // 在modal關閉時，不需要檢查
                return
            }
            let error = false;

            // 參數初始化
            this.newSubject.isTitleError = false;
            this.newSubject.titleErrText = '';
            this.newSubject.isSpeechError = false;
            this.newSubject.speechErrText = '';
            this.newSubject.isCommentError = false;
            this.newSubject.commentErrText = '';
            this.newSubject.isRoleError = false;
            this.newSubject.roleErrText = '';

            // 檢查單選、多選、填充、重組
            if (this.newSubject.answer_type < 4 && !this.newSubject.title && !this.newSubject.image.path && !this.newSubject.audio.name && !this.newSubject.text_to_speech) {
                error = true;
            }
            // 檢查朗讀
            if (this.newSubject.answer_type == 4 && ((this.newSubject.recognize_language === '2' && !this.newSubject.title) || (this.newSubject.attachAudio == 1 && !this.newSubject.audio.name))) {
                error = true;
            }
            if (this.newSubject.attachAudio == 2 && this.newSubject.text_to_speech && this.newSubject.text_to_speech.length > 2500) {
                this.newSubject.isSpeechError = true;
                this.newSubject.speechErrText = "文字數量不能超過2500個字";
            }
            if (this.newSubject.is_group == 0) {
                if (this.newSubject.answer_type < 5) {
                    if (!this.newSubject.score.match(/^\d+$/g)) { // 檢查分數是否有填數字以外的字元或空白
                        error = true;
                    }
                    if (this.newSubject.answer_type != 4) {
                        if (this.newSubject.title.length > 2500) { // 檢查題目的字數
                            this.newSubject.isTitleError = true;
                            this.newSubject.titleErrText = "文字數量不能超過2500個字";
                            error = true;
                        }
                    }
                    else { // 朗讀題檢查一些額外項目
                        if (this.newSubject.title.length > 300) { // 檢查題目的字數
                            this.newSubject.isTitleError = true;
                            this.newSubject.titleErrText = "文字數量不能超過300個字";
                            error = true;
                        }
                        if (this.newSubject.attachAudio == 1 && !this.newSubject.audio.path && !this.newSubject.audio.file) {
                            error = true;
                        }
                    }
                    if (this.newSubject.comment.length > 2500) { // 檢查解析的字數
                        this.newSubject.isCommentError = true;
                        this.newSubject.commentErrText = "文字數量不能超過2500個字";
                    }
                    let emptyCount = 0;
                    // 檢查選擇題答案是否沒填
                    if (this.newSubject.answer_type == 1) {
                        this.newSubject.answer.forEach((el) => {
                            if (el == 0 || el == false) {
                                emptyCount++;
                            }
                        });
                    }
                    if (this.newSubject.answer_type < 2 && this.newSubject.answer.length == emptyCount) {
                        error = true;
                    }
                    this.newSubject.options.forEach((option) => {
                        option.isOptionError = false;
                        option.optionErrText = '';
                        if (this.newSubject.answer_type < 2) {
                            if (!option.title && !option.image.path && !option.image.file) {
                                error = true;
                            }
                            if (option.title.length > 150) {
                                option.isOptionError = true;
                                option.optionErrText = '文字數量不能超過150個字';
                                error = true;
                            }
                        }
                        else {
                            if (!option.title) {
                                error = true;
                            }
                            // 重組題
                            if (this.newSubject.answer_type == 3) {
                                let tempArray = option.title.replace(/(?:\\[rn]|[\r\n]+)+/g, ',').split(',');
                                if (tempArray.length != 0 ) {
                                    if (tempArray.length > 12) {
                                        option.isOptionError = true;
                                        option.optionErrText = '超過12行請刪除12行以後的資料';
                                        error = true;
                                        return;
                                    }
                                    tempArray.forEach((el, index) => {
                                        if (el.length > 30) {
                                            option.isOptionError = true;
                                            option.optionErrText = '第' + (index+1) + '行超過30個文字';
                                            error = true;
                                            return;
                                        }
                                        if (el.match(/ /g) && !el.match(/[^ ]/g)
                                            || !el && !el.match(/[^ ]/g)) {
                                            option.isOptionError = true;
                                            option.optionErrText = '第' + (index+1) + '行沒有輸入或是輸入空格，請檢查';
                                            error = true;
                                            return;
                                        }
                                    });
                                }
                            }
                        }
                        // 朗讀題朗讀
                        if (this.newSubject.answer_type == 4) {
                            let maxWords = this.newSubject.recognize_language !== '2' ? 300 : 600;
                            if (option.title.length > maxWords) {
                                option.isOptionError = true;
                                option.optionErrText = `文字數量不能超過${maxWords}個字`;
                                error = true;
                                return;
                            }
                        }
                    });
                }
                else {
                    // 對話題
                    if (this.newSubject.answer_type == 5) {
                        if (!this.newSubject.roleA || !this.newSubject.roleB) {
                            error = true;
                        }
                        let roleA = [],
                            roleB = [];

                        this.newSubject.sub_questions.forEach(el => {
                            el.options[0].isOptionError = false;
                            el.options[0].optionErrText = '';
                            el.isTitleError = false;
                            el.titleErrText = '';

                            if (this.newSubject.sub_questions.length > 1) {
                                if (el.voiceGroup == '0') {
                                    roleA.push(1);
                                }
                                if (el.voiceGroup == '1') {
                                    roleB.push(1);
                                }
                            }

                            // 朗讀內容的字數檢查(目前沒有中文，所以字數都一樣)
                            let maxWords = this.newSubject.recognize_language !== '2' ? 50 : 150;
                            if (el.options[0].title && el.options[0].title.length > maxWords) {
                                el.options[0].isOptionError = true;
                                el.options[0].optionErrText = `文字數量不能超過${maxWords}個字`;
                                error = true;
                                return;
                            }
                            // 翻譯的字數檢查 (目前沒有中文，所以字數都一樣)
                            let translateMaxWords = this.newSubject.recognize_language !== '2' ? 500 : 500;
                            if (el.title && el.title.length > translateMaxWords) {
                                el.isTitleError = true;
                                el.titleErrText = `文字數量不能超過${translateMaxWords}個字`;
                                error = true;
                                return;
                            }

                            // 檢查 朗讀內容、中文翻譯、音檔
                            if (!el.options[0].title || !el.title || (el.attachAudio == 1 && !el.audio.name)) {
                                error = true;
                                return;
                            }
                        });

                        if (!error &&
                            this.newSubject.sub_questions.length > 1 &&
                            (roleA.length == 0 ||
                            roleB.length == 0)) {
                                let roleText = roleA.length == 0 ? 'B' : 'A';
                                error = true;
                                this.newSubject.isRoleError = true;
                                this.newSubject.roleErrText = '不能都是角色 ' + roleText + ' 請重新選擇';
                            }
                    }
                    // 翻譯題
                    else if (this.newSubject.answer_type == 6) {
                        if (!this.newSubject.title ||
                            !this.newSubject.description ||
                            !this.newSubject.isPastExamCorrection) {
                                error = true;
                            }
                        this.newSubject.options.forEach((option) => {
                            option.isOptionError = false;
                            let maxWords = 500;
                            if (!option.title) {
                                error = true;
                                return;
                            }
                            if (option.title.length > maxWords) {
                                option.isOptionError = true;
                                option.optionErrText = `文字數量不能超過${maxWords}個字`;
                                error = true;
                                return;
                            }
                        });
                        if (this.newSubject.leastWords < 5 ||
                            !this.newSubject.leastWords.toString().match(/^\d+$/g)) {
                            error = true;
                        }
                    }
                    // 寫作題
                    else if (this.newSubject.answer_type == 7) {
                        if (!this.newSubject.title ||
                            !this.newSubject.request ||
                            !this.newSubject.description) {
                            error = true;
                        }
                        if (!this.newSubject.isPastExamAppraisal &&
                            !this.newSubject.isSentenceCorrection &&
                            !this.newSubject.isArticleOptimization) {
                            error = true;
                        }
                        if (!this.newSubject.basicWords.toString().match(/^\d+$/g)) {
                            error = true;
                        }
                        if (this.newSubject.leastWords < 5 ||
                            !this.newSubject.leastWords.toString().match(/^\d+$/g)) {
                            error = true;
                        }
                    }
                }
            }
            else {
                if (this.newSubject.title.length > 2500) { // 檢查題組題幹的字數
                    this.newSubject.isTitleError = true;
                    this.newSubject.titleErrText = "文字數量不能超過2500個字";
                    error = true;
                }
                this.newSubject.sub_questions.forEach((el) => {
                    el.isTitleError = false;
                    el.titleErrText = '';
                    el.isCommentError = false;
                    el.commentErrText = '';
                    if (!el.score.match(/^\d+$/g)) { // 檢查分數是否有填數字以外的字元或空白
                        error = true;
                    }
                    if (el.title.length > 2500) { // 檢查題目的字數 (限制提高但不能沒有限制)
                        el.isTitleError = true;
                        el.titleErrText = "文字數量不能超過2500個字";
                        error = true;
                    }
                    if (el.comment.length > 2500) { // 檢查解析的字數
                        el.isCommentError = true;
                        el.commentErrText = "文字數量不能超過2500個字";
                    }
                    if (!el.title && !el.image.path) {
                        error = true;
                    }
                    let emptyCount = 0;
                    // 檢查選擇題答案是否沒填
                    if (this.newSubject.answer_type == 1) {
                        el.answer.forEach((el) => {
                            if (el == 0 || el == false) {
                                emptyCount++;
                            }
                        });
                    }
                    if (this.newSubject.answer_type < 2 && el.answer.length == emptyCount) {
                        error = true;
                    }
                    el.options.forEach((option) => {
                        option.isOptionError = false;
                        option.optionErrText = false;
                        if (!option.title && !option.image.path && !option.image.file) {
                            error = true;
                        }
                        if (option.title.length > 300) { // 檢查題目的字數
                            option.isOptionError = true;
                            option.optionErrText = "文字數量不能超過300個字";
                            error = true;
                        }
                    });
                });
            }

            return error;
        },
        showAddSingleDialogue(categoryClassType) {
            this.newSubject = this.initialNewSubjectList(categoryClassType);
            $('#addSingleDialogue').modal('show');
        },
        addSingle() { // 新增單題
            let params = new FormData(),
                categoryNo = 0,
                tags = [],
                data = {};

            // 檢查題目、附加圖片、附加音檔和tts是否有填其中一個
            if (!this.newSubject.title && !this.newSubject.image.path && !this.newSubject.audio.path && !this.newSubject.text_to_speech) {
                return;
            }

            // 如果分類有題目，抓出目前的index是多少
            if (this.subjectList.length > 0) {
                this.subjectList.forEach((el, index) => {
                    if (el.isClick) {
                        categoryNo = index+2;
                    }
                });
            }

            if (this.newSubject.tags.length > 0) {
                this.newSubject.tags.forEach((el) => {
                    tags.push(el.name);
                });
            }

            data = {
                articleId: this.$route.params.id,
                categoryId: this.categoryId,
                categoryNo: categoryNo,
                answerType: this.newSubject.answer_type,
                score: this.newSubject.score,
                title: this.newSubject.title,
                text_to_speech: this.newSubject.text_to_speech,
                comment: this.newSubject.comment,
                difficulty_level: this.newSubject.difficulty_level,
                tags: tags,
                options: [],
            };

            if (this.newSubject.options.length > 0) {
                this.newSubject.options.forEach((el, index) => {
                    let option = {
                        title: el.title,
                        is_answer: 0
                    };

                    // 單選題 (若answer[0]等於index，is_answer = 1)
                    if (this.newSubject.answer_type == 0 && this.newSubject.answer[0] == index) {
                        option.is_answer = 1;
                    }
                    // 多選題 (若answer[index]是1或true，is_answer = 1)
                    if (this.newSubject.answer_type == 1 && this.newSubject.answer[index]) {
                        option.is_answer = 1;
                    }
                    // 填充題、重組題、朗讀題 (每個都是答案，所以每個is_answer = 1)
                    if (this.newSubject.answer_type > 1) {
                        option.is_answer = 1;
                    }

                    if(el.image.path) {
                        option.image = 'option_image_' + index;
                        params.append('option_image_' + index, el.image.file); // 先把option file append進去
                    }
                    data.options.push(option);
                });
            }

            if (this.newSubject.image.file) {
                params.append('image', this.newSubject.image.file);
            }

            if (this.newSubject.audio.file) {
                params.append('upload_audio', this.newSubject.audio.file);
            }

            if (this.newSubject.media.file) {
                params.append('comment_media', this.newSubject.media.file);
            }

            params.append('questionInfo', JSON.stringify(data));

            this.isSetDataReady.getAddSubjectStatus = true;
            this.$httpRequest.post('/admin_api/question/create_question', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let result = response.data.result,
                                subjectIndex = categoryNo > 0 ? categoryNo-2 : this.subjectList.length-1; // 如果categoryNo = 0代表放在最後面

                            this.newSubject.id = result.id;
                            this.newSubject.no = result.no;

                            this.newSubject.options.forEach((el, index) => {
                                el.id = result.options[index].id;
                                el.no = result.options[index].no;
                                if(result.options[index].image) { // 上傳完成後若回傳選項image路徑
                                    el.image.path = result.options[index].image;
                                }
                            });

                            if (result.image) { // 上傳完成後若回傳image路徑
                                this.newSubject.image.path = result.image;
                            }

                            if (result.audio) { // 上傳完成後若回傳音檔路徑
                                this.newSubject.audio.path = result.audio;
                                this.newSubject.attachAudio = 0;
                            }

                            if (result.comment_media) { // 上傳完成後若回傳解析媒體路徑
                                this.newSubject.media.path = result.comment_media && result.comment_media.path ? result.comment_media.path : '';
                                this.newSubject.media.name = result.comment_media && result.comment_media.name ? result.comment_media.name : '';
                            }

                            if (this.subjectList.length == 0) { // 如果題目陣列裡完全沒有值用push
                                this.subjectList.push(this.newSubject);
                                this.oldSubjectList = this.$_.cloneDeep(this.subjectList);
                            }
                            else {
                                this.subjectList.splice(subjectIndex+1, 0, this.newSubject);
                                this.oldSubjectList.splice(subjectIndex+1, 0, this.newSubject); // 舊資料也要新增
                                this.oldSubjectList = this.$_.cloneDeep(this.oldSubjectList); // 必須要再複製一次，不然新舊資料會不一樣，到updatePracticeData會比對不出來
                            }
                            this.categoryList.forEach((el) => {
                                if(el.id == this.categoryId) {
                                    el.total_num = parseInt(el.total_num) + 1;
                                }
                            });
                        }
                    }
                    $('#addSingleDialogue').modal('hide');
                    this.isSetDataReady.getAddSubjectStatus = false;
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showAddGroupDialogue(categoryClassType) {
            if (this.updatePracticeData.updateInfo) {
                this.$store.dispatch('common/setAlert', { msg: '請先儲存目前修改內容才能新增題目', status: 'danger' });
                return;
            }
            this.newSubject = this.initialNewSubjectList(categoryClassType);
            $('#addGroupDialogue').modal('show');
        },
        addGroup() { // 新增題組
            let params = new FormData(),
                categoryNo = 0,
                data = {};

            // 檢查題目、附加圖片、附加音檔和tts是否有填其中一個
            if (!this.newSubject.title && !this.newSubject.image.path && !this.newSubject.audio.path && !this.newSubject.text_to_speech) {
                return;
            }

            // 如果分類有題目，抓出目前的index是多少
            if (this.subjectList.length > 0) {
                this.subjectList.forEach((el, index) => {
                    if (el.isClick) {
                        categoryNo = index+2;
                    }
                });
            }

            data = {
                categoryId: this.categoryId,
                categoryNo: categoryNo,
                answerType: this.newSubject.answer_type,
                title: this.newSubject.title,
                textToSpeech: this.newSubject.text_to_speech,
                subQuestions: []
            };

            if (this.newSubject.sub_questions.length > 0) {
                this.newSubject.sub_questions.forEach((subQus, sIndex) => {
                    let tempSubQus = {
                            score: subQus.score,
                            title: subQus.title,
                            comment: subQus.comment,
                            difficultyLevel: subQus.difficulty_level,
                            options: [],
                            tags: []
                        }

                    // 取出tag name放進一維陣列
                    if (subQus.tags.length > 0) {
                        subQus.tags.forEach((el) => {
                            tempSubQus.tags.push(el.name);
                        });
                    }
                    // 子題圖片
                    if (subQus.image.file) {
                        params.append('question-' + sIndex + '-image', subQus.image.file);
                    }
                    // 子題解析媒體
                    if (subQus.media.file) {
                        params.append('question-' + sIndex + '-comment-file', subQus.media.file);
                    }
                    if (subQus.options.length > 0) {
                        subQus.options.forEach((option, oIndex) => {
                            let tempOption = {
                                    title: option.title,
                                    isAnswer: 0
                                };

                            // 單選題 (若answer[0]等於oIndex，isAnswer = 1)
                            if (this.newSubject.answer_type == 0 && subQus.answer[0] == oIndex) {
                                tempOption.isAnswer = 1;
                            }
                            // 多選題 (若answer[oIndex]是1或true，isAnswer = 1)
                            if (this.newSubject.answer_type == 1 && subQus.answer[oIndex]) {
                                tempOption.isAnswer = 1;
                            }
                            // 選項圖片
                            if(option.image.path) {
                                params.append('question-' + sIndex + '-option-' + oIndex + '-image', option.image.file);
                            }

                            tempSubQus.options.push(tempOption);
                        });
                    }
                    data.subQuestions.push(tempSubQus);
                });
            }

            // 題組圖片
            if (this.newSubject.image.file) {
                params.append('groupiamge', this.newSubject.image.file);
            }
            // 題組音檔
            if (this.newSubject.audio.file) {
                params.append('groupaudio', this.newSubject.audio.file);
            }

            params.append('groupQuestionInfo', JSON.stringify(data));

            this.isSetDataReady.getAddSubjectStatus = true;
            this.$httpRequest.post('/admin_api/question/create_group_questions', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.initialize();
                        // if (response.data.result) {
                        //     let result = response.data.result,
                        //         subjectIndex = categoryNo > 0 ? categoryNo-2 : this.subjectList.length-1; // 如果categoryNo = 0代表放在最後面

                        //     this.newSubject.id = result.id;
                        //     this.newSubject.no = result.no;

                        //     this.newSubject.sub_questions.forEach((subQus, sIndex) => {
                        //         subQus.id = result.sub_questions[sIndex].id;
                        //         subQus.no = result.sub_questions[sIndex].no;
                        //         subQus.options.forEach((option, oIndex) => {
                        //             option.id = result.sub_questions[sIndex].options[oIndex].id;
                        //             option.no = result.sub_questions[sIndex].options[oIndex].no;
                        //             if(result.sub_questions[sIndex].options[oIndex].image) { // 上傳完成後若回傳選項image路徑
                        //                 option.image.path = result.sub_questions[sIndex].options[oIndex].image;
                        //             }
                        //         });
                        //     });

                        //     if (result.image) { // 上傳完成後若回傳image路徑
                        //         this.newSubject.image.path = result.image;
                        //     }

                        //     if (result.audio) { // 上傳完成後若回傳音檔路徑
                        //         this.newSubject.audio.path = result.audio;
                        //         this.newSubject.attachAudio = 0;
                        //     }

                        //     if (result.comment_media) { // 上傳完成後若回傳解析媒體路徑
                        //         this.newSubject.media.path = result.comment_media && result.comment_media.path ? result.comment_media.path : '';
                        //         this.newSubject.media.name = result.comment_media && result.comment_media.name ? result.comment_media.name : '';
                        //     }

                        //     if (this.subjectList.length == 0) { // 如果題目陣列裡完全沒有值用push
                        //         this.subjectList.push(this.newSubject);
                        //         this.oldSubjectList = this.$_.cloneDeep(this.subjectList);
                        //     }
                        //     else {
                        //         this.subjectList.splice(subjectIndex+1, 0, this.newSubject);
                        //         this.oldSubjectList.splice(subjectIndex+1, 0, this.newSubject); // 舊資料也要新增
                        //         this.oldSubjectList = this.$_.cloneDeep(this.oldSubjectList); // 必須要再複製一次，不然新舊資料會不一樣，到updatePracticeData會比對不出來
                        //     }
                        //     this.categoryList.forEach((el) => {
                        //         if(el.id == this.categoryId) {
                        //             el.total_num = parseInt(el.total_num) + 1;
                        //         }
                        //     });
                        //     this.isSetDataReady.getAddSubjectStatus = false;
                        //     $('#addGroupDialogue').modal('hide');
                        // }
                    }
                    $('#addGroupDialogue').modal('hide');
                    this.isSetDataReady.getAddSubjectStatus = false;
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addSubQuestion(subQuestionIndex, subjectIndex) { // 新增題組的子題
            let obj = {};
            obj = {
                    score: '0',
                    title: '',
                    image: {
                        name: '',
                        path: ''
                    },
                    options: [{
                                    title: '',
                                    image: {
                                        path: '',
                                        name: '',
                                        file: ''
                                    },
                                    isOptionError: false,
                                    optionErrText: ''
                                },
                                {
                                    title: '',
                                    image: {
                                        path: '',
                                        name: '',
                                        file: ''
                                    },
                                    isOptionError: false,
                                    optionErrText: ''
                                }],
                    answer: [],
                    comment: '',
                    media: {
                        name: '',
                        path: ''
                    },
                    difficulty_level: '0',
                    tags: [],
                    isClick: false,
                    isShowAdvance: false,
                    isTitleError: false,
                    titleErrText: false,
                    isCommentError: false,
                    commentErrText: false
                };
            if (subjectIndex === undefined) { // 新增
                // if (this.newSubject.sub_questions.length >= 10) {
                //     this.$store.dispatch('common/setAlert', { msg: '最多只能新增10個子題', status: 'danger' });
                //     return;
                // }
                if (this.newSubject.answer_type == 1) { // 多選題的答案陣列要新assign
                    obj.answer = [false, false];
                }
                this.newSubject.sub_questions.splice(subQuestionIndex+1, 0, obj);
            }
            else { // 編輯狀態新增子題
                if (this.subjectList[subjectIndex].answer_type == 1) { // 多選題的答案陣列要新assign
                    obj.answer = [false, false];
                }
                this.subjectList[subjectIndex].sub_questions.splice(subQuestionIndex+1, 0, obj);
                this.oldSubjectList[subjectIndex].sub_questions.splice(subQuestionIndex+1, 0, obj);
                this.oldSubjectList = this.$_.cloneDeep(this.oldSubjectList);
                this.renderMdCount++;
                this.isAddSubQuestion = true;
                this.isAddSubOption = true;
            }
        },
        showAddSpeakDialogue(categoryClassType) {
            this.newSubject = this.initialNewSubjectList(categoryClassType);
            $('#addSpeakDialogue').modal('show');
        },
        checkSpeakTopic() {
            if (this.newSubject.speakTopicType == '0') {
                this.newSubject.beforeAnswer = '1';
            }
        },
        addSpeak() { // 新增朗讀
            let params = new FormData(),
                categoryNo = 0,
                tags = [],
                data = {};

            // 檢查中文翻譯、上傳音檔和tts是否有填其中一個
            if (( this.newSubject.recognize_language === '2' && !this.newSubject.title ) || ( this.newSubject.attachAudio == 1 && !this.newSubject.audio.path )) {
                return;
            }

            // 如果分類有題目，抓出目前的index是多少
            this.subjectList.forEach((el, index) => {
                if (el.isClick) {
                    categoryNo = index + 2;
                }
            });

            for (let el of this.newSubject.tags) {
                tags.push(el.name);
            }

            data = {
                articleId: this.$route.params.id,
                categoryId: this.categoryId,
                categoryNo: categoryNo,
                isShowSpeakText: this.newSubject.speakTopicType,
                isShowSpeakAudio: this.newSubject.beforeAnswer,
                answerType: this.newSubject.answer_type,
                title: this.newSubject.title,
                recognizeLanguage: this.newSubject.recognize_language,
                text_to_speech: this.newSubject.text_to_speech,
                tags: tags,
                options: [],
            };

            for (let el of this.newSubject.options) {
                let option = {
                    title: el.title,
                    is_answer: 1
                };
                data.options.push(option);

                if (this.newSubject.attachAudio == 2) {
                    data.text_to_speech = el.title;
                }
            }

            // 題目圖片
            if (this.newSubject.image.file) {
                params.append('image', this.newSubject.image.file);
            }

            if (this.newSubject.audio.file) {
                params.append('upload_audio', this.newSubject.audio.file);
            }

            params.append('questionInfo', JSON.stringify(data));

            this.isSetDataReady.getAddSubjectStatus = true;
            this.$httpRequest.post('/admin_api/question/create_question', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let result = response.data.result,
                                subjectIndex = categoryNo > 0 ? categoryNo - 2 : this.subjectList.length - 1; // 如果categoryNo = 0代表放在最後面

                            this.newSubject.id = result.id;
                            this.newSubject.no = result.no;

                            this.newSubject.options.forEach((el, index) => {
                                el.id = result.options[index].id;
                                el.no = result.options[index].no;
                            });

                            if (result.audio) { // 上傳完成後若回傳音檔路徑
                                this.newSubject.audio.path = result.audio;
                            }

                            if (this.subjectList.length == 0) { // 如果題目陣列裡完全沒有值用push
                                this.subjectList.push(this.newSubject);
                                this.oldSubjectList = this.$_.cloneDeep(this.subjectList);
                            }
                            else {
                                this.subjectList.splice(subjectIndex+1, 0, this.newSubject);
                                this.oldSubjectList.splice(subjectIndex+1, 0, this.newSubject); // 舊資料也要新增
                                this.oldSubjectList = this.$_.cloneDeep(this.oldSubjectList); // 必須要再複製一次，不然新舊資料會不一樣，到updatePracticeData會比對不出來
                            }

                            for (let el of this.categoryList) {
                                if(el.id == this.categoryId) {
                                    el.total_num = parseInt(el.total_num) + 1;
                                }
                            }
                        }
                    }
                    this.isSetDataReady.getAddSubjectStatus = false;
                    $('#addSpeakDialogue').modal('hide');
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        addNewOption() {
            let obj = {
                    title: '',
                    image: {
                        path: '',
                        name: '',
                        file: ''
                    },
                    isOptionError: false,
                    optionErrText: ''
                }
            this.newSubject.options.splice(this.newSubject.options.length, 0, obj);
            if (this.newSubject.answer_type == 1) { // 多選題的答案陣列也要新增value
                this.newSubject.answer.push(false);
            }
        },
        addOption(subjectId, subjectIndex, optionIndex) { // 新增選項
            // 新增選項暫時不支援圖片上傳，標題和答案也不用填
            let params = new FormData(),
                data = {
                    questionId: subjectId,
                    title: '', // 內容應都為空
                    is_answer: 0,
                };

            params.append('optionsInfo', JSON.stringify(data));

            this.$httpRequest.post('/admin_api/question/add_option', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let result = response.data.result,
                                obj = {
                                    id: result[0].id,
                                    no: result[0].no,
                                    title: '',
                                    is_answer: 0,
                                    image: {
                                        path: result[0].image ? result[0].image : 0,
                                        name: '',
                                        file: ''
                                    },
                                    isOptionError: false,
                                    optionErrText: ''
                                };

                            this.subjectList[subjectIndex].options.splice(optionIndex+1, 0, obj);
                            this.oldSubjectList[subjectIndex].options.splice(optionIndex+1, 0, obj); // 舊資料也要新增
                            if (this.subjectList[subjectIndex].answer_type == 1) { // 多選題的答案陣列也要新增value
                                this.subjectList[subjectIndex].answer.splice(optionIndex, 0, false);
                                this.oldSubjectList[subjectIndex].answer.splice(optionIndex, 0, false);
                            }
                            this.oldSubjectList = this.$_.cloneDeep(this.oldSubjectList); // 必須要再複製一次，不然新舊資料會不一樣，到updatePracticeData會比對不出來

                            // let focus new option's input
                            //this.$nextTick(() => this.$refs['option'+ result.id][0].focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addSubOption(subQuestionIndex, subjectIndex) { // 新增選項
            let option = {
                    title: '',
                    image: {
                        path: '',
                        name: '',
                        file: ''
                    },
                    isOptionError: false,
                    optionErrText: ''
                };

            if (subjectIndex === undefined) { // 新增
                this.newSubject.sub_questions[subQuestionIndex].options.splice(this.newSubject.sub_questions[subQuestionIndex].options.length, 0, option);
                if (this.newSubject.answer_type == 1) { // 多選題的答案陣列也要新增value
                    this.newSubject.sub_questions[subQuestionIndex].answer.push(false);
                }
            }
            else { // 編輯
                let optionIndex = this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options.length;
                this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options.splice(optionIndex, 0, option);
                this.oldSubjectList[subjectIndex].sub_questions[subQuestionIndex].options.splice(optionIndex, 0, option);
                if (this.subjectList[subjectIndex].answer_type == 1) { // 多選題的答案陣列也要新增value
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].answer.splice(optionIndex, 0, false);
                    this.oldSubjectList[subjectIndex].sub_questions[subQuestionIndex].answer.splice(optionIndex, 0, false);
                }
                this.isAddSubOption = true;
            }
        },
        showAddConversationDialogue(categoryClassType) {
            if (this.updatePracticeData.updateInfo) {
                this.$store.dispatch('common/setAlert', { msg: '請先儲存目前修改內容才能新增題目', status: 'danger' });
                return;
            }
            this.newSubject = this.initialNewSubjectList(categoryClassType);
            $('#addConversationDialogue').modal('show');
        },
        addConversation() {
            let params = new FormData(),
                newQusList = [],
                nowPositionIndex = 0,
                data = {};

            // 檢查中文翻譯、上傳音檔和tts是否有填其中一個
            if (( this.newSubject.recognize_language === '2' && !this.newSubject.sub_questions[0].title ) || ( this.newSubject.sub_questions[0].attachAudio == 1 && !this.newSubject.sub_questions[0].audio.path )) {
                return;
            }

            this.subjectList.forEach((el, index) => {
                // 組出內容都是id的物件陣列
                let subQus = [];
                el.sub_questions.forEach(sl => {
                    subQus.push({
                        id: sl.id
                    })
                });
                newQusList.push({
                    id: el.id,
                    subQuestions: subQus
                });
                // 抓出目前位子(index是多少)
                if (el.isClick) {
                    nowPositionIndex = index
                }
            });

            // 組出新增這組的資料
            let newQus = {
                    roleGender: this.newSubject.roleA + this.newSubject.roleB,
                    language: '2',
                    subQuestions: []
                };

            if (this.newSubject.sub_questions.length > 0) {
                let subQuestionIndex = 0;
                for (let row of this.newSubject.sub_questions) {
                    let item = {
                        voiceGroup: row.voiceGroup,
                        title: row.title,
                        options: row.options,
                        upload_audio: row.audio.file ? 'upload_audio' + nowPositionIndex + subQuestionIndex : ''
                    }
                    newQus.subQuestions.push(item);

                    if (row.audio.file) {
                        params.append('upload_audio' + nowPositionIndex + subQuestionIndex, row.audio.file);
                    }
                    subQuestionIndex++;
                }
            }

            if (nowPositionIndex > 0) {
                newQusList.splice( nowPositionIndex+1, 0, newQus);
            }
            else {
                newQusList.push(newQus);
            }

            data = {
                articleId: this.$route.params.id,
                conversations: {
                    categoryId: this.categoryId,
                    update: newQusList
                }
            };

            params.append('updateInfo', JSON.stringify(data));

            this.isSetDataReady.getAddSubjectStatus = true;

            this.$httpRequest.post('/admin_api/practice/update_practice', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.initialize();
                    }
                    $('#addConversationDialogue').modal('hide');
                    this.isSetDataReady.getAddSubjectStatus = false;
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        addConversationSubQuestion(subQuestionIndex, subjectIndex) { // 新增題組的子題
            let obj = {};
            obj = {
                voiceGroup: '0',
                attachAudio: '2',
                audio: {
                    name: '',
                    path: ''
                },
                options: [{
                    title: '',
                    isOptionError: false,
                    optionErrText: ''
                }],
                title: '', // 中文翻譯
                isClick: false,
                isAudioError: false,
                audioErrText: '',
                isTitleError: false,
                titleErrText: '',
            };
            if (subjectIndex === undefined) { // 新增
                if (this.newSubject.sub_questions.length >= 10) {
                    this.$store.dispatch('common/setAlert', { msg: '最多只能新增10個子題', status: 'danger' });
                    return;
                }
                this.newSubject.sub_questions.splice(subQuestionIndex+1, 0, obj);
            }
            else { // 編輯狀態新增子題
                if (this.subjectList[subjectIndex].sub_questions.length >= 10) {
                    this.$store.dispatch('common/setAlert', { msg: '最多只能新增10個子題', status: 'danger' });
                    return;
                }
                this.subjectList[subjectIndex].sub_questions.splice(subQuestionIndex+1, 0, obj);
                this.oldSubjectList[subjectIndex].sub_questions.splice(subQuestionIndex+1, 0, obj);
                this.oldSubjectList = this.$_.cloneDeep(this.oldSubjectList);
                this.subjectList[subjectIndex].isAddSubQuestion = true; // 到時候看要不要多一個對話的boolean值判斷
            }
        },
        showAddTranslationDialogue(categoryClassType) {
            if (this.updatePracticeData.updateInfo) {
                this.$store.dispatch('common/setAlert', { msg: '請先儲存目前修改內容才能新增題目', status: 'danger' });
                return;
            }
            this.newSubject = this.initialNewSubjectList(categoryClassType);
            $('#addTranslationDialogue').modal('show');
        },
        addTranslation() {
            let params = new FormData(),
                categoryNo = 0,
                tags = [],
                data = {};

            // 檢查題目、說明是否沒填
            if (!this.newSubject.title || !this.newSubject.description) {
                return;
            }

            // 如果分類有題目，抓出目前的index是多少
            if (this.subjectList.length > 0) {
                this.subjectList.forEach((el, index) => {
                    if (el.isClick) {
                        categoryNo = index+2;
                    }
                });
            }

            if (this.newSubject.tags.length > 0) {
                this.newSubject.tags.forEach((el) => {
                    tags.push(el.name);
                });
            }

            data = {
                articleId: this.$route.params.id,
                categoryId: this.categoryId,
                categoryNo: categoryNo,
                answerType: this.newSubject.answer_type,
                title: this.newSubject.title,
                description: this.newSubject.description,
                tips: this.newSubject.tips,
                options: [{ title: this.newSubject.options[0].title }],
                comment: this.newSubject.comment,
                correctionMechanism: {
                    modes: {
                        translation: {
                            correct: this.newSubject.isPastExamCorrection ? true : false
                        }
                    },
                    words: {
                        least: this.newSubject.leastWords
                    }
                },
                tags: tags,
            };

            params.append('questionInfo', JSON.stringify(data));

            this.isSetDataReady.getAddSubjectStatus = true;

            this.$httpRequest.post('/admin_api/question/create_question', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.initialize();
                    }
                    $('#addTranslationDialogue').modal('hide');
                    this.isSetDataReady.getAddSubjectStatus = false;
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        showAddWritingDialogue(categoryClassType) {
            if (this.updatePracticeData.updateInfo) {
                this.$store.dispatch('common/setAlert', { msg: '請先儲存目前修改內容才能新增題目', status: 'danger' });
                return;
            }
            this.newSubject = this.initialNewSubjectList(categoryClassType);
            $('#addWritingDialogue').modal('show');
        },
        addWriting() {
            let params = new FormData(),
                categoryNo = 0,
                images = [],
                tags = [],
                data = {};

            // 檢查題目、說明是否沒填
            if (!this.newSubject.title || !this.newSubject.description) {
                return;
            }

            // 如果分類有題目，抓出目前的index是多少
            if (this.subjectList.length > 0) {
                this.subjectList.forEach((el, index) => {
                    if (el.isClick) {
                        categoryNo = index+2;
                    }
                });
            }

            if (this.newSubject.tags.length > 0) {
                this.newSubject.tags.forEach((el) => {
                    tags.push(el.name);
                });
            }

            data = {
                articleId: this.$route.params.id,
                categoryId: this.categoryId,
                categoryNo: categoryNo,
                answerType: this.newSubject.answer_type,
                title: this.newSubject.title,
                request: this.newSubject.request,
                description: this.newSubject.description,
                relatedWords: this.newSubject.relatedWords,
                compositionReference: this.newSubject.compositionReference,
                compositionParagraph: {
                    paragraphs: this.newSubject.paragraphs,
                    style: this.newSubject.paragraph == 0 ? null : 1
                },
                correctionMechanism: {
                    modes: {
                        composition: {
                            summary: this.newSubject.isPastExamAppraisal ? true : false,
                            sentence: this.newSubject.isSentenceCorrection ? true : false,
                            optimize: this.newSubject.isArticleOptimization ? true : false,
                        }
                    },
                    words: {
                        basic: this.newSubject.basicWords,
                        least: this.newSubject.leastWords
                    }
                },
                tags: tags,
            };

            if (this.newSubject.images.length > 0) {
                this.newSubject.images.forEach((el, index) => {
                    images.push('image_' + index);
                    params.append('image_' + index, el.file);
                });
                data.images = images;
            }

            params.append('questionInfo', JSON.stringify(data));

            this.isSetDataReady.getAddSubjectStatus = true;

            this.$httpRequest.post('/admin_api/question/create_question', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.initialize();
                    }
                    $('#addWritingDialogue').modal('hide');
                    this.isSetDataReady.getAddSubjectStatus = false;
                    this.renderMdCount++;
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        setAnswer(subjectIndex, optionIndex) {
            // 暫時無用，未來看會不會有需要設定is_answer?
            console.log(this.subjectList[subjectIndex].answer, optionIndex);
        },
        disableQus(subjectIndex, subjectId) { // 停用題目
            let pickNum = 0;
            if (this.categoryList.length > 0) { // 找出這個分類的抽題量
                this.categoryList.forEach((el)=> {
                    if (el.id == this.categoryId) {
                        pickNum = el.pick_num;
                    }
                });
            }
            if (this.subjectList.length <= pickNum) { // 檢查刪除是不是會低於分類的抽題量
                this.$store.dispatch('common/setAlert', { msg: '此分類的總題量不能低於此分類的抽題量', status: 'danger' });
                return;
            }
            if (this.$_.indexOf(this.disableQuestionIds, subjectId) == -1) { // 檢查要刪除的題目id是否存在於題目刪除陣列中
                this.disableQuestionIds.push(subjectId);
            }
            this.subjectList[subjectIndex].is_disable = true;
        },
        deleteSubject(subjectIndex, subjectId) { // 刪除題目
            let pickNum = 0;
            if (this.categoryList.length > 0) { // 找出這個分類的抽題量
                this.categoryList.forEach((el)=> {
                    if (el.id == this.categoryId) {
                        pickNum = el.pick_num;
                    }
                });
            }
            if (this.subjectList.length <= pickNum) { // 檢查刪除是不是會低於分類的抽題量
                this.$store.dispatch('common/setAlert', { msg: '此分類的總題量不能低於此分類的抽題量', status: 'danger' });
                return;
            }

            this.isProhibitSelect = true; // 刪除題目不能選到題目
            this.subjectList.splice(subjectIndex, 1);
            this.oldSubjectList.splice(subjectIndex, 1);
            if (this.$_.indexOf(this.deleteSubjectIds, subjectId) == -1) { // 檢查要刪除的題目id是否存在於題目刪除陣列中
                this.deleteSubjectIds.push(subjectId);
            }
        },
        deleteSubQuestion(subQuestionIndex, subjectIndex) { // 刪除題組子題
            if (subjectIndex === undefined) { // 新增
                if (this.newSubject.sub_questions.length > 1) {
                    this.newSubject.sub_questions.splice(subQuestionIndex, 1);
                }
            }
            else { // 編輯
                if (this.subjectList[subjectIndex].sub_questions.length > 1 ) {
                    let subQustionId = this.subjectList[subjectIndex].sub_questions[subQuestionIndex].id;
                    this.subjectList[subjectIndex].sub_questions.splice(subQuestionIndex, 1);
                    this.oldSubjectList[subjectIndex].sub_questions.splice(subQuestionIndex, 1);
                    if (this.$_.indexOf(this.deleteSubjectIds, subQustionId) == -1 // 檢查要刪除的題目id是否存在於題目刪除陣列中
                        && subQustionId !== undefined) { // 題組子題的新增可能沒有id，所以要判斷subQustionId是否有值
                        this.deleteSubjectIds.push(subQustionId);
                    }
                }
            }
        },
        deleteOption(optionIndex, subjectIndex) { // 刪除單題選項
            if (subjectIndex === undefined) { // 新增
                this.newSubject.options.splice(optionIndex, 1);
                // 單選題刪除有答案的選項，需清空答案
                if (this.newSubject.answer_type == 0) {
                    if (this.newSubject.answer[0] == optionIndex) {
                        this.newSubject.answer = [];
                    }
                    else {
                        this.newSubject.answer[0] = this.newSubject.answer[0]-1;
                    }
                }
                // 多選題刪除有答案的選項，需刪除答案
                if (this.newSubject.answer_type == 1) {
                    this.newSubject.answer.splice(optionIndex, 1);
                }
            }
            else {
                let questionList = this.subjectList[subjectIndex],
                    optionId = this.subjectList[subjectIndex].options[optionIndex].id;
                if (questionList.answer_type < 2 && questionList.options.length > 2
                    || questionList.answer_type == 2 && questionList.options.length > 1) {
                    // 單選題刪除有答案的選項，需清空答案
                    if (questionList.answer_type == 0) {
                        if (questionList.answer[0] == optionIndex) {
                            questionList.answer = [];
                        }
                        else {
                            if (questionList.answer[0] > optionIndex) {
                                questionList.answer[0] = questionList.answer[0]-1;
                            }
                        }
                    }
                    // 多選題刪除有答案的選項，需刪除答案
                    if (questionList.answer_type == 1) {
                        questionList.answer.splice(optionIndex, 1);
                    }
                    questionList.options.splice(optionIndex, 1);
                    this.oldSubjectList[subjectIndex].options.splice(optionIndex, 1);
                    if (this.$_.indexOf(this.deleteOptionIds, optionId) == -1) {
                        this.deleteOptionIds.push(optionId);
                    }
                }
            }
        },
        deleteSubOption(optionIndex, subQuestionIndex, subjectIndex) { // 刪除題組子題選項
            if (subjectIndex === undefined) { // 新增
                this.newSubject.sub_questions[subQuestionIndex].options.splice(optionIndex, 1);
                // 單選題刪除有答案的選項，需清空答案
                if (this.newSubject.answer_type == 0) {
                    if (this.newSubject.sub_questions[subQuestionIndex].answer[0] == optionIndex) {
                        this.newSubject.sub_questions[subQuestionIndex].answer = [];
                    }
                    else {
                        this.newSubject.sub_questions[subQuestionIndex].answer[0] = this.newSubject.sub_questions[subQuestionIndex].answer[0]-1;
                    }
                }
                // 多選題刪除有答案的選項，需刪除答案
                if (this.newSubject.answer_type == 1) {
                    this.newSubject.sub_questions[subQuestionIndex].answer.splice(optionIndex, 1);
                }
            }
            else { // 編輯
                let subQuestionList = this.subjectList[subjectIndex].sub_questions[subQuestionIndex],
                    optionId = this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options[optionIndex].id;
                if (this.subjectList[subjectIndex].answer_type < 2 && subQuestionList.options.length > 2) {
                    // 單選題刪除有答案的選項，需清空答案
                    if (this.subjectList[subjectIndex].answer_type == 0) {
                        if (subQuestionList.answer[0] == optionIndex) {
                            subQuestionList.answer = [];
                        }
                        else {
                            if (subQuestionList.answer[0] > optionIndex) {
                                subQuestionList.answer[0] = subQuestionList.answer[0]-1;
                            }

                        }
                    }
                    // 多選題刪除有答案的選項，需刪除答案
                    if (this.subjectList[subjectIndex].answer_type == 1) {
                        subQuestionList.answer.splice(optionIndex, 1);
                    }
                    subQuestionList.options.splice(optionIndex, 1);
                    this.oldSubjectList[subjectIndex].sub_questions[subQuestionIndex].options.splice(optionIndex, 1);
                    if (this.$_.indexOf(this.deleteOptionIds, optionId) == -1
                        && optionId !== undefined) { // 題組子題選項的新增可能沒有id，所以要判斷optionId是否有值
                        this.deleteOptionIds.push(optionId);
                    }
                }
            }
        },
        sortSubjects(evt) {
            let subjectItem = this.oldSubjectList[evt.oldIndex]; // 找出舊的項目
            this.oldSubjectList.splice(evt.oldIndex, 1); // 刪除舊的項目
            this.oldSubjectList.splice(evt.newIndex, 0, subjectItem); // 插入到新的位子
            this.isSortSubject = true;
        },
        setSubjectIndex(index) {
            this.subjectIndex = null;
            // 因為draggable拖拉功能的@end只能傳event，不能帶參數(subjectList的index)進來
            // @change事件會比@end事件早執行，所以先透過setSubjectIndex來設定subjectList的index
            this.subjectIndex = index;
            this.renderMdCount++;
        },
        sortSubQuestion(evt) {
            let subQuestionItem = this.oldSubjectList[this.subjectIndex].sub_questions[evt.oldIndex]; // 找出舊的項目
            this.oldSubjectList[this.subjectIndex].sub_questions.splice(evt.oldIndex, 1); // 刪除舊的項目
            this.oldSubjectList[this.subjectIndex].sub_questions.splice(evt.newIndex, 0, subQuestionItem); // 插入到新的位子
            this.subjectList[this.subjectIndex].isSortSubSubject = true;
            this.isSortSubSubject = true;
        },
        sortOptions(evt) {
            let answer_type = this.subjectList[this.subjectIndex].answer_type,
                answer = [],
                answerArr = [];

            // 重整answer陣列的順序
            if (answer_type < 2) {
                if (answer_type == 0) { // 把單選題的answer[0]還原成完整陣列
                    this.oldSubjectList[this.subjectIndex].options.forEach((el, index) => {
                        if (index == this.oldSubjectList[this.subjectIndex].answer[0]) {
                            answerArr.push(1);
                        }
                        else {
                            answerArr.push(0);
                        }
                    });
                }
                if (answer_type == 1) { // 多選本身answer就是完整陣列了
                    answerArr = this.oldSubjectList[this.subjectIndex].answer;
                }

                let value = answerArr[evt.oldIndex]; // 找出舊有位子的值
                answerArr.splice(evt.oldIndex, 1); // 刪除舊的項目
                answerArr.splice(evt.newIndex, 0, value); // 插入到新的位子

                if (answer_type == 0) { // 把單選題的answer[0]設值
                    answerArr.forEach((el, index) => {
                        if (el == 1) {
                            answer[0] = index;
                        }
                    });
                }

                if (answer_type == 1) { // 多選題直接等於改變後的陣列
                    answer = answerArr
                }

                this.$set(this.subjectList[this.subjectIndex], 'answer', answer);
                this.$set(this.oldSubjectList[this.subjectIndex], 'answer', answer);
            }

            // 重整選項options陣列的順序
            let optionItem = this.oldSubjectList[this.subjectIndex].options[evt.oldIndex]; // 找出舊的項目
            this.oldSubjectList[this.subjectIndex].options.splice(evt.oldIndex, 1); // 刪除舊的項目
            this.oldSubjectList[this.subjectIndex].options.splice(evt.newIndex, 0, optionItem); // 插入到新的位子
            this.isSortSubject = true;
        },
        setSubQuestionIndex(subjectIndex, subQuestionIndex) {
            this.subjectIndex = null;
            this.subQuestionIndex = null;
            // 因為draggable拖拉功能的@end只能傳event，不能帶參數(subQuestion的index)進來
            // @change事件會比@end事件早執行，所以先透過setSubQuestionIndex來設定subQuestion的index
            this.subjectIndex = subjectIndex;
            this.subQuestionIndex = subQuestionIndex;
        },
        sortSubOptions(evt) {
            let answer_type = this.subjectList[this.subjectIndex].answer_type,
                answer = [],
                answerArr = [];

            // 重整answer陣列的順序
            if (answer_type < 2) {
                if (answer_type == 0) { // 把單選題的answer[0]還原成完整陣列
                    this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex].options.forEach((el, index) => {
                        if (index == this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex].answer[0]) {
                            answerArr.push(1);
                        }
                        else {
                            answerArr.push(0);
                        }
                    });
                }
                if (answer_type == 1) { // 多選本身answer就是完整陣列了
                    answerArr = this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex].answer;
                }

                let value = answerArr[evt.oldIndex]; // 找出舊有位子的值
                answerArr.splice(evt.oldIndex, 1); // 刪除舊的項目
                answerArr.splice(evt.newIndex, 0, value); // 插入到新的位子

                if (answer_type == 0) { // 把單選題的answer[0]設值
                    answerArr.forEach((el, index) => {
                        if (el == 1) {
                            answer[0] = index;
                        }
                    });
                }

                if (answer_type == 1) { // 多選題直接等於改變後的陣列
                    answer = answerArr
                }

                this.$set(this.subjectList[this.subjectIndex].sub_questions[this.subQuestionIndex], 'answer', answer);
                this.$set(this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex], 'answer', answer);
            }

            // 重整選項options陣列的順序
            let optionItem = this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex].options[evt.oldIndex]; // 找出舊的項目
            this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex].options.splice(evt.oldIndex, 1); // 刪除舊的項目
            this.oldSubjectList[this.subjectIndex].sub_questions[this.subQuestionIndex].options.splice(evt.newIndex, 0, optionItem); // 插入到新的位子
            this.isSortSubSubject = true;
        },
        uploadTitleImage(type) { // 新增(pop up)單題[題目圖片]的上傳
            let v = this;

            this.uploadErrorMsg.cover = null;

            let tempUploadFile = this.$refs['uploadTitleImage' + type].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {

                if (type == 'Writing') {
                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.newSubject.images.push({
                            path: dataURL,
                            name: tempUploadFile.name,
                            file: tempUploadFile
                        });
                    }
                }
                else {
                    this.newSubject.image = this.$_.mapValues(this.newSubject.image, () => null);

                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.newSubject.image.path = dataURL;
                    }

                    this.newSubject.image.name = tempUploadFile.name;
                    this.newSubject.image.file = tempUploadFile;
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadTitleImage' + type].value = null;
        },
        uploadEditTitleImage(subjectIndex, subjectId, isMulti) { // 編輯單題[題目圖片]的上傳
            let v = this;

            this.uploadErrorMsg.cover = null;

            let tempUploadFile = this.$refs['uploadTitleImage' + subjectId][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {

                if (isMulti) {
                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.subjectList[subjectIndex].images.push({
                            path: dataURL,
                            name: tempUploadFile.name,
                            file: tempUploadFile
                        });
                    }

                }
                else {

                    this.subjectList[subjectIndex].image = this.$_.mapValues(this.subjectList[subjectIndex].image, () => null);

                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.subjectList[subjectIndex].image.path = dataURL;
                    }

                    this.subjectList[subjectIndex].image.name = tempUploadFile.name;
                    this.subjectList[subjectIndex].image.file = tempUploadFile;
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadTitleImage' + subjectId][0].value = null;
        },
        uploadSubTitleImage(subQuestionIndex, subjectIndex) { // 題組子題[題目圖片]的上傳 (新增: 用到subQuestionIndex，編輯: 用到subjectIndex跟subQuestionIndex)
            let v = this,
                refsName = subQuestionIndex;

            this.uploadErrorMsg.cover = null;

            if (subjectIndex !== undefined) {
                refsName = subjectIndex + '' + subQuestionIndex; // 防止兩個數值加起來，中間加字串
            }

            let tempUploadFile = this.$refs['uploadSubTitleImage' + refsName][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {

                if (subjectIndex === undefined) { // 新增
                    this.newSubject.sub_questions[subQuestionIndex].image = this.$_.mapValues(this.newSubject.sub_questions[subQuestionIndex].image, () => null);

                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.newSubject.sub_questions[subQuestionIndex].image.path = dataURL;
                    }

                    this.newSubject.sub_questions[subQuestionIndex].image.name = tempUploadFile.name;
                    this.newSubject.sub_questions[subQuestionIndex].image.file = tempUploadFile;
                }
                else { // 編輯
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].image = this.$_.mapValues(this.subjectList[subjectIndex].sub_questions[subQuestionIndex].image, () => null);

                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.subjectList[subjectIndex].sub_questions[subQuestionIndex].image.path = dataURL;
                    }

                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].image.name = tempUploadFile.name;
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].image.file = tempUploadFile;
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadSubTitleImage' + refsName][0].value = null;
        },
        uploadAudio(type) { // 新增(pop up)單題[附加音檔]的上傳
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs['uploadAudio' + type].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp3'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {
                this.newSubject.audio = {
                    name: tempUploadFile.name,
                    file: tempUploadFile,
                    path: URL.createObjectURL(tempUploadFile)
                };
            } else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadAudio' + type].value = '';
        },
        uploadConversationAudio(subjectIndex) { // 新增對話(pop up)子題[附加音檔]的上傳
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs['uploadConversationAudio' + subjectIndex][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp3'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);
            if (!this.uploadErrorMsg.file) {
                this.newSubject.sub_questions[subjectIndex].audio = {
                    name: tempUploadFile.name,
                    file: tempUploadFile,
                    path: URL.createObjectURL(tempUploadFile)
                };
            } else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadConversationAudio' + subjectIndex].value = '';
        },
        uploadEditAudio(subjectIndex, subjectId) { // 編輯單題[附加音檔]的上傳
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs['uploadAudio' + subjectId][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp3'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {
                this.subjectList[subjectIndex].audio = {
                    name: tempUploadFile.name,
                    file: tempUploadFile,
                    path: URL.createObjectURL(tempUploadFile)
                };
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadAudio' + subjectId][0].value = '';
        },
        uploadConversationEditAudio(subjectIndex, subQuestionIndex) {
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs['uploadConversationAudio' + subjectIndex + subQuestionIndex][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp3'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);
            if (!this.uploadErrorMsg.file) {
                this.subjectList[subjectIndex].sub_questions[subQuestionIndex].audio = {
                    name: tempUploadFile.name,
                    file: tempUploadFile,
                    path: URL.createObjectURL(tempUploadFile)
                };
            } else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadConversationAudio' + subjectIndex + subQuestionIndex].value = '';
        },
        uploadOptionImage(optionIndex) { // 新增(pop up)單題[選項圖片]的上傳
            let v = this;

            this.uploadErrorMsg.cover = null;

            // 因為新增題目只能用選項的index，但會跟編輯題目的optionId重複，所以uploadOptionImage後面加一個0並組起來
            let tempUploadFile = this.$refs['uploadOptionImage0' + optionIndex][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {
                this.newSubject.options[optionIndex].image = this.$_.mapValues(this.newSubject.options[optionIndex].image, () => null);

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.newSubject.options[optionIndex].image.path = dataURL;
                }

                this.newSubject.options[optionIndex].image.name = tempUploadFile.name;
                this.newSubject.options[optionIndex].image.file = tempUploadFile;
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadOptionImage0' + optionIndex][0].value = null;
        },
        uploadEditOptionImage(subjectIndex, optionIndex, optionId) { // 編輯單題[選項圖片]的上傳
            let v = this;

            this.uploadErrorMsg.cover = null;

            let tempUploadFile = this.$refs['uploadOptionImage' + optionId][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {

                this.subjectList[subjectIndex].options[optionIndex].image = this.$_.mapValues(this.subjectList[subjectIndex].options[optionIndex].image, () => null);

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.subjectList[subjectIndex].options[optionIndex].image.path = dataURL;
                    v.$forceUpdate(); // 強制重整物件裡的資料，必免無法讀到檔案
                }

                this.subjectList[subjectIndex].options[optionIndex].image.name = tempUploadFile.name;
                this.subjectList[subjectIndex].options[optionIndex].image.file = tempUploadFile;
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadOptionImage' + optionId][0].value = null;
        },
        uploadSubOptionImage(optionIndex, subQuestionIndex, subjectIndex) {  // 題組子題[選項圖片]的上傳 (新增: 用到optionIndex跟subQuestionIndex，編輯: 用到optionIndex跟subQuestionIndex跟subjectIndex)
            let v = this,
                refsName = subQuestionIndex+''+optionIndex;

            this.uploadErrorMsg.cover = null;

            if (subjectIndex !== undefined) {
                refsName = subjectIndex + '' + subQuestionIndex + '' + optionIndex; // 防止數值加起來，中間加字串
            }

            // 因為新增題目只能用選項的index，但會跟編輯題目的optionId重複，所以uploadOptionImage後面加一個0並組起來
            let tempUploadFile = this.$refs['uploadSubOptionImage' + refsName][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {

                if (subjectIndex === undefined) { // 新增
                    this.newSubject.sub_questions[subQuestionIndex].options[optionIndex].image = this.$_.mapValues(this.newSubject.sub_questions[subQuestionIndex].options[optionIndex].image, () => null);

                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.newSubject.sub_questions[subQuestionIndex].options[optionIndex].image.path = dataURL;
                    }

                    this.newSubject.sub_questions[subQuestionIndex].options[optionIndex].image.name = tempUploadFile.name;
                    this.newSubject.sub_questions[subQuestionIndex].options[optionIndex].image.file = tempUploadFile;
                }
                else {
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options[optionIndex].image = this.$_.mapValues(this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options[optionIndex].image, () => null);

                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = function() {
                        let dataURL = reader.result;
                        v.subjectList[subjectIndex].sub_questions[subQuestionIndex].options[optionIndex].image.path = dataURL;
                    }

                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options[optionIndex].image.name = tempUploadFile.name;
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].options[optionIndex].image.file = tempUploadFile;
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadSubOptionImage' + refsName][0].value = null;
        },
        uploadMedia(type) { // 新增(pop up)單題[解析媒體]的上傳
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs['uploadMedia' + type].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp4', 'mp3', 'jpg', 'jpeg', 'png', 'gif'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {
                this.newSubject.media = this.$_.mapValues(this.newSubject.media, () => null);
                this.newSubject.media.name = tempUploadFile.name;
                this.newSubject.media.file = tempUploadFile;
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadMedia' + type].value = null;
        },
        uploadEditMedia(subjectIndex, subjectId) { // 編輯題目的上傳[解析媒體]
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs['uploadMedia' + subjectId][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp4', 'mp3', 'jpg', 'jpeg', 'png', 'gif'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {
                this.subjectList[subjectIndex].media = this.$_.mapValues(this.subjectList[subjectIndex].media, () => null);
                this.subjectList[subjectIndex].media.name = tempUploadFile.name;
                this.subjectList[subjectIndex].media.file = tempUploadFile;
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadMedia' + subjectId][0].value = null;
        },
        uploadSubMedia(subQuestionIndex, subjectIndex) { // 題組子題[解析媒體]的上傳 (新增: 用到subQuestionIndex，編輯: 用到subjectIndex跟subQuestionIndex)
            let refsName = subQuestionIndex;
            this.uploadErrorMsg.file = null;

            if (subjectIndex !== undefined) {
                refsName = subjectIndex + '' + subQuestionIndex; // 防止兩個數值加起來，中間加字串
            }

            let tempUploadFile = this.$refs['uploadSubMedia' + refsName][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp4', 'mp3', 'jpg', 'jpeg', 'png', 'gif'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {

                if (subjectIndex === undefined) { // 新增
                    this.newSubject.sub_questions[subQuestionIndex].media = this.$_.mapValues(this.newSubject.sub_questions[subQuestionIndex].media, () => null);
                    this.newSubject.sub_questions[subQuestionIndex].media.name = tempUploadFile.name;
                    this.newSubject.sub_questions[subQuestionIndex].media.file = tempUploadFile;
                }
                else { // 編輯
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].media = this.$_.mapValues(this.subjectList[subjectIndex].sub_questions[subQuestionIndex].media, () => null);
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].media.name = tempUploadFile.name;
                    this.subjectList[subjectIndex].sub_questions[subQuestionIndex].media.file = tempUploadFile;
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案過大或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadSubMedia' + refsName][0].value = null;
        },
        showImportSubjectDialogue() { // 題目匯入
            $('#importSubjectDialogue').modal('show');
        },
        addSubjectFile() {
            let v = this;

            this.uploadErrorMsg.file = null;

            let tempUploadFile = v.$refs.subjectFile.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['xlsx', 'xls'];
            let perFileLimitSize = 209715200;  // 200mb

            this.uploadErrorMsg.file = v.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.subjectFile.path = dataURL;
                }

                this.subjectFile.name = tempUploadFile.name;
                this.subjectFile.file = tempUploadFile;
            }

            this.$refs.subjectFile.value = null;
        },
        importSubjectFile() {
            let params = new FormData();
            params.append('articleId', this.$route.params.id);
            params.append('upload', this.subjectFile.file);

            this.$httpRequest.post('/admin_api/', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.subjectFile = {
                            path: '',
                            name: '',
                            file: ''
                        };
                        $('#importSubjectDialogue').modal('hide');
                        this.initialize();
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showAdjustSubjectDialogue(index, data, pickNum) { // 調整題量
            this.selectedCategoryIndex = index;
            this.adjustSubjectData = data;
            this.pickNum = pickNum; // 因為如果用adjustSubjectData物件裡的pick_num，陣列內容會同步，所以另外新增一個pickNum
            $('#adjustSubjectDialogue').modal('show');
        },
        adjustSubject() {
            if (this.pickNum == '') {
                return;
            }
            this.categoryList.forEach((el, i) => {
                if (i === this.selectedCategoryIndex) {
                    el.pick_num = this.pickNum;
                }
            });
            $('#adjustSubjectDialogue').modal('hide');
        },
        showAdjustPracticeTimesDialogue(index, data, practiceTimes) { // 調整練習次數
            this.selectedCategoryIndex = index;
            this.adjustSubjectData = data;
            this.practiceTimes = practiceTimes; // 因為如果用adjustSubjectData物件裡的pick_num，陣列內容會同步，所以另外新增一個pickNum
            $('#practiceTimesDialogue').modal('show');
        },
        editPracticeTimes() {
            if (this.practiceTimes == '') {
                return;
            }
            this.categoryList.forEach((el, i) => {
                if (i === this.selectedCategoryIndex) {
                    el.practiceTimes = el.practice_times = this.practiceTimes;
                }
            });
            $('#practiceTimesDialogue').modal('hide');
        },
        playAudio(url) {
            this.audio.paused;
            this.audio.src = url;
            this.audio.play();
        }
    }
}
