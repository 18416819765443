export default {
    /**
     * 一般分類(重組題)
     *
     * @param  number  questionContentType  題目內容的組成類型
     * 題目組成含有多個成分, 距離上方 60px 後開始顯示內容
     * 不顯示圖片
     *
     * @param  number  choiceOptionContentTypeInfo  各選項內容的組成資訊
     * 雙欄 2列顯示
     * 選項標號上下置中
     */
    props: [
        'activeQuestion',
        'isLastQuestion',
        'questionContentType',
        'choiceOptionContentTypeInfo',
        'answerSelectedOptionItems',
        'activeWordItemIndex',
        'minOptionsCount',
        'isShowHandinBtn',
        'isPostingApi',
        'handInResult',
        'isShowHandInFeedback'
    ],
    computed: {
        practiceQuestionInfo() {
            return this.$store.state.article.practiceQuestionInfo;
        },
    },
    mounted: function() {
        this.initQuestion();

        // resize events
        window.addEventListener('resize', this.$parent.setQuestionWordItemWidth);
        window.addEventListener('resize', this.$parent.setAnswerContentBlockHeight);
        window.addEventListener('resize', this.$parent.setOptionListAndItemHeight);
        window.addEventListener('resize', this.$parent.setOptionTextItemStyle);
    },
    beforeDestroy: function() {
        // resize events
        window.removeEventListener('resize', this.$parent.setQuestionWordItemWidth);
        window.removeEventListener('resize', this.$parent.setAnswerContentBlockHeight);
        window.removeEventListener('resize', this.$parent.setOptionListAndItemHeight);
        window.removeEventListener('resize', this.$parent.setOptionTextItemStyle);
    },
    methods: {
        initQuestion() {
            this.$nextTick(() => {
                this.$parent.setQuestionWordItemWidth();
                this.$parent.setAnswerContentBlockHeight();
                this.$parent.setOptionListAndItemHeight();
                this.$parent.setOptionTextItemStyle();
            });
        }
    }
}
