export default {
    data: function() {
        return {
            isShowSearchDropdown: false,
            deletingSearchedItems: {}  // 正在刪除的最近搜尋項目
        }
    },
    watch: {
        'isSetHeaderDataReady.getMyRecentSearchedData'(newValue) {
            if (!newValue) {
                this.isShowSearchDropdown = false;
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isSetHeaderDataReady: {
            get() {
                return this.$store.state.common.isSetHeaderDataReady;
            },
            set(value) {
                this.$store.commit('common/setIsSetHeaderDataReady', value);
            }
        },
        inputKeyword: {
            get() {
                return this.$store.state.common.inputKeyword;
            },
            set(value) {
                this.$store.commit('common/setInputKeyword', value);
            }
        },
        recentSearchedList: {
            get() {
                return this.$parent.recentSearchedList;
            },
            set(value) {
                this.$parent.setRecentSearchedList(value);
            }
        }
    },
    mounted: function() {
        if (!this.inputKeyword) {
            this.$emit('load');
        }
    },
    methods: {
        async getMyRecentSearchedData() {
            if (this.windowWidth < 768) {
                this.isShowSearchDropdown = true;
            }

            if (this.isSetHeaderDataReady.getMyRecentSearchedData) {
                if (this.windowWidth >= 768) {
                    this.isShowSearchDropdown = !!this.recentSearchedList.length;
                }

                return;
            }

            this.$httpRequest.get('/api/search/get_search_records')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.recentSearchedList = result;

                            if (this.windowWidth >= 768) {
                                this.isShowSearchDropdown = !!this.recentSearchedList.length;
                            }

                            this.isSetHeaderDataReady.getMyRecentSearchedData = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        keywordInputBlurredHandler(event) {
            let toggleElem = document.getElementById('searchDropdownToggle');
            let menuElem = document.getElementById('searchDropdownMenu');

            if (event.relatedTarget &&
                (toggleElem.contains(event.relatedTarget) || menuElem.contains(event.relatedTarget))) {
                return;
            }

            this.isShowSearchDropdown = false;
        },
        deleteSearchedItem(index) {
            let params = {
                keyword: this.recentSearchedList[index]
            }

            this.$set(this.deletingSearchedItems, index, 1);

            this.$httpRequest.post('/api/search/delete_search_keyword', params)
                .then(response => {
                    this.$delete(this.deletingSearchedItems, index);

                    if (response.data.state == 'OK') {
                        this.$delete(this.recentSearchedList, index);
                        this.$parent.focusKeywordInput();
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        searchRecentItem() {
            this.isShowSearchDropdown = false;
            this.inputKeyword = decodeURIComponent(this.$route.query.q);
        }
    }
}
