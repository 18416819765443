export default {
    data: function() {
        return {
            isSearchingWord: false,  // 是否正在發送字典API查詢單字

            audioElem: new Audio(),
            playPromise: null,
            playingSoundItem: {},

            isPostingApi: {
                updateWordIsImportant: false,  // 標記/取消標記單字
                updateMarkedWord: false,  // 收藏/取消收藏單字
            }
        }
    },
    computed: {
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        dictionaryResult: {
            get() {
                return this.$store.state.article.dictionaryResult;
            },
            set(value) {
                this.$store.commit('article/setDictionaryResult', value);
            }
        },
        activeSearchedWord() {
            return this.$store.state.article.activeSearchedWord;
        }
    },
    created: function() {
        this.searchWord();
    },
    beforeDestroy: function() {
        this.pauseAudio();  // 離開頁面需停止音檔播放

        this.$parent.isRemovedMarkedWord = false;
    },
    methods: {
        searchWord() {
            if (this.isSearchingWord) {
                return;
            }

            this.isSearchingWord = true;
            this.$store.dispatch('article/searchWord')
                .then(() => {
                    this.isSearchingWord = false;
                })
                .catch((error) => {
                    console.log('catched error: ' + error);
                });
        },
        playPronunciationAudio(item) {
            if (item.isPlayingSound) {
                return;
            }

            if (!item.pronunciationAudioFile) {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                return;
            }

            if (item != this.playingSoundItem) {
                this.playingSoundItem.isPlayingSound = false;
            }
            this.playingSoundItem = item;

            this.audioElem.src = item.pronunciationAudioFile;
            if (this.audioElem.paused) {
                this.playPromise = this.audioElem.play();

                // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                if (this.playPromise && this.playPromise !== undefined) {
                    this.playPromise
                        .catch((error) => {
                            if (error.name === 'NotSupportedError') {
                                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                            }
                            console.log('catched error: ' + error);
                        });
                }
            }
            this.audioElem.onplaying = () => {
                item.isPlayingSound = true;
            };
            this.audioElem.onended = () => {
                item.isPlayingSound = false;
            };
        },
        pauseAudio() {
            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.playingSoundItem.isPlayingSound = false;
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                    });
            }
        },
        updateWordIsImportant() {
            if (this.isPostingApi.updateWordIsImportant) {
                return;
            }

            let payload = {
                wordItem: this.activeSearchedWord
            }

            this.isPostingApi.updateWordIsImportant = true;

            this.$store.dispatch('article/updateWordIsImportant', payload)
                .then(() => {
                    this.isPostingApi.updateWordIsImportant = false;
                })
                .catch((error) => {
                    console.log('catched error: ' + error);
                });
        },
        async updateMarkedWord() {
            if (this.isPostingApi.updateMarkedWord) {
                return;
            }

            let payload = {
                articleId: this.$route.params.id
            }

            this.isPostingApi.updateMarkedWord = true;
            await this.$store.dispatch('article/updateMarkedWord', payload);
            this.isPostingApi.updateMarkedWord = false;
            this.$parent.isRemovedMarkedWord = true;
        }
    }
}
