import WebSocket from '@/global/websocket.js'
import { getRecognizedDisplayedText, getRecognizedComment } from '@/assets/js/utils/article_practice.js'

// components
const Header = resolve => require(["@/components/article/practicing/components/header.vue"], resolve)
const RecordController = resolve => require(["@/components/article/practicing/speak/_record_controller.vue"], resolve)
const RecognizedScoreInfo = resolve => require(["@/components/article/practicing/speak/_recognized_score_info.vue"], resolve)

export default {
    props: {
        testedId: {
            type: String,
            default: '',
        },
        question: {
            type: Object,
            default: () => {},
        },
        headerInfo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Header,
        RecordController,
        RecognizedScoreInfo,
    },
    data: function() {
        return {
            contentTypeOptions: [
                { value: '0', text: '文字' },
                { value: '1', text: '圖片' },
            ],
            practicingUrl: location.href,

            readingContent: {},
            selectedContentType: '0',
            audio: new Audio(),
            websocket: new WebSocket('Recognize', process.env.VUE_APP_RECOGNIZE_WS_URL, this.getRecognizedResult),
            isPracticeState: false,
            tempPracticeInfo: {},
            tempRecognizedInfo: {},
            lastRecognizedInfo: {},
            practiceTimerId: 0,
            practiceTime: 0,
            isRecognizing: false,
            isPlayingPracticeAudio: false,
            isEnlargeText: false,
            isShowFeedback: false,
            isShowNextBtn: false,

            isReadingContentReady: false,
            isPostingApi: {
                practiceDone: false,  // 單一題練習結束
            },
        }
    },
    created: function() {
        this.initReadingContent();
        this.websocket.connect({
            action: 'register',
            data: { practiceTestedId: this.testedId },
        });
    },
    beforeDestroy: function() {
        this.audio.pause();
        this.websocket.close();
    },
    methods: {
        initReadingContent() {
            const q = this.question;
            const defaultData = {
                id: q.id,
                text: q.options[0].title.text,
                image: q.title.file_info.images[0] ? q.title.file_info.images[0].file_url : '',
                translation: q.title.text,
                demoAudio: q.title.file_info.audios[0].file_url,
                showText: q.is_show_speak_text,
                showAudio: q.is_show_speak_audio,
            };
            const variableData = {
                displayedText: defaultData.text,
                practiceAudio: '',
                recognizedResult: {},
            };
            this.readingContent = {...defaultData, ...variableData};
        },

        enterPracticeState() {
            this.audio.pause();
            this.isRecognizing = false;
            this.isPracticeState = true;
        },
        startPracticing() {
            // 暫存上次辨識結果, 若錄音失敗可恢復為上一次結果
            if (this.isShowNextBtn) {
                this.saveLastRecognizedInfo();
            }
            this.practiceTime = 0;
            this.practiceTimerId = setInterval(() => this.practiceTime++, 1000);
        },
        saveLastRecognizedInfo() {
            this.lastRecognizedInfo = {
                displayedText: this.readingContent.displayedText,
                practiceAudio: this.readingContent.practiceAudio,
                recognizedResult: {...this.readingContent.recognizedResult},
            };
            this.readingContent.displayedText = this.readingContent.text;
            this.readingContent.practiceAudio = '';
            this.readingContent.recognizedResult = {};
        },
        restoreLastRecognizedInfo() {
            this.readingContent = {...this.readingContent, ...this.lastRecognizedInfo};
            this.lastRecognizedInfo = {};
        },
        recognizeText(file) {
            this.audio.pause();

            if (!file) {
                this.isPracticeState = false;
                this.restoreLastRecognizedInfo();
                this.tempPracticeInfo = {};
                this.tempRecognizedInfo = {};
                return;
            }

            clearInterval(this.practiceTimerId);

            this.isPracticeState = false;
            this.isRecognizing = true;

            this.tempPracticeInfo = {
                audio: URL.createObjectURL(file),
            };

            const answer = {
                practiceTestedId: this.testedId,
                questionId: this.readingContent.id,
                answerOption: {
                    answerContent: '',
                    answerIds: [],
                    answerWords: [],
                    answerCompositions: [],
                },
                time: this.practiceTime,
            };

            let params = new FormData();
            params.append('answer', JSON.stringify(answer));
            params.append('upload', file);  // 將音檔傳至 Server 進行辨識

            this.$httpRequest.post('/api/practice/handin', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            this.tempPracticeInfo.recognizeId = result.recognize_id;
                        }
                    }
                })
                .catch(error => {
                    this.isPracticeState = false;
                    this.restoreLastRecognizedInfo();
                    this.tempPracticeInfo = {};
                    this.tempRecognizedInfo = {};
                    console.error('Catched Error:', error);
                });
        },
        getRecognizedResult(data) {
            // 註冊連線成功
            if (data.body === 'success') {
                this.isReadingContentReady = true;
            }

            // 收到辨識結果
            if (data.ptq_id) {
                const recognizeId = data.ptq_id;
                const recognizeMode = data.mode;

                if (recognizeId !== this.tempPracticeInfo.recognizeId) {
                    return;
                }
                if (recognizeMode !== 'voice') {
                    return;
                }

                const result = data.result.voice;

                this.tempRecognizedInfo = {
                    displayedText: getRecognizedDisplayedText(result.detail.text),
                    recognizedResult: {
                        score: result.score,
                        comment: getRecognizedComment(result.score),
                        correctRate: `${result.detail.lightRate}%`,
                        fluency: result.detail.fluency,
                    },
                };

                this.showRecognizedResult();
            }
        },
        showRecognizedResult() {
            this.tempRecognizedInfo = {
                ...this.tempRecognizedInfo,
                practiceAudio: this.tempPracticeInfo.audio,
                showAudio: true,
            };
            this.readingContent = {...this.readingContent, ...this.tempRecognizedInfo};
            this.selectedContentType = '0';
            this.tempPracticeInfo = {};
            this.tempRecognizedInfo = {};

            this.isRecognizing = false;
            this.isShowNextBtn = true;

            this.isShowFeedback = true;
            this.audio.onplaying = null;
            this.audio.onended = async () => {
                await new Promise(resolve => setTimeout(resolve, 700));  // 等待過場
                this.isShowFeedback = false;
            };
            const score = this.readingContent.recognizedResult.score;
            this.audio.src = require(`@/assets/sound/article/answer_${score > 2 ? 'correct' : 'wrong'}.wav`);
            this.playAudio();
        },

        playDemoAudio() {
            this.audio.pause();
            this.audio.onplaying = () => {
                this.isPlayingPracticeAudio = false;
            };
            this.audio.onpause = null;
            this.audio.src = this.readingContent.demoAudio;
            this.playAudio();
        },
        playPracticeAudio() {
            this.audio.pause();
            this.audio.onplaying = () => {
                this.isPlayingPracticeAudio = true;
            };
            this.audio.onpause = () => {
                this.isPlayingPracticeAudio = false;
            };
            this.audio.src = this.readingContent.practiceAudio;
            this.playAudio();
        },

        async playAudio() {
            try {
                if (this.audio.paused) {
                    await this.audio.play();
                }
            } catch (error) {
                if (error.name === 'NotSupportedError') {
                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                }
                console.error('Catched Error:', error);
            }
        },

        showRecognizedScoreInfoDialogue() {
            $('#recognizedScoreInfoDialogue').modal('show');
        },
        changeQuestion() {
            this.audio.pause();
            this.websocket.close();

            const params = {
                practiceTestedId: this.testedId,
                questionIds: [this.readingContent.id],
            };

            this.isPostingApi.practiceDone = true;

            this.$httpRequest.post('/api/practice/speek_question_done', params)
                .then(response => {
                    this.isPostingApi.practiceDone = false;

                    if (response.data.state == 'OK') {
                        this.$emit('changeQuestion');
                    }
                })
                .catch(error => {
                    this.isPostingApi.practiceDone = false;
                    console.error('Catched Error:', error);
                });
        },
    },
}
