import WebSocket from '@/global/websocket.js'
import { getRecognizedDisplayedText } from '@/assets/js/utils/article_practice.js'

// components
const Header = resolve => require(["@/components/article/practicing/components/header.vue"], resolve)
const RecordController = resolve => require(["@/components/article/practicing/speak/_record_controller.vue"], resolve)
const RecognizedScoreInfo = resolve => require(["@/components/article/practicing/speak/_recognized_score_info.vue"], resolve)

export default {
    props: {
        testedId: {
            type: String,
            default: '',
        },
        question: {
            type: Object,
            default: () => {},
        },
        headerInfo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Header,
        RecordController,
        RecognizedScoreInfo,
    },
    data: function() {
        return {
            practicingUrl: location.href,

            conversation: {},
            activeMsgId: '',
            hiddenMsgIds: [],
            hiddenLabelMsgIds: {},
            speakingMsgId: '',
            transitingMsgId: '',
            breathingTimerId: 0,
            breathingRatio: 0,

            audio: new Audio(),
            websocket: new WebSocket('Recognize', process.env.VUE_APP_RECOGNIZE_WS_URL, this.getRecognizedResult),
            isDemoState: false,
            isRepeatPlaying: false,
            playingIndex: -1,
            isPracticeState: false,
            practicingRole: '',
            practicingIndex: -1,  // 循環練習
            practicingMsgId: '',  // 單句練習
            tempPracticeInfo: {},  // key: msgId
            tempRecognizeIds: {},  // key: recognizeId, val: msgId
            tempRecognizedInfo: {},  // key: msgId
            lastRecognizedInfo: {},  // key: msgId
            practiceTimerId: 0,
            practiceTime: 0,
            isExchangingMessage: false,
            isExchangingRole: false,
            isRecognizing: false,
            isEnlargeText: false,
            isShowNextBtn: false,

            isConversationReady: false,
            isPostingApi: {
                practiceDone: false,  // 單一題練習結束
            },
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
    },
    created: function() {
        this.initConversation();
        this.websocket.connect({
            action: 'register',
            data: { practiceTestedId: this.testedId },
        });
    },
    beforeDestroy: function() {
        this.audio.pause();
        this.websocket.close();
        clearInterval(this.breathingTimerId);
    },
    methods: {
        initConversation() {
            // basic
            let cInfo = {
                id: this.question.id,
                msgIds: [],
                msgInfos: {},
            };

            // messages
            for (const sq of this.question.sub_questions) {
                const msgId = sq.id;
                cInfo.msgIds.push(msgId);

                const msgData = {
                    role: sq.voice_group === '0' ? 'sender' : 'receiver',
                    defaultAvatar: sq.options[0].title.file_info.images[0].file_url,
                    text: sq.options[0].title.text,
                    translation: sq.title.text,
                    demoAudio: sq.title.file_info.audios[0].file_url,
                };
                const variableData = {
                    displayedText: msgData.text,
                    practiceAudio: '',
                    recognizedScore: '',
                };
                let msgInfo = {...msgData, ...variableData};
                this.$set(cInfo.msgInfos, msgId, msgInfo);
            }

            this.conversation = cInfo;
        },

        enterPracticeState() {
            this.stopPlayingMessage();
            // 開始循環練習, 清空暫存資料
            if (!this.practicingMsgId) {
                this.tempPracticeInfo = {};
                this.tempRecognizeIds = {};
            }
            this.isRecognizing = false;
            this.isPracticeState = true;
        },
        startPracticing() {
            // 單句練習
            if (this.practicingMsgId) {
                const msgId = this.practicingMsgId;
                this.practiceTime = 0;
                this.practiceTimerId = setInterval(() => this.practiceTime++, 1000);
                this.startBreathing();
                this.speakingMsgId = msgId;
                this.activeMsgId = msgId;
                this.$set(this.hiddenLabelMsgIds, msgId, 1);
                this.saveLastRecognizedInfo(msgId);
            }
            // 循環練習
            else {
                this.practiceConversation('sender');
            }
        },
        practiceConversation(role) {
            // 正在循環對話, 不進行任何動作
            if (this.practicingIndex >= 0) {
                return;
            }

            this.practicingRole = role;
            for (const msgId of this.conversation.msgIds) {
                this.$set(this.hiddenLabelMsgIds, msgId, 1);
            }
            // 暫存上次辨識結果, 若錄音失敗可恢復為上一次結果
            if (this.isShowNextBtn) {
                this.restoreLastRecognizedInfo();
                for (const msgId of this.conversation.msgIds) {
                    this.saveLastRecognizedInfo(msgId);
                }
            }
            this.practiceNextMessage();
        },
        async practiceNextMessage() {
            // 對話句子轉換
            if (this.practicingIndex >= 0) {
                this.stopBreathing();
                this.speakingMsgId = '';
                this.isExchangingMessage = true;
                await new Promise(resolve => setTimeout(resolve, 200));  // 轉換停留
                this.isExchangingMessage = false;
            }

            if (!this.isPracticeState) {
                return;
            }

            this.practicingIndex++;
            const msgId = this.conversation.msgIds[this.practicingIndex];
            if (!msgId) {
                // 一輪對話結束, 角色交換
                if (this.practicingRole === 'sender') {
                    this.speakingMsgId = '';
                    this.isExchangingRole = true;
                    await new Promise(resolve => setTimeout(resolve, 2000));  // 等待過場
                    this.isExchangingRole = false;
                    this.stopPracticingConversation();
                    this.practiceConversation('receiver');
                }
                // 兩輪對話結束, 等待辨識
                else {
                    this.speakingMsgId = '';
                    this.isPracticeState = false;
                    this.isRecognizing = true;
                    // 若所有對話句子皆辨識完成, 則直接顯示辨識結果
                    if (Object.keys(this.tempRecognizedInfo).length === Object.keys(this.hiddenLabelMsgIds).length) {
                        this.showRecognizedResult();
                    }
                }
                return;
            }

            if (this.activeMsgId) {
                this.hiddenMsgIds.push(this.activeMsgId);
            }
            this.startBreathing();
            this.speakingMsgId = msgId;
            this.activeMsgId = msgId;
            const role = this.conversation.msgInfos[msgId].role;

            if (role !== this.practicingRole) {  // 系統播放音檔
                const src = this.conversation.msgInfos[msgId].demoAudio;
                this.audio.onplaying = null;
                this.audio.onended = () => {
                    this.practiceNextMessage();
                };
                this.audio.src = src;
                this.playAudio();
            } else {  // 使用者練習
                this.practiceTime = 0;
                this.practiceTimerId = setInterval(() => this.practiceTime++, 1000);
            }
        },
        async practiceAssignedMessage(msgId) {
            if (!msgId) {
                return;
            }

            this.transitingMsgId = msgId;
            await new Promise(resolve => setTimeout(resolve, 200));  // 等待過場動畫
            this.transitingMsgId = '';
            this.practicingMsgId = msgId;
            this.enterPracticeState();
        },
        stopPracticingConversation() {
            this.stopBreathing();
            this.speakingMsgId = '';
            this.activeMsgId = '';
            this.hiddenMsgIds = [];
            this.hiddenLabelMsgIds = {};
            this.practicingRole = '';
            this.practicingIndex = -1;
        },
        saveLastRecognizedInfo(msgId) {
            if (!msgId) {
                return;
            }

            this.lastRecognizedInfo[msgId] = {
                displayedText: this.conversation.msgInfos[msgId].displayedText,
                practiceAudio: this.conversation.msgInfos[msgId].practiceAudio,
                recognizedScore: this.conversation.msgInfos[msgId].recognizedScore,
            };
            this.conversation.msgInfos[msgId].displayedText = this.conversation.msgInfos[msgId].text;
            this.conversation.msgInfos[msgId].practiceAudio = '';
            this.conversation.msgInfos[msgId].recognizedScore = '';
        },
        restoreLastRecognizedInfo() {
            for (const msgId of Object.keys(this.lastRecognizedInfo)) {
                this.conversation.msgInfos[msgId] = {...this.conversation.msgInfos[msgId], ...this.lastRecognizedInfo[msgId]};
            }
            this.lastRecognizedInfo = {};
        },
        recognizeMessage(file) {
            // 錄音失敗
            if (!file) {
                this.isPracticeState = false;
                this.stopPracticingConversation();
                this.restoreLastRecognizedInfo();
                this.practicingMsgId = '';
                this.tempPracticeInfo = {};
                this.tempRecognizeIds = {};
                this.tempRecognizedInfo = {};
                return;
            }

            clearInterval(this.practiceTimerId);

            let msgId = '';

            // 循環練習
            if (this.practicingIndex >= 0) {
                msgId = this.conversation.msgIds[this.practicingIndex];
                this.practiceNextMessage();
            }
            // 單句練習
            else {
                this.stopBreathing();
                this.speakingMsgId = '';
                this.activeMsgId = '';
                msgId = this.practicingMsgId;
                this.conversation.msgInfos[msgId].displayedText = '';
                this.practicingMsgId = '';
                this.isPracticeState = false;
                this.isRecognizing = true;
            }

            if (!msgId) {
                return;
            }

            this.tempPracticeInfo[msgId] = {
                audio: URL.createObjectURL(file),
            };

            const answer = {
                practiceTestedId: this.testedId,
                questionId: msgId,
                answerOption: {
                    answerContent: '',
                    answerIds: [],
                    answerWords: [],
                    answerCompositions: [],
                },
                time: this.practiceTime,
            };

            let params = new FormData();
            params.append('answer', JSON.stringify(answer));
            params.append('upload', file);  // 將音檔傳至 Server 進行辨識

            this.$httpRequest.post('/api/practice/handin', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            const recognizeId = result.recognize_id;
                            this.tempRecognizeIds[recognizeId] = msgId;
                            if (this.tempPracticeInfo[msgId]) {
                                this.tempPracticeInfo[msgId].recognizeId = recognizeId;
                            }
                        }
                    }
                })
                .catch(error => {
                    this.audio.pause();
                    this.isPracticeState = false;
                    this.stopPracticingConversation();
                    this.restoreLastRecognizedInfo();
                    this.practicingMsgId = '';
                    this.tempPracticeInfo = {};
                    this.tempRecognizeIds = {};
                    this.tempRecognizedInfo = {};
                    console.error('Catched Error:', error);
                });
        },
        getRecognizedResult(data) {
            // 註冊連線成功
            if (data.body === 'success') {
                this.isConversationReady = true;
            }

            // 收到辨識結果
            if (data.ptq_id) {
                const recognizeId = data.ptq_id;
                const msgId = this.tempRecognizeIds[recognizeId];
                this.$delete(this.tempRecognizeIds, recognizeId);
                const recognizeMode = data.mode;

                if (!this.tempPracticeInfo[msgId]) {
                    return;
                }
                if (recognizeId !== this.tempPracticeInfo[msgId].recognizeId) {
                    return;
                }
                if (recognizeMode !== 'voice') {
                    return;
                }

                const result = data.result.voice;

                this.tempRecognizedInfo[msgId] = {
                    displayedText: getRecognizedDisplayedText(result.detail.text),
                    recognizedScore: result.score,
                };

                // 循環練習需所有對話句子皆辨識完成再顯示結果
                if ((this.practicingIndex >= 0) && (!this.isRecognizing || (Object.keys(this.tempRecognizedInfo).length < Object.keys(this.hiddenLabelMsgIds).length))) {
                    return;
                }

                this.showRecognizedResult();
            }
        },
        showRecognizedResult() {
            if (this.practicingIndex >= 0) {
                this.stopPracticingConversation();
            }

            for (const msgId of Object.keys(this.tempRecognizedInfo)) {
                this.$delete(this.hiddenLabelMsgIds, msgId);

                this.tempRecognizedInfo[msgId] = {
                    ...this.tempRecognizedInfo[msgId],
                    practiceAudio: this.tempPracticeInfo[msgId].audio,
                };

                if (this.isDemoState || (this.isPracticeState && this.practicingRole)) {
                    this.lastRecognizedInfo[msgId] = {...this.lastRecognizedInfo[msgId], ...this.tempRecognizedInfo[msgId]};
                } else {
                    this.conversation.msgInfos[msgId] = {...this.conversation.msgInfos[msgId], ...this.tempRecognizedInfo[msgId]};
                }

                this.$delete(this.tempPracticeInfo, msgId);
                this.$delete(this.tempRecognizedInfo, msgId);
            }

            // 尚有對話句子正在辨識中
            if (Object.keys(this.tempPracticeInfo).length) {
                return;
            }

            // 所有對話句子皆辨識完成
            this.isRecognizing = false;
            this.isShowNextBtn = true;
        },

        enterDemoState() {
            this.stopPlayingMessage();
            for (const msgId of this.conversation.msgIds) {
                this.saveLastRecognizedInfo(msgId);
            }
            this.isDemoState = true;
        },
        exitDemoState() {
            this.stopPlayingMessage();
            this.restoreLastRecognizedInfo();
            this.isRepeatPlaying = false;
            this.isDemoState = false;
        },
        playConversation() {
            this.stopPlayingMessage();

            if (this.isRepeatPlaying) {
                this.isRepeatPlaying = false;
                return;
            }
            this.isRepeatPlaying = true;

            let msgId = '';
            const _playMessage = () => {
                msgId = this.conversation.msgIds[this.playingIndex];
                const src = this.conversation.msgInfos[msgId].demoAudio;
                if (!src) {
                    return;
                }

                this.audio.src = src;
                this.playAudio();
            };

            this.audio.onplaying = () => {
                this.startBreathing();
                this.speakingMsgId = msgId;
                this.activeMsgId = msgId;
            };
            this.audio.onended = async () => {
                await new Promise(resolve => setTimeout(resolve, 200));  // 轉換停留
                if (!this.isDemoState || !this.isRepeatPlaying) return;
                this.stopBreathing();
                this.speakingMsgId = '';
                this.hiddenMsgIds.push(msgId);
                // 最後一句對話播畢後, 則再從第一句開始播放
                if (this.hiddenMsgIds.length === this.conversation.msgIds.length) {
                    this.hiddenMsgIds = [];
                    this.playingIndex = 0;
                } else {
                    this.playingIndex++;
                }
                _playMessage();
            };

            this.playingIndex = 0;
            _playMessage();
        },
        playSingleMessage(msgId, src) {
            if (!msgId || !src) {
                return;
            }

            this.stopPlayingMessage();

            this.audio.onplaying = () => {
                this.startBreathing();
                this.speakingMsgId = msgId;
            };
            this.audio.onended = () => {
                this.stopBreathing();
                this.speakingMsgId = '';
            };
            this.audio.src = src;
            this.playAudio();
        },
        stopPlayingMessage() {
            this.audio.pause();
            this.stopBreathing();
            this.speakingMsgId = '';
            this.activeMsgId = '';
            this.hiddenMsgIds = [];
        },

        async playAudio() {
            try {
                if (this.audio.paused) {
                    await this.audio.play();
                }
            } catch (error) {
                if (error.name === 'NotSupportedError') {
                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                }
                console.error('Catched Error:', error);
            }
        },
        startBreathing() {
            this.breathingRatio = 0.875;
            this.breathingTimerId = setInterval(this.getRandomRatio, 200);
        },
        stopBreathing() {
            clearInterval(this.breathingTimerId);
        },
        getRandomRatio() {
            const rand = (min, max) => Math.random() * (max - min) + min;
            this.breathingRatio = rand(0.875, 1);
        },

        showRecognizedScoreInfoDialogue() {
            $('#recognizedScoreInfoDialogue').modal('show');
        },
        changeQuestion() {
            this.audio.pause();
            this.websocket.close();

            const params = {
                practiceTestedId: this.testedId,
                questionIds: this.conversation.msgIds,
            };

            this.isPostingApi.practiceDone = true;

            this.$httpRequest.post('/api/practice/speek_question_done', params)
                .then(response => {
                    this.isPostingApi.practiceDone = false;

                    if (response.data.state == 'OK') {
                        this.$emit('changeQuestion');
                    }
                })
                .catch(error => {
                    this.isPostingApi.practiceDone = false;
                    console.error('Catched Error:', error);
                });
        },
    },
}
