import Paginate from 'vuejs-paginate'

// components
const GroupList = resolve => require(["@/components/user/portfolio/_group_list.vue"], resolve)

export default {
    props: {
        type: {
            required: true,
            type: String,
            validator: value => {
                return ['', 'course', 'mission', 'event'].includes(value)
            },
        },
        title: {
            type: String,
            default: '',
        },
        selectedArticleIds: {
            type: Array,
            default: () => [],
        },
        selectedMissionIds: {
            type: Array,
            default: () => [],
        },
        selectedEventId: {
            type: String,
            default: '',
        },
    },
    components: {
        Paginate,
        GroupList,
    },
    data: function() {
        return {
            mainItems: {},
            userList: [],

            perPage: 50,
            pageCount: 0,
            selectedPage: 0,

            selectedGroup: {
                id: '',
                name: ''
            },
            isRenderGroupList: false,

            isSetDataReady: {
                getResult: false,  // 取得歷程
            },
            isPostingApi: {
                downloadExcel: false,  // 下載報表
            },
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
    },
    created: function() {
        this.initialize();
    },
    methods: {
        async initialize() {
            if (!this.type) {
                this.isSetDataReady.getResult = true;
                return;
            }

            this.isSetDataReady.getResult = false;
            await this.getResult();
            this.isSetDataReady.getResult = true;
            this.$emit('loadedData');
        },
        getResult(pageNum = 1) {
            return new Promise((resolve, reject) => {
                this.selectedPage = pageNum;

                this.userList = [ { id: '-1' } ];

                let params = {
                    page: pageNum,
                    itemPerPage: this.perPage,
                };

                // 篩選班級
                if (this.selectedGroup.id) {
                    params.groupId = this.selectedGroup.id;
                }

                // 查詢類別的勾選項目
                let apiUrl = '';
                switch (this.type) {
                    case 'course': {
                        apiUrl = '/api/portfolio/get_learning_table';
                        params.articleIds = this.selectedArticleIds;
                        break;
                    }
                    case 'mission': {
                        apiUrl = '/api/portfolio/get_mission_table';
                        params.missionIds = this.selectedMissionIds;
                        break;
                    }
                    case 'event': {
                        apiUrl = '/api/portfolio/get_event_table';
                        params.eventIds = [this.selectedEventId];
                        break;
                    }
                }

                this.$httpRequest.post(apiUrl, params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                this.userList = [];

                                const userCount = +result.statistics.user_count;
                                this.pageCount = Math.ceil(userCount / this.perPage);

                                // 若選擇頁數超過頁數範圍時, 則切換至最後一頁
                                if (this.pageCount && pageNum > this.pageCount) {
                                    this.selectedPage = this.pageCount;
                                    this.getResult(this.selectedPage);
                                    return;
                                }

                                // 組出主要項目為文章時的資料
                                const _getArticleMainItems = (articles) => {
                                    for (const a of articles) {
                                        const aId = a.id;

                                        // 主項目資訊
                                        const mainId = `a-${aId}`;
                                        let mainInfo = {
                                            name: a.name,
                                            url: `/article/${aId}`,
                                            subItems: {},
                                        };

                                        // 子項目資訊
                                        let subId = '', subInfo = {};

                                        // 閱讀項目
                                        subId = `${aId}-r`;
                                        subInfo = {
                                            name: '文章閱讀',
                                            assignedCount: userCount,
                                            completeCount: +a.reads,
                                        };
                                        this.$set(mainInfo.subItems, subId, subInfo);

                                        // 口說練習項目
                                        for (const el of a.speaks) {
                                            subId = `${aId}-p-${el.id}`;
                                            subInfo = {
                                                name: el.name,
                                                assignedCount: userCount,
                                                completeCount: +el.tested,
                                            };
                                            this.$set(mainInfo.subItems, subId, subInfo);
                                        }

                                        // 一般練習項目
                                        for (const el of a.practices) {
                                            subId = `${aId}-p-${el.id}`;
                                            subInfo = {
                                                name: el.name,
                                                assignedCount: userCount,
                                                completeCount: +el.tested,
                                            };
                                            this.$set(mainInfo.subItems, subId, subInfo);
                                        }

                                        this.$set(this.mainItems, mainId, mainInfo);
                                    }
                                };

                                // 組出主要項目為文章時, 使用者的歷程資料
                                const _getUsersArticleMainItems = (uData, articles, isAssigned = true) => {
                                    for (const a of articles) {
                                        const aId = a.id;

                                        // 主項目資訊
                                        const mainId = `a-${aId}`;
                                        let mainInfo = {
                                            isAssigned: isAssigned,
                                            subItems: {},
                                            doneCount: 0,
                                        };

                                        // 子項目資訊
                                        let subId = '', subInfo = {};

                                        // 閱讀項目
                                        subId = `${aId}-r`;
                                        if (this.mainItems[mainId].subItems[subId]) {
                                            subInfo = {
                                                type: 'read',
                                                isDone: a.is_read,
                                            };
                                            this.$set(mainInfo.subItems, subId, subInfo);
                                            subInfo.isDone && mainInfo.doneCount++;
                                        }

                                        // 口說練習項目
                                        for (const el of a.speaks) {
                                            subId = `${aId}-p-${el.id}`;
                                            if (this.mainItems[mainId].subItems[subId]) {
                                                subInfo = {
                                                    type: 'speak',
                                                    isDone: el.score !== '',
                                                    score: +el.score,
                                                    historyUrl: `/article/${aId}/practice-history/${el.id}?student=${uData.id}`,
                                                };
                                                this.$set(mainInfo.subItems, subId, subInfo);
                                                subInfo.isDone && mainInfo.doneCount++;
                                            }
                                        }

                                        // 一般練習項目
                                        for (const el of a.practices) {
                                            subId = `${aId}-p-${el.id}`;
                                            if (this.mainItems[mainId].subItems[subId]) {
                                                subInfo = {
                                                    type: 'practice',
                                                    isDone: el.total !== '',
                                                    total: +el.total,
                                                    correct: +el.success,
                                                    historyUrl: `/article/${aId}/practice-history/${el.id}?student=${uData.id}`,
                                                };
                                                this.$set(mainInfo.subItems, subId, subInfo);
                                                subInfo.isDone && mainInfo.doneCount++;
                                            }
                                        }

                                        this.$set(uData.mainItems, mainId, mainInfo);
                                    }
                                };

                                // 查詢類別: 課程
                                if (this.type === 'course') {
                                    // 文章為主要項目(mainItems)
                                    _getArticleMainItems(result.statistics.articles);

                                    // 使用者歷程
                                    for (const u of result.students) {
                                        let uData = {
                                            id: u.id,
                                            name: u.name,
                                            mainItems: {},
                                            totalCount: +u.total,
                                            doneCount: +u.done,
                                            hasUnassignItem: false,
                                        };

                                        _getUsersArticleMainItems(uData, u.articles);

                                        this.userList.push(uData);
                                    }
                                }

                                // 查詢類別: 任務
                                if (this.type === 'mission') {
                                    // 任務為主要項目(mainItems)
                                    for (const m of result.statistics.missions) {
                                        const mId = m.id;

                                        // 主項目資訊
                                        const mainId = `m-${mId}`;
                                        let mainInfo = {
                                            name: m.name,
                                            url: `/user/mission?m=${mId}`,
                                            subItems: {},
                                        };

                                        // 子項目資訊
                                        let subId = '', subInfo = {};
                                        let assignedCount = +m.assign_count;

                                        for (const a of m.articles) {
                                            const aId = a.id;
                                            const namePostfix = `-${a.name} (${a.course_name})`;

                                            // 閱讀項目
                                            if (a.view_read) {
                                                subId = `${mId}-${aId}-r`;
                                                subInfo = {
                                                    name: `文章閱讀${namePostfix}`,
                                                    assignedCount: assignedCount,
                                                    completeCount: +a.reads,
                                                };
                                                this.$set(mainInfo.subItems, subId, subInfo);
                                            }

                                            // 口說練習項目
                                            for (const el of a.speaks) {
                                                subId = `${mId}-${aId}-p-${el.id}`;
                                                subInfo = {
                                                    name: `${el.name}${namePostfix}`,
                                                    assignedCount: assignedCount,
                                                    completeCount: +el.tested,
                                                };
                                                this.$set(mainInfo.subItems, subId, subInfo);
                                            }

                                            // 一般練習項目
                                            for (const el of a.practices) {
                                                subId = `${mId}-${aId}-p-${el.id}`;
                                                subInfo = {
                                                    name: `${el.name}${namePostfix}`,
                                                    assignedCount: assignedCount,
                                                    completeCount: +el.tested,
                                                };
                                                this.$set(mainInfo.subItems, subId, subInfo);
                                            }
                                        }

                                        this.$set(this.mainItems, mainId, mainInfo);
                                    }

                                    // 使用者歷程
                                    for (const u of result.students) {
                                        let uData = {
                                            id: u.id,
                                            name: u.name,
                                            mainItems: {},
                                            totalCount: +u.total,
                                            doneCount: +u.done,
                                            hasUnassignItem: false,
                                        };

                                        for (const m of u.missions) {
                                            const mId = m.id;
                                            const isAssigned = m.is_assigned;

                                            // 使用者有未指派的主項目
                                            if (!isAssigned) {
                                                uData.hasUnassignItem = true;
                                            }

                                            // 主項目資訊
                                            const mainId = `m-${mId}`;
                                            let mainInfo = {
                                                isAssigned: isAssigned,
                                                subItems: {},
                                                doneCount: 0,
                                            };

                                            // 子項目資訊
                                            let subId = '', subInfo = {};

                                            for (const a of m.articles) {
                                                const aId = a.id;

                                                // 閱讀項目
                                                subId = `${mId}-${aId}-r`;
                                                if (this.mainItems[mainId].subItems[subId]) {
                                                    subInfo = {
                                                        type: 'read',
                                                        isDone: a.is_read,
                                                    };
                                                    this.$set(mainInfo.subItems, subId, subInfo);
                                                    subInfo.isDone && mainInfo.doneCount++;
                                                }

                                                // 口說練習項目
                                                for (const el of a.speaks) {
                                                    subId = `${mId}-${aId}-p-${el.id}`;
                                                    if (this.mainItems[mainId].subItems[subId]) {
                                                        subInfo = {
                                                            type: 'speak',
                                                            isDone: el.score !== '',
                                                            score: +el.score,
                                                            historyUrl: `/article/${aId}/practice-history/${el.id}?student=${uData.id}`,
                                                        };
                                                        this.$set(mainInfo.subItems, subId, subInfo);
                                                        subInfo.isDone && mainInfo.doneCount++;
                                                    }
                                                }

                                                // 一般練習項目
                                                for (const el of a.practices) {
                                                    subId = `${mId}-${aId}-p-${el.id}`;
                                                    if (this.mainItems[mainId].subItems[subId]) {
                                                        subInfo = {
                                                            type: 'practice',
                                                            isDone: el.total !== '',
                                                            total: +el.total,
                                                            correct: +el.success,
                                                            historyUrl: `/article/${aId}/practice-history/${el.id}?student=${uData.id}`,
                                                        };
                                                        this.$set(mainInfo.subItems, subId, subInfo);
                                                        subInfo.isDone && mainInfo.doneCount++;
                                                    }
                                                }

                                                this.$set(uData.mainItems, mainId, mainInfo);
                                            }
                                        }

                                        this.userList.push(uData);
                                    }
                                }

                                // 查詢類別: 活動
                                if (this.type === 'event') {
                                    // 目前只開放查詢一個活動歷程
                                    for (const e of result.statistics.events) {
                                        // 文章為主要項目(mainItems)
                                        _getArticleMainItems(e.articles);
                                    }

                                    // 使用者歷程
                                    for (const u of result.students) {
                                        let uData = {
                                            id: u.id,
                                            name: u.name,
                                            mainItems: {},
                                            totalCount: +u.total,
                                            doneCount: +u.done,
                                            hasUnassignItem: false,
                                        };

                                        for (const e of u.events) {
                                            const isAssigned = e.is_assigned;

                                            // 使用者有未指派的主項目
                                            if (!isAssigned) {
                                                uData.hasUnassignItem = true;
                                            }

                                            _getUsersArticleMainItems(uData, e.articles, isAssigned);
                                        }

                                        this.userList.push(uData);
                                    }
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        filterGroup(res) {
            this.selectedGroup = res;
            this.getResult(1);
        },
        hideMainItem(mainId) {
            this.$delete(this.mainItems, mainId);

            for (const u of this.userList) {
                if (u.mainItems[mainId].isAssigned) {
                    u.totalCount -= Object.keys(u.mainItems[mainId].subItems).length;
                    u.doneCount -= u.mainItems[mainId].doneCount;
                }

                this.$delete(u.mainItems, mainId);
            }
        },
        downloadExcel() {
            let params = {};

            // 篩選班級
            if (this.selectedGroup.id) {
                params.groupId = this.selectedGroup.id;
            }

            // 查詢類別的勾選項目
            let apiUrl = '';
            switch (this.type) {
                case 'course': {
                    apiUrl = '/api/portfolio/get_learning_report';
                    params.articleIds = this.selectedArticleIds;
                    break;
                }
                case 'mission': {
                    apiUrl = '/api/portfolio/get_mission_report';
                    params.missionIds = this.selectedMissionIds;
                    break;
                }
                case 'event': {
                    apiUrl = '/api/portfolio/get_event_report';
                    params.eventIds = [this.selectedEventId];
                    break;
                }
            }

            this.isPostingApi.downloadExcel = true;

            this.$httpRequest.post(apiUrl, params)
                .then(async (response) => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            try {
                                await this.$util.downloadFile(result.file_url, result.file_name);
                                this.isPostingApi.downloadExcel = false;
                            } catch (error) {
                                this.isPostingApi.downloadExcel = false;
                                console.error('Catched Error:', error);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.downloadExcel = false;
                    console.error('Catched Error:', error);
                });
        },
    },
}
