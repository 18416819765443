import InfiniteScroll from "vue-infinite-scroll"
// directives
import ClickOutside from '@/assets/js/directives/click_outside'
// components
const PaymentDetailsList = resolve => require(["@/components/user/orders/_payment_details_list.vue"], resolve)

export default {
    props: ['activeTab', 'allSubscribeOrdersInfo', 'orderNoToCourseId', 'allPurchasedOrderNos'],
    directives: {
        InfiniteScroll,
        ClickOutside
    },
    components: {
        PaymentDetailsList
    },
    data: function() {
        return {
            orderList: [],
            expandingOrderItemIndex: null,  // 正在展開的訂單項目 index
            allPaymentOrderNos: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            isDetailsFirstLoaded: false  // 明細資料是否第一次載入完成
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isGiftOrder() {
            return this.$store.state.user.isGiftOrder;
        },
        expandingOrderItem() {  // 正在展開的訂單項目資訊
            if (this.$_.isNull(this.expandingOrderItemIndex)) {
                return {};
            }

            return this.orderList[this.expandingOrderItemIndex];
        }
    },
    created: function() {
        if ((this.activeTab === 'subscribe' && this.allSubscribeOrdersInfo.length) ||
            (this.activeTab === 'purchased' && this.allPurchasedOrderNos.length)) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                setTimeout(() => {
                    // 訂閱制
                    if (this.activeTab === 'subscribe') {
                        this.getSubscribeOrderList()
                            .then(() => this.busy = false)
                            .catch(error => console.log('catched error: ' + error));
                    }
                    // 課程制
                    if (this.activeTab === 'purchased') {
                        this.getPurchasedOrderList()
                            .then(() => this.busy = false)
                            .catch(error => console.log('catched error: ' + error));
                    }
                }, 200);
            }
        },
        getSubscribeOrderList() {
            return new Promise((resolve, reject) => {
                let orderNos = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempOrdersInfo = this.allSubscribeOrdersInfo[this.loadingIndex];
                    if (!tempOrdersInfo) {
                        this.isGetAllList = true;
                        break;
                    }
                    orderNos.push(tempOrdersInfo.orderNo);
                    this.loadingIndex++;
                }

                if (orderNos.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    orderNos: orderNos,
                    isGift: this.isGiftOrder ? '1' : '0'
                }

                this.$httpRequest.post('/api/order/get_subscribe_list_course_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let orderNo of orderNos) {
                                    let courseId = this.orderNoToCourseId[orderNo];
                                    let tempOrder = this.isGiftOrder ? result[orderNo] : result[courseId];
                                    if (tempOrder) {
                                        let orderInfo = {
                                            order_no: orderNo,
                                            order_date: tempOrder.order_date,
                                            is_subscribing: tempOrder.is_subscribing,
                                            is_purchased: tempOrder.is_purchased,
                                            exchange_list: tempOrder.exchange_list ? tempOrder.exchange_list : []
                                        }
                                        let courseList = [
                                            {
                                                course_id: courseId,
                                                course_alias: tempOrder.course_alias,
                                                name: tempOrder.name,
                                                cover_url: tempOrder.cover_url,
                                                course_price: tempOrder.course_price,
                                                price_name: tempOrder.price_name,
                                                unit: tempOrder.unit,
                                                price_per_unit: tempOrder.price_per_unit
                                            }
                                        ]
                                        this.orderList.push(this.setOrderItemInfo(orderInfo, courseList));
                                    }
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getPurchasedOrderList() {
            return new Promise((resolve, reject) => {
                let orderNos = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempOrderNo = this.allPurchasedOrderNos[this.loadingIndex];
                    if (!tempOrderNo) {
                        this.isGetAllList = true;
                        break;
                    }
                    orderNos.push(tempOrderNo);
                    this.loadingIndex++;
                }

                if (orderNos.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    orderNos: orderNos
                }

                this.$httpRequest.post('/api/order/get_buyout_list_order_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let orderNo of orderNos) {
                                    let tempOrder = result[orderNo];
                                    if (tempOrder) {
                                        tempOrder.order_info.order_no = orderNo;

                                        this.orderList.push(this.setOrderItemInfo(tempOrder.order_info, tempOrder.course_list));
                                    }
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        setOrderItemInfo(orderInfo, courseList) {
            let result = {};

            result = {
                orderDate: orderInfo.order_date,  // 訂購日期
                courses: [],  // 訂單課程
                originalTotalPrice: 0, // 原始總金額，算折扣使用
                exchangedCount: 0
            }

            let exchangeList = [];
            // 贈送訂單
            result.exchangedTitleList = '受贈者\n';
            if (orderInfo.exchange_list.length > 0) {
                let num = 1,
                    firstExchangedUser = '',
                    otherExchangedUsers = '';

                orderInfo.exchange_list.forEach( el => {
                    if (el.is_exchanged) {
                        result.exchangedCount += 1;
                        if (num == 1) {
                            firstExchangedUser = el;
                        }
                        else {
                            otherExchangedUsers += num + '. ' + el.user_id + ' ' + el.fullname + '\n';
                        }
                        num++;
                    }
                    exchangeList.push({
                        code: el.code,
                        isExchanged: el.is_exchanged
                    })
                });

                if (result.exchangedCount == 1) {
                    result.exchangedTitleList += firstExchangedUser.user_id + ' ' + firstExchangedUser.fullname;
                }
                if (result.exchangedCount > 1) {
                    result.exchangedTitleList += '1. ' + firstExchangedUser.user_id + ' ' + firstExchangedUser.fullname + '\n' + otherExchangedUsers;
                }
            }

            // 訂閱制
            if (this.activeTab === 'subscribe') {
                // 訂單資訊
                result.orderNo = orderInfo.order_no;

                // 訂閱狀態
                result.isSubscribing = orderInfo.is_subscribing;  // true: 訂閱中  false: 已終止

                // 付款狀態
                result.isPurchased = orderInfo.is_purchased;  // true: 付款成功  false: 付款失敗

                // 訂單課程
                for (let el of courseList) {
                    let tempUnit = 1,
                        tempPricePerUnit = el.course_price,
                        tempPlanName = '';

                    // 使用訂閱方案購買
                    if (el.price_name) {
                        tempUnit = el.unit;
                        tempPricePerUnit = el.price_per_unit;
                        tempPlanName = el.price_name.substring(0, 4);
                    }

                    result.originalTotalPrice += (+tempUnit * +tempPricePerUnit);

                    result.courses.push({
                        id: el.course_id,
                        alias: el.course_alias,
                        name: el.name,
                        cover: el.cover_url,
                        unit: +tempUnit,
                        pricePerUnit: +tempPricePerUnit,
                        planName: tempPlanName
                    });
                }
            }
            // 課程制
            if (this.activeTab === 'purchased') {
                // 訂單資訊
                result.orderNo = orderInfo.order_no;
                result.device = orderInfo.device;
                result.discount = +orderInfo.discount;
                result.paidAmount = +orderInfo.order_price;
                result.currency = orderInfo.currency;

                // 贈送訂單
                result.exchangeList = exchangeList;  // 兌換列表

                // 付款狀態
                result.isPurchased = orderInfo.is_purchased;  // true: 付款成功  false: 付款失敗
                result.isRefunded = orderInfo.is_refunded;  // true: 退款成功  false: 無退款

                // 訂單課程
                for (let el of courseList) {
                    let tempPlanName = '';

                    // 使用合購優惠購買
                    if (el.cbi_id !== '0') {
                        tempPlanName = '合購優惠';
                    }

                    result.originalTotalPrice += +el.buy_price;

                    result.courses.push({
                        id: el.course_id,
                        alias: el.course_alias,
                        name: el.name,
                        cover: el.cover_url,
                        price: +el.buy_price,
                        planName: tempPlanName
                    });
                }
            }

            return result;
        },
        expandDetailInfo(index, courseId, orderNo) {
            $('.lms-btn.plain-text').blur();

            this.isDetailsFirstLoaded = false;

            // 若為目前正在展開的項目, 則收合該區塊
            if (this.expandingOrderItemIndex === index) {
                this.expandingOrderItemIndex = null;
                return;
            }

            // 課程制 課程只需將卡片資訊帶入明細即可, 不需呼叫 API
            if (this.activeTab === 'purchased') {
                this.expandingOrderItemIndex = index;
                return;
            }

            // 以下為 訂閱制 課程才需處理
            let params = {}, apiUrl = '';

            // 贈送訂單 (透過原始 orderNo 取得明細中所有的 orderNos)
            if (this.isGiftOrder) {
                params.orderNo = orderNo;
                apiUrl = '/api/order/get_gift_subscribe_detail_order_nos';
            }
            // 自用訂單 (透過課程 id 取得明細中所有的 orderNos)
            else {
                params.courseId = courseId;
                apiUrl = '/api/order/get_course_order_nos';
            }

            this.$httpRequest.get(apiUrl, params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allPaymentOrderNos = result;
                            this.expandingOrderItemIndex = index;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        collapseDetailInfo(event) {
            // 解析度 768 以下 or 尚未展開任何項目, 不需做以下處理
            if (this.windowWidth < 768 || this.$_.isNull(this.expandingOrderItemIndex)) {
                return;
            }

            // 點擊下拉選單切換購買用途選項時, 不需收合訂單明細
            let purposeSelectDOM = document.getElementById('purchasingPurposeSelect');
            if (purposeSelectDOM && event.target === purposeSelectDOM) {
                return;
            }

            // 正在展開的訂單項目, 點擊項目上的資訊時不需收合訂單明細
            let expandingOrderItemDOM = document.getElementById(`orderItem${this.expandingOrderItemIndex}`);
            if (expandingOrderItemDOM && expandingOrderItemDOM.contains(event.target)) {
                return;
            }

            this.expandingOrderItemIndex = null;
        }
    }
}
