import { render, staticRenderFns } from "./_record_controller.vue?vue&type=template&id=e630252c&scoped=true&"
import script from "@/assets/js/components/article/practicing/speak/_record_controller.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/practicing/speak/_record_controller.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/article/practicing/speak/_record_controller.scss?vue&type=style&index=0&id=e630252c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e630252c",
  null
  
)

export default component.exports