import { render, staticRenderFns } from "./_words_rearranging.vue?vue&type=template&id=124b1ac7&"
import script from "@/assets/js/components/article/practicing/body/_words_rearranging.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/practicing/body/_words_rearranging.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports