import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allMarkedWordIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            markedWordList: [],
            currentWordIdsLength: this.allMarkedWordIds.length,

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            isPostingApi: {
                updateWordIsImportant: false,  // 標記/取消標記單字
            }
        }
    },
    watch: {
        '$parent.isRemovedMarkedWord'(newValue) {
            if (newValue) {
                this.removeItemFromList();
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        activeSearchedWord: {
            get() {
                return this.$store.state.article.activeSearchedWord;
            },
            set(value) {
                this.$store.commit('article/setActiveSearchedWord', value);
            }
        },
        tempWordInfoList() {
            return this.$store.state.article.tempWordInfoList;
        }
    },
    created: function() {
        if (this.allMarkedWordIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getMarkedWordList()
                    .then(() => this.busy = false)
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getMarkedWordList() {
            // 進入畫面需取得第一個單字內容
            let getFirstActiveWord = () => {
                if (!this.$parent.isSetDataReady.getFirstActiveWord) {
                    this.activeSearchedWord = this.markedWordList[0];
                    this.$parent.isSetDataReady.getFirstActiveWord = true;
                    this.$parent.renderDictResultKey++;
                }
            }

            return new Promise((resolve, reject) => {
                let wordIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempMarkedWordId = this.allMarkedWordIds[this.loadingIndex];
                    if (!tempMarkedWordId) {
                        this.isGetAllList = true;
                        break;
                    }
                    wordIds.push(tempMarkedWordId);
                    this.loadingIndex++;
                }

                if (wordIds.length == 0) {
                    resolve();
                    return;
                }

                if (this.$_.isEmpty(this.userInfo)) {
                    wordIds.forEach(id => {
                        this.markedWordList.push(this.tempWordInfoList[id]);
                    });
                    getFirstActiveWord();

                    resolve();
                    return;
                }

                let params = {
                    wordIds: wordIds
                }

                this.$httpRequest.post('/api/article/get_marked_words_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    wordIds.forEach(id => {
                                        let tempWord = result[id];
                                        if (tempWord && !this.$_.isNull(tempWord.dictionary)) {
                                            this.markedWordList.push({
                                                id: tempWord.id,
                                                word: tempWord.word,
                                                isImportant: tempWord.is_important === '1',
                                                isMarked: true,
                                                dictionary: {
                                                    lexicalCategory: this.articleUtil.setLexicalCategoryAbbrev(tempWord.dictionary.lexical_category),
                                                    translation: tempWord.dictionary.translation
                                                }
                                            });
                                        }
                                    });
                                    getFirstActiveWord();
                                }

                                resolve();
                            } else {
                                reject("article/favorites/_word_list.js: 'getMarkedWordList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("article/favorites/_word_list.js: 'getMarkedWordList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("article/favorites/_word_list.js: 'getMarkedWordList' get error");
                    });
            });
        },
        changeActiveWord(item) {
            this.activeSearchedWord = item;
            this.$parent.renderDictResultKey++;
        },
        async updateWordIsImportant(item) {
            if (this.isPostingApi.updateWordIsImportant) {
                return;
            }

            let payload = {
                wordItem: item
            }

            this.isPostingApi.updateWordIsImportant = true;
            await this.$store.dispatch('article/updateWordIsImportant', payload);
            this.isPostingApi.updateWordIsImportant = false;
        },
        removeItemFromList() {
            // 移除單字列表中, 被取消收藏的單字項目, 並將下一個單字設為 active 狀態
            let currentIndex = this.$_.findIndex(this.markedWordList, el => {
                return el.id === this.activeSearchedWord.id;
            });

            this.markedWordList = this.$_.remove(this.markedWordList, el => {
                return el.id !== this.activeSearchedWord.id;
            });
            this.currentWordIdsLength--;

            // 如果取消收藏所有單字, 則不再執行以下邏輯
            if (!this.currentWordIdsLength) {
                this.$parent.isRemoveAllWords = true;
                return;
            }

            let isLastItem = currentIndex + 1 > this.currentWordIdsLength;
            let newActiveIndex = isLastItem ? 0 : currentIndex;

            if (this.markedWordList[newActiveIndex]) {
                this.activeSearchedWord = this.markedWordList[newActiveIndex];
            }
            // 如果沒有下一 index 的項目, 並且非列表的最後一個項目, 則表示需先做載入更多
            else {
                // 先載入以下20個項目, 再 assign activeSearchedWord
                this.busy = true;
                this.getMarkedWordList()
                    .then(() => {
                        this.busy = false;
                        this.activeSearchedWord = this.markedWordList[newActiveIndex];
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            // 如果刪除列表最後一個項目, 將列表卷軸移到最上方
            if (isLastItem) {
                this.$nextTick(() => {
                    $('.favorite-word-list').animate({
                        scrollTop: 0
                    }, 500);
                });
            }

            this.$parent.renderDictResultKey++;
        }
    }
}
