export const writingTypes = {
    '1': '議論文',
    '2': '說明文',
    '3': '記敘/描述文',
    '4': '應用文',
};

export const writingStructures = {
    '1': [  // 議論文
        {
            name: '引言/串場',
            description: '吸引讀者注意或快速引導進入討論的重點，通常包括或鋪陳議題的背景資訊。',
        },
        {
            name: '主旨',
            description: '<span class="text-weight-medium">當每段落屬不同屬文體時，建議一段落一主旨，且若段落只有一個論點，可代替論點主題句。</span>此主旨應概括"所有"論點後的整體立場或中心觀點，讓指導讀者快速理解核心資訊。',
        },
        {
            name: '論點主題句',
            description: '通常在 "某" 論點的開頭，概括"此"論點的重點，讓讀者預測接下來要議論的內容。若此文體有多個論點，每個應搭配一個論點主題句簡述，確保支持或呼應全文/段落主旨。',
        },
        {
            name: '論點展開',
            description: '承 "此" 論點主題句，提供證據或例子(如數據/研究、專家觀點、個人經驗或事實性資料等)。若全文有若干論點，每個論點應有對應的論點展開，以加強論點的說服力。',
        },
        {
            name: '論點結語',
            description: '承"此"論點展開。若論點展開內容較複雜，建議給一個"小"總結，有助於讀者判斷此論點描述已結束。',
        },
        {
            name: '全文/段落結論',
            description: '<span class="text-weight-medium">當每段落不同文體時，建議一段落一結論，且若段落只有一個論點，可代替論點結語。</span>此結論常重新概括/強調全文或此段的立場之重要性、提出建議、呼籲行動或展望未來發展等，以加深讀者對主題的理解和反思。',
        },
    ],
    '2': [  // 說明文
        {
            name: '引言/串場',
            description: '吸引讀者注意，介紹即將說明的內容。',
        },
        {
            name: '主旨',
            description: '<span class="text-weight-medium">當每段落屬不同屬文體時，建議一段落一主旨，且若段落只有一個主題，可代替主題說明句。</span>此主旨應概括 "所有" 要說明的主題概念(如人、事、時、地、物等)，讓指導讀者快速理解整體核心資訊。',
        },
        {
            name: '主題說明句',
            description: '通常在 "某" 說明的開頭，讓讀者預測"此"主題要的重點。若此文體有多個要說明的主題，每個主題應搭配一個說明主題句，以支持或呼應全文/段落主旨。',
        },
        {
            name: '說明展開',
            description: '承 "此" 主題說明句，提供詳細的解釋、資訊、例子或證據，以便讓讀者更好地理解和掌握所描述的內容。',
        },
        {
            name: '主題結語',
            description: '承"此"說明展開。若說明展開內容較複雜，建議給一個 "小" 總結，有助讀者判斷此主題已說明結束。',
        },
        {
            name: '全文/段落結論',
            description: '<span class="text-weight-medium">當每段落不同文體時，建議一段落一結論，且若段落只有一個主題，可代替主題結語。</span>此結論常總結全文或此段的 "所有" 說明內容、重申主旨句、提出結論或彙總要點。',
        },
    ],
    '3': [  // 記敘/描述文
        {
            name: '引言/串場',
            description: '吸引讀者注意，介紹即將敘述的內容。',
        },
        {
            name: '全文概述',
            description: '<span class="text-weight-medium">當每段落屬不同屬文體時，建議一段落一主旨，且若段落只有一個場景，可代替場景概述句。</span>此主旨應概括 "所有" 場景的整體印象(人、事、時、地、物等)，讓指導讀者快速理解整體核心資訊。',
        },
        {
            name: '場景概述句',
            description: '通常在 "某" 場景的開頭，讓讀者理解 "此" 場景的重點(類似於議論文中的論點主題句，但更強調敘述的內容和情節)。若有多個場景，每個場景應有對應的概述，以支持或呼應全文/段落概述。',
        },
        {
            name: '場景展開',
            description: '承 "此" 場景概述句，以時間或空間順序加以描述 "此" 場景之細節，旨在讀者可清晰、生動地想像此場景之內容或畫面。',
        },
        {
            name: '場景結語',
            description: '承 "此" 場景展開。若場景展開內容校複雜時，一個 "小" 總結陳述或思考(類似於議論文中的論點結論句)，有助讀者判斷此場景已描述結束。',
        },
        {
            name: '全文/段落結論',
            description: '<span class="text-weight-medium">當每段落不同文體時，建議一段落一結論，且若段落只有一個場景，可代替場景結語。</span>為了呼應概述，此結論常全文或此段的 "所有" 場景，給讀者一個完整的回顧(可能包括對事件的反思、作者的感受或畫面統整)。',
        },
    ],
    '4': [  // 應用文
        {
            name: '全文主旨',
            description: '簡單陳述此文件的目的(例如email的主旨)，讓讀者知道溝通的核心資訊。',
        },
        {
            name: '背景介紹',
            description: '在某些情況下，你可能需要提供與主旨相關的背景信息，以便讀者更好地理解問題或解決方案的背景。',
        },
        {
            name: '問題與目的',
            description: '清楚地描述此文所要解決的問題或提供的信息。',
        },
        {
            name: '提議或方案',
            description: '對於問題或目的提出具體的解決方案或建議，說明應該如何解決問題或實現目標。',
        },
        {
            name: '操作/示例',
            description: '若牽涉關於操作性的說明，建議描述操作步驟、相關的示例或案例，以更好地理解和應用所述內容，讓讀者按照指示進行相應關操作。',
        },
        {
            name: '全文結論',
            description: '為了給讀者留下一個良好的印象，通常是結束語句、強調感謝或期待未來合作的態度。',
        },
    ],
};

export const feedbackModes = {
    'sentence': {
        modeName: '逐句批改v1',
        tabName: 'AI逐句批改',
    },
    'summary': {
        modeName: '學測歷屆總評v1',
        tabName: 'AI總評批改',
    },
    'optimize': {
        modeName: '優化文章+改寫說明v1',
        tabName: 'AI原文優化',
    },
};
