import '@/global/markdown/md_preview.css'

// common
const ImageLoader = resolve => require(["@/components/common/image_loader.vue"], resolve)

export default {
    /**
     * 題組分類-解析度 992 以上
     *
     * @param  number  questionContentType  題幹內容的組成類型
     * 題幹組成一個或多個成分時, 距離上方 20px 後開始顯示內容
     * 當只有一個音檔元素時, 音檔icon元件需有題目區塊高度 50% 的外框, icon元件需上下左右置中於外框內
     *
     * @param  number  childQuestionContentType  子題內容的組成類型
     * 子題組成若含有圖片, 則文字在上, 圖片在下
     *
     * @param  number  choiceOptionContentTypeInfo  各選項內容的組成資訊
     * 選項組成若含有圖片, 則文字在上, 圖片在下
     */
    props: [
        'activeQuestion',
        'activeChildQuestion',
        'isLastQuestion',
        'questionContentType',
        'childQuestionContentType',
        'choiceOptionContentTypeInfo',
        'currentQuestionScrollTop',
        'isShowHandinBtn',
        'isPostingApi',
        'handInResult',
        'isShowHandInFeedback',
        'isCommentCardExpanded'
    ],
    components: {
        ImageLoader
    },
    computed: {
        practiceQuestionInfo() {
            return this.$store.state.article.practiceQuestionInfo;
        },
    },
    mounted: function() {
        this.$nextTick(() => $('.practicing-question .question-content').scrollTop(this.currentQuestionScrollTop));
    }
}
