import { render, staticRenderFns } from "./gift_orders.vue?vue&type=template&id=7a8480f6&"
import script from "@/assets/js/components/order/gift_orders.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/order/gift_orders.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports