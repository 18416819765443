// common
const OrderItemList = resolve => require(["@/components/order/checkout/_order_item_list.vue"], resolve)
const CourseCard = resolve => require(["@/components/common/card/course.vue"], resolve)

export default {
    props: ['ordersPageTab', 'interestedCourseList', 'exchangeCode'],
    components: {
        OrderItemList,
        CourseCard
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isAppWebview() {
            return this.$store.state.common.isAndroidWebview || this.$store.state.common.isiOSWebview;
        },
        appDownload() {
            return this.$store.state.common.appDownload;
        },
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        isGiftOrder() {
            return this.$store.state.order.isGiftOrder;
        }
    },
    methods: {
        copyExchangeCode(index) {
            let responseToast = this.orderUtil.copyExchangeCode(index);
            this.$store.dispatch('common/setAlert', responseToast);
        }
    }
}
