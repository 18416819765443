import Paginate from 'vuejs-paginate'

export default {
    props: ['isCertificateStart', 'individual', 'frontendInfos'],
    components: {
        Paginate
    },
    data: function() {
        return {
            countyOptions: ['全國'],

            selectedOption: {
                county: '全國'
            },

            chartsList: [],
            myTeamIds: [],  // 老師
            teamsInfo: {},
            expandedTIds: {},
            personalResults: [],  // 學生

            perPage: 20,
            pageCount: 0,
            totalCount: 0,
            selectedPage: 1,

            isSetDataReady: {
                getCountyOptions: false,  // 取得臺灣縣市選項列表
                initializeCharts: false,  // 篩選條件下的排行榜
                getChartsList: false,  // 取得排行榜
            },
            isPostingApi: {
                downloadReport: false,  // 下載報表
            }
        }
    },
    watch: {
        userInfo(newValue) {
            if (this.$_.isEmpty(newValue)) {
                // 未登入使用者需初始個人報名團隊或得分資訊
                this.myTeamIds = [];
                this.teamsInfo = {};
                this.expandedTIds = {};
                this.personalResults = [];
            } else {
                this.initializeCharts();
            }
        },
        selectedOptStr() {
            this.initializeCharts();
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        countyDistrict() {
            return this.$store.state.common.countyDistrict;
        },
        selectedOptStr() {
            return Object.values(this.selectedOption).join('|');
        },
    },
    created: function() {
        this.getCountyOptions();
        this.initializeCharts();
    },
    methods: {
        async getCountyOptions() {
            await this.$store.dispatch('common/getCountyDistrict');
            let tempCounties = this.frontendInfos.counties.length > 0 ? this.frontendInfos.counties : this.$_.cloneDeep(this.countyDistrict.counties);
            this.countyOptions = this.countyOptions.concat(tempCounties);
            this.isSetDataReady.getCountyOptions = true;
        },

        async initializeCharts() {
            this.selectedPage = 1;
            this.isSetDataReady.initializeCharts = false;
            await this.getChartsPages();
            await this.getChartsList();
            this.isSetDataReady.initializeCharts = true;
        },
        getChartsPages() {
            return new Promise((resolve, reject) => {
                let params = {
                    alias: this.$route.params.alias,
                    isGroup: 1,
                    itemPerPage: this.perPage
                }

                // 篩選縣市
                if (this.selectedOption.county !== '全國') {
                    params.county = this.selectedOption.county
                }

                this.$httpRequest.get('/api/event/get_leaderboard_pages', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            this.pageCount = result.total_page;
                            this.totalCount = result.total_count;

                            resolve();
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getChartsList() {
            return new Promise((resolve, reject) => {
                // 換頁時置頂卷軸, 方便使用者查看排行榜
                if (this.isSetDataReady.initializeCharts) {
                    window.scroll(0, 0);
                }

                this.isSetDataReady.getChartsList = false;
                this.chartsList = [];

                let params = {
                    alias: this.$route.params.alias,
                    page: this.selectedPage,
                    itemPerPage: this.perPage
                }

                // 篩選縣市
                if (this.selectedOption.county !== '全國') {
                    params.county = this.selectedOption.county
                }

                this.$httpRequest.get('/api/event/get_group_rank', params)
                    .then(async (response) => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                // 若選擇頁數無結果時, 則需重新取得總頁數, 再取最後一頁列表資料
                                if (this.pageCount && !result.pages.length) {
                                    await this.getChartsPages();
                                    this.selectedPage = this.pageCount;
                                    this.getChartsList(this.selectedPage);
                                    return;
                                }

                                // 排行榜資訊
                                for (let el of result.pages) {
                                    this.chartsList.push({
                                        rank: el.rank,
                                        name: el.name,
                                        score: el.score,
                                        county: el.county,
                                        school: el.school,
                                        memberCount: el.people,
                                        groupId: el.group_id,
                                        isCertificate: el.is_certificate
                                    });
                                }

                                // 老師: 各隊伍排名資訊
                                if (!this.myTeamIds.length) {
                                    for (let el of result.teams) {
                                        let tId = el.event_group_id;
                                        this.myTeamIds.push(tId);

                                        let tInfo = {
                                            rank: el.rank,
                                            name: el.name,
                                            score: el.score,
                                            county: el.county,
                                            school: el.school,
                                            memberCount: el.people
                                        }
                                        this.$set(this.teamsInfo, tId, tInfo);
                                    }
                                }

                                // 學生: 隊伍貢獻資訊
                                if (!this.personalResults.length) {
                                    for (let el of result.personal) {
                                        this.personalResults.push({
                                            rank: el.rank,
                                            name: el.name,
                                            score: el.score,
                                            memberCount: el.total_students,
                                            personalRank: el.personal_rank,
                                            personalScore: el.personal_score
                                        });
                                    }
                                }

                                this.isSetDataReady.getChartsList = true;

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        expandTeam(tId) {
            if (!this.teamsInfo[tId]) {
                return;
            }

            // 收合
            if (this.expandedTIds[tId]) {
                this.$delete(this.expandedTIds, tId);
                return;
            }

            this.$set(this.expandedTIds, tId, 1);

            // 已讀取過資料
            if (this.teamsInfo[tId].members) {
                return;
            }

            let params = {
                egId: tId
            }

            this.$httpRequest.get('/api/event/get_event_group_member_scores', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let members = [];
                            for (let el of result) {
                                members.push({
                                    rank: el.rank,
                                    name: el.name,
                                    score: el.score
                                });
                            }

                            this.$set(this.teamsInfo[tId], 'members', members);
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        downloadReport() {
            let params = {
                alias: this.$route.params.alias
            }

            this.isPostingApi.downloadReport = true;

            this.$httpRequest.post('/api/event/get_group_report', params)
                .then(async (response) => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            try {
                                await this.$util.downloadFile(result.url, result.name);
                                this.isPostingApi.downloadReport = false;
                            } catch (error) {
                                this.isPostingApi.downloadReport = false;
                                console.error('Catched Error:', error);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.downloadReport = false;
                    console.error('Catched Error:', error);
                });
        },
        downloadCertificate(team) {
            window.location.href = process.env.VUE_APP_ROOT_API +
            '/api/event/team_certificate?eventId=' + this.$route.params.alias +
            '&groupId=' + team.groupId;
        }
    }
}
